var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "personexisting" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      !(_vm.currentUserCanConfigureSettings || _vm.isPersonalProfile)
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("users.no-permission-to-edit")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    class: [
                                      "d-sm-flex",
                                      "pl-3",
                                      { "d-none": !_vm.isPersonalProfile },
                                      {
                                        "breadcrumb-processing-opacity":
                                          _vm.processing
                                      }
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  !_vm.isPersonalProfile
                                    ? _c("fd-back-button", {
                                        attrs: {
                                          route: function(item) {
                                            return _vm.$router.push(
                                              _vm.$store.getters.backBreadcrumb
                                                .to || "/people"
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _vm.userHasLoaded && _vm.user.isActiveWithUnusedLogin
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-3 mb-0 mx-3",
                              attrs: {
                                color: "warning",
                                transition: "scale-transition"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { align: "center", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "shrink pr-2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { color: "inherit" } },
                                        [_vm._v("fas fa-snooze")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "grow pr-1" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "users.user-not-activated-message"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "shrink" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: _vm.resendNewAccountEmail
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "users.user-activation-resend"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "mt-3",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: {
                                "data-cy": "details",
                                href: "#tab-" + _vm.detailsTab.key
                              },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.details")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.emailTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.emailTab.key,
                                  attrs: {
                                    "data-cy": "email",
                                    href: "#tab-" + _vm.emailTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.emailTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.email-shorthand")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-at")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mobileTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.mobileTab.key,
                                  attrs: {
                                    "data-cy": "phone",
                                    href: "#tab-" + _vm.mobileTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.mobileTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.mobile")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-mobile-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPersonalProfile &&
                          (_vm.projectsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.projectsTab.key,
                                  attrs: {
                                    href: "#tab-" + _vm.projectsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.projectsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("projects.sub-menu-title")
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-project-diagram")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPersonalProfile &&
                          (_vm.contractorsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.contractorsTab.key,
                                  attrs: {
                                    href: "#tab-" + _vm.contractorsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.contractorsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "people.existing-person.tabs.contractor-selection"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-people-arrows")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showDisciplines &&
                          !_vm.isPersonalProfile &&
                          (_vm.disciplinesTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.disciplinesTab.key,
                                  attrs: {
                                    href: "#tab-" + _vm.disciplinesTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.disciplinesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("disciplines.menu-title")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-user-hard-hat")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showAreas &&
                          !_vm.isPersonalProfile &&
                          (_vm.areasTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.areasTab.key,
                                  attrs: { href: "#tab-" + _vm.areasTab.key },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.areasTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("projects.areas.sub-menu-title")
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-map-marked-alt")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPersonalProfile &&
                          (_vm.securityTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.securityTab.key,
                                  attrs: {
                                    "data-cy": "security",
                                    href: "#tab-" + _vm.securityTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.securityTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.security")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-shield-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.attachmentsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.attachmentsTab.key,
                                  attrs: {
                                    "data-cy": "attachments",
                                    href: "#tab-" + _vm.attachmentsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.attachmentsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.attachments")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-paperclip")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.userValues.tags,
                                              "selected-items": _vm.user.tags,
                                              "item-label": "name",
                                              disabled:
                                                _vm.isPersonalProfile ||
                                                _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.user,
                                                  "tags",
                                                  $event
                                                )
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.user,
                                                  "tags",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-3",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "avatar-cropper-container",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12",
                                            md: "6",
                                            lg: "4"
                                          }
                                        },
                                        [
                                          _c(
                                            "slim-cropper",
                                            {
                                              staticClass: "profile-image",
                                              attrs: {
                                                image: _vm.userPhoto,
                                                disabled: _vm.processing,
                                                label: _vm.$t(
                                                  "common.drop-avatar-image-here"
                                                )
                                              },
                                              on: {
                                                "update:image": function(
                                                  $event
                                                ) {
                                                  _vm.userPhoto = $event
                                                }
                                              }
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "file",
                                                  name: "slim"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 mt-0 mb-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6",
                                            lg: "8"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-3 pb-1 pb-lg-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "common.firstname"
                                                      ),
                                                      disabled:
                                                        _vm.user.legacyID > 0 ||
                                                        _vm.processing,
                                                      rules:
                                                        _vm.userRules.firstName,
                                                      readonly:
                                                        _vm.user.legacyID > 0
                                                    },
                                                    model: {
                                                      value: _vm.user.firstName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.firstName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "common.lastname"
                                                      ),
                                                      disabled:
                                                        _vm.user.legacyID > 0 ||
                                                        _vm.processing,
                                                      rules:
                                                        _vm.userRules.lastName,
                                                      readonly:
                                                        _vm.user.legacyID > 0
                                                    },
                                                    model: {
                                                      value: _vm.user.lastName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.lastName"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 pt-md-3",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "pt-0 pb-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.userValues
                                                          .languageID,
                                                      label: _vm.$t(
                                                        "languages.preferred-language-select"
                                                      ),
                                                      disabled: _vm.processing,
                                                      clearable: "",
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "languages.preferred-language-inline-info"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.languageID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "languageID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.languageID"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "users.account-identifier"
                                                      ),
                                                      outlined: "",
                                                      "persistent-hint": "",
                                                      disabled: "",
                                                      readonly: true,
                                                      hint: _vm.$t(
                                                        "users.account-identifier-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user
                                                          .personalIdentifier,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "personalIdentifier",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.personalIdentifier"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-divider", {
                                            staticClass: "mt-3 mb-3"
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "users.employee-code"
                                                      ),
                                                      disabled:
                                                        _vm.user.legacyID > 0 ||
                                                        _vm.processing,
                                                      readonly:
                                                        _vm.user.legacyID > 0
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.employeeCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "employeeCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.employeeCode"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "data-cy": "archive",
                                              label: _vm.$t("common.archived"),
                                              disabled:
                                                _vm.isPersonalProfile ||
                                                _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.isArchived,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isArchived",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isArchived"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.emailTab.key,
                              attrs: { value: "tab-" + _vm.emailTab.key }
                            },
                            [
                              _c("fd-user-email-address-new", {
                                attrs: {
                                  show: _vm.newEmailAddressDialogVisible,
                                  personID: _vm.userID
                                },
                                on: {
                                  cancel: _vm.cancelNewEmailAddressDialog,
                                  emailAdded: _vm.emailAddressAdded
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                "data-cy": "addemail",
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click:
                                                  _vm.openNewEmailAddressDialog
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "users.user-email-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.emailAddressTableSearch,
                                              callback: function($$v) {
                                                _vm.emailAddressTableSearch = $$v
                                              },
                                              expression:
                                                "emailAddressTableSearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:emailAddress",
                                            value: _vm.$t("common.address"),
                                            expression: "$t('common.address')",
                                            arg: "emailAddress"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:verified.hide-when-extra-small",
                                            value: _vm.$t("common.verified"),
                                            expression: "$t('common.verified')",
                                            arg: "verified",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:allowNotifications.hide-when-extra-small",
                                            value: _vm.$t("common.notify"),
                                            expression: "$t('common.notify')",
                                            arg: "allowNotifications",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/emailaddress/" + item.id
                                              )
                                            },
                                            expression:
                                              "\n                      item => $router.push(`/emailaddress/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass: "fd-enabling-table",
                                        attrs: {
                                          items: _vm.user.emailAddresses,
                                          search: _vm.emailAddressTableSearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["address"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.allowNotifications",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.allowNotifications
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.allowNotifications
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.verified",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.isVerified
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !!item.isVerified
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "teal",
                                                          "text-color": "white"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/emailaddress/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deletePersonalEmailAddress(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.mobileTab.key,
                              attrs: { value: "tab-" + _vm.mobileTab.key }
                            },
                            [
                              _c("fd-user-phone-number-new", {
                                attrs: {
                                  show: _vm.newPhoneNumberDialogVisible,
                                  personID: _vm.userID
                                },
                                on: {
                                  cancel: _vm.cancelNewPhoneNumberDialog,
                                  phoneNumberAdded: _vm.phoneNumberAdded
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click:
                                                  _vm.openNewPhoneNumberDialog
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "users.user-mobile-number-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.phoneNumberTableSearch,
                                              callback: function($$v) {
                                                _vm.phoneNumberTableSearch = $$v
                                              },
                                              expression:
                                                "phoneNumberTableSearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:phoneNumber",
                                            value: _vm.$t("common.number"),
                                            expression: "$t('common.number')",
                                            arg: "phoneNumber"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:verified.hide-when-extra-small",
                                            value: _vm.$t("common.verified"),
                                            expression: "$t('common.verified')",
                                            arg: "verified",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:allowNotifications.hide-when-extra-small",
                                            value: _vm.$t("common.notify"),
                                            expression: "$t('common.notify')",
                                            arg: "allowNotifications",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/phonenumber/" + item.id
                                              )
                                            },
                                            expression:
                                              "\n                      item => $router.push(`/phonenumber/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass: "fd-enabling-table",
                                        attrs: {
                                          items: _vm.user.phoneNumbers,
                                          search: _vm.phoneNumberTableSearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["phoneNumber"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.allowNotifications",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.allowNotifications
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.allowNotifications
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.verified",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.isVerified
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !!item.isVerified
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "teal",
                                                          "text-color": "white"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/phonenumber/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deletePersonalPhoneNumber(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.projectsTab.key,
                              attrs: { value: "tab-" + _vm.projectsTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { background: "#EAEAEA" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: _vm.projectUsageType,
                                                    callback: function($$v) {
                                                      _vm.projectUsageType = $$v
                                                    },
                                                    expression:
                                                      "projectUsageType"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "projects.projects-selection-type-all-projects"
                                                      ),
                                                      value: "entire"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "projects.projects-selection-type-individual-projects"
                                                      ),
                                                      value: "selection"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.projectUsageType ==
                                                "selection",
                                              expression:
                                                "projectUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "projects.only-included-projects-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedProjects,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedProjects = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedProjects"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "projects.projects-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: "Search",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectsTableSearch,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.projectsTableSearch = $$v
                                                          },
                                                          expression:
                                                            "projectsTableSearch"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "projectsDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(50vh - 100px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.projects,
                                              search: _vm.projectsTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedProjectsSelected,
                                                        indeterminate:
                                                          _vm.someSearchedProjectsSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedProjectselected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipProjectSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.contractorsTab.key,
                              attrs: { value: "tab-" + _vm.contractorsTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-start fd-inline-radio-button-qualifier fd-additional-config-control-callout mx-0 px-2 pt-5 pb-3"
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "12", md: "6" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "fd-contractors-tab-control-padding",
                                                    attrs: {
                                                      outlined: "",
                                                      disabled:
                                                        _vm.user.legacyID > 0 ||
                                                        _vm.isPersonalProfile ||
                                                        _vm.processing,
                                                      rules:
                                                        _vm.userRules
                                                          .contractorID,
                                                      label: _vm.$t(
                                                        "people.existing-person.contractors.home-contractor-select"
                                                      ),
                                                      items:
                                                        _vm.contractorsForPerson,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      hint: _vm.$t(
                                                        "people.existing-person.contractors.home-contractor-select-hint"
                                                      ),
                                                      "persistent-hint": "",
                                                      dense:
                                                        _vm.$vuetify.breakpoint
                                                          .xsOnly
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.contractorID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "contractorID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.contractorID"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "fd-contractors-tab-extra-info d-none d-sm-flex",
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "0px",
                                                        left: "0px"
                                                      }
                                                    },
                                                    [_vm._v("fas fa-home")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.user.legacyID > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "my-0 pb-0 pt-1 px-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      staticClass: "mb-0",
                                                      attrs: { type: "info" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "people.existing-person.data-set-by-legacy-system"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fd-radio-button-left-position-container"
                                        },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: { row: "" },
                                              model: {
                                                value: _vm.contractorUsageType,
                                                callback: function($$v) {
                                                  _vm.contractorUsageType = $$v
                                                },
                                                expression:
                                                  "contractorUsageType"
                                              }
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.existing-person.contractors.selection-type-all"
                                                  ),
                                                  value: "entire"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.existing-person.contractors.selection-type-individual"
                                                  ),
                                                  value: "selection"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.contractorUsageType ==
                                                "selection",
                                              expression:
                                                "contractorUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-contractor",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "people.existing-person.contractors.only-included-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedContractors,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedContractors = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedContractors"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "people.existing-person.contractors.selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "pa-0 mr-3",
                                                            staticStyle: {
                                                              "margin-top":
                                                                "-3px"
                                                            },
                                                            attrs: {
                                                              "append-icon":
                                                                "search",
                                                              label: "Search",
                                                              "single-line": "",
                                                              "hide-details":
                                                                "",
                                                              clearable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contractorsTableSearch,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.contractorsTableSearch = $$v
                                                              },
                                                              expression:
                                                                "contractorsTableSearch"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:icon.no-sort.class_fd-user-inactive-cell",
                                                value: "",
                                                expression: "''",
                                                arg: "icon",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "class_fd-user-inactive-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "contractorsDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(50vh - 100px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.contractors,
                                              search:
                                                _vm.contractorsTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.icon",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        class: {
                                                                          "d-none": !(
                                                                            item.id ==
                                                                            _vm
                                                                              .user
                                                                              .contractorID
                                                                          )
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-home"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "people.existing-person.contractors.home-contractor"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allVisibleContractorsSelected,
                                                        indeterminate:
                                                          _vm.someVisibleContractorsSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedContractorselected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("fd-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled:
                                                          item.id ==
                                                            _vm.user
                                                              .contractorID ||
                                                          _vm.processing,
                                                        readonly:
                                                          item.id ==
                                                          _vm.user.contractorID,
                                                        onTextKey:
                                                          "common.default"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipContractorSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showDisciplines
                            ? _c(
                                "v-tab-item",
                                {
                                  key: _vm.disciplinesTab.key,
                                  attrs: {
                                    value: "tab-" + _vm.disciplinesTab.key
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticStyle: {
                                                "margin-top": "15px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ml-1 mr-1 fd-inner-table-fab-button",
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                    fab: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                  attrs: {
                                                                    color:
                                                                      "black",
                                                                    dark: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [_vm._v("info")]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    92516267
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "users.user-disciplines-listing-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.disciplinesTableSearch,
                                                  callback: function($$v) {
                                                    _vm.disciplinesTableSearch = $$v
                                                  },
                                                  expression:
                                                    "disciplinesTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              }
                                            ],
                                            attrs: {
                                              items: _vm.personDisciplines,
                                              search:
                                                _vm.disciplinesTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["address"],
                                              "mobile-breakpoint": "0"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showAreas
                            ? _c(
                                "v-tab-item",
                                {
                                  key: _vm.areasTab.key,
                                  attrs: { value: "tab-" + _vm.areasTab.key }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticStyle: {
                                                "margin-top": "15px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ml-1 mr-1 fd-inner-table-fab-button",
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                    fab: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                  attrs: {
                                                                    color:
                                                                      "black",
                                                                    dark: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [_vm._v("info")]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    92516267
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "people.existing-person.areas-selection-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.areasTableSearch,
                                                  callback: function($$v) {
                                                    _vm.areasTableSearch = $$v
                                                  },
                                                  expression: "areasTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !_vm.filterAreas ||
                                          _vm.filterAreas.length == 0
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    { attrs: { type: "info" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "people.existing-person.areas.no-areas-selected"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                arg: "actions",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-actions-cell": true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "fd-actions-table fd-expanding-table",
                                            attrs: {
                                              items: _vm.filterAreas,
                                              search: _vm.areasTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["address"],
                                              "mobile-breakpoint": "0",
                                              "show-expand": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "expanded-item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 4 }
                                                        },
                                                        [
                                                          !!item.subAreas &&
                                                          item.subAreas.length >
                                                            0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fd-expanding-table-single-details-section pt-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-subheader",
                                                                    {
                                                                      staticClass:
                                                                        "pl-0 py-1",
                                                                      staticStyle: {
                                                                        height:
                                                                          "auto"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "SubAreas"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    item.subAreas,
                                                                    function(
                                                                      subarea
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key:
                                                                            subarea.id,
                                                                          staticClass:
                                                                            "py-1 pb-2",
                                                                          staticStyle: {
                                                                            width:
                                                                              "100%"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                subarea.name
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pt-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-alert",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "info"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "people.existing-person.areas.no-sub-areas-selected"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.actions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                link:
                                                                                  "",
                                                                                disabled:
                                                                                  true ||
                                                                                  _vm.processing,
                                                                                to:
                                                                                  "/area/" +
                                                                                  item.id
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                mdi-pencil\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.edit"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                disabled: true
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.deletePersonalEmailAddress(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                mdi-delete\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.remove"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2118582175
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.securityTab.key,
                              attrs: { value: "tab-" + _vm.securityTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "securityform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-start fd-additional-config-control-callout ml-0 mr-0 pt-5 pb-5"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-security-tab-extra-info d-none d-sm-flex"
                                            },
                                            [_vm._v("fas fa-user-shield")]
                                          ),
                                          _vm._v(" "),
                                          _c("v-switch", {
                                            staticClass:
                                              "fd-security-tab-switch-placement",
                                            attrs: {
                                              "data-cy": "cansignin",
                                              label: _vm.$t(
                                                "users.can-sign-in-label"
                                              ),
                                              disabled:
                                                _vm.user.isArchived ||
                                                !_vm.userHasContactMethod ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.can-sign-in-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isLoginActive,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isLoginActive",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isLoginActive"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.user.isArchived ||
                                          !_vm.userHasContactMethod
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _vm.user.isArchived
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          staticClass: "mb-0",
                                                          attrs: {
                                                            type: "info"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "people.existing-person.unarchive-to-allow-login"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : !_vm.userHasContactMethod
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          staticClass: "mb-0",
                                                          attrs: {
                                                            type: "info"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "people.existing-person.add-contact-method-to-allow-login"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-2 ml-5" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.security.force-readonly-access"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.security.force-readonly-access-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .forceReadonlyAccess,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "forceReadonlyAccess",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.forceReadonlyAccess"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "users.user-security-import-work-orders"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.user
                                                      .forceReadonlyAccess ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.user-security-import-work-orders-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canImportWorkOrders,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canImportWorkOrders",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canImportWorkOrders"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "users.user-security-submit-scaffold-requests"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.user
                                                      .forceReadonlyAccess ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.user-security-submit-scaffold-requests-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canSubmitScaffoldRequests,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canSubmitScaffoldRequests",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canSubmitScaffoldRequests"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "order-1 order-sm-2 order-md-3 order-lg-4",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "users.user-security-approve-scaffold-requests"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.user.isPlanner ||
                                                    _vm.user
                                                      .forceReadonlyAccess ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.user-security-approve-scaffold-requests-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canApproveScaffoldRequests,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canApproveScaffoldRequests",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canApproveScaffoldRequests"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "order-2 order-sm-1",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "users.user-security-manage-to-do-lists"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.user.isPlanner ||
                                                    _vm.user
                                                      .forceReadonlyAccess ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.user-security-manage-to-do-lists-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canEditWorkOrderPlannedWorkDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canEditWorkOrderPlannedWorkDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canEditWorkOrderPlannedWorkDate"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "order-3 order-md-2",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "users.user-security-configure-settings"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.user-security-configure-settings-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canConfigureSettings,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canConfigureSettings",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canConfigureSettings"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", {
                                            staticClass:
                                              "d-none d-lg-flex order-lg-3",
                                            attrs: {
                                              cols: "0",
                                              sm: "0",
                                              md: "0",
                                              lg: "3"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-2 ml-5" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-4 mb-0 pb-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-subheader",
                                                { staticClass: "px-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("common.role")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "mx-2 ml-5 fd-inline-radio-button-qualifier"
                                        },
                                        [
                                          _vm.user.isLoginActive &&
                                          _vm.user.legacyID > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "my-0 pb-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("fd-alert", {
                                                    attrs: {
                                                      type: "info",
                                                      label: _vm.$t(
                                                        "people.existing-person.data-set-by-legacy-system"
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.user.isLoginActive &&
                                          _vm.user.forceReadonlyAccess
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "my-0 pb-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("fd-alert", {
                                                    attrs: {
                                                      type: "warning",
                                                      label: _vm.$t(
                                                        "people.security.read-only-access-role-warning"
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.security.is-foreman"
                                                  ),
                                                  disabled:
                                                    _vm.user.legacyID > 0 ||
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.security.is-foreman-hint"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.user.isForeman,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "isForeman",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.isForeman"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.security.is-general-foreman"
                                                  ),
                                                  disabled:
                                                    _vm.user.legacyID > 0 ||
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.security.is-general-foreman-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user.isGeneralForeman,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "isGeneralForeman",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.isGeneralForeman"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.security.is-coordinator"
                                                  ),
                                                  disabled:
                                                    _vm.user.legacyID > 0 ||
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.security.is-coordinator-hint"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.user.isCoordinator,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "isCoordinator",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.isCoordinator"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.security.is-planner"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.security.is-planner-hint"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.user.isPlanner,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "isPlanner",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.isPlanner"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { value: "tab-" + _vm.attachmentsTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "attachmentsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("input", {
                                                    ref: "addFileButton",
                                                    staticClass: "d-none",
                                                    attrs: { type: "file" },
                                                    on: {
                                                      change: function(v) {
                                                        return _vm.selectNewFile(
                                                          v.target.files[0]
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "add-fab-button-primary white--text",
                                                                    attrs: {
                                                                      fab: "",
                                                                      elevation:
                                                                        "5",
                                                                      depressed:
                                                                        _vm.disabled ||
                                                                        _vm.processing,
                                                                      disabled:
                                                                        _vm.disabled ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openNewExternalLinkDialog
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "attachments.external-link.menu-title"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.selectFile
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.local-file"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "users.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg: "icon",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                    arg: "action",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "show-when-extra-small": true,
                                                      "class_fd-actions-cell-edit-only": true
                                                    }
                                                  }
                                                ],
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "fd-action-table"
                                                  : "fd-actions-table",
                                                attrs: {
                                                  items: _vm.attachments,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "mobile-breakpoint": "0"
                                                },
                                                on: {
                                                  "dblclick:row": function(
                                                    e,
                                                    data
                                                  ) {
                                                    return _vm.openAttachment(
                                                      data.item
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.icon",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        item.type == "link"
                                                          ? _c("v-icon", [
                                                              _vm._v("fa-link")
                                                            ])
                                                          : item.type ==
                                                              "file" &&
                                                            item.isPreviewable
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                "fa-camera"
                                                              )
                                                            ])
                                                          : _c("v-icon", [
                                                              _vm._v("fa-file")
                                                            ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.action",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.canOpenInNew ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openAttachment(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              {
                                                                "table-5-actions-column-min-width":
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .mdAndUp,
                                                                "table-4-actions-column-min-width": !_vm
                                                                  .$vuetify
                                                                  .breakpoint
                                                                  .mdAndUp
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.canOpenInNew ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-open-in-new\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.open-in-new-tab"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewFile(
                                                                                        item.file
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgAndUp ||
                                                                        _vm.processing,
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      link:
                                                                                        "",
                                                                                      disabled:
                                                                                        !item.file ||
                                                                                        _vm.processing
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.downloadFile(
                                                                                            item.file
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    mdi-cloud-download\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.download"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.isPersonalProfile ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.remove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              !_vm.isPersonalProfile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: "",
                                        small: _vm.$vuetify.breakpoint.xsOnly
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.delete")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              !_vm.isPersonalProfile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        link: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-cy": "save",
                                    color: "primary",
                                    loading: _vm.saving,
                                    disabled:
                                      _vm.processing ||
                                      !(
                                        _vm.currentUserCanConfigureSettings ||
                                        _vm.isPersonalProfile
                                      )
                                  },
                                  on: { click: _vm.save }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.save")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }