import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import fileHandling, { confirmUniqueName, FileData } from "@fd/lib/vue/mixins/fileHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import store from "../../store";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import userAccess from "../../dataMixins/userAccess";
import scaffoldRequest, {
  WorkPackageWithNameCode,
  ParseScaffoldRequestWithExtraDetails
} from "../../dataMixins/scaffoldRequest";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "./ActiveWorkForScaffoldDialog.vue";

type Keyword = Tag;

import {
  LegacyRequestType,
  LegacyArea,
  LegacySubArea,
  LegacyContractorWithChildIDs,
  LegacyEmployee,
  LegacyWorkOrderWithRequestDetails,
  LegacyDisciplineWithEmployeeIDs,
  LegacyScaffoldSearchResult,
  Tag,
  LegacyContractor,
  LegacyRequestSubType,
  workOrderService,
  ScaffoldRequestTypes,
  environmentService
} from "../../services";

import {
  scaffoldRequestService,
  workPackageService,
  legacyReferenceDataService,
  legacyScaffoldService
} from "../../services";
import { WorkForScaffoldDetailsFromScaffoldRequest } from "../../dataMixins/scaffoldRequestList";
import {
  ParseWorkOrderWithLegacyDetails,
  WorkForScaffoldDetailsFromWorkOrder
} from "../../dataMixins/workOrderList";

const ScaffoldRequestNewDialog = FDVue.extend({
  name: "fd-legacy-scaffold-request-new-dialog",

  mixins: [dialogSupport, errorHandling, userAccess, fileHandling, scaffoldRequest],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-date-picker": () => import("@fd/lib/vue/components/DatePicker.vue"),
    "fd-text-field": () => import("@fd/lib/vue/components/TextField.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-work-package-selector": () => import("./WorkPackageSelectionDialog.vue")
  },

  data: function() {
    return {
      sheet: false,
      parts: null,
      tablesearchparts: null,

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined,

      /*** GLOBAL ****/
      step: 1,
      lastStep: 4,

      basicStep: 1,
      notesStep: 3,
      optionsStep: 4,
      filesStep: 2,

      // Form data errors
      basicStepError: false,
      notesStepError: false,
      optionsStepError: false,
      filesStepError: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      submitting: false,

      /*** DATA ***/
      // Reference
      requestTypes: [] as LegacyRequestType[],
      allRequestSubTypes: [] as LegacyRequestSubType[],
      requestingContractors: [] as LegacyContractorWithChildIDs[],
      allRequestors: [] as LegacyEmployee[],
      allDisciplines: [] as LegacyDisciplineWithEmployeeIDs[],
      allAreas: [] as LegacyArea[],
      allSubAreas: [] as LegacySubArea[],

      /*** TAG NUMBER ***/
      availableScaffolds: [] as LegacyScaffoldSearchResult[],
      selectedScaffold: null as LegacyScaffoldSearchResult | null,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // Main entity
      legacyRequest: {
        hasDayShift: false as boolean | undefined,
        hasNightShift: false as boolean | undefined,
        hasEnergizedEquipment: false as boolean | undefined,
        requiresConfinedSpacePermit: false as boolean | undefined,
        requiresLockoutProcedure: false as boolean | undefined,
        workPackageIDs: null as Array<string> | undefined | null,
        siteID: 1,
        applyToScaffoldID: null as number | null,
        requestStatusID: 1 as number | null,
        requestTypeID: null as number | null,
        requestingContractorID: null as number | null,
        disciplineID: null as number | null,
        requestorID: null as number | null,
        areaID: null as number | null,
        subAreaID: null as number | null,
        startDate: null as number | null,
        requiredDate: null as number | null,
        requiredUntilDate: null as number | null,
        location: "" as string | null,
        description: "" as string | null,
        notes: "" as string | null,
        siteContact: "" as string | null,
        status: true
      } as LegacyWorkOrderWithRequestDetails,

      // *** FILES ***
      tablesearchfiles: "",
      files: [] as FileData[]
    };
  },

  computed: {
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    requestSubTypes(): LegacyRequestSubType[] {
      let legacyRequestTypeID = this.legacyRequest.requestTypeID;
      if (!legacyRequestTypeID) return [];

      var requestSubTypes = this.allRequestSubTypes.filter(x => {
        return x.requisitionTypeID == legacyRequestTypeID;
      });

      if (!this.legacyRequest.requestSubTypeID && requestSubTypes.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.requestSubTypeID = requestSubTypes[0].id;
        });
      }

      return requestSubTypes;
    },
    areas(): LegacyArea[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allAreas.filter(x => {
        return !!selectedContractor?.areaIDs?.length && selectedContractor!.areaIDs.includes(x.id!);
      });
      if (!this.legacyRequest.areaID && areas.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): LegacySubArea[] {
      let areaID = this.legacyRequest.areaID;
      if (!areaID) return [];

      var subAreas = this.allSubAreas.filter(x => {
        return x.areaID == areaID;
      });

      if (!this.legacyRequest.subAreaID && subAreas.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.subAreaID = subAreas[0].id;
        });
      }

      return subAreas;
    },
    disciplines(): LegacyDisciplineWithEmployeeIDs[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplines = this.allDisciplines.filter(x => {
        return (
          !!selectedContractor?.disciplineIDs?.length &&
          selectedContractor!.disciplineIDs.includes(x.id!)
        );
      });

      if (!this.legacyRequest.disciplineID) {
        let curUserDiscipline = disciplines.find(
          d =>
            !!this.allRequestors.find(
              r =>
                r.id == this.curUserAccess.legacyID &&
                !!d.employeeIDs?.length &&
                d.employeeIDs.includes(r.id!)
            )
        );
        if (!!curUserDiscipline) {
          this.legacyRequest.disciplineID = curUserDiscipline.id;
        } else {
          if (disciplines.length == 1) {
            this.$nextTick(() => {
              this.legacyRequest.disciplineID = disciplines[0].id;
            });
          }
        }
      }

      return disciplines;
    },
    selectedRequestingContractorName(): string | undefined {
      return this.requestingContractors.find(x => x.id == this.legacyRequest.requestingContractorID)
        ?.name;
    },
    requestors(): LegacyEmployee[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplineID = this.legacyRequest.disciplineID;
      if (!disciplineID) return [];

      let selectedDiscipline = this.disciplines.find(x => x.id == disciplineID);
      if (!selectedDiscipline) return [];

      let requestors = this.allRequestors.filter(x => {
        return (
          !!selectedContractor?.employeeIDs?.length &&
          selectedContractor!.employeeIDs.includes(x.id!) &&
          !!selectedDiscipline?.employeeIDs?.length &&
          selectedDiscipline!.employeeIDs.includes(x.id!)
        );
      });

      if (!this.legacyRequest.requestorID) {
        let curUserRequestor = requestors.find(r => r.id == this.curUserAccess.legacyID);
        if (!!curUserRequestor) {
          this.legacyRequest.requestorID = curUserRequestor.id;
        } else {
          if (requestors.length == 1) {
            this.$nextTick(() => {
              this.legacyRequest.requestorID = requestors[0].id;
            });
          }
        }
      }

      return requestors;
    }
  },

  methods: {
    async openWorkDetailsForScaffoldDialog(): Promise<boolean> {
      this.processing = true;
      var allRequests = (
        await scaffoldRequestService.getSubmittedRequestsForScaffoldTag(
          `${this.legacyRequest.applyToScaffoldID}`
        )
      ).map(x => ParseScaffoldRequestWithExtraDetails(x));
      var convertedRequests = allRequests.map(x => WorkForScaffoldDetailsFromScaffoldRequest(x));

      var allWorkOrders = (
        await workOrderService.getActiveWorkOrdersForScaffoldTag(
          null,
          `${this.legacyRequest.applyToScaffoldID}`
        )
      ).map(x => ParseWorkOrderWithLegacyDetails(x));
      let convertedWorkOrders = allWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));

      var allScaffoldWork: WorkForScaffoldDetails[] = convertedRequests.concat(convertedWorkOrders);
      let dismantleScaffoldWork = allScaffoldWork.filter(
        x => x.requestType == ScaffoldRequestTypes.Dismantle
      );

      let result = true;
      if (dismantleScaffoldWork.length > 0) {
        console.log("WO's Scaffold Has Other WOs.  Show Popup!");
        result = await openActiveWorkForScaffoldDialog(
          this.legacyRequest.applyToScaffoldID,
          allScaffoldWork,
          undefined,
          false,
          true,
          true
        );
      }
      this.processing = false;
      return result;
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDraft();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.basicStepError = !(this.$refs.basicform as HTMLFormElement).validate();
      this.notesStepError = !(this.$refs.notesform as HTMLFormElement).validate();
      this.optionsStepError = !(this.$refs.optionsform as HTMLFormElement).validate();
      this.filesStepError = !(this.$refs.filesform as HTMLFormElement).validate();
      return !(
        this.basicStepError ||
        this.notesStepError ||
        this.optionsStepError ||
        this.filesStepError
      );
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async submitRequest() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.submitting = true;
      try {
        await this.updateIsDraft(false);
        let submitted = await this.saveData(true);

        if (submitted) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.new-scaffold-request.save-success"),
            type: "success",
            undoCallback: null
          };
          store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },

    //Method used in conjunction with new view dialog.
    async saveDraft() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        await this.updateIsDraft(true);
        if (!(await this.saveData(false))) {
          this.processing = false;
          this.saving = false;
          return;
        }

        var snackbarPayload = {
          text: this.$t("scaffold-requests.new-scaffold-request.save-success"),
          type: "success",
          undoCallback: null
        };
        store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveData(isBeingSubmitted: boolean): Promise<boolean> {
      if (!this.validate()) {
        var message = this.$t("scaffold-requests.new-scaffold-request.error-message");
        if (this.basicStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new-scaffold-request.steps.details");
        if (this.notesStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new-scaffold-request.steps.notes");
        if (this.optionsStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new-scaffold-request.steps.options");
        if (this.filesStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new-scaffold-request.steps.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }
      // Save web request
      // The following are numeric values entered as strings due to being text field entry.
      if (this.legacyRequest.applyToScaffoldID)
        this.legacyRequest.applyToScaffoldID = +this.legacyRequest.applyToScaffoldID;

      this.legacyRequest.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      this.legacyRequest.requestTypeName = this.requestTypes.find(
        x => x.id == this.legacyRequest.requestTypeID
      )?.name;

      this.legacyRequest.requestingContractorName = this.selectedRequestingContractorName;
      this.legacyRequest.disciplineName = this.disciplines.find(
        x => x.id == this.legacyRequest.disciplineID
      )?.name;

      let selectedRequestor = this.requestors.find(x => x.id == this.legacyRequest.requestorID);
      this.legacyRequest.requestingEmployeeFirstName = selectedRequestor?.firstName;
      this.legacyRequest.requestingEmployeeLastName = selectedRequestor?.lastName;

      this.legacyRequest.areaName = this.areas.find(x => x.id == this.legacyRequest.areaID)?.name;
      this.legacyRequest.subAreaName = this.subAreas.find(
        x => x.id == this.legacyRequest.subAreaID
      )?.name;

      // Get the list of selected IWP IDs
      this.legacyRequest.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);

      if (!!this.selectedScaffold) {
        this.legacyRequest.applyToScaffoldID = this.selectedScaffold.id;
      } else {
        this.legacyRequest.applyToScaffoldID = undefined;
      }

      var newRequestID = await scaffoldRequestService.addScaffoldRequestByLegacyWorkOrder(
        this.legacyRequest,
        isBeingSubmitted
      );

      if (this.files.length) {
        for (let index = 0; index < this.files.length; index++) {
          const file = this.files[index];
          await scaffoldRequestService.uploadScaffoldRequestFile(
            newRequestID,
            file.name,
            file.file as Blob
          );
          await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
            newRequestID,
            file.name,
            file.file as Blob
          );
        }
      }
      return true;
    },

    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      this.requestTypes = await legacyReferenceDataService.getAllRequestTypes();
    },

    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      this.allRequestSubTypes = await legacyReferenceDataService.getAllRequestSubTypes();
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      this.allAreas = await legacyReferenceDataService.getVisibleAreas();
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      this.allSubAreas = await legacyReferenceDataService.getVisibleSubAreas();
    },

    // DOES NOT manage processing or error message logic
    async loadRequestingContractors(): Promise<void> {
      this.requestingContractors = await (
        await legacyReferenceDataService.getScaffoldRequestingContractors()
      ).sort((a: LegacyContractor, b: LegacyContractor) => {
        let desc: Boolean = false;
        let val1 = a.name!;
        let val2 = b.name!;
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      this.allDisciplines = await legacyReferenceDataService.getAllDisciplines();
    },

    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await legacyReferenceDataService.getAllRequestors();
      this.allRequestors = requestors.map(x => {
        return {
          ...x,
          name: `${x.firstName} ${x.lastName}`
        };
      });
    },

    // *** DETAILS ***
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await legacyScaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: this.getScaffoldDescription(x),
            details: this.getScaffoldDetails(x)
          } as LegacyScaffoldSearchResult;
        });
      }
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    // *** FILES ***
    async selectNewFile(originalFile: any) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.files);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.files.push(fileData);
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      console.log(`handleEdit fileName: ${fileName}`);

      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;

        this.files.push(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.files.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.editingFileData = undefined;
      }
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.files.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.files.splice(fileIndex, 1);
    }
  },

  watch: {
    "inlineMessage.message": function(newValue, oldValue) {
      // TODO: For some reason the validate is setting the message, but then errorHandling is nulling it out right away
      console.log(`inlineMessage.message changed from ${oldValue} to ${newValue}`);
    },
    search(this: any, newValue: string) {
      this.hasLoaded = false;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      if (!newValue?.length) {
        this.searchItems = [];
        return;
      }

      var obj = this;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.loading = true;
        try {
          obj.loadWorkPackages(newValue);
        } finally {
          obj.timer = null;
          obj.loading = false;
          obj.hasLoaded = true;
        }
      }, 1500);
    },
    selectedScaffold: async function(newValue) {
      if (!newValue) {
        this.legacyRequest.applyToScaffoldID = undefined;
        this.legacyRequest.areaID = undefined;
        this.legacyRequest.subAreaID = undefined;
        this.legacyRequest.location = undefined;
        this.dataSetByScaffold = false;
      } else {
        let scaffold = newValue;
        this.legacyRequest.applyToScaffoldID = scaffold.id;
        var proceed = await this.openWorkDetailsForScaffoldDialog();
        if (!proceed) {
          this.legacyRequest.applyToScaffoldID = undefined;
          let self = this;
          this.$nextTick(() => {
            self.selectedScaffold = null;
          });
          return;
        }
        this.legacyRequest.areaID = scaffold.projectID;
        this.legacyRequest.subAreaID = scaffold.areaID;
        this.legacyRequest.location = scaffold.location;
        this.dataSetByScaffold = true;
      }
    },
    "legacyRequest.requestTypeID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;
    },
    "legacyRequest.areaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.legacyRequest.subAreaID)
      ) {
        this.legacyRequest.subAreaID = null;
      }
    },
    "legacyRequest.requestingContractorID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.disciplineID &&
        !this.disciplines.map(x => x.id).includes(this.legacyRequest.disciplineID)
      ) {
        this.legacyRequest.disciplineID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.requestorID &&
        !this.requestors.map(x => x.id).includes(this.legacyRequest.requestorID)
      ) {
        this.legacyRequest.requestorID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.areaID &&
        !this.areas.map(x => x.id).includes(this.legacyRequest.areaID)
      ) {
        this.legacyRequest.areaID = null;
      }
      if (
        !!this.legacyRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.legacyRequest.subAreaID)
      ) {
        this.legacyRequest.subAreaID = null;
      }
    },
    "legacyRequest.disciplineID": function(newValue, oldValue) {
      console.log(`disciplineID changed from ${oldValue} to ${newValue}`);
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.requestorID &&
        !this.requestors.map(x => x.id).includes(this.legacyRequest.requestorID)
      ) {
        this.legacyRequest.requestorID = null;
      }
    }
  },

  created: async function() {
    this.processing = true;

    try {
      await Promise.all([
        this.loadRequestingContractors(),
        this.loadDisciplines(),
        this.loadRequestors(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.$store.dispatch("LOAD_TAGS"),
        this.loadRequestTypes(),
        this.loadRequestSubTypes()
      ]);

      let environment = await environmentService.getCurrent();
      if (!!environment) {
        this.legacyRequest.isWalkdownRequired = environment.defaultScaffoldRequestRequiresWalkDown;
      }
      this.legacyRequest.requestingContractorID = this.curUserAccess.homeContractorLegacyID;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

export default ScaffoldRequestNewDialog;

export async function createNewScaffoldRequest(options?: {}): Promise<boolean> {
  let dialog = createDialog(ScaffoldRequestNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

