import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { VForm } from "@fd/lib/vue/types";
import { mapMutations, mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

import { filterBySuppliers, filterByTags, getTagsInUse } from "../services/taggableItems";
import {
  Tag,
  SupplierWithTags,
  PartWithTags,
  Owner,
  Region,
  ProjectLocation,
  ProjectWithParts
} from "../services";

type ProjectWithExtraDetails = ProjectWithParts & { archived: boolean };
type PartWithTagsAndSelected = PartWithTags & { selected: boolean };

export default FDVue.extend({
  name: "fd-project-existing",

  mixins: [errorHandling, tabbedView, rules],

  components: {
    "fd-area-new-dialog": () => import("./components/AreaNewDialog.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data() {
    return {
      showNewDialog: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      archivedLoading: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      partsTab: {
        tabname: "Parts",
        key: "1",
        visible: false
      } as Tab,
      areasTab: {
        tabname: "Areas",
        key: "2",
        visible: false
      } as Tab,

      selectableParts: [] as Array<PartWithTagsAndSelected>,
      partsCatalogUsageType: "" as "entire" | "selection",

      lastSelectedPartIDs: [] as string[],

      // These objects are used for the view specific "Tags" filtering.
      tagsSelectedForFiltering: [] as Tag[],

      // These objects are used for the view specific "Suppliers" filtering.
      suppliersSelectedForFiltering: [] as string[],

      showOnlyIncluded: true,

      selectedsupplier: null,

      tablesearchparts: "",

      project: {
        name: "",
        description: "",
        productivity: null as number | null,
        labourRate: null as number | null,
        ownerID: "",
        regionID: "",
        archived: false
      } as ProjectWithExtraDetails
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.partsTab, this.areasTab] as Tab[];
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.loadProjectWithAreas({
            projectID: this.$route.params.projectID,
            forcedArchivedState: this.showArchived,
            archivedFromDate: this.showArchivedFromDate,
            archivedToDate: this.showArchivedToDate
          });
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.loadProjectWithAreas({
              projectID: this.$route.params.projectID,
              forcedArchivedState: this.showArchived,
              archivedFromDate: this.showArchivedFromDate,
              archivedToDate: this.showArchivedToDate
            });
          } catch (error) {
            this.handleError((error as Error) as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    storeProject(): ProjectWithParts {
      return this.$store.state.projects.fullList.find(
        (x: ProjectWithParts) => x.id == this.$route.params.projectID
      );
    },

    // This individual Project record will have an Owner record associated to it, therefore we will need to
    // get the list of all enabled Owners and include the currently selected one whether or not that Owner
    // record is currently enabled.
    owners(): Owner[] {
      return this.$store.getters.getSortedEnabledInUseOwners(this.project.ownerID);
    },

    // This individual Project record will have an Region record associated to it, therefore we will need to
    // get the list of all enabled Regions and include the currently selected one whether or not that Region
    // record is currently enabled.
    regions(): Region[] {
      return this.$store.getters.getSortedEnabledInUseRegions(this.project.regionID);
    },

    // Get all the areas that are associated to the current Project object.
    areas(): {}[] {
      return this.$store.state.projectLocations
        .filter((x: ProjectLocation) => x.projectID == this.$route.params.projectID)
        .map((x: ProjectLocation) => {
          return {
            ...x,
            archived: !!x.archivedDate
          };
        });
    },

    // ** THIS needs to be updated once the actual list of previously selected parts for a project is defined.
    // CURRENTLY it is just using the list of all enabled parts for mockup purposes.
    parts(): Array<PartWithTagsAndSelected> {
      let returnValue = filterBySuppliers<PartWithTags & { selected: boolean }>(
        this.suppliersSelectedForFiltering,
        filterByTags(this.tagsSelectedForFiltering, this.selectableParts)
      );
      if (this.showOnlyIncluded) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedPartIDs(): string[] {
      return this.selectableParts.filter(x => x.selected).map(x => x.id!);
    },

    searchedParts(): Array<PartWithTagsAndSelected> {
      // This is a hack because the parts list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .partsDataTable as any)?.customFilter;
      if (this.tablesearchparts && customFilter) {
        return this.parts.filter(
          x =>
            customFilter(x.name!, this.tablesearchparts, x) ||
            customFilter(x.description!, this.tablesearchparts, x)
        );
      } else {
        return this.parts;
      }
    },

    allSearchedPartsSelected: {
      get(): boolean {
        return this.searchedParts.findIndex(x => !x.selected) === -1;
      }
    },

    someSearchedPartsSelected: {
      get(): boolean {
        return (
          this.searchedParts.findIndex(x => x.selected) !== -1 &&
          this.searchedParts.findIndex(x => !x.selected) !== -1
        );
      }
    },

    // ** THIS needs to be updated once the actual list of previously selected parts for a project is defined.
    // CURRENTLY it is just using the list of all enabled parts for mockup purposes.
    suppliersInUse(): SupplierWithTags[] {
      return this.$store.getters.getSortedInUseSuppliers(this.$store.state.parts.fullList);
    },

    // ** THIS needs to be updated once the actual list of previously selected parts for a project is defined.
    // CURRENTLY it is just using the list of all enabled parts for mockup purposes.
    tagsInUse(): Tag[] {
      return getTagsInUse(this.$store.state.tags.fullList, this.$store.getters.sortedEnabledParts);
    },

    tablesearchareas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    openNewDialog() {
      this.showNewDialog = true;
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    async flipPartSelected(item: PartWithTags & { selected: boolean }) {
      this.processing = true;
      try {
        await this.updateProjectParts({
          projectID: this.$route.params.projectID,
          partIDs: [item.id],
          included: !item.selected
        });
      } catch (error) {
        this.handleError(error as Error, "projects.areas.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },

    async flipSearchedPartsSelected() {
      this.processing = true;
      try {
        let selected = !this.allSearchedPartsSelected;
        let changedPartIDs = [] as string[];
        for (let part of this.searchedParts) {
          if (part.selected !== selected) {
            part.selected = selected;
            changedPartIDs.push(part.id!);
          }
        }
        await this.updateProjectParts({
          projectID: this.$route.params.projectID,
          partIDs: changedPartIDs,
          included: selected
        });
      } catch (error) {
        this.handleError(error as Error, "projects.areas.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Save button.
    async save() {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.project.archived) {
          this.project.archivedDate = null;
        } else if (this.project.archived && !this.project.archivedDate) {
          this.project.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateProject({
          ...this.project,
          ownerID: this.project.ownerID ? this.project.ownerID : null,
          regionID: this.project.regionID ? this.project.regionID : null,
          productivity: this.project.productivity,
          labourRate: this.project.labourRate,
          parts: this.partsCatalogUsageType == "selection" ? this.selectedPartIDs : null
        });
        this.$router.push("/projects");
      } catch (error) {
        this.handleError(error as Error, "projects.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteProject({ id: this.$route.params.projectID, name: this.project.name });
        this.$router.push("/projects");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/projects");
    },

    async deleteTableItem(item: any) {
      this.processing = true;
      try {
        await this.deleteArea({ id: item.id, name: item.name, enabled: item.enabled });
      } catch (error) {
        this.handleError(error as Error, "projects.areas.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: any) {
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateArea({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error as Error, "projects.areas.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async setPartsCatalogUsageTypeToSelection() {
      this.showOnlyIncluded = false;
      await this.updateProject({
        id: this.$route.params.projectID,
        name: this.project.name,
        partIDs: this.lastSelectedPartIDs
      });
    },

    async setPartsCatalogUsageTypeToEntire() {
      this.lastSelectedPartIDs = this.selectedPartIDs;
      await this.updateProject({
        id: this.$route.params.projectID,
        name: this.project.name,
        partIDs: null
      });
    },

    lookupSupplier(supplierID: string) {
      if (this.suppliersInUse) {
        let supplier = this.suppliersInUse.find(x => x.id == supplierID);
        if (supplier) {
          return supplier.alias;
        } else {
          return "(unknown)";
        }
      } else {
        return "Loading...";
      }
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadProjectWithAreas: "LOAD_PROJECT_WITH_AREAS",
      loadOwners: "LOAD_OWNERS",
      loadRegions: "LOAD_REGIONS",
      updateProject: "UPDATE_PROJECT",
      deleteProject: "DELETE_PROJECT",
      updateProjectParts: "UPDATE_PROJECT_PARTS",
      updateArea: "UPDATE_AREA",
      deleteArea: "DELETE_AREA",
      loadParts: "LOAD_PARTS",
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS"
    })
  },

  watch: {
    project(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/projects") {
        this.notifyNewBreadcrumb({
          text: this.$t("projects.list-title"),
          to: "/projects",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/projects/${this.$route.params.projectID}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "areas",
      parentalContext: "projects",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadProjectWithAreas({
          projectID: this.$route.params.projectID,
          showAll: this.showArchived
        }),
        this.loadOwners(),
        this.loadRegions(),
        this.loadParts(),
        this.loadSuppliers(),
        this.loadTags()
      ]);
      let project = this.storeProject;

      let selectedPartIDs: Set<string>;
      if (project.partIDs) {
        selectedPartIDs = new Set<string>(project.partIDs);
        this.partsCatalogUsageType = "selection";
      } else {
        selectedPartIDs = new Set<string>();
        this.partsCatalogUsageType = "entire";
      }

      this.selectableParts = this.$store.getters.sortedEnabledParts.map((x: PartWithTags) => ({
        ...x,
        selected: selectedPartIDs.has(x.id!)
      }));

      // TODO: Consider more elegant approaches to solving this problem...
      this.$watch(
        () => this.storeProject.partIDs,
        () => {
          let selectedPartIDs: Set<string>;
          if (this.storeProject.partIDs) {
            selectedPartIDs = new Set<string>(this.storeProject.partIDs);
            this.partsCatalogUsageType = "selection";
          } else {
            selectedPartIDs = new Set<string>();
            this.partsCatalogUsageType = "entire";
          }
          for (let part of this.selectableParts) {
            part.selected = selectedPartIDs.has(part.id!);
          }
        },
        { deep: false }
      );
      this.project = { ...project, archived: !!project.archivedDate };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

