import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";

export default FDVue.extend({
  name: "fd-count-sheet-yard-selection-form",

  mixins: [rules],

  props: {
    processing: { type: Boolean, default: false },
    selectSourceYard: { type: Boolean, default: false },
    selectDestinationYard: { type: Boolean, default: false },
    sourceYardID: { type: String, default: undefined },
    destinationYardID: { type: String, default: undefined },
    yards: { type: Array, default: () => [] }
  }
});

