import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations, mapActions, mapState } from "vuex";
import i18n from "../i18n";
import { ProjectLocation } from "../services";
import rules from "@fd/lib/vue/rules";

type ProjectLocationWithArchived = ProjectLocation & { archived: boolean };

export default FDVue.extend({
  name: "fd-sub-area-existing",

  mixins: [errorHandling, rules],

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  data() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      //Simple object for the form data
      subArea: {
        name: "",
        description: "",
        archived: true
      } as ProjectLocationWithArchived
    };
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    // Method used in conjunction with the Save button.
    async save() {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.subArea.archived) {
          this.subArea.archivedDate = null;
        } else if (this.subArea.archived && !this.subArea.archivedDate) {
          this.subArea.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateSubArea(this.subArea);
        this.$router.push(`/areas/${this.subArea.parentLocationID}`);
      } catch (error) {
        this.handleError(error, "projects.areas.sub-areas.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSubArea({ id: this.subArea.id, name: this.subArea.name });
        this.$router.push(`/areas/${this.subArea.parentLocationID}`);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push(`/areas/${this.subArea.parentLocationID}`);
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadProjectWithAreas: "LOAD_PROJECT_WITH_AREAS",
      loadAreaWithSubAreas: "LOAD_AREA_WITH_SUBAREAS",
      loadSubArea: "LOAD_SUBAREA",
      updateSubArea: "UPDATE_SUBAREA",
      deleteSubArea: "DELETE_SUBAREA"
    })
  },

  watch: {
    subArea: async function(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/projects") {
        this.notifyNewBreadcrumb({
          text: this.$t("projects.list-title"),
          to: "/projects",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        await this.loadAreaWithSubAreas({
          areaID: newValue.parentLocationID,
          showAll: false
        });

        var area = this.$store.state.projectLocations.find(
          (x: any) => x.id == newValue.parentLocationID
        ) as ProjectLocation;

        await this.loadProjectWithAreas({
          projectID: area.projectID,
          showAll: false
        });

        this.notifyNewBreadcrumb({
          text: this.$store.state.project.name,
          to: `/projects/${this.$store.state.project.id}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: area.name,
          to: `/areas/${newValue.parentLocationID}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/subareas/${this.$route.params.subAreaID}`
      });
    }
  },

  created: async function() {
    this.inlineMessage.message = null;
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadSubArea(this.$route.params.subAreaID);
      var subArea = (this.$store.state.projectLocations as ProjectLocation[]).find(
        x => x.id == this.$route.params.subAreaID
      )!;
      this.subArea = { ...subArea, archived: !!subArea.archivedDate };
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});
