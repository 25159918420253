var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { staticClass: "fd-duration-picker-fieldset" },
    [
      _c(
        "legend",
        { staticStyle: { "padding-right": "4px", "padding-left": "4px" } },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "0.75rem", color: "inherit" } },
            [_vm._v(_vm._s(_vm.label))]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "ml-5 mr-0", attrs: { "data-cy": _vm.cy } },
        [
          _c(
            "v-col",
            { staticClass: "first-col", attrs: { cols: _vm.colSpan } },
            [
              _c("v-autocomplete", {
                ref: "hourselect",
                attrs: {
                  outlined: "",
                  name: "hourselect",
                  label: _vm.$t("common.hours"),
                  disabled: _vm.disabled,
                  items: _vm.hourOptions,
                  "hide-details": true,
                  dense: ""
                },
                model: {
                  value: _vm.hoursValue,
                  callback: function($$v) {
                    _vm.hoursValue = $$v
                  },
                  expression: "hoursValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              class: { "last-col": !_vm.showSeconds },
              attrs: { cols: _vm.colSpan }
            },
            [
              _c("v-select", {
                ref: "minuteselect",
                attrs: {
                  outlined: "",
                  name: "minuteselect",
                  label: _vm.$t("common.minutes"),
                  disabled: _vm.disabled,
                  items: _vm.minuteOptions,
                  "hide-details": true,
                  dense: ""
                },
                model: {
                  value: _vm.minutesValue,
                  callback: function($$v) {
                    _vm.minutesValue = $$v
                  },
                  expression: "minutesValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSeconds
            ? _c(
                "v-col",
                { staticClass: "last-col", attrs: { cols: _vm.colSpan } },
                [
                  _c("v-select", {
                    ref: "secondselect",
                    attrs: {
                      outlined: "",
                      name: "secondselect",
                      label: _vm.$t("common.seconds"),
                      disabled: _vm.disabled,
                      items: _vm.secondOptions,
                      "hide-details": true,
                      dense: ""
                    },
                    model: {
                      value: _vm.secondsValue,
                      callback: function($$v) {
                        _vm.secondsValue = $$v
                      },
                      expression: "secondsValue"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }