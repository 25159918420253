import FDVue from "@fd/lib/vue";
import { BulkImportResults, partService, PartsImportSettings } from "../../services";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { mapActions } from "vuex";
import { legacyArticleService } from "../../services";
import store from "../../store";
import rules from "@fd/lib/vue/rules";

export default FDVue.extend({
  mixins: [rules],

  props: {
    closeAction: Function,
    partRules: Object
  },

  data: () => ({
    processing: false,
    downloading: false,
    downloadingerrors: false,
    uploading: false,

    shouldImportToSP1: false,

    file: null as any,
    returnFile: null as Blob | null,
    sp1ImportResults: null as BulkImportResults | null,
    sp2ImportResults: null as BulkImportResults | null,

    // Used to map XLS Template Columns to DB Column Names
    // Default values are loaded from the server
    partsImportColumnMapping: {} as PartsImportSettings,

    // The following is responsible for the inline messages that may be presented to the user.
    // If the message property is null then no message will be shown to the user.
    inlineMessageBulkSP1Upload: {
      message: null as any,
      type: "error" as "success" | "info" | "warning" | "error"
    },
    inlineMessageBulkSP2Upload: {
      message: null as any,
      type: "error" as "success" | "info" | "warning" | "error"
    }
  }),

  computed: {},

  methods: {
    closeDialog() {
      this.returnFile = null;
      this.$props.closeAction();
    },

    async loadColumnMapping() {
      this.partsImportColumnMapping = await partService.getDefaultImportSettings();
      console.log(JSON.stringify(this.partsImportColumnMapping));
    },

    async uploadFiles() {
      console.log("uploadFiles()");
      // First reset the inline message if there are any.
      this.inlineMessageBulkSP1Upload.message = null;
      this.inlineMessageBulkSP2Upload.message = null;
      this.sp1ImportResults = null;
      this.sp2ImportResults = null;
      this.returnFile = null;

      if (!(this.$refs.uploadForm as HTMLFormElement).validate()) {
        console.log((this.$refs.uploadForm as HTMLFormElement).validate());
        return;
      }

      this.processing = true;
      this.uploading = true;

      var isSuccessful = true;
      if (isSuccessful) isSuccessful = !this.shouldImportToSP1 || (await this.validateSP1File());
      if (isSuccessful) isSuccessful = await this.validateSP2File();
      if (isSuccessful) isSuccessful = !this.shouldImportToSP1 || (await this.uploadSP1File());
      if (isSuccessful) isSuccessful = await this.uploadSP2File();

      this.processing = false;
      this.uploading = false;

      if (isSuccessful) this.showSnackbarAndCloseDialog();

      // if (this.shouldImportToSP1) {
      //   // uploadSP1File automatically calls the SP2 version when complete
      //   await this.uploadSP1File();
      // } else {
      //   await this.uploadSP2File();
      // }
    },
    showSnackbarAndCloseDialog() {
      var snackbarPayload = {
        text: this.$t("common.bulk-upload-results.import-success"),
        type: "success",
        undoCallback: null
      };
      store.dispatch("SHOW_SNACKBAR", snackbarPayload);
    },

    async validateSP1File(): Promise<boolean> {
      console.log("validateSP1File()");

      var isSuccessful = false;
      try {
        this.returnFile = await legacyArticleService.validateArticleList(
          this.partsImportColumnMapping.identifyingColumnFriendlyName || "",
          this.partsImportColumnMapping.publicID || "",
          this.partsImportColumnMapping.name || "",
          this.partsImportColumnMapping.description || "",
          this.partsImportColumnMapping.weight || "",
          this.partsImportColumnMapping.mpp || "",
          this.partsImportColumnMapping.rentalRate || "",
          this.partsImportColumnMapping.countSheetGroupID || "",
          this.partsImportColumnMapping.costUsed || "",
          this.partsImportColumnMapping.costNew || "",
          this.partsImportColumnMapping.designation || "",
          this.file
        );
        isSuccessful = this.returnFile == null;
      } catch (error) {
        console.log("\tError: " + error);

        var errorLabel =
          error.statusCode == 422
            ? "common.bulk-upload-results.column-mismatch-error"
            : "common.bulk-upload-results.sp1-import-error";
        this.inlineMessageBulkSP1Upload.message = this.$t(errorLabel);
        this.inlineMessageBulkSP1Upload.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },

    async uploadSP1File(): Promise<boolean> {
      console.log("uploadSP1File()");
      this.processing = true;
      this.uploading = true;

      var isSuccessful = false;
      try {
        this.sp1ImportResults = await legacyArticleService.uploadArticleList(
          this.partsImportColumnMapping.identifyingColumnFriendlyName || "",
          this.partsImportColumnMapping.publicID || "",
          this.partsImportColumnMapping.name || "",
          this.partsImportColumnMapping.description || "",
          this.partsImportColumnMapping.weight || "",
          this.partsImportColumnMapping.mpp || "",
          this.partsImportColumnMapping.rentalRate || "",
          this.partsImportColumnMapping.countSheetGroupID || "",
          this.partsImportColumnMapping.costUsed || "",
          this.partsImportColumnMapping.costNew || "",
          this.partsImportColumnMapping.designation || "",
          this.file
        );
        isSuccessful = this.sp1ImportResults.successful;
      } catch (error) {
        console.log("\tError: " + error);

        var errorLabel =
          error.statusCode == 422
            ? "common.bulk-upload-results.column-mismatch-error"
            : "common.bulk-upload-results.sp1-import-error";
        this.inlineMessageBulkSP1Upload.message = this.$t(errorLabel);
        this.inlineMessageBulkSP1Upload.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },

    async validateSP2File(): Promise<boolean> {
      console.log("validateSP2File()");
      var isSuccessful = false;
      try {
        this.returnFile = await partService.validateCustomizedPartList(
          this.partsImportColumnMapping.identifyingColumnFriendlyName || "",
          this.partsImportColumnMapping.publicID || "",
          this.partsImportColumnMapping.name || "",
          this.partsImportColumnMapping.description || "",
          this.partsImportColumnMapping.longDescription || "",
          this.partsImportColumnMapping.weight || "",
          this.partsImportColumnMapping.mpp || "",
          this.partsImportColumnMapping.lashingMPP || "",
          this.partsImportColumnMapping.cleatingMPP || "",
          this.partsImportColumnMapping.carpentryMPP || "",
          this.partsImportColumnMapping.otherMPP || "",
          this.partsImportColumnMapping.rentalRate || "",
          this.partsImportColumnMapping.costUsed || "",
          this.partsImportColumnMapping.costNew || "",
          this.partsImportColumnMapping.designation || "",
          this.partsImportColumnMapping.tagNames || "",
          this.partsImportColumnMapping.supplierNames || "",
          this.file
        );
        isSuccessful = this.returnFile == null;
      } catch (error) {
        console.log("\tError: " + error);
        if (error.statusCode == 422) {
          // Column mismatch error is version agnostic as it's related to the columns on the screen or in the DB
          this.inlineMessageBulkSP2Upload.message = this.$t(
            "common.bulk-upload-results.column-mismatch-error"
          );
        } else {
          // Importing into V2 is the default functionality and is implied unless otherwise specified
          // If the user is trying to also upload into v1, then we need to specify which one failed.
          var importErrorLabel = this.shouldImportToSP1
            ? "common.bulk-upload-results.sp2-import-error-including-version"
            : "common.bulk-upload-results.sp2-import-error";
          this.inlineMessageBulkSP2Upload.message = this.$t(importErrorLabel);
        }
        this.inlineMessageBulkSP2Upload.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async uploadSP2File(): Promise<boolean> {
      console.log("uploadSP2File()");

      var isSuccessful = false;
      try {
        this.sp2ImportResults = await partService.uploadCustomizedPartList(
          this.partsImportColumnMapping.identifyingColumnFriendlyName || "",
          this.partsImportColumnMapping.publicID || "",
          this.partsImportColumnMapping.name || "",
          this.partsImportColumnMapping.description || "",
          this.partsImportColumnMapping.longDescription || "",
          this.partsImportColumnMapping.weight || "",
          this.partsImportColumnMapping.mpp || "",
          this.partsImportColumnMapping.lashingMPP || "",
          this.partsImportColumnMapping.cleatingMPP || "",
          this.partsImportColumnMapping.carpentryMPP || "",
          this.partsImportColumnMapping.otherMPP || "",
          this.partsImportColumnMapping.rentalRate || "",
          this.partsImportColumnMapping.costUsed || "",
          this.partsImportColumnMapping.costNew || "",
          this.partsImportColumnMapping.designation || "",
          this.partsImportColumnMapping.tagNames || "",
          this.partsImportColumnMapping.supplierNames || "",
          this.file
        );
        isSuccessful = this.sp2ImportResults.successful;
      } catch (error) {
        console.log("\tError: " + error);
        if (error.statusCode == 422) {
          // Column mismatch error is version agnostic as it's related to the columns on the screen or in the DB
          this.inlineMessageBulkSP2Upload.message = this.$t(
            "common.bulk-upload-results.column-mismatch-error"
          );
        } else {
          // Importing into V2 is the default functionality and is implied unless otherwise specified
          // If the user is trying to also upload into v1, then we need to specify which one failed.
          var importErrorLabel = this.shouldImportToSP1
            ? "common.bulk-upload-results.sp2-import-error-including-version"
            : "common.bulk-upload-results.sp2-import-error";
          this.inlineMessageBulkSP2Upload.message = this.$t(importErrorLabel);
        }
        this.inlineMessageBulkSP2Upload.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },

    async downloadReturnFile() {
      // First reset the inline message if there are any.
      this.inlineMessageBulkSP1Upload.message = null;
      this.inlineMessageBulkSP2Upload.message = null;

      if (!this.returnFile) {
        this.inlineMessageBulkSP1Upload.message = this.$t(
          "common.bulk-upload-general.file-does-not-exist"
        );
        this.inlineMessageBulkSP1Upload.type = "warning";
        return;
      }

      this.downloadingerrors = true;
      this.processing = true;
      try {
        await downloadBlob(this.returnFile, this.file.name);
      } catch (error) {
        this.inlineMessageBulkSP1Upload.message = this.$t("unexpected-network-error");
        this.inlineMessageBulkSP1Upload.type = "error";
      } finally {
        this.processing = false;
        this.downloadingerrors = false;
      }
    },

    async downloadTemplate() {
      // First reset the inline message if there are any.
      this.inlineMessageBulkSP1Upload.message = null;
      this.inlineMessageBulkSP2Upload.message = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let partListTemplate = await partService.downloadPartListTemplate();
        downloadBlob(partListTemplate, "PartListTemplate.xlsm");
      } catch (error) {
        this.inlineMessageBulkSP2Upload.message = this.$t("unexpected-network-error");
        this.inlineMessageBulkSP2Upload.type = "error";
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },
    async downloadFullPartsList() {
      // First reset the inline message if there are any.
      this.inlineMessageBulkSP2Upload.message = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let partListTemplate = await partService.downloadPartList();
        downloadBlob(partListTemplate, "PartList.xlsm");
      } catch (error) {
        this.inlineMessageBulkSP2Upload.message = this.$t("unexpected-network-error");
        this.inlineMessageBulkSP2Upload.type = "error";
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },

    ...mapActions({
      loadParts: "LOAD_PARTS"
    })
  },
  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    },
    shouldImportToSP1() {
      console.log("shouldImportToSP1");
      this.inlineMessageBulkSP1Upload.message = null;
      this.inlineMessageBulkSP2Upload.message = null;
      console.log(`\tthis.returnFile = ${this.returnFile}`);
      this.returnFile = null;
    }
  },
  created: async function() {
    await this.loadColumnMapping();
  }
});
