/**
 * This file is here to separate out some service error handling that screens have come to rely on
 * and the method wrapping/error reporting that isn't widely used but causes issues with Vuetify data tables.
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import ServiceError from "../../client-util/serviceError";

export type MessageType = "success" | "info" | "warning" | "error";

// The following is responsible for the inline messages that may be presented to the user.
// If the message property is null then no message will be shown to the user.
// The type can be one of the following strings:
// 'success'
// 'info'
// 'warning'
// 'error'
export interface InlineMessage {
  message: VueI18n.TranslateResult | null;
  type: MessageType;
}

export type ErrorHandlingOverride = {
  [statusCode: string]: {
    errorMessageKey?: string | null | undefined;
    type?: MessageType | null | undefined;
  };
};

export default Vue.extend({
  data: function() {
    return {
      inlineMessage: {
        message: null,
        type: "error"
      } as InlineMessage,
      // The following will control whether the controls on screen are disabled while we are
      // processing and error states associated with active work
      processing: false
    };
  },

  methods: {
    generateErrorInformation(
      error: Error,
      defaultErrorMessageKey: string = "unexpected-network-error",
      overrides: ErrorHandlingOverride | null = null
    ) {
      let parsedError = error as ServiceError;
      let errorMessageKey = `error-messages.${parsedError.details}`;
      let type = "error" as MessageType;
      switch (parsedError.statusCode) {
        case 401: {
          errorMessageKey = "error-messages.401";
          break;
        }
        case 403: {
          errorMessageKey = "error-messages.403";
          break;
        }
        case 404: {
          errorMessageKey = "error-messages.404";
          break;
        }
        case 500:
        case 504: {
          errorMessageKey = "";
          break;
        }
        default: {
          if (!!parsedError.details) {
            errorMessageKey = `error-messages.${parsedError.details}`;
          } else {
            // empty out the errorMessageKey so it uses the default
            errorMessageKey = "";
          }
          break;
        }
      }

      if (!errorMessageKey.length) errorMessageKey = `${defaultErrorMessageKey}`;

      var override = !!overrides ? overrides[`${parsedError.statusCode}`] : null;
      if (!!override) {
        if (!!override.errorMessageKey) errorMessageKey = override.errorMessageKey;
        if (!!override.type) type = override.type;
      }

      return { message: this.$t(errorMessageKey), type: type };
    },
    // Unhandled server errors come back as a 500.  The `error500MessageKey` is the default message to show when the server hasn't returned its own key
    handleError(
      error: Error,
      defaultErrorMessageKey: string = "unexpected-network-error",
      overrides: {
        [statusCode: string]: {
          errorMessageKey?: string | null | undefined;
          type?: MessageType | null | undefined;
        };
      } | null = null
    ) {
      var messageInfo = this.generateErrorInformation(error, defaultErrorMessageKey, overrides);
      this.inlineMessage.message = messageInfo.message;
      this.inlineMessage.type = messageInfo.type;
    }
  }
});

