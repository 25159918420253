var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("walkdowns.existing-walkdown.title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("fd-walkdown-form", {
            ref: "walkdownform",
            attrs: {
              walkdown: _vm.workOrder.walkdown,
              workOrder: _vm.workOrder,
              walkdownReadonly: _vm.walkdownReadonly
            }
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(_vm._s(_vm.inlineMessage.message))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "submit-small",
                    outlined: "",
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.walkdownReadonly || _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveDialog(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("walkdowns.existing-walkdown.submit")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "d-none d-sm-flex",
                  attrs: {
                    "data-cy": "submit",
                    outlined: "",
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.walkdownReadonly || _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveDialog(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("walkdowns.existing-walkdown.submit")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.saving,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save",
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.walkdownReadonly || _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveDialog(false)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.save")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }