import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { AccessInformation } from "../services";

export default FDVue.extend({
  computed: {
    curUserID(): string {
      return this.$store.state.curUserID;
    },
    curUserFullName(): string {
      return this.$store.state.curUserFullName;
    },
    curUserAccess(): AccessInformation {
      return this.$store.state.curUserAccess;
    },
    curUserHomeContractorName(): string | TranslateResult {
      return this.curUserAccess?.homeContractorName ?? this.$t("common.unknown");
    },
    curUserCanViewAllContractors(): boolean {
      return !this.curUserAccess?.contractorIDs || !!this.curUserAccess?.includesAllContractors;
    },
    curUserContractorIDs(): string[] {
      return this.curUserAccess?.contractorIDs ?? [];
    },
    curUserAccessibleKeys(): string[] {
      return Object.keys(this.curUserAccess).filter(
        accessKey => accessKey.startsWith("can") && (this.curUserAccess as any)[accessKey] == true
      );
    },

    currentUserCanConfigureSettings(): boolean {
      return this.curUserAccess?.canConfigureSettings ?? false;
    },
    currentUserCanConfigurePrivateSettings(): boolean {
      return this.curUserAccess?.canConfigurePrivateSettings ?? false;
    },

    currentUserCanSubmitScaffoldRequests(): boolean {
      return this.curUserAccess?.canSubmitScaffoldRequests ?? false;
    },

    currentUserCanImportWorkOrders(): boolean {
      return this.curUserAccess?.canImportWorkOrders ?? false;
    },

    currentUserCanViewScaffoldRequestApprovals(): boolean {
      return this.curUserAccess?.canViewScaffoldRequestApprovals ?? false;
    },
    currentUserCanViewWorkOrders(): boolean {
      return this.curUserAccess?.canViewWorkOrders ?? false;
    },
    currentUserCanViewWorkOrderEstimates(): boolean {
      return this.curUserAccess?.canViewWorkOrderEstimates ?? false;
    },
    currentUserCanViewWorkOrderSchedule(): boolean {
      return this.curUserAccess?.canViewWorkOrderSchedule ?? false;
    },
    currentUserCanEditWorkOrderSchedule(): boolean {
      return this.curUserAccess?.canEditWorkOrderSchedule ?? false;
    },
    currentUserCanViewToDoList(): boolean {
      return this.curUserAccess?.canViewToDoList ?? false;
    },
    currentUserCanViewScaffolds(): boolean {
      return this.curUserAccess?.canViewScaffolds ?? false;
    },
    currentUserCanViewMaterialApproval(): boolean {
      return this.curUserAccess?.canViewMaterialApproval ?? false;
    },
    currentUserCanViewTransfers(): boolean {
      return this.curUserAccess?.canViewTransfers ?? false;
    },
    currentUserCanCreateTransfers(): boolean {
      return this.curUserAccess?.canCreateTransfers ?? false;
    }
  }
});

