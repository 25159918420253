import { DirectiveOptions } from "vue";
import { VNodeDirective } from "vue/types/umd";
import { DataTableHeader } from "vuetify";
import { VDataTable } from "../types";
import Sortable, { dragged, SortableEvent, SortableOptions } from "sortablejs";
export { SortableEvent } from "sortablejs";

export const FDHiddenArgumentName = "__hidden";

interface DataTableConfig {
  dataTable: VDataTable;
  columns: DataTableColumnConfig[];
  rowClickEventHandler:
    | ((
        item: any,
        options: {
          select: (value: boolean) => void;
          isSelected: boolean;
          expand: (value: boolean) => void;
          isExpanded: boolean;
        }
      ) => void)
    | null;
  rowDoubleClickEventHandler:
    | ((
        mouseEvent: MouseEvent,
        options: {
          select: (value: boolean) => void;
          isSelected: boolean;
          expand: (value: boolean) => void;
          isExpanded: boolean;
          item: any;
        }
      ) => void)
    | null;
  windowResizeEventHandler: ((this: Window, ev: UIEvent) => void) | null;
}
interface DataTableColumnConfig {
  name: string;
  headerMetadata: DataTableHeader;
  minimumWidthVisible: number | undefined;
  maximumWidthVisible: number | undefined;
  hidden?: boolean | undefined;
}
var dataTableConfigSet = new WeakMap<VDataTable, DataTableConfig>();

// Bounds values match vuetify, as per https://vuetifyjs.com/en/features/breakpoints/
const screenBounds = {
  extraSmall: {
    min: 0,
    max: 599
  },
  small: {
    min: 600,
    max: 959
  },
  medium: {
    min: 960,
    max: 1263
  },
  large: {
    min: 1264,
    max: 1903
  },
  extraLarge: {
    min: 1904,
    max: 999999
  },
  mobile: {
    min: 0,
    max: 1194
  }
};

const columnVisibilityModifiers = {
  hide: {
    extraSmall: "hide-when-extra-small",
    smallOrSmaller: "hide-when-small",
    mediumOrSmaller: "hide-when-medium",
    largeOrSmaller: "hide-when-large",
    mobile: "hide-when-mobile", // mobile breakpoint specified in Vuetify in the environment's options.ts file
    // NOTE: `extraLargeOrSmaller` is redundant as that basically means "always"
    headerEmpty: "hide-when-header-text-empty",
    hidden: "hidden"
  },
  show: {
    extraSmall: "show-when-extra-small",
    smallOrSmaller: "show-when-small",
    mediumOrSmaller: "show-when-medium",
    largeOrSmaller: "show-when-large",
    mobile: "show-when-mobile" // mobile breakpoint specified in Vuetify in the environment's options.ts file
    // NOTE: `extraLargeOrSmaller` is redundant as that basically means "always"
  }
};

function getVisibilityWidths(modifiers: { [key: string]: boolean } | undefined) {
  let minimumWidthVisible: number | undefined;
  let maximumWidthVisible: number | undefined;
  if (modifiers) {
    if (columnVisibilityModifiers.hide.extraSmall in modifiers) {
      // Item is hidden when screen width is "Extra Small" (visible when "Small" or greater)
      minimumWidthVisible = screenBounds.small.min;
    } else if (columnVisibilityModifiers.hide.smallOrSmaller in modifiers) {
      // Item is hidden when screen width is "Small" or smaller (visible when "Medium" or greater)
      minimumWidthVisible = screenBounds.medium.min;
    } else if (columnVisibilityModifiers.hide.mediumOrSmaller in modifiers) {
      // Item is hidden when screen width is "Medium" or smaller (visible when "Large" or greater)
      minimumWidthVisible = screenBounds.large.min;
    } else if (columnVisibilityModifiers.hide.largeOrSmaller in modifiers) {
      // Item is hidden when screen width is "Large" or smaller (visible when "Extra Large")
      minimumWidthVisible = screenBounds.extraLarge.min;
    } else if (columnVisibilityModifiers.hide.mobile in modifiers) {
      // Item is hidden when screen width is smaller than mobile
      minimumWidthVisible = screenBounds.mobile.max + 1;
    }

    if (columnVisibilityModifiers.show.extraSmall in modifiers) {
      // Item is hidden when screen width is "Small" or higher
      maximumWidthVisible = screenBounds.extraSmall.max;
    } else if (columnVisibilityModifiers.show.smallOrSmaller in modifiers) {
      // Item is hidden when screen width is "Medium" or higher
      maximumWidthVisible = screenBounds.small.max;
    } else if (columnVisibilityModifiers.show.mediumOrSmaller in modifiers) {
      // Item is hidden when screen width is "Large" or higher
      maximumWidthVisible = screenBounds.medium.max;
    } else if (columnVisibilityModifiers.show.largeOrSmaller in modifiers) {
      // Item is hidden when screen width is "Extra Large"
      maximumWidthVisible = screenBounds.large.max;
    } else if (columnVisibilityModifiers.show.mobile in modifiers) {
      // Item is hidden when screen width is larger than mobile
      maximumWidthVisible = screenBounds.mobile.max;
    }
  }
  return { minimumWidthVisible, maximumWidthVisible };
}

function createColumn(dataTable: VDataTable, columnConfig: DataTableColumnConfig) {
  // We store data table configuration data in a weak map; if no record exists yet we
  // will initialize a new set and add the appropriate configuration data
  let dataTableConfig = dataTableConfigSet.get(dataTable);
  if (!dataTableConfig) {
    dataTableConfig = {
      dataTable,
      columns: [columnConfig],
      rowClickEventHandler: null,
      rowDoubleClickEventHandler: null,
      windowResizeEventHandler: null
    };
    dataTableConfigSet.set(dataTable, dataTableConfig);
  } else {
    if (dataTableConfig.columns.indexOf(columnConfig) < 0) {
      dataTableConfig.columns.push(columnConfig);
    }
  }

  // If this is the first column requiring width controls we need to add an event handler
  // to accommodate size changes
  if (columnConfig.minimumWidthVisible || columnConfig.maximumWidthVisible) {
    if (!dataTableConfig.windowResizeEventHandler) {
      dataTableConfig.windowResizeEventHandler = () =>
        handleWindowResize(dataTableConfig!, window.innerWidth);
      window.addEventListener("resize", dataTableConfig.windowResizeEventHandler);
    }
  }
  let newHeaders = dataTableConfig.columns.map(x => x.headerMetadata);
  let inPlaceHeaders = dataTableConfig.dataTable.headers;
  if (inPlaceHeaders.length > newHeaders.length) {
    inPlaceHeaders.splice(newHeaders.length, inPlaceHeaders.length - newHeaders.length);
  }
  for (let i = 0; i < newHeaders.length; i++) {
    if (i >= inPlaceHeaders.length) {
      inPlaceHeaders.push(newHeaders[i]);
    } else if (inPlaceHeaders[i] !== newHeaders[i]) {
      inPlaceHeaders[i] = newHeaders[i];
    }
  }

  // When we add a new column we need to recalculate the current set
  handleWindowResize(dataTableConfig, window.innerWidth);

  function handleWindowResize(dataTableConfig: DataTableConfig, width: number) {
    updateTableHeaders(dataTableConfig, width);
  }
}

function updateColumn(dataTable: VDataTable, columnConfig: DataTableColumnConfig) {
  let dataTableConfig = dataTableConfigSet.get(dataTable);
  if (!dataTableConfig) return;

  // Find the column being updated based on the header's arg/value.
  let curArgColumnConfig = dataTableConfig.columns.find(x => x.name == columnConfig.name);
  if (!curArgColumnConfig) return;

  curArgColumnConfig.headerMetadata = columnConfig.headerMetadata;
  curArgColumnConfig.hidden = columnConfig.hidden;
  curArgColumnConfig.minimumWidthVisible = columnConfig.minimumWidthVisible;
  curArgColumnConfig.maximumWidthVisible = columnConfig.maximumWidthVisible;

  updateTableHeaders(dataTableConfig);
}

function updateTableHeaders(dataTableConfig: DataTableConfig, width?: number) {
  width = width ?? window.innerWidth;

  let inPlaceHeaders = dataTableConfig.dataTable.headers;

  // Look for headers that exist in the table but not in the config
  // Since the `arg` value can change dynamically, an existing column may have had its value changed
  // And we have no other way to compare table columns to configured columns
  let headersToRemove: DataTableHeader<any>[] = [];
  inPlaceHeaders.forEach(header => {
    var x = dataTableConfig.columns.find(x => x.headerMetadata.value == header.value);
    if (!x) {
      headersToRemove.push(header);
    }
  });

  if (headersToRemove.length) {
    headersToRemove.forEach(x => {
      dataTableConfig.dataTable.headers.splice(dataTableConfig.dataTable.headers.indexOf(x), 1);
    });
  }

  inPlaceHeaders = dataTableConfig.dataTable.headers;

  dataTableConfig.columns.forEach(columnConfig => {
    var hidden =
      (!!columnConfig.minimumWidthVisible && columnConfig.minimumWidthVisible > width!) ||
      (!!columnConfig.maximumWidthVisible && columnConfig.maximumWidthVisible < width!) ||
      columnConfig.hidden;

    let header = inPlaceHeaders.find(x => x.value == columnConfig.headerMetadata.value);
    if (!header) {
      // If the arg changed dynamically, then the new value may not have existed in the table.
      // Create the column and add to the table
      createColumn(dataTableConfig.dataTable, columnConfig);
      return;
    }

    // Update the text in case it has changed (such as the user toggling the selected language in the app)
    header.text = columnConfig.headerMetadata.text;

    // Here we apply whatever styles we need to.
    // The `.class` header property applies ONLY to the header cell in the header row
    // We need to apply the class to the `.cellClass` property to have it also apply to the cells in the column.

    // We start by ensureing the class property is a string array
    // Since a list of classes in a single string are space separated, we split by that if the property is a string
    if (typeof header.class === "string") {
      header.class = (header.class as string).split(" ");
    }
    header.class = header.class ?? [];
    if (typeof header.cellClass === "string") {
      header.cellClass = (header.cellClass as string).split(" ");
    }
    header.cellClass = header.cellClass ?? [];

    var hiddenClass = "d-none";
    if (hidden) {
      if (!header.class.includes(hiddenClass)) header.class.push(hiddenClass);
      if (!header.cellClass.includes(hiddenClass)) header.cellClass.push(hiddenClass);
    } else {
      if (header.class.includes(hiddenClass))
        header.class.splice(header.class.indexOf(hiddenClass), 1);
      if (header.cellClass.includes(hiddenClass))
        header.cellClass.splice(header.class.indexOf(hiddenClass), 1);
    }
  });

  // // Only reset the headers if they are different; because of Vue's rules for handling
  // // props (we can't fully reset them) I have to manipulate the array in-place
  // if (inPlaceHeaders.length > newHeaders.length) {
  //   inPlaceHeaders.splice(newHeaders.length, inPlaceHeaders.length - newHeaders.length);
  // }
  // for (let i = 0; i < newHeaders.length; i++) {
  //   if (i >= inPlaceHeaders.length) {
  //     inPlaceHeaders.push(newHeaders[i]);
  //   } else if (inPlaceHeaders[i] !== newHeaders[i]) {
  //     inPlaceHeaders[i] = newHeaders[i];
  //   }
  // }
}

function cleanupDataTableConfigOnUnbind(componentInstance: Vue) {
  // The node that creates it is irrelevant since we should all be destroyed at roughly
  // the same time; if this assumption ever changes we'll need to reassess
  let dataTableConfig = dataTableConfigSet.get(componentInstance as VDataTable);
  if (dataTableConfig) {
    if (dataTableConfig.windowResizeEventHandler) {
      window.removeEventListener("resize", dataTableConfig.windowResizeEventHandler);
      dataTableConfig.windowResizeEventHandler = null;
    }
    if (dataTableConfig.rowClickEventHandler) {
      dataTableConfig.dataTable.$off("click:row", dataTableConfig.rowClickEventHandler);
      dataTableConfig.rowClickEventHandler = null;
    }
    if (dataTableConfig.rowDoubleClickEventHandler) {
      dataTableConfig.dataTable.$off("dblclick:row", dataTableConfig.rowDoubleClickEventHandler);
      dataTableConfig.rowDoubleClickEventHandler = null;
    }
  } else {
    throw new Error(
      "Unexpected missing data table configuration record in column directive unbind"
    );
  }
}

function getColumnConfig(
  directive: VNodeDirective,
  dataTable: VDataTable
): DataTableColumnConfig | undefined {
  if (!directive.arg) return undefined;

  // Get configured size visibility boundaries
  let { minimumWidthVisible, maximumWidthVisible } = getVisibilityWidths(directive.modifiers);

  // Get header options
  let options = {} as DataTableHeader;
  switch (typeof directive.value) {
    case "object":
      options = directive.value as DataTableHeader;
      break;
    case "string":
      let newText = directive.value;
      if (options.text != newText) options.text = newText;
      break;
    case "undefined": {
      // TODO: Is "common" the best/only prefix?
      let newText = dataTable.$t("common." + directive.arg).toString();
      if (options.text != newText) options.text = newText;
      break;
    }
    default:
      throw new Error("Unrecognized column attribute type");
  }

  var classes = [];
  for (var mod in directive.modifiers) {
    if (mod.startsWith("class_")) {
      let c = mod.replace("class_", "");
      classes.push(c);
    }
  }

  // Determine column visibility based on either the argument value or the column modifiers
  var hidden = false;
  if (classes.includes("d-none")) {
    // Class "d-none" is used to show and hide columns.  As such, if hidden is false this class will be manually removed from the column class list.  So we force hidden to be true if the dev manually put this class on
    hidden = true;
  } else if (
    !!directive.modifiers &&
    columnVisibilityModifiers.hide.hidden in directive.modifiers
  ) {
    // Column is hidden if given the `.hidden` modifier
    hidden = true;
  } else if (
    !options.text.length &&
    !!directive.modifiers &&
    columnVisibilityModifiers.hide.headerEmpty in directive.modifiers
  ) {
    // Column is hidden if its header text is empty, and the column has been given the 'hide-when-header-text-empty' modifier
    // This allows the logic to hide a column to be entirely within the jsx, however does not allow visible columns without a header to also be hidden
    // example: v-fd-column:archived.hide-when-header-text-empty="showArchived ? $t('common.archived') : ''"
    hidden = true;
  } else if (directive.arg == FDHiddenArgumentName) {
    // Column is hidden if its argument is FDHiddenArgumentName value.  This allows the column to be shown/hidden if the directive argument is bound dynamically
    // Logic for this needs to happen both in the jsx and the vue object, but it allows the dynamically visible column to have empty header text
    // example:
    //   vue: v-fd-column:[archivedColumnArg]="''"
    //    ts: computed: archivedColumnArg() { return this.showArchived ? "archived" : "__hidden"; }
    hidden = true;
  }
  return {
    name: (directive as any).rawName,
    headerMetadata: {
      ...options,
      value: directive.arg,
      sortable: !(!!directive.modifiers && "no-sort" in directive.modifiers),
      class: classes,
      cellClass: classes
    },
    minimumWidthVisible,
    maximumWidthVisible,
    hidden
  };
}

export const FDColumnDirective: DirectiveOptions = {
  bind(el, directive, vnode, oldVnode) {
    let dataTable = (vnode.componentInstance as unknown) as VDataTable;

    let columnConfig = getColumnConfig(directive, dataTable);
    if (!columnConfig) return;

    // Set up global data table data and add the column to the current instance; if this
    // is the first column to set up we'll need to do some additional construction
    createColumn(dataTable, columnConfig);
  },
  update(el, directive, vnode, oldVnode) {
    let dataTable = (vnode.componentInstance as unknown) as VDataTable;

    let columnConfig = getColumnConfig(directive, dataTable);
    if (!columnConfig) return;

    updateColumn(dataTable, columnConfig);
  },
  unbind(el, directive, vnode, oldVnode) {
    cleanupDataTableConfigOnUnbind(vnode.componentInstance!);
  }
};

export const FDRowNavigateDirective: DirectiveOptions = {
  bind(el, directive, vnode, oldVnode) {
    let dataTable = vnode.componentInstance as VDataTable;
    let dataTableConfig = dataTableConfigSet.get(dataTable);

    // Get configured size visibility boundaries
    let { minimumWidthVisible, maximumWidthVisible } = getVisibilityWidths(directive.modifiers);

    // Get or create the required table configuration
    if (!dataTableConfig) {
      dataTableConfig = {
        dataTable,
        columns: [],
        rowClickEventHandler: null,
        rowDoubleClickEventHandler: null,
        windowResizeEventHandler: null
      };
      dataTableConfigSet.set(dataTable, dataTableConfig);
    }

    createColumn(dataTable, {
      name: (directive as any).rawName,
      headerMetadata: {
        text: "",
        value: "fd-nav",
        sortable: false,
        class: "fd-navigation-cell",
        cellClass: "fd-navigation-cell"
      },
      minimumWidthVisible,
      maximumWidthVisible
    });
    dataTable.$scopedSlots["item.fd-nav"] = props => [
      dataTable.$createElement(
        "v-icon",
        {
          attrs: {
            small: true,
            disabled: false //TODO: needs to bind to something real
          }
        },
        "mdi-chevron-right"
      )
    ];

    dataTableConfig.rowDoubleClickEventHandler = (mouseEvent, options) =>
      handleRowDoubleClick(options.item, minimumWidthVisible, maximumWidthVisible, directive.value);
    dataTable.$on("dblclick:row", dataTableConfig.rowDoubleClickEventHandler);

    dataTableConfig.rowClickEventHandler = item =>
      handleRowClick(item, minimumWidthVisible, maximumWidthVisible, directive.value);
    dataTable.$on("click:row", dataTableConfig.rowClickEventHandler);

    function handleRowClick(
      item: any,
      minimumWidthVisible: number | undefined,
      maximumWidthVisible: number | undefined,
      callback: (item: any) => void
    ) {
      if (
        (!minimumWidthVisible || window.innerWidth >= minimumWidthVisible) &&
        (!maximumWidthVisible || window.innerWidth <= maximumWidthVisible)
      ) {
        callback(item);
      }
    }

    function handleRowDoubleClick(
      item: any,
      minimumWidthVisible: number | undefined,
      maximumWidthVisible: number | undefined,
      callback: (item: any) => void
    ) {
      if (!maximumWidthVisible || window.innerWidth > maximumWidthVisible) {
        callback(item);
      }
    }
  },
  unbind(el, directive, vnode, oldVnode) {
    cleanupDataTableConfigOnUnbind(vnode.componentInstance!);
  }
};

export const FDTableSortableDirective: DirectiveOptions = {
  bind(el: any, binding, vnode: any) {
    const options: SortableOptions = {
      handle: ".fd-drag-handle",
      animation: 200,
      ghostClass: "ghost",
      setData(dataTransfer: DataTransfer, draggedElement: HTMLElement) {
        vnode.child.$emit("sort:data", dataTransfer, draggedElement);
      },
      onChoose(event: SortableEvent) {
        vnode.child.$emit("sort:choose", event);
      },
      onUnchoose(event: SortableEvent) {
        vnode.child.$emit("sort:unchoose", event);
      },
      onStart(event: SortableEvent) {
        vnode.child.$emit("sort:start", event);
      },
      onEnd(event: SortableEvent) {
        vnode.child.$emit("sort:end", event);
      },
      onAdd(event: SortableEvent) {
        vnode.child.$emit("sort:add", event);
      },
      onUpdate(event: SortableEvent) {
        vnode.child.$emit("sort:update", event);
      },
      onSort(event: SortableEvent) {
        vnode.child.$emit("sort:sorted", event);
      },
      onRemove(event: SortableEvent) {
        vnode.child.$emit("sort:remove", event);
      },
      onFilter(event: SortableEvent) {
        vnode.child.$emit("sort:filter", event);
      },
      onMove(event: Sortable.MoveEvent) {
        vnode.child.$emit("sort:move", event);
      },
      onClone(event: SortableEvent) {
        vnode.child.$emit("sort:clone", event);
      },
      onChange(event: SortableEvent) {
        vnode.child.$emit("sort:change", event);
      },
      ...(binding.value as SortableOptions)
    };
    Sortable.create(el.getElementsByTagName("tbody")[0], options);

    let dataTable = vnode.componentInstance as VDataTable;

    createColumn(dataTable, {
      name: "fd-drag",
      headerMetadata: {
        text: "",
        value: "fd-drag",
        sortable: false,
        class: "fd-drag-handle",
        cellClass: "fd-drag-handle"
      },
      minimumWidthVisible: undefined,
      maximumWidthVisible: undefined,
      hidden: false
    });
    dataTable.$scopedSlots["item.fd-drag"] = props => [
      dataTable.$createElement(
        "v-icon",
        {
          attrs: {
            small: true
          }
        },
        "far fa-grip-lines"
      )
    ];
  }
};

