import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  scaffoldService,
  legacyReferenceDataService,
  ScaffoldWithDetails,
  ScaffoldStatuses,
  Tag,
  ProjectLocation,
  projectLocationService
} from "../services";
import userAccess from "../dataMixins/userAccess";
import { filterByTags } from "../services/taggableItems";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { valueInArray } from "@fd/lib/client-util/array";

type ScaffoldWithExtraDetails = ScaffoldWithDetails & {
  archived: boolean;
  tagNumber: string;
  specificWorkLocation: string | undefined;
  scaffoldContractorName: string | undefined;
  plannedErect: string | undefined;
  actualErect: string | undefined;
  plannedDismantle: string | undefined;
  actualDismantle: string | undefined;
  workPackageNames: string[] | undefined;
  daysStanding: number | undefined;
};

type FilteringContext = {
  dismantled: boolean;
  standing: boolean;
};

export default FDVue.extend({
  name: "fd-scaffolds-list",

  mixins: [errorHandling, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    bgcolor: (el, binding) => {
      el.style.backgroundColor = binding.arg!;
    }
  },

  components: {},

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // allContractors: [] as ContractorWithTags[],

      submitting: false,
      approving: false,
      declining: false,
      archivedLoading: false,

      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      allScaffolds: [] as ScaffoldWithExtraDetails[],
      legacyStatusNames: [] as string[],
      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1],

      scaffoldsForMockup: [
        {
          tagNumber: "1234",
          scaffoldStatusName: "Dismantled",
          areaName: "4000 | Utility",
          subAreaName: "4120 | INTERCONNECT PIPE RACK [MIDDLE]",
          specificWorkLocation: "1150 area substation column A/7",
          scaffoldContractorName: "Sample Contractor",
          plannedErect: "Mon, Nov 1, 2021",
          actualErect: "Tue, Nov 2, 2021",
          plannedDismantle: "Thu, Nov 12, 2021",
          actualDismantle: "N/A",
          workPackages: "workpack1"
        }
      ]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["customSort", "formatDate"];
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return this.showArchived ? 5 : 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return this.showArchived ? 11 : 10;
      } else if (this.$vuetify.breakpoint.md) {
        return this.showArchived ? 14 : 13;
      }

      return 25;
    },

    filterContext: {
      get(): FilteringContext {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    standingContextSelected: {
      get(): boolean {
        return this.filterContext.standing;
      },
      set(val: boolean) {
        var context = this.filterContext;
        context.standing = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },

    dismantledContextSelected: {
      get(): boolean {
        return this.filterContext.dismantled;
      },
      set(val: boolean) {
        var context = this.filterContext;
        context.dismantled = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },

    canViewContractorFilter(): boolean {
      let allSelectableContractorIDs = this.allScaffolds.map(x => x.contractorID);
      let distinctSelectableContractorID = [...new Set(allSelectableContractorIDs)];
      return distinctSelectableContractorID.length > 0;
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    selectedStatuses: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.statusesForFiltering;
      },
      set(val) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    },

    selectableAreas(): any[] {
      let selectableAreaIDs = this.allScaffolds.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => selectableAreaIDs.includes(x.id!));
    },

    selectedAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): any[] {
      let selectableSubAreaIDs = this.allScaffolds
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          selectableSubAreaIDs.includes(x.id!) &&
          !!selectedAreaIDs.length &&
          selectedAreaIDs.includes(x.parentLocationID)
      );
    },

    selectedSubAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectedContractorNames: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get(): boolean {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val: boolean) {
        this.allScaffolds = [];
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.loadScaffolds();

          if (this.standingContextSelected && this.standingCount == 0) {
            this.standingContextSelected = false;
          }
          if (this.dismantledContextSelected && this.dismantledCount == 0) {
            this.dismantledContextSelected = false;
          }
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      console.log(`showArchivedMinDate minDate: ${minDate}`);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      console.log(`showArchivedMaxDate maxDate: ${maxDate}`);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        console.log(`showArchivedDateRange.get dates: ${dates}`);
        return dates;
      },
      async set(val: any[]) {
        console.log(`showArchivedDateRange.set val: ${val}`);
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.loadScaffolds();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    selectableFilterStatuses(): any[] {
      return this.legacyStatusNames.map(x => {
        return {
          name: x,
          count: this.allScaffolds.filter(wo => wo.scaffoldStatusName == x).length
        };
      });
    },

    selectableContractorNames(): string[] {
      let allContractorNames = this.allScaffolds.map(x => x.contractorName);
      let distinctContractorNames = [...new Set(allContractorNames)].map(
        x => x ?? `${this.$t("common.unassigned")}`
      );
      return distinctContractorNames.sort();
    },

    statusItems(): { value: number; text: string }[] {
      var values = Object.keys(ScaffoldStatuses);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: ScaffoldStatuses[x]
        };
      });
      return items;
    },
    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allScaffolds);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },
    standingCount(): number {
      return this.allScaffolds?.filter(x => x.scaffoldStatus == ScaffoldStatuses.Erected).length;
    },
    dismantledCount(): number {
      return this.allScaffolds?.filter(
        x =>
          x.scaffoldStatus == ScaffoldStatuses.Dismantled ||
          x.scaffoldStatus == ScaffoldStatuses.Cancelled
      ).length;
    },
    scaffolds(): ScaffoldWithExtraDetails[] {
      var selectedScaffolds = this.allScaffolds;

      selectedScaffolds = selectedScaffolds.filter(
        x =>
          (this.standingContextSelected && x.scaffoldStatus == ScaffoldStatuses.Erected) ||
          (this.dismantledContextSelected &&
            (x.scaffoldStatus == ScaffoldStatuses.Dismantled ||
              x.scaffoldStatus == ScaffoldStatuses.Cancelled))
      );

      if (this.selectedStatuses.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.scaffoldStatusName, this.selectedStatuses)
        );
      }
      if (this.selectedContractorNames.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(
            x.scaffoldContractorName ?? `${this.$t("common.unassigned")}`,
            this.selectedContractorNames
          )
        );
      }
      if (this.selectedAreas.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (this.selectedSubAreas.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }

      selectedScaffolds = filterByTags(this.tagsSelectedForFiltering, selectedScaffolds);

      return selectedScaffolds;
    }
  },

  methods: {
    formatDate(item: Date | string | undefined | null): string {
      return DateUtil.stripTimeFromLocalizedDateTime(item);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    customSort(
      items: ScaffoldWithDetails[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let temp = {} as ScaffoldWithDetails;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "tagNumber") {
        propName = "legacyID";
      } else if (propName === "plannedErect") {
        propName = "plannedErectDate";
      } else if (propName === "actualErect") {
        propName = "actualErectDate";
      } else if (propName === "plannedDismantle") {
        propName = "plannedDismantleDate";
      }

      items.sort((a: ScaffoldWithDetails, b: ScaffoldWithDetails) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
      return items;
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadScaffolds();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadScaffolds() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      let scaffolds = await scaffoldService.getAllScaffolds(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.allScaffolds = scaffolds.map(x => {
        return {
          ...x,
          archived: !!x.archivedDate,
          tagNumber: `${x.legacyID}`,
          specificWorkLocation: x.specificWorkLocation,
          scaffoldContractorName: x.lastWorkOrderContractorName,
          plannedErect: !!x.plannedErectDate
            ? DateUtil.stripTimeFromLocalizedDateTime(x.plannedErectDate)
            : undefined,
          actualErect: !!x.actualErectDate
            ? DateUtil.stripTimeFromLocalizedDateTime(x.actualErectDate)
            : undefined,
          plannedDismantle: !!x.plannedDismantleDate
            ? DateUtil.stripTimeFromLocalizedDateTime(x.plannedDismantleDate)
            : undefined,
          actualDismantle: !!x.actualDismantleDate
            ? DateUtil.stripTimeFromLocalizedDateTime(x.actualDismantleDate)
            : undefined,
          workPackageNames: x.workPackages?.map(x => (x.name ?? "") + " | " + (x.activityID ?? "")),
          daysStanding: !!x.actualErectDate
            ? Math.round(
                ((!!x.actualDismantleDate ? x.actualDismantleDate : new Date()).getTime() -
                  x.actualErectDate.getTime()) /
                  86400000
              )
            : undefined
        };
      });
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // DOES NOT manage processing or error message logic
    async loadLegacyStatusNames(): Promise<void> {
      this.legacyStatusNames = (await legacyReferenceDataService.getAllScaffoldStatuses()).map(
        x => x.name!
      );
    },

    // DOES NOT manage processing or error message logic
    // async loadContractors(): Promise<void> {
    //   this.allContractors = await contractorService.getAll(false, null, null);
    // },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeCreate: async function() {},

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffolds",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: { standing: true, dismantled: false } as FilteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffolds.list-title"),
      to: "/scaffolds",
      resetHistory: true
    });
    try {
      await Promise.all([
        this.loadLegacyStatusNames(),
        this.loadScaffolds(),
        this.loadAreas(),
        this.loadSubAreas()
        // this.loadContractors()
      ]);

      if (this.standingContextSelected && this.standingCount == 0) {
        this.standingContextSelected = false;
      }
      if (this.dismantledContextSelected && this.dismantledCount == 0) {
        this.dismantledContextSelected = false;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

