var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: "",
        disabled:
          !_vm.value || (!_vm.showRequestBadge && !_vm.showWorkOrderBadge)
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              !!_vm.value
                ? _c(
                    "v-row",
                    _vm._g({ attrs: { "no-gutters": "" } }, on),
                    [
                      _c(
                        "v-badge",
                        {
                          staticClass:
                            "fd-scheduler-scaffold-work-orders-badge",
                          attrs: {
                            value: _vm.showRequestBadge,
                            color: "primary",
                            content: _vm.requestCount,
                            tile: ""
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.value))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-badge",
                        {
                          staticClass:
                            "fd-scheduler-scaffold-work-orders-badge",
                          style: "left: " + _vm.workOrderBadgePadding + "px;",
                          attrs: {
                            value: _vm.showWorkOrderBadge,
                            color: "green",
                            content: _vm.workOrderCount
                          }
                        },
                        [_vm._v(" \n      ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-badge",
                        {
                          staticClass:
                            "fd-scheduler-scaffold-work-orders-badge fd-triangle-down-badge",
                          style: "left: " + _vm.dismantleBadgePadding + "px;",
                          attrs: {
                            value: _vm.showDismantleBadge,
                            color: "red",
                            content: "D"
                          }
                        },
                        [_vm._v(" \n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }
        }
      ])
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.tooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }