var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName:
          "v-fd-column:[iconColumnArgument].no-sort.class_fd-user-inactive-cell.hide-when-small",
        value: "",
        expression: "''",
        arg: _vm.iconColumnArgument,
        modifiers: {
          "no-sort": true,
          "class_fd-user-inactive-cell": true,
          "hide-when-small": true
        }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:image.no-sort.class_fd-user-image-cell.hide-when-small",
        value: "",
        expression: "''",
        arg: "image",
        modifiers: {
          "no-sort": true,
          "class_fd-user-image-cell": true,
          "hide-when-small": true
        }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:lastName",
        value: _vm.$t("common.lastname"),
        expression: "$t('common.lastname')",
        arg: "lastName"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:firstName",
        value: _vm.$t("common.firstname"),
        expression: "$t('common.firstname')",
        arg: "firstName"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:contractor.hide-when-small",
        value: _vm.$t("contractors.contractor-table-header"),
        expression: "$t('contractors.contractor-table-header')",
        arg: "contractor",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:employeeCode.hide-when-small",
        value: _vm.$t("users.employee-code"),
        expression: "$t('users.employee-code')",
        arg: "employeeCode",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty.class_fd-archived-cell",
        value: _vm.showArchived ? _vm.$t("common.archived") : "",
        expression: "\n    showArchived ? $t('common.archived') : ''\n  ",
        arg: "archived",
        modifiers: {
          "hide-when-extra-small": true,
          "hide-when-header-text-empty": true,
          "class_fd-archived-cell": true
        }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:canLogIn.hide-when-extra-small.class_fd-cansignin-cell",
        value: _vm.$t("users.can-sign-in-label"),
        expression: "\n    $t('users.can-sign-in-label')\n  ",
        arg: "canLogIn",
        modifiers: {
          "hide-when-extra-small": true,
          "class_fd-cansignin-cell": true
        }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
        arg: "actions",
        modifiers: {
          "no-sort": true,
          "hide-when-extra-small": true,
          "class_fd-actions-cell": true
        }
      },
      {
        name: "fd-row-navigate",
        rawName: "v-fd-row-navigate.show-when-extra-small",
        value: function(item) {
          return _vm.$emit("navigate", item)
        },
        expression: "item => $emit('navigate', item)",
        modifiers: { "show-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:primaryEmailAddress.hidden",
        value: "",
        expression: "''",
        arg: "primaryEmailAddress",
        modifiers: { hidden: true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:primaryPhoneNumber.hidden",
        value: "",
        expression: "''",
        arg: "primaryPhoneNumber",
        modifiers: { hidden: true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:roles.hidden",
        value: "",
        expression: "''",
        arg: "roles",
        modifiers: { hidden: true }
      }
    ],
    class: [
      { "fd-icon-table": _vm.anyInactiveUsers },
      "fd-actions-table",
      { "fd-archiving-table": _vm.showArchived },
      { "fd-archive-bonus-padding-bottom": _vm.showArchived }
    ],
    attrs: {
      "data-cy": "people-list",
      items: _vm.items,
      search: _vm.tablesearch,
      loading: _vm.processing,
      "loading-text": _vm.$t("common.table-loading-message"),
      "sort-by": ["lastName"],
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([
      {
        key: "footer.prepend",
        fn: function() {
          return [
            _c("fd-archived-data-loader", {
              staticClass: "ml-2 mr-3",
              attrs: {
                showArchived: _vm.showArchived,
                showArchivedDateRange: _vm.showArchivedDateRange,
                showArchivedMinDate: _vm.showArchivedMinDate,
                showArchivedMaxDate: _vm.showArchivedMaxDate,
                loading: _vm.archivedLoading,
                disabled: _vm.processing
              },
              on: {
                "input:showArchived": function(v) {
                  return _vm.$emit("showArchivedChanged", v)
                },
                "input:showArchivedDateRange": function(v) {
                  return _vm.$emit("showArchivedDateRangeChanged", v)
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "item.fd-nav",
        fn: function() {
          return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
        },
        proxy: true
      },
      {
        key: "item.isActiveWithUnusedLogin",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: {
                  disabled: !_vm.$vuetify.breakpoint.lgAndUp || _vm.processing,
                  right: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          item.isActiveWithUnusedLogin
                            ? _c("v-icon", _vm._g({}, on), [
                                _vm._v("far fa-snooze")
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("users.person-has-unused-login")) +
                    "\n    "
                )
              ]
            )
          ]
        }
      },
      {
        key: "item.image",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-img", {
              staticStyle: { "border-radius": "50%" },
              attrs: {
                height: "44",
                width: "44",
                "lazy-src": "",
                src:
                  "/services/FormidableDesigns.Services.V1.UserService.DownloadUserPhoto?id=" +
                  item.id
              }
            })
          ]
        }
      },
      {
        key: "item.contractor",
        fn: function(ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.contractorsInUse.length > 0
                    ? (
                        _vm.contractorsInUse.find(function(x) {
                          return x.id == item.contractorID
                        }) || { name: "" }
                      ).name
                    : ""
                ) +
                "\n  "
            )
          ]
        }
      },
      {
        key: "item.archived",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("fd-checkbox", {
              attrs: { value: item.isArchived, disabled: _vm.processing },
              on: {
                input: function($event) {
                  return _vm.$emit("flipArchived", item)
                }
              }
            })
          ]
        }
      },
      {
        key: "item.canLogIn",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("fd-checkbox", {
              attrs: {
                cy: "cansignin",
                value: item.isLoginActive,
                readonly: item.isArchived || item.loginState == 0,
                disabled:
                  item.isArchived || item.loginState == 0 || _vm.processing,
                "inactive-tooltip": item.isArchived
                  ? _vm.$t("users.archived-people-cannot-sign-in")
                  : _vm.$t("users.view-details-to-create-new-user")
              },
              on: {
                input: function($event) {
                  return _vm.$emit("flipCanLogIn", item)
                }
              }
            })
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "div",
              { staticClass: "table-actions-column-min-width" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled:
                        !_vm.$vuetify.breakpoint.lgAndUp || _vm.processing,
                      left: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      link: "",
                                      disabled: _vm.processing
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("navigate", item)
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      "\n              mdi-pencil\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled:
                        !_vm.$vuetify.breakpoint.lgAndUp || _vm.processing,
                      top: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      disabled: _vm.processing
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        disabled: _vm.processing
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "deleteTableItem",
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-delete\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.remove")))])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }