var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px",
        "content-class": "fd-date-picker-content",
        "data-cy": (!!_vm.cy ? _vm.cy : _vm.dataCy) + "-picker-menu"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "fd-date-picker-text-field",
                      class: _vm.textFieldClass,
                      style: _vm.textFieldStyle,
                      attrs: {
                        "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
                        label: _vm.$props.label,
                        value: _vm.formattedDate,
                        rules: _vm.$props.rules,
                        disabled: _vm.$props.disabled,
                        readonly: "",
                        outlined: _vm.outlined,
                        clearable: _vm.clearable
                      },
                      on: { "click:clear": _vm.clear },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-input__icon v-input__icon--prepend-inner"
                                  },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        class: {
                                          "v-badge--dense":
                                            _vm.displayBadge &&
                                            !_vm.displayBadgeAsDot
                                        },
                                        attrs: {
                                          dot: _vm.displayBadgeAsDot,
                                          value:
                                            _vm.displayDot || _vm.displayBadge,
                                          color: _vm.badgeColor,
                                          content: _vm.badgeContent
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "\n              event\n            "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    "v-text-field",
                    _vm.$attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen"
      }
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        staticClass: "fd-date-picker",
        attrs: {
          disabled: _vm.$props.disabled,
          locale: _vm.i18nLocale,
          min: _vm.formattedMin,
          max: _vm.formattedMax,
          "data-cy": (!!_vm.cy ? _vm.cy : _vm.dataCy) + "-date-picker"
        },
        on: { input: _vm.input },
        model: {
          value: _vm.valueString,
          callback: function($$v) {
            _vm.valueString = $$v
          },
          expression: "valueString"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }