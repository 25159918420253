import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  CountSheetGroupWithSortedParts,
  CountSheetSelectionType,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../../../dataMixins/countSheet";
import {
  CountSheetReviewStatus,
  CountSheetType,
  CountSheetWithParts,
  ScaffoldRequestTypes,
  WorkOrderWithLegacyDetails
} from "../../../services";

export default FDVue.extend({
  name: "fd-count-sheet-form",

  mixins: [serviceErrorHandling],

  components: {},

  props: {
    workOrder: { type: Object },
    countSheet: { type: Object },
    countSheetGroups: { type: Array },
    restrictContentHeight: { type: Boolean, default: true },
    preventApproval: { type: Boolean, default: false },
    canPickIndividual: { type: Boolean, default: true },
    canRemoveAll: { type: Boolean, default: true },
    canHaveNoMaterial: { type: Boolean, default: true },
    forceReadonly: { type: Boolean, default: false }
  },

  data: function() {
    return {
      openPanels: [] as number[],
      isOverriding: false,
      isDeclining: false,
      hideAssignedTotal: false,
      initialCountSheetTypeID: undefined as CountSheetType | undefined
    };
  },

  watch: {
    countSheet() {
      this.setSelectionTypeFromCountSheetTypeID(this.countSheet?.countSheetTypeID);
      console.log(`CountSheetForm.countSheet changed selectionType: ${this.selectionType}`);
      if (this.selectionType == "removeall" && this.countSheetIsEditable) {
        this.partsForSummary.forEach(x => {
          x.overridden = false;
          x.rejected = false;
          x.addCount = 0;
          x.addCountOverride = 0;
          x.removeCount = x.assigned > 0 ? x.assigned : 0;
          x.removeCountOverride = 0;
          x.total = x.assigned - x.removeCount;
          x.totalOverride = 0;
        });
      }
    },
    countSheetGroups() {
      // If the count sheet is approved, the summary panel is the only one visible, so open it automatically
      if (!this.countSheetIsEditable || this.partsForSummary.length > 0) this.openPanels = [0];
      else this.openPanels = [];

      let overriddenParts = this.partsForSummary.filter(x => x.overridden);
      this.isOverriding = overriddenParts.length > 0;
      console.log(`CountSheetForm.countSheetGroups changed selectionType: ${this.selectionType}`);
    }
  },

  computed: {
    showSelectionTypeBar(): boolean {
      let optionCount = 0;
      if (this.canPickIndividual) optionCount += 1;
      if (this.canRemoveAll) optionCount += 1;
      if (this.canHaveNoMaterial) optionCount += 1;
      return optionCount > 1;
    },
    selectionType: {
      get(): CountSheetSelectionType {
        let selectionType: CountSheetSelectionType = "individual";
        if (this.countSheet?.countSheetTypeID == CountSheetType.NotApplicable)
          selectionType = "notapplicable";
        else if (this.countSheet?.countSheetTypeID == CountSheetType.RemoveAll)
          selectionType = "removeall";
        return selectionType;
      },
      set(val: CountSheetSelectionType) {
        let countSheetTypeID = CountSheetType.Individual;
        if (val == "notapplicable") countSheetTypeID = CountSheetType.NotApplicable;
        else if (val == "removeall") countSheetTypeID = CountSheetType.RemoveAll;

        var validSelectionTypes: CountSheetType[] = [];
        if (this.canPickIndividual) validSelectionTypes.push(CountSheetType.Individual);
        if (this.canRemoveAll) validSelectionTypes.push(CountSheetType.RemoveAll);
        if (this.canHaveNoMaterial) validSelectionTypes.push(CountSheetType.NotApplicable);

        if (!validSelectionTypes.includes(countSheetTypeID))
          countSheetTypeID = validSelectionTypes[0];

        if (!!this.countSheet) this.countSheet.countSheetTypeID = countSheetTypeID;

        if (!this.initialCountSheetTypeID) this.initialCountSheetTypeID = countSheetTypeID;
        console.log(
          `CountSheetForm.selectionType.set countSheetTypeID: ${countSheetTypeID} (${CountSheetType[countSheetTypeID]})`
        );
      }
    },
    partIdentifierColumns(): number {
      let columns = 6;
      if (this.hideAssignedTotal) columns = 8;
      if (this.allowAdminActions) columns -= 1;
      if (this.$vuetify.breakpoint.xsOnly) columns -= 1;
      return columns;
    },
    partAmountColumns(): number {
      let columns = 6;
      if (this.hideAssignedTotal) columns = 4;
      if (this.allowAdminActions) columns -= 1;
      if (this.countSheetIsEditable) columns -= 1;
      if (this.$vuetify.breakpoint.xsOnly) columns += 1;
      return columns;
    },
    partEditClearColumns(): number {
      if (this.countSheetIsEditable) return 1;
      return 0;
    },
    partAdminActionsColumns(): number {
      if (this.allowAdminActions) return 2;
      return 0;
    },
    // We can't attach a v-if onto expansion panels for each group, so this does that check for us
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (!this.countSheetIsEditable && !this.allowAdminActions) return [];
      if (this.selectionType == "removeall" || this.selectionType == "notapplicable") return [];
      return this.countSheetGroups as CountSheetGroupWithSortedParts[];
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(
        this.countSheetGroups as CountSheetGroupWithSortedParts[]
      );
    },
    totalAssignedForSummary(): number {
      return this.partsForSummary.reduce((a, b) => a + +b.assigned, 0);
    },
    totalHasAnyOverrides(): boolean {
      let overriddenPart = this.partsForSummary.find(x => !!x.overridden);
      return !!overriddenPart;
    },
    totalAddCountForSummary(): number {
      return this.partsForSummary.reduce((a, b) => a + +b.addCount, 0);
    },
    totalAddCountWithOverridesForSummary(): number {
      return this.partsForSummary.reduce(
        (a, b) => a + (b.overridden ? +b.addCountOverride! : +b.addCount),
        0
      );
    },
    totalRemoveCountForSummary(): number {
      return this.partsForSummary.reduce((a, b) => a + +b.removeCount, 0);
    },
    totalRemoveCountWithOverridesForSummary(): number {
      return this.partsForSummary.reduce(
        (a, b) => a + (b.overridden ? +b.removeCountOverride! : +b.removeCount),
        0
      );
    },
    totalTotalForSummary(): number {
      return this.partsForSummary.reduce((a, b) => a + +b.total, 0);
    },
    totalTotalWithOverridesForSummary(): number {
      return this.partsForSummary.reduce(
        (a, b) => a + (b.overridden ? +b.totalOverride! : +b.total),
        0
      );
    },
    countSheetIsDeclined(): boolean {
      return this.countSheet?.reviewStatusID == CountSheetReviewStatus.Declined;
    },
    countSheetIsEditable(): boolean {
      return (
        !this.forceReadonly &&
        (this.countSheet?.reviewStatusID == CountSheetReviewStatus.Draft ||
          this.countSheet?.reviewStatusID == CountSheetReviewStatus.Declined)
      );
    },
    countSheetCanAddParts(): boolean {
      return (
        (this.workOrder as WorkOrderWithLegacyDetails).scaffoldRequestType !=
        ScaffoldRequestTypes.Dismantle
      );
    },
    countSheetCanRemoveParts(): boolean {
      return (
        (this.workOrder as WorkOrderWithLegacyDetails).scaffoldRequestType !=
        ScaffoldRequestTypes.Erect
      );
    },
    allowAdminActions(): boolean {
      return !this.preventApproval && (this.countSheetIsOverridable || this.countSheetIsApprovable);
    },
    countSheetIsOverridable(): boolean {
      return (
        !this.preventApproval &&
        this.countSheet?.reviewStatusID == CountSheetReviewStatus.Pending &&
        (this.countSheet as CountSheetWithParts).currentUserPermissions.canOverrideCountSheetValues
      );
    },
    countSheetIsApprovable(): boolean {
      return (
        !this.preventApproval &&
        this.countSheet?.reviewStatusID == CountSheetReviewStatus.Pending &&
        (this.countSheet as CountSheetWithParts).currentUserPermissions.canApprove
      );
    }
  },

  methods: {
    setSelectionTypeFromCountSheetTypeID(countSheetTypeID: CountSheetType) {
      if (!!countSheetTypeID) {
        if (countSheetTypeID == CountSheetType.NotApplicable) this.selectionType = "notapplicable";
        else if (countSheetTypeID == CountSheetType.RemoveAll) this.selectionType = "removeall";
        else if (countSheetTypeID == CountSheetType.Individual) this.selectionType = "individual";
      } else {
        this.selectionType = "individual";
      }
    },
    overridePartCounts(part: PartWithCounts) {
      if (this.isDeclining) return;
      if (!this.isOverriding) {
        this.isOverriding = true;
        this.$emit("override:start");
      }
      if (this.selectionType != "individual")
        this.setSelectionTypeFromCountSheetTypeID(CountSheetType.Individual);

      part.overridden = true;
    },
    cancelOverridePartCounts(part: PartWithCounts) {
      part.overridden = false;
      part.addCountOverride = 0;
      part.removeCountOverride = 0;
      part.totalOverride = 0;
      let overriddenParts = this.partsForSummary.filter(x => x.overridden);
      if (overriddenParts.length == 0) {
        this.isOverriding = false;
        this.$emit("override:cancel");
      }
      if (
        !!this.initialCountSheetTypeID &&
        this.initialCountSheetTypeID != this.countSheet.countSheetTypeID
      ) {
        this.setSelectionTypeFromCountSheetTypeID(this.initialCountSheetTypeID);
      }
    },
    declinePartCounts(part: PartWithCounts) {
      if (this.isOverriding) return;
      if (!this.isDeclining) {
        this.isDeclining = true;
        this.$emit("decline:start");
      }

      part.rejected = true;
      console.log(`  after rejected: ${part.rejected}`);
    },
    cancelDeclinePartCounts(part: PartWithCounts) {
      part.rejected = false;
      let declinedParts = this.partsForSummary.filter(x => x.rejected === true);
      if (declinedParts.length == 0) {
        this.isDeclining = false;
        this.$emit("decline:cancel");
      }
    },
    clearPartCounts(part: PartWithCounts) {
      part.addCount = 0;
      part.removeCount = 0;
      part.total = part.assigned;
    },
    partAddValueChanged(part: PartWithCounts) {
      part.total = (part.assigned ?? 0) + (part.addCount ?? 0) - (part.removeCount ?? 0);
    },
    partAddOverrideValueChanged(part: PartWithCounts) {
      part.totalOverride =
        (part.assigned ?? 0) + (part.addCountOverride ?? 0) - (part.removeCountOverride ?? 0);
      console.log(`partAddOverrideValueChanged new total: ${part.totalOverride}`);
    },
    partRemoveValueChanged(part: PartWithCounts) {
      part.total = (part.assigned ?? 0) + (part.addCount ?? 0) - (part.removeCount ?? 0);
    },
    partRemoveOverrideValueChanged(part: PartWithCounts) {
      part.totalOverride =
        (part.assigned ?? 0) + (part.addCountOverride ?? 0) - (part.removeCountOverride ?? 0);
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!) + 1;
      if (!this.openPanels.includes(groupPanelNumber)) {
        this.openPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectLastFieldInPreviousGroup(fieldName: string, currentGroupNumber: number) {
      if (currentGroupNumber <= 1) return;
      let groupNumber = currentGroupNumber - 1;
      var visibleGroup = this.visibleCountSheetGroups.find(x => x.order == groupNumber);
      if (!visibleGroup) {
        this.selectLastFieldInPreviousGroup(fieldName, groupNumber);
        return;
      }
      let self = this;
      // Wait a tick to allow the table's page change to update its current items
      this.$nextTick(() => {
        self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let currentGroupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        this.selectLastFieldInPreviousGroup(fieldName, currentGroupNumber);
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, currentGroupNumber, previousIndex);
      e.preventDefault();
    },
    selectFirstFieldInNextGroup(fieldName: string, currentGroupNumber: number) {
      let groupNumber = currentGroupNumber + 1;
      let maxGroupNumber = this.visibleCountSheetGroups.reduce(
        (a: number, b) => Math.max(b.order ?? -1, a),
        0
      );
      if (groupNumber > maxGroupNumber) return;

      var visibleGroup = this.visibleCountSheetGroups.find(x => x.order == groupNumber);
      if (!visibleGroup) {
        this.selectFirstFieldInNextGroup(fieldName, groupNumber);
        return;
      }

      let self = this;
      // Wait a tick to allow the table's page change to update its current items
      this.$nextTick(() => {
        self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
      });
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let currentGroupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        this.selectFirstFieldInNextGroup(fieldName, currentGroupNumber);
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, currentGroupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    }
  },

  mounted: function() {
    console.log(`CountSheetForm.mounted selectionType: ${this.selectionType}`);
  }
});

