import { max } from "moment";
import FDVue from "..";
import ImageView from "@fd/lib/vue/components/ImageViewer.vue";
import ImageEdit from "@fd/lib/vue/components/ImageEditor.vue";
export { ImageInfo } from "../components/ImageViewer.vue";
import heic2any from "heic2any";

export type FileData = {
  name: string;
  file?: Blob;
  isPhoto?: boolean;
  isPreviewable?: boolean;
};

// Picture file extensions which we can display in-app
export const photoFileExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "heic",
  "heif",
  "tiff",
  "tif",
  "bmp",
  "webp",
  "ico"
];
// Any "photo" file is previewable
export const previewableFileExtensions = photoFileExtensions.concat([]);
// Any previewable (including photo) file can be opened in a new tab
export const openInNewWindowsFileExtensions = previewableFileExtensions.concat([
  "pdf",
  "svg",
  "html"
]);

export const mimeTypesByFileExtension = {
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  svg: "image/svg+xml",
  heic: "image/jpeg", // Gets converted to a jpeg
  heif: "image/jpeg", // Gets converted to a jpeg
  tiff: "image/tiff",
  tif: "image/tiff",
  bmp: "image/bmp",
  webp: "image/webp",
  ico: "image/x-icon",
  pdf: "application/pdf"
} as { [type: string]: string };

export function componentsFromFileName(
  fileName: string | undefined
): { extension: string; name: string } {
  var extension = (fileName ?? "").split(".").pop() ?? "";
  var name = (fileName ?? "").replace("." + extension, "");
  return {
    extension: extension,
    name: name
  };
}
export function confirmUniqueName(
  fileName: string,
  referenceFiles: FileData[],
  foundCount: number = 0
): string {
  console.log(`confirmUniqueName fileName: ${fileName} in ${referenceFiles.map(x => x.name)}`);
  var newFileName = fileName.toLowerCase();
  var foundFile = referenceFiles.find(x => x.name.toLowerCase() == fileName.toLowerCase());
  if (foundFile != undefined) {
    console.log(`\tFound file with name ${foundFile.name}`);

    var extension =
      fileName
        ?.toLowerCase()
        .split(".")
        .pop() ?? "";
    var name = newFileName.replace("." + extension, "");

    if (foundCount >= 1) {
      let oldSuffix = ` (${foundCount})`;
      foundCount += 1;
      let newSuffix = ` (${foundCount})`;
      console.log(`\tReplacing ${oldSuffix} with ${newSuffix}`);
      name = name.replace(oldSuffix, newSuffix);
    } else {
      foundCount += 1;
      let newSuffix = ` (${foundCount})`;
      console.log(`\tAdding suffix ${newSuffix} to ${name}`);
      name = `${name}${newSuffix}`;
    }

    newFileName = `${name}.${extension}`;
    console.log(`\tConfirming uniqueness of ${newFileName}`);
    newFileName = confirmUniqueName(newFileName, referenceFiles, foundCount);
  }
  console.log(`unique name found: ${newFileName}`);
  return newFileName;
}
export function isFilePhoto(fileName: string): boolean {
  var extension = fileName
    ?.toLowerCase()
    .split(".")
    .pop();
  if (!extension) return false;
  return photoFileExtensions.includes(extension);
}
export function isFilePreviewable(fileName: string): boolean {
  var extension = fileName
    ?.toLowerCase()
    .split(".")
    .pop();
  if (!extension) return false;
  return previewableFileExtensions.includes(extension);
}
export function canOpenFileInNewWindow(fileName: string | null | undefined): boolean {
  if (!fileName?.length) return false;
  var extension = fileName
    ?.toLowerCase()
    .split(".")
    .pop();
  if (!extension) return false;
  return openInNewWindowsFileExtensions.includes(extension);
}
export function mimeTypeForFileName(fileName: string): string {
  var extension = fileName
    ?.toLowerCase()
    .split(".")
    .pop();
  if (!extension) return "";
  return mimeTypesByFileExtension[extension];
}

export default FDVue.extend({
  components: {
    "fd-image-viewer": ImageView,
    "fd-image-editor": ImageEdit
  },
  data: function() {
    return {
      imageName: "",
      imageSource: "",
      editImageSource: undefined as any
    };
  },
  methods: {
    /**
     * Convenience method to interrogate the file type and optimize as necessary before uploading a file to the server.
     * @param {File} originalFile The file being uploaded to the server and therefore needing to be optimized. Image files will be optimized if possible, non-image files will not be changed.
     * @param {Array} existingFileList A list of existing files against which to compare the name of the file being optimized, to ensure a unique name.
     * @returns {FileData} `FileData` object containing the optimized file (if an image), or the original file if not.
     */
    async optimizedFileDataForUpload(
      originalFile: File,
      existingFileList?: Array<FileData> | undefined
    ): Promise<FileData | undefined> {
      if (!originalFile) return undefined;

      let file = originalFile;
      let isPreviewable = isFilePreviewable(file.name);
      let isImage = isFilePhoto(file.name);
      if (isImage) {
        // PNG files don't get modified in any way passing in a lower quality
        // So we save space by restricting file dimensions instead
        if (file.type == "image/png") {
          file = await this.resizeImageFile(originalFile, 1920, 1920);
        } else {
          file = await this.resizeImageFile(originalFile, undefined, undefined, 0.25);
        }
      }

      let uniqueFileName = file.name;
      if (!!existingFileList?.length) {
        uniqueFileName = confirmUniqueName(file.name, existingFileList);
      }
      let fileData: FileData = {
        name: uniqueFileName,
        file: file,
        isPreviewable: isPreviewable,
        isPhoto: isImage
      };
      return fileData;
    },
    /**
     * Loads the Base64 string to an image element, and then resizes it using `resizeImageFile`
     * @param {String} base64 - The base64 string (must include MIME type)
     * @param {Number} newWidth - The width of the image in pixels
     * @param {Number} newHeight - The height of the image in pixels
     * @param {Number} type - The standard MIME type for the image format to return. If you do not specify this parameter, the default value is a PNG format image.
     * @param {Number} quality - A Number between 0 and 1 indicating the image quality to be used when creating images using file formats that support lossy compression (such as image/jpeg or image/webp). A user agent will use its default quality value if this option is not specified, or if the number is outside the allowed range.
     */
    async resizeBase64Img(
      base64: string,
      maxWidth: number | undefined,
      maxHeight: number | undefined,
      type: string | undefined,
      quality: number | undefined
    ): Promise<string> {
      let loadedImage = await this.getImageFromDataURL(base64);
      let width = loadedImage.width;
      let height = loadedImage.height;

      let newDimensions = this.calculateImageScale(width, height, maxWidth, maxHeight);
      let newWidth = newDimensions.width;
      let newHeight = newDimensions.height;

      if (width == newWidth && height == newHeight && quality == 1) return base64;

      return this.resizeImage(loadedImage, newWidth, newHeight, type, quality);
    },
    /**
     * Converts the file to a base 64 string, resizes it using `resizeBase64Img`, then converts that result back to a File to be returned.
     * @param {Number} type - The standard MIME type for the image format to return. If you do not specify this parameter, the default value is a PNG format image.
     * @param {Number} quality - A Number between 0 and 1 indicating the image quality to be used when creating images using file formats that support lossy compression (such as image/jpeg or image/webp). A user agent will use its default quality value if this option is not specified, or if the number is outside the allowed range.
     */
    async resizeImageFile(
      file: File,
      maxWidth: number | undefined,
      maxHeight: number | undefined,
      quality?: number | undefined
    ): Promise<File> {
      if (file.name.toLowerCase().includes(".heic") || file.name.toLowerCase().includes(".heif")) {
        var convertedBlob: Blob = (await heic2any({ blob: file, toType: "image/jpeg" })) as Blob;
        var extension = file.name?.split(".").pop() ?? "";
        let filename = file.name.replace(`.${extension}`, ".jpeg");
        file = new File([convertedBlob], filename, { type: "image/jpeg" });
      }
      let dataURL = this.covertFileToDataURL(file);
      let resizedDataURL = await this.resizeBase64Img(
        dataURL,
        maxWidth,
        maxHeight,
        file.type,
        quality
      );
      return await this.dataUrlToFile(resizedDataURL, file.name, file.type);
    },
    /**
     * Resize an image element containing image data, returning an base64 string for the image of the given quality
     * @param {HTMLImageElement} img - The already-loaded HTML Image element to be manipulated using the canvas
     * @param {Number} newWidth - The width of the image in pixels
     * @param {Number} newHeight - The height of the image in pixels
     * @param {Number} type - The standard MIME type for the image format to return. If you do not specify this parameter, the default value is a PNG format image.
     * @param {Number} quality - A Number between 0 and 1 indicating the image quality to be used when creating images using file formats that support lossy compression (such as image/jpeg or image/webp). A user agent will use its default quality value if this option is not specified, or if the number is outside the allowed range.
     */
    resizeImage(
      img: HTMLImageElement,
      newWidth: number,
      newHeight: number,
      type: string | undefined,
      quality: number | undefined
    ): string {
      var canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      let context = canvas.getContext("2d")!;
      var widthScale = newWidth / img.width;
      var heightScale = newHeight / img.height;
      context.scale(widthScale, heightScale);
      context.drawImage(img, 0, 0);
      return canvas.toDataURL(type, quality);
    },
    calculateImageScale(
      width: number,
      height: number,
      maxWidth: number | undefined,
      maxHeight: number | undefined
    ): { width: number; height: number } {
      // scale 200 x 100 to max 100 x 100 = 100 x 50
      // scale 200 x 100 to max 50 x 100 = 50 x 25
      // scale 100 x 200 to max 100 x 50 = 25 x 50
      // scale 100 x 200 to max 100 x 100 = 50 x 100

      var widthRatio = 1;
      if (!!maxWidth) widthRatio = width < maxWidth ? 1 : width / maxWidth;
      var heightRatio = 1;
      if (!!maxHeight) heightRatio = height < maxHeight ? 1 : height / maxHeight;

      var scaleDivisor = Math.max(widthRatio, heightRatio);

      var newWidth = Math.floor(width / scaleDivisor);
      var newHeight = Math.floor(height / scaleDivisor);

      // console.log(`resizing image: ${JSON.stringify(dimensions)}`);
      // console.log(`\tScaling down by ${scaleDivisor}`);
      // console.log(`\tnew width: ${newWidth}, new height: ${newHeight}`);

      return {
        width: newWidth,
        height: newHeight
      };
    },
    covertFileToDataURL(blob: Blob | undefined) {
      if (!blob) return "";
      return URL.createObjectURL(blob);
    },
    async dataUrlToFile(dataUrl: string, fileName: string, type: string): Promise<File> {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      return new File([blob], fileName, { type: type });
    },
    getImageFromDataURL(dataURL: string): Promise<HTMLImageElement> {
      return new Promise((resolve, reject) => {
        var img = document.createElement("img");
        img.onload = function() {
          resolve(img);
        };
        img.onerror = function() {
          reject(img);
        };
        img.src = dataURL;
      });
    }
  }
});

