/**
 * Helper to allow a view to have tabs.
 *
 * To implement, do the following:
 * 1. Setup your tab(s) in your data property:
 *  example: `basicTab: { tabname: "Basic", key: '0', visible: true } as Tab`
 * 
 * 2. Fill in the `firstTabKey` in your data property:
 *  example: `firstTabKey: '0'`
 * 
 * 3. Fill in the `tabDefinitions` computed property:
 ```
tabDefinitions(): Tab[] {
  // Details is not included since it's the first tab and is always visible
  return [this.notesTab, this.iwpTab, this.optionsTab, this.filesTab] as Tab[];
}
 ```
 * 
 * 4. Set the `selectedTab` value in the context, using the value of your `firstTabKey` data
 example:
 ```
 this.setFilteringContext({
  context: "context",
  selectedTab: this.firstTabKey
});
 ```
 * 
 * 5. Implement your v-tab and v-tab-items in your .vue file, 
 *  making sure to bind your tabs to the `Tab` objects created in step #1
 example:
 ```
<v-tab
  :key="basicTab.key"
  @click="tabSelected(basicTab)"
  :href="`#tab-${basicTab.key}`"
  v-if="basicTab.visible || windowWidth > 599"
>
  TAB NAME HERE
</v-tab>

<v-tab-item :key="basicTab.key" :value="`tab-${basicTab.key}`">
  TAB CONTENT HERE
</v-tab-item>
 ```
 * 
 */

import FDVue from "..";
import { mapMutations } from "vuex";

export type Tab = {
  tabname: String;
  key: string;
  visible: Boolean;
};

const tabbedView = FDVue.extend({
  data: function() {
    return {
      // Override this in the implementation
      firstTabKey: ``
    };
  },

  mounted() {
    // The following is used to compensate for the apparent bug in vuetify that is preventing the underline from appearing
    // on the initial tab that is in focus within the view. This kicks it in the butt to cause it to render appropriately.
    // Without it the line under the initial tab that has focus will NOT show up until you resize the screen which then
    // causes it to re-render and show up.
    const initial = (this.$refs.tab as any).$el.offsetWidth;
    const interval = setInterval(() => {
      if (this.$refs.tab) {
        if ((this.$refs.tab as any).$el.offsetWidth !== initial) {
          clearInterval(interval);
          (this.$refs.tabs as any).callSlider();
        }
      }
    }, 100);
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Override this in the implementation
      return [] as Tab[];
    },

    hiddenTabDefinitions(): Tab[] {
      // Override this in the implementation
      return this.tabDefinitions.filter(x => !x.visible);
    },

    visibleTabDefinitions(): Tab[] {
      // Override this in the implementation
      return this.tabDefinitions.filter(x => x.visible);
    },

    selectedTab(): Tab | undefined {
      return this.tabDefinitions.find(x => x.key == this.activeTabKey);
    },

    active_tab: {
      get(this: any): any {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.selectedTab;
      },
      set(this: any, val: any) {
        this.setSelectedTab(val);
      }
    },

    activeTabKey(): string {
      return this.active_tab.replace("tab-", "");
    }
  },

  methods: {
    // *** NAVIGATION ***
    showNewTabFromMoreMenu(item: Tab) {
      //First identify the tab that was previously marked to be visible and reverse that.
      this.visibleTabDefinitions.forEach(x => {
        if (x.key != this.firstTabKey) x.visible = false;
      });

      //Next mark the tab that is to be shown as visible in the tabDefinitions
      item.visible = true;

      //Finally mark the Active Tab as the one that has just been selected from the "More" overflow tab menu.
      this.$nextTick(() => {
        this.active_tab = "tab-" + item.key;
      });
    },
    // Override this in the view's implementation to do any post-tab selection processing
    tabSelectionCompleted(selectedTab: Tab) {},

    tabSelected(selectedTab: Tab) {
      this.setSelectedTab(`tab-${selectedTab.key}`);

      //If the user clicks on the first tab you don't want to remove the one dynamic tab.
      if (selectedTab.key != this.firstTabKey) {
        //Since the user clicked on a tab look for any tab definitions that have been set to visible
        //and set it to invisible unless of course it is the currently visible tab.
        this.visibleTabDefinitions.forEach(x => {
          if (x.key != this.firstTabKey) x.visible = false;
        });

        selectedTab.visible = true;
        this.$nextTick(() => {
          this.active_tab = "tab-" + selectedTab.key;
        });
      }

      this.tabSelectionCompleted(selectedTab);
    },

    ...mapMutations({
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  beforeMount: function() {
    if (!!this.active_tab && !!this.selectedTab) {
      this.tabSelected(this.selectedTab);
    }
  }
});

export default tabbedView;
