import FDVue from "../";

export type ImageInfo = {
  name: string;
  source: string;
};

export default FDVue.extend({
  name: "fd-image-viewer",

  props: {
    name: { type: String, default: undefined },
    value: { type: String, default: "" },
    cy: { type: String, default: "fd-image-viewer" },
    imageList: { type: Array, default: () => undefined },
    allowAddImage: { type: Boolean, default: false }
  },

  data: function() {
    return {
      currentImageName: ""
    };
  },

  computed: {},

  methods: {
    close() {
      this.currentImageName = "";
      this.$emit("input", undefined);
    },
    caroueslChanged(idx: number) {
      console.log(`carouselChanged`);
      let imageList = this.imageList as ImageInfo[];
      this.currentImageName = imageList[idx].name;
    },
    addImage() {
      this.$emit("add:image");
    }
  },

  watch: {}
});
