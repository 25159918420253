import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import store from "../store";
import {
  Tag,
  PersonWithDetails,
  PersonalEmailAddressWithVerified,
  PersonalPhoneNumberWithVerified,
  PersonLoginStates,
  ContractorWithTags
} from "../services";
import { checkLogin } from "../login";

// TODO: Review this code - why a mixin? Replace with configuration method use?
type UserWithExtraDetails = PersonWithDetails & {
  tags: Tag[];
  isActiveWithUnusedLogin: boolean;
};

var newUserMixin = FDVue.extend({
  mixins: [rules],
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        emailAddresses: [] as PersonalEmailAddressWithVerified[],
        phoneNumbers: [] as PersonalPhoneNumberWithVerified[],
        contractorID: undefined as string | null | undefined,
        contractorIDs: [] as string[],
        includesAllContractors: false,
        languageID: undefined as string | null | undefined,
        tags: [] as Tag[],
        isActiveWithUnusedLogin: false,
        isLoginActive: false,
        projectIDs: [] as string[],
        includesAllProjects: true,

        canConfigureSettings: false,
        canConfigurePrivateSettings: false,
        canUpdateAssociatedWorkOrdersWithoutBeingAssigned: false,
        forceReadonlyAccess: false,

        canSubmitScaffoldRequests: false,
        canApproveScaffoldRequests: false,
        canApproveScaffoldRequestsFromAllAssignedContractors: false,

        canWalkDownUnassignedWorkOrders: false,
        canApproveWalkdowns: false,

        canApproveCountSheets: false,

        canOverrideTransferValues: false,
        canCreateTransfers: false,

        canImportWorkOrders: false,
        canEditWorkOrderArea: false,
        canEditWorkOrderAreaForAllAssignedContractors: false,
        canEditWorkOrderLocation: false,
        canEditWorkOrderLocationForAllAssignedContractors: false,
        canEditWorkOrderWorkDescription: false,
        canEditWorkOrderWorkDescriptionForAllAssignedContractors: false,
        canEditWorkOrderPriority: false,
        canEditWorkOrderPriorityForAllAssignedContractors: false,
        canEditWorkOrderProgress: false,
        canEditWorkOrderStatus: false,
        canCancelWorkOrder: false,
        canEditWorkOrderRequiredDate: false,
        canEditWorkOrderPlannedWorkDate: false,
        canEditWorkOrderContractor: false,
        canEditWorkOrderCoordinator: false,
        canEditWorkOrderGeneralForeman: false,
        canEditWorkOrderGeneralForemanForAllAssignedContractors: false,
        canEditWorkOrderForeman: false,
        canEditWorkOrderForemanForAllAssignedContractors: false,

        canViewScaffoldRequestApprovals: false,
        canViewWorkOrders: false,
        canViewWorkOrderEstimates: false,
        canViewWorkOrderSchedule: false,
        canEditWorkOrderSchedule: false,
        canViewToDoList: false,
        canViewScaffolds: false,
        canViewMaterialApproval: false,
        canViewTransfers: false
      } as UserWithExtraDetails
    };
  },
  computed: {
    userRules() {
      return {
        firstName: [this.rules.required],
        lastName: [this.rules.required],
        emailAddress: [this.rules.required, this.rules.validEmail],
        phoneNumber: [this.rules.validPhoneNumber],
        contractorID: [],
        addFile: [this.rules.limitedFileSize]
      };
    },
    userValues() {
      return {
        contractorID: this.$store.getters.sortedEnabledContractors as ContractorWithTags[],
        languageID: this.$store.state.languages.fullList,
        tags: this.$store.getters.sortedEnabledTags
      };
    }
  },
  methods: {
    async saveUser() {
      var newID = await store.dispatch("ADD_USER", {
        ...this.user,
        tagIDs: this.user.tags.length > 0 ? this.user.tags.map(x => x.id) : null
      });

      //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
      //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
      await checkLogin();

      return newID;
    },
    async refreshUserReferenceData() {
      await Promise.all([
        this.$store.dispatch("LOAD_TAGS"),
        this.$store.dispatch("LOAD_CONTRACTORS")
      ]);
    }
  },
  created() {
    this.refreshUserReferenceData();
  }
});

function createLoadUserMixin() {
  return newUserMixin.extend({
    created() {},
    data() {
      return {
        userID: ""
      };
    },
    computed: {
      // TODO: We don't really need these computed values once we get the state strongly typed
      allUsers(): PersonWithDetails[] {
        return (store.state as any).users.fullList as PersonWithDetails[];
      },
      allTags(): Tag[] {
        return (store.state as any).tags.fullList as Tag[];
      }
    },
    methods: {
      async loadUser(userID: string) {
        this.userID = userID;
        await store.dispatch("LOAD_USER", userID);
        let loadedUser = this.allUsers.find(x => x.id == userID);
        if (loadedUser) {
          this.user = {
            ...loadedUser,
            id: undefined,
            organizationID: undefined,
            isActiveWithUnusedLogin:
              !loadedUser.isArchived && loadedUser.loginState == PersonLoginStates.Unused,
            tagIDs: undefined,
            tags: loadedUser.tagIDs
              ? loadedUser.tagIDs.map(x => this.allTags.find(y => y.id == x)).filter(x => !!x)
              : []
          } as UserWithExtraDetails;
          let contractorIDs = this.user.contractorIDs ?? [];
          if (!!this.user.contractorID && !contractorIDs.includes(this.user.contractorID)) {
            contractorIDs.push(this.user.contractorID);
            this.user.contractorIDs = contractorIDs;
          }
        } else {
          // TODO: Should we raise an error here?
        }
      },
      async saveUser() {
        this.user.canConfigurePrivateSettings = undefined;
        await store.dispatch("UPDATE_USER", {
          ...this.user,
          id: this.userID,
          tagIDs: this.user.tags.length > 0 ? this.user.tags.map(x => x.id) : null
        });

        //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
        //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
        await checkLogin();
      },
      // Used to save the user info when saving from the "My Profile" page
      async savePartialUser() {
        var userToUpdate = {
          ...this.user,
          id: this.userID,
          tagIDs: undefined
        };

        // The following properties are not allowed to be updated from the "My Profile" page and need to be set to undefined so the server ignores them
        userToUpdate.contractorID = undefined;
        userToUpdate.archivedDate = undefined;

        userToUpdate.canConfigureSettings = undefined;
        userToUpdate.canConfigurePrivateSettings = undefined;
        userToUpdate.canUpdateAssociatedWorkOrdersWithoutBeingAssigned = undefined;
        userToUpdate.forceReadonlyAccess = undefined;

        userToUpdate.canSubmitScaffoldRequests = undefined;
        userToUpdate.canApproveScaffoldRequests = undefined;
        userToUpdate.canApproveScaffoldRequestsFromAllAssignedContractors = undefined;

        userToUpdate.canWalkDownUnassignedWorkOrders = undefined;
        userToUpdate.canApproveWalkdowns = undefined;

        userToUpdate.canApproveCountSheets = undefined;

        userToUpdate.canCreateTransfers = undefined;
        userToUpdate.canOverrideTransferValues = undefined;

        userToUpdate.canImportWorkOrders = undefined;
        userToUpdate.canEditWorkOrderArea = undefined;
        userToUpdate.canEditWorkOrderAreaForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderWorkDescription = undefined;
        userToUpdate.canEditWorkOrderWorkDescriptionForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderLocation = undefined;
        userToUpdate.canEditWorkOrderLocationForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderPriority = undefined;
        userToUpdate.canEditWorkOrderPriorityForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderProgress = undefined;
        userToUpdate.canEditWorkOrderProgressForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderStatus = undefined;
        userToUpdate.canEditWorkOrderStatusForAllAssignedContractors = undefined;
        userToUpdate.canCancelWorkOrder = undefined;
        userToUpdate.canCancelWorkOrderForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderRequiredDate = undefined;
        userToUpdate.canEditWorkOrderRequiredDateForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderPlannedWorkDate = undefined;
        userToUpdate.canEditWorkOrderPlannedWorkDateForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderContractor = undefined;
        userToUpdate.canEditWorkOrderCoordinator = undefined;
        userToUpdate.canEditWorkOrderCoordinatorForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderGeneralForeman = undefined;
        userToUpdate.canEditWorkOrderGeneralForemanForAllAssignedContractors = undefined;
        userToUpdate.canEditWorkOrderForeman = undefined;
        userToUpdate.canEditWorkOrderForemanForAllAssignedContractors = undefined;

        userToUpdate.canViewScaffoldRequestApprovals = undefined;
        userToUpdate.canViewWorkOrders = undefined;
        userToUpdate.canViewWorkOrderEstimates = undefined;
        userToUpdate.canViewWorkOrderSchedule = undefined;
        userToUpdate.canEditWorkOrderSchedule = undefined;
        userToUpdate.canViewToDoList = undefined;
        userToUpdate.canViewScaffolds = undefined;
        userToUpdate.canViewMaterialApproval = undefined;
        userToUpdate.canViewTransfers = undefined;

        userToUpdate.projectIDs = undefined;
        userToUpdate.includesAllProjects = undefined;

        await store.dispatch("UPDATE_USER", userToUpdate);

        //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
        //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
        await checkLogin();
      },
      async deleteUser() {
        await store.dispatch("DELETE_USER", { id: this.userID });
      }
    }
  });
}

export default {
  new: newUserMixin,
  load: createLoadUserMixin
};

