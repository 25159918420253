import FDVue from "../index";
import * as DateUtil from "../../client-util/datetime";
import VueI18n from "vue-i18n";

export type DateRangePreset = {
  fromDate: Date | string;
  toDate: Date | string;
  key: string;
  labelKey: string;
};

export default FDVue.extend({
  name: "fd-date-range-picker",

  inheritAttrs: false,

  props: {
    label: { type: String, default: undefined },
    value: Array,
    rules: Array,
    loading: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    outlined: { default: true, type: Boolean },
    clearable: { default: false, type: Boolean },
    i18nLocale: { default: "en-us", type: String },
    cy: { type: String, default: "fd-date-range-picker" },
    format: { type: String, default: "llll" },
    min: { type: [String, Date], default: undefined },
    max: { type: [String, Date], default: undefined },
    textFieldClass: { type: [Object, String, Array], default: undefined },
    textFieldStyle: { type: String, default: undefined },
    displayDot: { type: Boolean, default: false },
    rangeSeparator: { default: " ~ ", type: String },
    displayRangeAsPresetLabel: { type: Boolean, default: true },

    // Preset Menu Options
    presetMenuTitleKey: {
      type: String,
      default: "fd-date-range-picker.preset-menu-title"
    },
    showCustomPreset: { type: Boolean, default: true },
    presets: {
      type: Array,
      default: () => [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
        // {
        //   fromDate: DateUtil.addMonthsToDate(null, -12),
        //   toDate: DateUtil.addDaysToDate(null, 0),
        //   key: "previous-year",
        //   labelKey: "fd-date-range-picker.preset-previous-year-label"
        // } as DateRangePreset
      ]
    },

    // Date Pickers
    fromPickerTitleKey: {
      type: String,
      default: "fd-date-range-picker.from-picker-title"
    },
    toPickerTitleKey: {
      type: String,
      default: "fd-date-range-picker.to-picker-title"
    },
    invalidFromDateMessageKey: {
      type: String,
      default: "fd-date-range-picker.invalid-from-date-message"
    },
    invalidToDateMessageKey: {
      type: String,
      default: "fd-date-range-picker.invalid-to-date-message"
    },
    cancelButtonLabelKey: {
      type: String,
      default: "fd-date-range-picker.cancel-button-label"
    },
    doneButtonLabelKey: {
      type: String,
      default: "fd-date-range-picker.done-button-label"
    }
  },

  data: () => ({
    fromValid: true,
    toValid: true,
    fromDate: undefined as Date | undefined,
    toDate: undefined as Date | undefined,
    selectedPresetKey: undefined as string | undefined,
    menuOpen: false,
    customPreset: {
      key: "custom",
      labelKey: "fd-date-range-picker.custom-preset-label"
    } as {
      key: string;
      labelKey: string;
    }
  }),

  computed: {
    allVisiblePresets(): { key: string; labelKey: string }[] {
      var presets = this.presets as {
        key: string;
        labelKey: string;
      }[];
      if (this.showCustomPreset) {
        presets.push(this.customPreset);
      }
      return presets;
    },
    // The text displayed in the text box.  Displays the DATE values (based on the supplied format string), separated by the set range separator
    dateRangeText: {
      cache: false,
      get(): string | VueI18n.TranslateResult | undefined {
        // if (this.displayRangeAsPresetLabel && this.selectedPresetKey != this.customPreset.key) {
        //   let selectedPreset = (this.presets as DateRangePreset[]).find(
        //     x =>
        //       DateUtil.isoDateString(x.fromDate) == this.fromDateISOString &&
        //       DateUtil.isoDateString(x.toDate) == this.toDateISOString
        //   );
        //   if (!!selectedPreset) {
        //     return this.$t(selectedPreset.labelKey);
        //   }
        // }
        var dateStrings = [];
        if (!!this.fromDate) {
          var dateVal = this.fromDate;
          var dateString = DateUtil.stripTimeFromLocalizedDateTime(dateVal, this.$props.format);
          dateStrings.push(dateString);
        }
        if (!!this.toDate) {
          var dateVal = this.toDate;
          var dateString = DateUtil.stripTimeFromLocalizedDateTime(dateVal, this.$props.format);
          dateStrings.push(dateString);
        }
        return dateStrings.join(this.rangeSeparator);
      }
    },
    fromDateISOString: {
      cache: false,
      get(): string {
        return DateUtil.isoDateString(this.fromDate);
      }
    },
    toDateISOString: {
      cache: false,
      get(): string {
        return DateUtil.isoDateString(this.toDate);
      }
    },
    formattedMin(): string | undefined {
      console.log(`formattedMin min: ${this.min}`);
      return !!this.min ? DateUtil.isoDateString(this.min) : undefined;
    },
    formattedMax(): string | undefined {
      console.log(`formattedMax max: ${this.max}`);
      return !!this.max ? DateUtil.isoDateString(this.max) : undefined;
    }
  },

  methods: {
    fromDateChanged(val: string) {
      this.fromDate = this.parseDate(val);

      var minDate = !!this.min ? this.parseDate(this.min) : null;
      this.fromValid = !minDate || this.fromDate.getTime() >= minDate.getTime();

      this.$emit("input:fromDate", this.fromDate);

      // Changing the from date could change the max allowed date.  Wait for the emit to trickle through then check
      this.$nextTick(() => {
        var maxDate = !!this.max ? this.parseDate(this.max) : null;
        this.toValid = !maxDate || (!!this.toDate && this.toDate.getTime() <= maxDate.getTime());
      });
    },
    toDateChanged(val: string) {
      this.toDate = this.parseDate(val);

      var maxDate = !!this.max ? this.parseDate(this.max) : null;
      this.toValid = !maxDate || this.toDate.getTime() <= maxDate.getTime();

      this.$emit("input:toDate", this.toDate);

      // Changing the to date could change the min allowed date.  Wait for the emit to trickle through then check
      this.$nextTick(() => {
        var minDate = !!this.min ? this.parseDate(this.min) : null;
        this.fromValid =
          !minDate || (!!this.fromDate && this.fromDate.getTime() >= minDate.getTime());
      });
    },
    cancel() {
      // Reset the values of the TO and FROM dates using the original passed-in value
      var dates = this.value as (string | Date)[];
      let fromDate = undefined as Date | undefined;
      if (!!dates?.length && dates.length > 0) {
        fromDate = this.parseDate(dates[0]);
      }
      this.fromDate = fromDate;

      let toDate = undefined as Date | undefined;
      if (!!dates?.length && dates.length > 1) {
        toDate = this.parseDate(dates[1]);
      }
      this.toDate = toDate;

      let selectedPreset = (this.presets as DateRangePreset[]).find(
        x =>
          DateUtil.isoDateString(x.fromDate) == this.fromDateISOString &&
          DateUtil.isoDateString(x.toDate) == this.toDateISOString
      );
      if (!!selectedPreset) this.selectedPresetKey = selectedPreset.key;
      else this.selectedPresetKey = this.customPreset.key;

      this.menuOpen = false;
    },
    done() {
      if (!this.fromValid || !this.toValid) return;

      var dates = [] as Date[];
      if (!!this.fromDate) {
        dates.push(this.fromDate);
      }
      if (!!this.toDate) {
        dates.push(this.toDate);
      }
      this.$emit("input", dates);
      this.menuOpen = false;
    },
    menuItemSelected(presetKey: string) {
      console.log(`menuItemSelected presetKey: ${presetKey}`);
      // If the user pressed custom, we always want to open the menu.
      if (presetKey == this.customPreset.key) {
        // Show FROM and TO date pickers
        this.menuOpen = true;
        return;
      }

      var selectedPreset = (this.presets as DateRangePreset[]).find(x => x.key == presetKey);
      if (!selectedPreset) {
        console.log(`Unable to find preset with key "${presetKey}"`);
        return;
      }

      var fromDate = this.parseDate(selectedPreset.fromDate);
      this.fromDate = fromDate;
      var toDate = this.parseDate(selectedPreset.toDate);
      this.toDate = toDate;

      this.done();
    },
    parseDate(val: string | Date): Date {
      // The value passed in may include time, or be in a format we don't like so parse it to ISO first
      var isoDateString = DateUtil.isoDateString(val);
      // This is a DATE range picker with no time functionality so we ignore time
      let newFullDateTimeString = `${isoDateString} 00:00:00`;
      let dateVal = new Date(
        DateUtil.localizedDateTimeString(newFullDateTimeString, this.$props.format)
      );
      return dateVal;
    }
  },

  created: function() {
    var dates = this.value as (string | Date)[];

    let fromDate = undefined as Date | undefined;
    if (!!dates?.length && dates.length > 0) {
      fromDate = this.parseDate(dates[0]);
    }
    this.fromDate = fromDate;

    let toDate = undefined as Date | undefined;
    if (!!dates?.length && dates.length > 1) {
      toDate = this.parseDate(dates[1]);
    }
    this.toDate = toDate;
  },

  mounted: function() {
    let selectedPreset = (this.presets as DateRangePreset[]).find(
      x =>
        DateUtil.isoDateString(x.fromDate) == this.fromDateISOString &&
        DateUtil.isoDateString(x.toDate) == this.toDateISOString
    );
    if (!!selectedPreset) this.selectedPresetKey = selectedPreset.key;
    else this.selectedPresetKey = this.customPreset.key;
  }
});

