import FDVue from "@fd/lib/vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { VForm } from "@fd/lib/vue/types";
import { mapActions } from "vuex";
import {
  BulkImportResults,
  LegacyWorkOrderImportSettings,
  legacyWorkOrderService
} from "../../services";
import store from "../../store";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../../dataMixins/userAccess";

export default FDVue.extend({
  mixins: [rules, errorHandling, userAccess],

  props: {
    closeAction: Function
  },

  data: () => ({
    processing: false,
    downloading: false,
    downloadingerrors: false,
    uploading: false,

    shouldImportToSP1: true,

    inlineMessageSP1Upload: {
      message: null as any,
      type: "error" as "success" | "info" | "warning" | "error"
    },

    // inlineMessageSP2Upload: {
    //   message: null as any,
    //   type: "error" as "success" | "info" | "warning" | "error"
    // },

    file: null as any,

    returnFile: null as any,
    sp1ImportResults: null as BulkImportResults | null,
    // sp2ImportResults: null as BulkImportResults | null,

    legacyWorkOrderImportColumnMapping: {} as LegacyWorkOrderImportSettings
  }),

  methods: {
    async loadColumnMapping() {
      this.legacyWorkOrderImportColumnMapping = await legacyWorkOrderService.getDefaultImportSettings();
    },
    async uploadFiles() {
      // First reset the inline message if there are any.
      this.inlineMessageSP1Upload.message = null;
      // this.inlineMessageSP2Upload.message = null;
      this.sp1ImportResults = null;
      // this.sp2ImportResults = null;
      this.returnFile = null;

      if (!(this.$refs.uploadForm as VForm).validate()) {
        console.log((this.$refs.uploadForm as VForm).validate());
        return;
      }

      this.processing = true;
      this.uploading = true;

      var isSuccessful = true;
      if (isSuccessful) isSuccessful = !this.shouldImportToSP1 || (await this.validateSP1File());
      // if (isSuccessful) isSuccessful = await this.validateSP2File();
      if (isSuccessful) isSuccessful = !this.shouldImportToSP1 || (await this.uploadSP1File());
      // if (isSuccessful) isSuccessful = await this.uploadSP2File();

      this.processing = false;
      this.uploading = false;

      if (isSuccessful) this.showSnackbarAndCloseDialog();
    },
    showSnackbarAndCloseDialog() {
      var snackbarPayload = {
        text: this.$t("common.bulk-upload-results.import-success"),
        type: "success",
        undoCallback: null
      };
      store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      // this.$props.closeAction(true);
    },

    async validateSP1File(): Promise<boolean> {
      console.log("validateSP1File()");

      var isSuccessful = false;
      try {
        this.returnFile = await legacyWorkOrderService.validateWorkOrderList(
          this.legacyWorkOrderImportColumnMapping.identifyingColumnFriendlyName || "",
          this.legacyWorkOrderImportColumnMapping.workOrderNumber || "",
          this.legacyWorkOrderImportColumnMapping.startDate || "",
          this.legacyWorkOrderImportColumnMapping.requiredDate || "",
          this.file
        );
        isSuccessful = this.returnFile == null;
      } catch (error) {
        var messageInfo = this.generateErrorInformation(
          error,
          "common.bulk-upload-results.sp1-import-error",
          {
            422: {
              errorMessageKey: "common.bulk-upload-results.column-mismatch-error"
            }
          }
        );

        this.inlineMessageSP1Upload.message = messageInfo.message;
        this.inlineMessageSP1Upload.type = messageInfo.type;
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async uploadSP1File(): Promise<boolean> {
      console.log("uploadSP1File()");
      var isSuccessful = false;
      try {
        // We only care about the results of the last call, the SP2 upload.
        this.sp1ImportResults = await legacyWorkOrderService.uploadWorkOrderList(
          this.legacyWorkOrderImportColumnMapping.identifyingColumnFriendlyName || "",
          this.legacyWorkOrderImportColumnMapping.workOrderNumber || "",
          this.legacyWorkOrderImportColumnMapping.startDate || "",
          this.legacyWorkOrderImportColumnMapping.requiredDate || "",
          this.file
        );
        isSuccessful = this.sp1ImportResults.successful;
      } catch (error) {
        var messageInfo = this.generateErrorInformation(
          error,
          "common.bulk-upload-results.sp1-import-error",
          {
            422: {
              errorMessageKey: "common.bulk-upload-results.column-mismatch-error"
            }
          }
        );

        this.inlineMessageSP1Upload.message = messageInfo.message;
        this.inlineMessageSP1Upload.type = messageInfo.type;
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async downloadReturnFile() {
      // First reset the inline message if there are any.
      this.inlineMessageSP1Upload.message = null;
      // this.inlineMessageSP2Upload.message = null;
      this.sp1ImportResults = null;
      // this.sp2ImportResults = null;

      if (!this.returnFile) {
        this.inlineMessageSP1Upload.message = this.$t(
          "common.bulk-upload-general.file-does-not-exist"
        );
        this.inlineMessageSP1Upload.type = "warning";
        return;
      }

      this.downloadingerrors = true;
      this.processing = true;
      try {
        downloadBlob(this.returnFile, this.file.name);
      } catch (error) {
        var messageInfo = this.generateErrorInformation(error);
        this.inlineMessageSP1Upload.message = messageInfo.message;
        this.inlineMessageSP1Upload.type = messageInfo.type;
      } finally {
        this.processing = false;
        this.downloadingerrors = false;
      }
    },
    async downloadTemplate() {
      // First reset the inline message if there are any.
      this.inlineMessageSP1Upload.message = null;
      // this.inlineMessageSP2Upload.message = null;
      this.sp1ImportResults = null;
      // this.sp2ImportResults = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let workOrderListTemplate = await legacyWorkOrderService.downloadWorkOrderListTemplate();
        downloadBlob(workOrderListTemplate, "WorkOrderListTemplate.xlsx");
      } catch (error) {
        var messageInfo = this.generateErrorInformation(error);
        this.inlineMessageSP1Upload.message = messageInfo.message;
        this.inlineMessageSP1Upload.type = messageInfo.type;
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },
    async downloadFullWorkOrdersList() {
      // First reset the inline message if there are any.
      this.inlineMessageSP1Upload.message = null;
      // this.inlineMessageSP2Upload.message = null;
      this.sp1ImportResults = null;
      // this.sp2ImportResults = null;
      this.returnFile = null;
      this.processing = true;
      this.downloading = true;
      try {
        let workOrderListTemplate = await legacyWorkOrderService.downloadWorkOrderList();
        // let workOrderListTemplate = await lorkOrderService.downloadWorkOrderList();
        downloadBlob(workOrderListTemplate, "WorkOrderList.xlsx");
      } catch (error) {
        var messageInfo = this.generateErrorInformation(error);
        this.inlineMessageSP1Upload.message = messageInfo.message;
        this.inlineMessageSP1Upload.type = messageInfo.type;
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },
    ...mapActions({
      // uploadSP2WorkOrders: "UPLOAD_WORK_ORDERS"
    })
  },
  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    },
    shouldImportToSP1() {
      this.inlineMessageSP1Upload.message = null;
      // this.inlineMessageSP2Upload.message = null;
      this.sp1ImportResults = null;
      // this.sp2ImportResults = null;
      this.returnFile = null;
    }
  },

  created: async function() {
    this.processing = true;
    await this.loadColumnMapping();
    this.processing = false;
  }
});
