var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("countsheet.dialog.title")) +
                      " -\n        " +
                      _vm._s(
                        _vm.$t("countsheet.dialog.title-work-order-id", [
                          _vm.workOrder.legacyID
                        ])
                      )
                  ),
                  !!_vm.workOrder.scaffoldNumber
                    ? _c("span", [
                        _vm._v(
                          ", " +
                            _vm._s(
                              _vm.$t("countsheet.dialog.title-scaffold-id", [
                                _vm.workOrder.scaffoldNumber
                              ])
                            )
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              !!_vm.countSheet
                ? _c(
                    "div",
                    {
                      staticStyle: { "font-size": "12px", "line-height": "1.5" }
                    },
                    [
                      _c("v-chip", { staticClass: "ml-2 fd-status-chip" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm
                                .$t(
                                  "countsheet.status." +
                                    _vm.countSheet.reviewStatusName
                                )
                                .toUpperCase()
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("fd-count-sheet-form", {
            attrs: {
              workOrder: !!_vm.countSheet ? _vm.countSheet.workOrder : {},
              countSheet: _vm.countSheet,
              countSheetGroups: _vm.countSheetGroups,
              "can-pick-individual": _vm.canPickIndividual,
              "can-remove-all": _vm.canRemoveAll,
              "can-have-no-material": _vm.canHaveNoMaterial,
              "prevent-approval": ""
            }
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    text: "",
                    color: "primary",
                    disabled: _vm.processing || !_vm.countSheetIsEditable,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-draft")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-4 mr-3 fd-button-context-separator" },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "submit",
                    color: "primary",
                    disabled:
                      _vm.processing ||
                      !_vm.countSheetIsEditable ||
                      !_vm.canSubmit,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.submitDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.submit")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }