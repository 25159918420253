var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "grey" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("terms.title")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "fd-privacy-terms" },
            [
              _c("v-card-text", { staticClass: "pt-0" }, [
                _c("h3", { staticClass: "mb-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("terms.last-updated", [_vm.lastUpdated]))
                  )
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.line1")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("terms.interpretation-and-definitions.header")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.interpretation-and-definitions.interpretation.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.interpretation-and-definitions.interpretation.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.interpretation-and-definitions.definitions.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.interpretation-and-definitions.definitions.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line2a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line2b",
                              [_vm.platformName]
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line3a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line3b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line4a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line4b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line5a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line5b",
                              [_vm.companyCountry]
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line6a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line6b",
                              [_vm.companyName, _vm.companyAddress]
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line7a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line7b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line8a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line8b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line9a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line9b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line10a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line10b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line11a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line11b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line12a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line12b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line13a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "terms.interpretation-and-definitions.definitions.line13b"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.line3")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.line4")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.acknowledgement.line5")))
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("terms.content.header")))]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.your-right-to-post.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.your-right-to-post.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.your-right-to-post.line2"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.your-right-to-post.line3"))
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.content-restrictions.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.content-restrictions.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.content-restrictions.line2"))
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line3"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line4"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line5"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line6"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line7"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line8"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("terms.content.content-restrictions.line9"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("terms.content.content-restrictions.line10")
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.content.content-restrictions.line11"))
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("terms.content.content-backups.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.content.content-backups.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.content.content-backups.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.content.content-backups.line3")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.content.content-backups.line4")))
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("terms.copyright.header")))]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.copyright.intellectual-property-infringement.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.copyright.intellectual-property-infringement.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "terms.copyright.intellectual-property-infringement.line2",
                          [_vm.companyInfoEmail]
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.copyright.intellectual-property-infringement.line3"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line1")))
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line2")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line3")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line4")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line5")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line6")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("terms.copyright.dmca-notice.line7")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("terms.copyright.dmca-notice.line8", [
                        _vm.companyInfoEmail
                      ])
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.intellectual-property.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.intellectual-property.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.intellectual-property.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.intellectual-property.line3")))
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.your-feedback.header")))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.your-feedback.line1")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.links-to-other-websites.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.links-to-other-websites.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.links-to-other-websites.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.links-to-other-websites.line3")))
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("terms.termination.header")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.termination.line1")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.termination.line2")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.limitation-of-liability.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.limitation-of-liability.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.limitation-of-liability.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.limitation-of-liability.line3")))
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.as-is-disclaimer.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.as-is-disclaimer.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.as-is-disclaimer.line2")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.as-is-disclaimer.line3")))
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.governing-law.header")))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.governing-law.line1")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.disputes-resolution.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.disputes-resolution.line1")))
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("terms.eu-users.header")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.eu-users.line1")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.us-legal-compliance.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.us-legal-compliance.line1")))
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.severability-and-waiver.header")))
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "terms.severability-and-waiver.severability.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("terms.severability-and-waiver.severability.line1")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("terms.severability-and-waiver.waiver.header")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("terms.severability-and-waiver.severability.line1")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.translation-interpretation.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("terms.translation-interpretation.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("terms.changes-to-terms.header")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.changes-to-terms.line1")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("terms.changes-to-terms.line2")))
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("terms.contact-us.header")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("terms.contact-us.line1")))]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("terms.contact-us.line2")) +
                        "\n            "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.companyContactURL,
                          rel: "external nofollow noopener",
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.companyContactURL))]
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }