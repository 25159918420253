import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import {
  scaffoldRequestService,
  contractorService,
  ScaffoldRequestWithDetails,
  ScaffoldRequestStatuses,
  projectLocationService,
  disciplineService,
  WorkOrderStatuses
} from "../services";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import scaffoldRequestList, {
  ScaffoldRequestWithExtraDetails,
  ParseScaffoldRequestWithExtraDetails
} from "../dataMixins/scaffoldRequestList";

export default FDVue.extend({
  name: "fd-scaffold-request-approvals-list",

  mixins: [scaffoldRequestList, rules],

  data: function() {
    return {
      approving: false,
      declining: false,
      cancelling: false
    };
  },

  computed: {
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return this.showArchived ? 5 : 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return this.showArchived ? 11 : 10;
      } else if (this.$vuetify.breakpoint.md) {
        return this.showArchived ? 14 : 13;
      }

      return 18;
    },

    requestedCount(): string {
      var count =
        this.allScaffoldRequests?.filter(x => x.workOrderStatus == WorkOrderStatuses.Submitted)
          ?.length ?? 0;
      return count < 100 ? `${count}` : "99+";
    },

    declinedCount(): string {
      var count =
        this.allScaffoldRequests?.filter(x => x.workOrderStatus == WorkOrderStatuses.Declined)
          ?.length ?? 0;
      return count < 100 ? `${count}` : "99+";
    },

    selectedViewType: {
      get(): "declined" | "requested" {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: "declined" | "requested") {
        var current = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    scaffoldRequests(): ScaffoldRequestWithExtraDetails[] {
      var selectedRequests = this.filteredScaffoldRequests;
      if (this.selectedViewType == "requested") {
        selectedRequests = selectedRequests.filter(
          x => x.workOrderStatus == WorkOrderStatuses.Submitted
        );
      } else if (this.selectedViewType == "declined") {
        selectedRequests = selectedRequests.filter(
          x => x.workOrderStatus == WorkOrderStatuses.Declined
        );
      }
      return selectedRequests;
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    requestCanBeCancelled(item: ScaffoldRequestWithExtraDetails): boolean {
      return item.currentUserPermissions.canCancel;
    },

    requestCanBeApproved(item: ScaffoldRequestWithExtraDetails): boolean {
      return (
        item.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted &&
        item.currentUserPermissions.canUpdateApproval
      );
    },

    requestCanBeDeclined(item: ScaffoldRequestWithExtraDetails): boolean {
      return (
        item.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted &&
        item.currentUserPermissions.canUpdateApproval
      );
    },

    /*** NAVIGATION ***/
    async loadRequests() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      let scaffoldRequests = await scaffoldRequestService.getApprovalsWithDetails(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.allScaffoldRequests = scaffoldRequests.map(x => ParseScaffoldRequestWithExtraDetails(x));
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // *** ACTIONS ***
    async cancelRequest(request: ScaffoldRequestWithDetails) {
      this.processing = true;
      this.cancelling = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.cancelScaffoldRequest(request.id!, reason);

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.cancel-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },

    async approveRequest(request: ScaffoldRequestWithExtraDetails) {
      if (this.scaffoldRequestScaffoldHasOtherDismantleWork(request)) {
        let proceed = await this.openWorkDetailsForScaffoldDialog(request, true);
        if (!proceed) return;
      }
      this.processing = true;
      this.approving = true;
      try {
        var scaffoldContractors = this.allContractors.filter(
          x => !!x.isScaffoldCompany && x.isScaffoldCompany
        );

        var title = this.$t("scaffold-request-approvals.assign-contractor");
        title += ` | R-${request.internalRequestNumber}`;
        var contractorID = await showItemSelectionDialog(
          title,
          this.$t("scaffold-request-approvals.contractor-label"),
          [this.rules.required],
          scaffoldContractors,
          "name",
          "id"
        );

        // If details is undefined the dialog was cancelled
        if (!contractorID) {
          // Change the value to something else and then back to its current to force a rebind
          this.approving = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          request.id!,
          true,
          contractorID,
          ""
        );

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },

    async declineRequest(request: ScaffoldRequestWithDetails) {
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          request.id!,
          false,
          null,
          reason
        );

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffold-request-approvals",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: "requested"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffold-request-approvals.list-title"),
      to: "/scaffoldrequestapprovals",
      resetHistory: true
    });

    try {
      this.processing = true;
      await Promise.all([
        this.loadRequests(),
        this.loadCurrentUserDisciplines(),
        this.loadDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadContractors()
      ]);
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

