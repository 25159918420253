var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ],
                      attrs: { "data-cy": "tagexisting.toolbar" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push("/tags/")
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("common.name"),
                                  disabled: _vm.processing,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.tag.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tag, "name", $$v)
                                  },
                                  expression: "tag.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-1", attrs: { cols: "12" } },
                            [
                              _c("fd-rich-textarea", {
                                attrs: {
                                  disabled: _vm.processing,
                                  label: _vm.$t("common.description"),
                                  allowImages: false
                                },
                                model: {
                                  value: _vm.tag.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tag, "description", $$v)
                                  },
                                  expression: "tag.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.tag.archived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tag, "archived", $$v)
                                  },
                                  expression: "tag.archived"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.inlineMessage.message) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "ml-0 mr-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: ""
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        link: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.saving,
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.save }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }