var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/contractors/"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: "#tab-" + _vm.detailsTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.details")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items": _vm.availableTags,
                                          "selected-items": _vm.selectedTags,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _vm.contractor.legacyID > 0
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-alert", {
                                            attrs: {
                                              type: "info",
                                              label: _vm.$t(
                                                "contractors.existing-contractor.data-set-by-legacy-system"
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled:
                                            _vm.contractor.legacyID > 0 ||
                                            _vm.processing,
                                          readonly: _vm.contractor.legacyID > 0,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.contractor.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.alias"),
                                          disabled:
                                            _vm.contractor.legacyID > 0 ||
                                            _vm.processing,
                                          readonly: _vm.contractor.legacyID > 0,
                                          "persistent-hint": "",
                                          hint: _vm.$t("common.alias-hint"),
                                          rules: [
                                            _vm.rules.max20Characters,
                                            _vm.rules.required
                                          ],
                                          maxlength: "20",
                                          counter: ""
                                        },
                                        model: {
                                          value: _vm.contractor.alias,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "alias",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.alias"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.is-scaffold-company"
                                          ),
                                          disabled:
                                            _vm.contractor.legacyID > 0 ||
                                            _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.contractor.isScaffoldCompany,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "isScaffoldCompany",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.isScaffoldCompany"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value: _vm.contractor.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.can-act-as-proxy"
                                          ),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.contractor.canActAsProxy,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "canActAsProxy",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.canActAsProxy"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.contractor.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.archived"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mr-0",
                                              attrs: {
                                                type: _vm.inlineMessage.type
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.inlineMessage.message
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                link: "",
                                                disabled: _vm.processing,
                                                color: "error",
                                                outlined: "",
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.deleteItem }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.delete"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.cancel }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.cancel"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.saving,
                                                disabled: _vm.processing,
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.save }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.save"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.peopleTab.key,
                              attrs: { href: "#tab-" + _vm.peopleTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.peopleTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("users.menu-title")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-users")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.peopleTab.key,
                              attrs: { value: "tab-" + _vm.peopleTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openNewUserDialog()
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.users-listing-header-additional-info"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearch,
                                              callback: function($$v) {
                                                _vm.tablesearch = $$v
                                              },
                                              expression: "tablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("fd-people-list", {
                                        attrs: {
                                          items: _vm.users,
                                          tablesearch: _vm.tablesearch,
                                          processing: _vm.processing,
                                          archivedLoading: _vm.archivedLoading,
                                          showArchived: _vm.showArchived,
                                          showArchivedDateRange:
                                            _vm.showArchivedDateRange,
                                          showArchivedMinDate:
                                            _vm.showArchivedMinDate,
                                          showArchivedMaxDate:
                                            _vm.showArchivedMaxDate
                                        },
                                        on: {
                                          navigate: function(item) {
                                            return _vm.$router.push(
                                              "/people/" + item.id
                                            )
                                          },
                                          showArchivedChanged: function(v) {
                                            return (_vm.showArchived = v)
                                          },
                                          showArchivedDateRangeChanged: function(
                                            v
                                          ) {
                                            return (_vm.showArchivedDateRange = v)
                                          },
                                          flipArchived: _vm.flipArchived,
                                          flipCanLogIn: _vm.flipCanLogIn,
                                          deleteTableItem: _vm.deleteTableItem
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }