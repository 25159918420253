import Vue from "vue";
import Router from "vue-router";

import store from "./store";
import { checkLogin } from "./login";
// Login Services
import { loginService } from "./services";

Vue.use(Router);

var router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "DropoffLocations",
      path: "/dropofflocations",
      component: () => import("./views/DropoffLocations.vue")
    },
    {
      name: "DropoffLocationExisting",
      path: "/dropofflocations/:id",
      component: () => import("./views/DropoffLocationExisting.vue")
    },
    {
      name: "Yards",
      path: "/yards",
      component: () => import("./views/Yards.vue")
    },
    {
      name: "YardExisting",
      path: "/yards/:id",
      component: () => import("./views/YardExisting.vue")
    },
    {
      name: "Configuration",
      path: "/configuration",
      component: () => import("./views/SiteConfiguration.vue")
    },
    {
      name: "CountSheets",
      path: "/countsheets",
      component: () => import("./views/CountSheets.vue")
    },
    {
      name: "CountSheetExisting",
      path: "/countsheets/:id",
      component: () => import("./views/CountSheetExisting.vue")
    },
    {
      name: "YardBalances",
      path: "/yardbalances",
      component: () => import("./views/PartsYardBalance.vue")
    },
    {
      name: "Transfers",
      path: "/transfers",
      component: () => import("./views/Transfers.vue")
    },
    {
      name: "TransferExisting",
      path: "/transfers/:id",
      component: () => import("./views/TransferExisting.vue")
    },
    {
      name: "Suppliers",
      path: "/suppliers",
      component: () => import("./views/Suppliers.vue")
    },
    {
      name: "SupplierExisting",
      path: "/suppliers/:id",
      component: () => import("./views/SupplierExisting.vue")
    },
    {
      name: "Mappings",
      path: "/mappings",
      component: () => import("./views/Mappings.vue")
    },
    {
      name: "MappingExisting",
      path: "/mappings/:id",
      component: () => import("./views/MappingExisting.vue")
    },
    {
      name: "Tags",
      path: "/tags",
      component: () => import("../../common/client/views/Tags.vue")
    },
    {
      name: "TagExisting",
      path: "/tags/:id",
      component: () => import("../../common/client/views/TagExisting.vue")
    },
    {
      name: "PartsCatalog",
      path: "/parts",
      component: () => import("./views/PartsCatalog.vue")
    },
    {
      name: "PartExisting",
      path: "/parts/:id",
      component: () => import("./views/PartExisting.vue")
    },
    {
      name: "WorkOrders",
      path: "/workorders",
      component: () => import("./views/WorkOrders.vue")
    },
    // {
    //   name: "WorkOrderExisting",
    //   path: "/workorders/:id",
    //   component: () => import("./views/WorkOrderExisting.vue")
    // },
    // {
    //   name: "Clients",
    //   path: "/clients",
    //   component: () => import("./views/Clients.vue")
    // },
    // {
    //   name: "ClientExisting",
    //   path: "/clients/:clientID",
    //   component: () => import("./views/ClientExisting.vue")
    // },
    {
      name: "Projects",
      path: "/projects",
      component: () => import("./views/Projects.vue")
    },
    {
      name: "ProjectExisting",
      path: "/projects/:projectID",
      component: () => import("./views/ProjectExisting.vue")
    },
    {
      name: "CountSheetGroups",
      path: "/countsheetgroups",
      component: () => import("./views/CountSheetGroups.vue")
    },
    {
      name: "CountSheetGroupExisting",
      path: "/countsheetgroups/:id",
      component: () => import("./views/CountSheetGroupExisting.vue")
    },
    {
      name: "Owners",
      path: "/owners",
      component: () => import("./views/Owners.vue")
    },
    {
      name: "OwnerExisting",
      path: "/owners/:id",
      component: () => import("./views/OwnerExisting.vue")
    },
    {
      name: "Contractors",
      path: "/contractors",
      component: () => import("./views/Contractors.vue")
    },
    {
      name: "ContractorExisting",
      path: "/contractors/:id",
      component: () => import("./views/ContractorExisting.vue")
    },
    {
      name: "IWPs",
      path: "/iwps",
      component: () => import("./views/WorkPackages.vue")
    },
    {
      name: "IWPExisting",
      path: "/iwps/:id",
      component: () => import("./views/WorkPackageExisting.vue")
    },
    {
      name: "Users",
      path: "/people",
      component: () => import("./views/People.vue")
    },
    {
      name: "PersonExisting",
      path: "/people/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonSearchResult",
      path: "/person/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonalProfile",
      path: "/personalprofile/",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "Regions",
      path: "/regions",
      component: () => import("./views/Regions.vue")
    },
    {
      name: "RegionExisting",
      path: "/regions/:id",
      component: () => import("./views/RegionExisting.vue")
    },
    {
      name: "Modifiers",
      path: "/modifiers",
      component: () => import("./views/Modifiers.vue")
    },
    {
      name: "ModifierExisting",
      path: "/modifiers/:modifierID",
      component: () => import("./views/ModifierExisting.vue")
    },
    {
      name: "ModifierValueExisting",
      path: "/modifiervalues/:modifiervalueID",
      component: () => import("./views/ModifierValueExisting.vue")
    },
    {
      name: "Estimates",
      path: "/estimates",
      component: () => import("./views/Estimates.vue")
    },
    {
      name: "Default",
      path: "/",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "LandingPage",
      path: "/landingpage",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "EstimateExisting",
      path: "/estimateexistingmockup",
      component: () => import("./views/EstimateExisting.vue")
    },
    {
      name: "AreaExisting",
      path: "/areas/:areaID",
      component: () => import("./views/AreaExisting.vue")
    },
    {
      name: "SubAreaExisting",
      path: "/subareas/:subAreaID",
      component: () => import("./views/SubAreaExisting.vue")
    },
    {
      name: "EmailAddressExisting",
      path: "/emailaddress/:emailAddressID",
      component: () => import("../../common/client/views/EmailAddressExisting.vue")
    },
    {
      name: "PhoneNumberExisting",
      path: "/phonenumber/:phoneNumberID",
      component: () => import("../../common/client/views/PhoneNumberExisting.vue")
    },
    {
      name: "MobileMore",
      path: "/mobilemore",
      component: () => import("./views/MobileMore.vue")
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("../../common/client/views/Login.vue")
    },
    {
      name: "PinturaTest",
      path: "/pintura",
      component: () => import("./views/experimental/PinturaTest.vue")
    },
    {
      name: "DraggableTableTest",
      path: "/draggabletable",
      component: () => import("./views/experimental/DraggableTableTest.vue")
    },
    {
      name: "Requests",
      path: "/scaffoldrequests",
      component: () => import("./views/LegacyScaffoldRequestList.vue")
    },
    {
      name: "RequestExisting",
      path: "/scaffoldrequests/:id",
      component: () => import("./views/LegacyScaffoldRequestExisting.vue")
    },
    {
      name: "RequestApprovals",
      path: "/scaffoldrequestapprovals",
      component: () => import("./views/ScaffoldRequestApprovals.vue")
    },
    {
      name: "RequestApprovalExisting",
      path: "/scaffoldrequestapprovals/:id",
      // component: () => import("./views/ScaffoldRequestExisting.vue")
      component: () => import("./views/LegacyScaffoldRequestExisting.vue")
    },
    {
      name: "Scaffolds",
      path: "/scaffolds",
      component: () => import("./views/Scaffolds.vue")
    },
    {
      name: "ScaffoldExisting",
      path: "/scaffolds/:id",
      component: () => import("./views/ScaffoldsExisting.vue")
    },
    {
      name: "EstimatedWorkOrders",
      path: "/estimatedworkorders",
      component: () => import("./views/WorkOrderEstimates.vue")
    },
    {
      name: "EstimatedWorkOrderExisting",
      path: "/estimatedworkorders/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "Administration",
      path: "/administration",
      component: () => import("./views/WorkOrderAdministration.vue")
    },
    {
      name: "AdministrationExisting",
      path: "/administration/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "CountSheetAdministration",
      path: "/countsheetadministration",
      component: () => import("./views/WorkOrderAdministration.vue")
    },
    {
      name: "CountSheetAdministrationExisting",
      path: "/countsheetadministration/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "Scheduler",
      path: "/scheduler",
      component: () => import("./views/WorkOrderScheduler.vue")
    },
    {
      name: "SchedulerExisting",
      path: "/scheduler/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "ToDoList",
      path: "/todolist",
      component: () => import("./views/ToDoList.vue")
    },
    {
      name: "History",
      path: "/history",
      component: () => import("./views/WorkOrderHistory.vue")
    },
    {
      name: "WorkOrderExisting",
      path: "/workorder/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    }
  ]
});

router.beforeResolve(async function(to, from, next) {
  let targetRoute = to.fullPath;
  //First check to see if the call is attempting to exercise the "forgot password" workflow and DO NOT check the authentication
  //status of the user since they obviously cannot be signed in if they are attempting to reset their password.
  if (targetRoute == "/login") {
    loginService.logout();
    store.commit("NOTIFY_NAVIGATION_STARTED");
    next();
  } else if (targetRoute != "/login" && !(await checkLogin())) {
    // If we don't have a login prior to running, do that first
    store.commit("SET_LOGIN_RETURN_PATH", targetRoute);
    next("/login");
  } else {
    // We've confirmed the user is logged in and navigating to a path "inside" the application
    // As such, we can show the UI bars
    store.commit("SET_SHOW_APP_BAR", true);
    store.commit("SET_SHOW_DRAWER", true);
    store.commit("SET_SHOW_FOOTER", true);
    store.commit("SET_SHOW_BOTTOM_BAR", true);

    // Before committing to navigation, reset the breadcrumb state to nothing; when a new page comes
    // up that wants to use a breadcrumb it will announce itself, which will replace the breadcrumb
    // state
    store.commit("NOTIFY_NAVIGATION_STARTED");
    next();
  }
});

export default router;

