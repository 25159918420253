import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  countSheetService,
  CountSheetReviewStatus,
  CountSheetWithParts,
  WorkOrderWithLegacyDetails
} from "../services";
import userAccess from "../dataMixins/userAccess";
import * as DateUtil from "@fd/lib/client-util/datetime";
import rules from "@fd/lib/vue/rules";
import { DateRangePreset } from "../../../lib/vue/components/DateRangePicker.vue";

type FormattedCountSheet = CountSheetWithParts & {
  scaffoldNumber: string | undefined;
  legacyWorkOrderID: number | string | undefined;
  requestTypeName: string | undefined;
  workOrderStatusName: string | undefined;
  formattedReviewStatusChangeTime: string | undefined;
  // Transfer IDs only exist for approved count sheets
  transferIDsList?: string | undefined;
};

export default FDVue.extend({
  name: "fd-countsheet-list",

  mixins: [serviceErrorHandling, userAccess, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("../views/components/WorkOrderDetails.vue"),
    "fd-scaffold-number-with-badges": () =>
      import("../views/components/ScaffoldNumberWithBadges.vue")
  },

  data: function() {
    return {
      archivedLoading: false,
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      pendingCountSheets: [] as FormattedCountSheet[],
      declinedCountSheets: [] as FormattedCountSheet[],
      approvedCountSheets: [] as FormattedCountSheet[]
    };
  },

  computed: {
    selectionType: {
      get(): "pending" | "declined" | "approved" {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: "pending" | "declined" | "approved") {
        var current = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    selectionTypeIsApproved(): boolean {
      return this.selectionType == "approved";
    },
    countSheets(): FormattedCountSheet[] {
      if (this.selectionType == "pending") return this.pendingCountSheets;
      if (this.selectionType == "declined") return this.declinedCountSheets;
      else if (this.selectionTypeIsApproved) return this.approvedCountSheets;

      return [];
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadTableData();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    formatDate(date: Date | string | undefined) {
      return DateUtil.localizedDateTimeString(date);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadCountSheets();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadPendingOrDeclinedCountSheets() {
      let allPendingOrDeclinedCountSheets = await countSheetService.getAllPendingOrDeclined();
      this.pendingCountSheets = allPendingOrDeclinedCountSheets
        .filter(x => x.reviewStatusID == CountSheetReviewStatus.Pending)
        .map(
          x =>
            ({
              ...x,
              scaffoldNumber: x.workOrder?.scaffoldNumber,
              legacyWorkOrderID: x.workOrder?.legacyID,
              requestTypeName: x.workOrder?.requestTypeName,
              workOrderStatusName: x.workOrder?.workOrderStatusName,
              formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime)
            } as FormattedCountSheet)
        );

      this.declinedCountSheets = allPendingOrDeclinedCountSheets
        .filter(x => x.reviewStatusID == CountSheetReviewStatus.Declined)
        .map(
          x =>
            ({
              ...x,
              scaffoldNumber: x.workOrder?.scaffoldNumber,
              legacyWorkOrderID: x.workOrder?.legacyID,
              requestTypeName: x.workOrder?.requestTypeName,
              workOrderStatusName: x.workOrder?.workOrderStatusName,
              formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime)
            } as FormattedCountSheet)
        );
    },

    async loadApprovedCountSheets() {
      let allApprovedCountSheets = await countSheetService.getAllApproved(
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.approvedCountSheets = allApprovedCountSheets.map(
        x =>
          ({
            ...x,
            scaffoldNumber: x.workOrder?.scaffoldNumber,
            legacyWorkOrderID: x.workOrder?.legacyID,
            requestTypeName: x.workOrder?.requestTypeName,
            workOrderStatusName: x.workOrder?.workOrderStatusName,
            formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime),
            transferIDsList: !!x.relatedLegacyTransferIDs
              ? x.relatedLegacyTransferIDs.join(", ")
              : undefined
          } as FormattedCountSheet)
      );
    },

    async loadCountSheets() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await Promise.all([this.loadPendingOrDeclinedCountSheets(), this.loadApprovedCountSheets()]);

      if (!this.pendingCountSheets.length && !!this.declinedCountSheets.length)
        this.selectionType = "declined";
      if (
        !this.pendingCountSheets.length &&
        !this.declinedCountSheets.length &&
        !!this.approvedCountSheets.length
      )
        this.selectionType = "approved";

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    },

    // *** SCAFF BADGE COUNTS ***

    workOrderRequestsBadgeCount(workOrder: WorkOrderWithLegacyDetails): number {
      return workOrder.scaffoldSubmittedRequestIDs?.length ?? 0;
    },

    workOrderScaffoldOtherActiveWorkOrderIDs(workOrder: WorkOrderWithLegacyDetails): string[] {
      let scaffoldWorkOrderIDs = workOrder.scaffoldActiveWorkOrderIDs ?? [];
      let otherWorkOrderIDs = scaffoldWorkOrderIDs.filter(x => x != workOrder.id);
      return otherWorkOrderIDs;
    },

    workOrderOtherCount(workOrder: WorkOrderWithLegacyDetails): number {
      return this.workOrderScaffoldOtherActiveWorkOrderIDs(workOrder).length;
    },

    workOrderCountBadgeCount(workOrder: WorkOrderWithLegacyDetails): number {
      return workOrder.scaffoldActiveWorkOrderIDs?.length ?? 0;
    },

    workOrderDismantleBadgeCount(workOrder: WorkOrderWithLegacyDetails): number {
      return workOrder.scaffoldDismantleWorkOrderIDs?.length ?? 0;
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "countsheets",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addDaysToDate(toDate, -14),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: "pending"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("countsheet.list.title"),
      to: "/countsheets",
      resetHistory: true
    });
    try {
      await Promise.all([this.reloadTableData()]);
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

