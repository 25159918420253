import { mapActions, mapMutations } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { PartWithTags, SupplierWithTags, Tag } from "../services";
import ServiceError from "@fd/lib/client-util/serviceError";
import userAccess from "../dataMixins/userAccess";

type PartWithTagsAndArchived = PartWithTags & { isArchived: boolean };

export default FDVue.extend({
  name: "fd-part-existing",

  mixins: [errorHandling, rules, userAccess],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      // The following customizes the tool bar for the rich text editor used in conjunction with the extended description for parts.
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }]
      ] as any[],

      part: {
        id: undefined,
        publicID: undefined,
        name: undefined,
        description: undefined,
        longDescription: undefined,
        weight: undefined,
        mpp: undefined,
        cleatingMPP: undefined,
        lashingMPP: undefined,
        carpentryMPP: undefined,
        otherMPP: undefined,
        rentalRate: undefined,
        costUsed: undefined,
        costNew: undefined,
        designation: undefined,
        isArchived: false
      } as PartWithTagsAndArchived,

      selectedTags: [] as Tag[],
      selectedSuppliers: [] as SupplierWithTags[],
      slidein: false
    };
  },

  computed: {
    partRules(): any {
      return {
        publicID: [this.rules.required],
        name: [this.rules.required],
        description: [],
        longDescription: [],
        weight: [this.rules.required, this.rules.numeric],
        mpp: [this.rules.required, this.rules.numeric],
        lashingMPP: [this.rules.numeric],
        cleatingMPP: [this.rules.numeric],
        carpentryMPP: [this.rules.numeric],
        otherMPP: [this.rules.numeric],
        rentalRate: [this.rules.numeric],
        costUsed: [this.rules.numeric],
        costNew: [this.rules.numeric],
        designation: []
      };
    },
    availableSuppliers(): any[] {
      return this.$store.getters.sortedEnabledSuppliers;
    },
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    // Method used in conjunction with the Save button.
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.part.isArchived && !this.part.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.part.archivedDate = archivedDate;

        await this.updatePart({
          ...this.part,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map((x: any) => x.id) : null,
          supplierIDs: this.selectedSuppliers.map((x: any) => x.id)
        });
        this.$router.push("/parts");
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    // async deleteItem() {
    //   this.inlineMessage.message = null;
    //   this.processing = true;
    //   try {
    //     await this.deletePart({ id: this.$route.params.id, name: this.part.name });
    //     this.$router.push("/parts");
    //   } catch (error) {
    //     this.handleError(error as ServiceError);
    //   } finally {
    //     this.processing = false;
    //   }
    // },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/parts");
    },
    ...mapMutations({
      setPart: "SET_PART",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadPart: "LOAD_PART",
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS",
      updatePart: "UPDATE_PART"
    })
  },

  watch: {
    part() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/parts") {
        this.notifyNewBreadcrumb({
          text: this.$t("parts.menu-title"),
          to: "/parts",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.part.name,
        to: `/parts/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/parts") {
      this.notifyNewBreadcrumb({
        text: this.$t("parts.menu-title"),
        to: "/parts",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await Promise.all([
        this.loadPart(this.$route.params.id),
        this.loadSuppliers(),
        this.loadTags()
      ]);
      let part = this.$store.state.parts.fullList.find((x: any) => x.id == this.$route.params.id);
      this.part = { ...part, isArchived: !!part.archivedDate };
      // TODO: Should the server return an empty array instead of null?
      if (part.tagIDs) {
        this.selectedTags = part.tagIDs
          .map((x: any) => this.$store.state.tags.fullList.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }
      this.selectedSuppliers = part.supplierIDs
        .map((x: any) => this.$store.state.suppliers.fullList.find((y: any) => y.id == x))
        .filter((x: any) => x);

      if (!this.selectedSuppliers?.length && this.availableSuppliers.length == 1) {
        this.selectedSuppliers = [this.availableSuppliers[0]];
      }
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  }
});

