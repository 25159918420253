import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import fileHandling, {
  componentsFromFileName,
  confirmUniqueName,
  canOpenFileInNewWindow,
  isFilePhoto,
  isFilePreviewable,
  FileData
} from "@fd/lib/vue/mixins/fileHandling";
import { mapMutations, mapActions } from "vuex";
import i18n from "../i18n";
import userData from "../dataMixins/person";
import {
  userService,
  ProjectWithParts,
  personalEmailAddressService,
  personalPhoneNumberService,
  loginService,
  disciplineService,
  Discipline,
  ContractorWithTags,
  locationFilterService,
  LocationFilterWithLocations,
  AreaWithSubAreas,
  ExternalLink,
  externalLinkService
} from "../services/index";
import { VForm } from "@fd/lib/vue/types";
import Slim from "@fd/lib/vue/components/SlimCropper.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { showAccessCodeEntryDialog } from "../../../common/client/views/components/AccessCodeEntryDialog.vue";
import userAccess from "../dataMixins/userAccess";

import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { Attachment } from "../dataMixins/attachment";

type ProjectWithPartsAndSelected = ProjectWithParts & { selected: boolean };
type ContractorWithTagsAndSelected = ContractorWithTags & { selected: boolean };

export default FDVue.extend({
  name: "fd-User-Existing",

  mixins: [serviceErrorHandling, tabbedView, userData.load(), userAccess, fileHandling],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-user-email-address-new": () =>
      import("../../../common/client/views/components/EmailAddressNewDialog.vue"),
    "fd-user-phone-number-new": () =>
      import("../../../common/client/views/components/PhoneNumberNewDialog.vue"),
    "slim-cropper": Slim,
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // *** GLOBAL ***
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      userHasLoaded: false,
      isPersonalProfile: false,

      slidein: false,

      detailserror: false,
      contractorerror: false,
      projectserror: false,
      permissionserror: false,
      fileserror: false,

      // The following defines all the non "Details" tabs for the view and controls whether they are visible.
      // This is quite relevant for when the platform is on a mobile device in portrait orientation.
      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      emailTab: {
        tabname: "Email",
        key: "1",
        visible: true
      } as Tab,
      mobileTab: {
        tabname: "Mobile",
        key: "2",
        visible: false
      } as Tab,
      projectsTab: {
        tabname: "Projects",
        key: "3",
        visible: false
      } as Tab,
      contractorsTab: {
        tabname: "Contractors",
        key: "4",
        visible: false
      } as Tab,
      disciplinesTab: {
        tabname: "Disciplines",
        key: "5",
        visible: false
      } as Tab,
      areasTab: {
        tabname: "Areas",
        key: "6",
        visible: false
      } as Tab,
      securityTab: {
        tabname: "Security",
        key: "7",
        visible: false
      } as Tab,
      filesTab: {
        tabname: "Files",
        key: "8",
        visible: false
      } as Tab,
      attachmentsTab: {
        tabname: "Attachments",
        key: "9",
        visible: false
      } as Tab,

      // *** DETAILS ***
      userPhoto: null as File | null,
      originalUserPhoto: null as File | null,

      // *** EMAIL ***
      newEmailAddressDialogVisible: false,
      emailAddressTableSearch: "",

      // *** MOBILE ***
      newPhoneNumberDialogVisible: false,
      phoneNumberTableSearch: "",

      // *** PROJECTS ***
      showOnlyIncludedProjects: false,
      projectsTableSearch: "",
      selectableProjects: [] as Array<ProjectWithPartsAndSelected>,

      // *** CONTRACTORS ***
      showOnlyIncludedContractors: false,
      contractorsTableSearch: "",

      // *** DISCIPLINES ***
      showDisciplines: true,
      disciplinesTableSearch: "",
      selectableDisciplines: [],
      personDisciplines: [] as Discipline[],

      // *** AREAS ***
      showAreas: true,
      areasFilter: null as LocationFilterWithLocations | null,
      areasTableSearch: "",

      // *** SECURITY ***

      // *** FILES ***
      touchedFileName: "",
      tablesearchfiles: "",
      // Files are uploaded immediately upon selection, so we don't need to track locally added files
      files: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined,

      disabled: false as Boolean
    };
  },

  computed: {
    attachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.files.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments;
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      var tabs = [this.emailTab, this.mobileTab, this.projectsTab];
      if (this.showDisciplines) {
        tabs.push(this.disciplinesTab);
      }
      if (this.showAreas) {
        tabs.push(this.areasTab);
      }
      var allTabs = tabs.concat([
        this.securityTab,
        this.filesTab,
        this.contractorsTab,
        this.attachmentsTab
      ]);
      return allTabs;
    },

    // *** PROJECTS ***
    projectUsageType: {
      get(): string {
        return this.user.includesAllProjects ? "entire" : "selection";
      },
      set(val: string) {
        this.user.includesAllProjects = val == "entire";
      }
    },

    projects(): Array<ProjectWithPartsAndSelected> {
      let returnValue = this.selectableProjects;
      if (this.showOnlyIncludedProjects) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedProjectIDs(): string[] {
      return this.selectableProjects.filter(x => x.selected).map(x => x.id!);
    },

    searchedProjects(): Array<ProjectWithPartsAndSelected> {
      // This is a hack because the projects list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .projectsDataTable as any)?.customFilter;
      if (this.projectsTableSearch && customFilter) {
        return this.projects.filter(
          x =>
            customFilter(x.name!, this.projectsTableSearch, x) ||
            customFilter(x.description!, this.projectsTableSearch, x)
        );
      } else {
        return this.projects;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedProjectsSelected(): boolean {
      return this.searchedProjects.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedProjectsSelected(): boolean {
      var searchedProjects = this.searchedProjects;
      return (
        searchedProjects.findIndex(x => x.selected) !== -1 &&
        searchedProjects.findIndex(x => !x.selected) !== -1
      );
    },

    // *** CONTRACTORS ***
    // Used for the "Home Contractor" selector
    contractorsForPerson(): ContractorWithTags[] {
      return this.userValues.contractorID;
      /*
      if (this.user.includesAllContractors) return this.userValues.contractorID;

      let contractorIDs = this.selectedContractorIDs ?? [];
      var filteredContractors = this.userValues.contractorID.filter(x =>
        contractorIDs.includes(x.id!)
      );
      return filteredContractors.sort((a, b) => {
        let nameA = a?.name ?? "";
        let nameB = b?.name ?? "";
        if (nameA < nameB) return -1;
        else if (nameA > nameB) return 1;
        else return 0;
      });
      */
    },
    contractorUsageType: {
      get(): string {
        return this.user.includesAllContractors ? "entire" : "selection";
      },
      set(val: string) {
        this.user.includesAllContractors = val == "entire";
      }
    },

    selectableContractors(): Array<ContractorWithTagsAndSelected> {
      return this.userValues.contractorID.map(
        x =>
          ({
            ...x,
            selected: this.user.contractorIDs?.length
              ? this.user.contractorIDs.indexOf(x.id!) > -1
              : false
          } as ContractorWithTagsAndSelected)
      );
    },

    contractors(): Array<ContractorWithTagsAndSelected> {
      let returnValue = this.selectableContractors;
      if (this.showOnlyIncludedContractors) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedContractorIDs(): string[] {
      return this.user.contractorIDs!;
      // return this.selectableContractors.filter(x => x.selected).map(x => x.id!);
    },

    visibleContractors(): Array<ContractorWithTagsAndSelected> {
      // This is a hack because the contractors list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .contractorsDataTable as any)?.customFilter;
      if (this.contractorsTableSearch && customFilter) {
        return this.contractors.filter(
          x =>
            customFilter(x.name!, this.contractorsTableSearch, x) ||
            customFilter(x.description!, this.contractorsTableSearch, x)
        );
      } else {
        return this.contractors;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allVisibleContractorsSelected(): boolean {
      return this.visibleContractors.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someVisibleContractorsSelected(): boolean {
      var visibleContractors = this.visibleContractors;
      return (
        visibleContractors.findIndex(x => x.selected) !== -1 &&
        visibleContractors.findIndex(x => !x.selected) !== -1
      );
    },

    // *** AREAS ***

    filterAreas(): AreaWithSubAreas[] {
      // Areas are direct children of projects, and are NOT children of other locations
      return this.areasFilter?.areas ?? [];
    },

    // *** SECURITY ***
    userHasContactMethod(): boolean {
      return !!this.user.emailAddresses?.length || !!this.user.phoneNumbers?.length;
    },
    enableCanViewWorkOrders(): boolean {
      return !this.user.canImportWorkOrders;
    }
  },

  methods: {
    async _initialize() {
      var userID = this.$route.params.id;
      if (this.$route.name == "PersonalProfile") {
        this.isPersonalProfile = true;
        userID = this.curUserID;
      }
      this.processing = true;
      this.userHasLoaded = false;
      await this.loadUser(userID);

      let userPhoto = await userService.downloadUserPhoto(userID);
      if (userPhoto) {
        this.userPhoto = this.originalUserPhoto = new File([userPhoto], "userPhoto", {
          type: userPhoto.type
        });
      }

      var referenceServiceCalls = [this.loadProjects(), this.loadUserFiles(), this.loadUserLinks()];
      if (this.showDisciplines) {
        referenceServiceCalls.push(this.loadPersonDisciplines());
      }
      if (this.showAreas) {
        referenceServiceCalls.push(this.loadPersonAreasFilter());
      }
      await Promise.all(referenceServiceCalls);

      // *** PROJECTS ***
      this.selectableProjects = this.$store.state.projects.fullList.map((x: ProjectWithParts) => ({
        ...x,
        selected: this.user.projectIDs?.length ? this.user.projectIDs.indexOf(x.id!) > -1 : false
      }));
      this.processing = false;
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },

    async resendNewAccountEmail() {
      await loginService.sendNewAccountNotice(
        `${document.location.protocol}//${document.location.host}`,
        this.userID!
      );
    },

    validate(): boolean {
      this.detailserror = !((this.$refs.detailsform as VForm)?.validate() ?? true);
      // this.projectserror = !((this.$refs.projectsform as VForm)?.validate() ?? true);
      this.permissionserror = !((this.$refs.securityform as VForm)?.validate() ?? true);
      // this.fileserror = !((this.$refs.filesform as VForm)?.validate() ?? true);
      return !(
        this.detailserror ||
        // this.projectserror ||
        // this.fileserror ||
        this.permissionserror
      );
    },

    // Method used in conjunction with the Save button.
    async save() {
      this.user.projectIDs = this.user.includesAllProjects ? [] : this.selectedProjectIDs;
      this.user.contractorIDs = this.user.includesAllContractors ? [] : this.selectedContractorIDs;
      if (!!this.user.contractorID && !this.user.contractorIDs!.includes(this.user.contractorID)) {
        this.user.contractorIDs!.push(this.user.contractorID);
      }

      if (!this.validate()) {
        var message = i18n.t("people.existing-person.error-message");
        if (this.detailserror) message += "\n\t- " + i18n.t("people.existing-person.tabs.details");
        if (this.contractorerror)
          message += "\n\t- " + i18n.t("people.existing-person.tabs.contractor-selection");
        if (this.projectserror)
          message += "\n\t- " + i18n.t("people.existing-person.tabs.projects-selection");
        if (this.permissionserror)
          message += "\n\t- " + i18n.t("people.existing-person.tabs.security");
        if (this.fileserror) message += "\n\t- " + i18n.t("people.existing-person.tabs.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      if (
        this.user.isLoginActive &&
        !this.user.emailAddresses?.length &&
        !this.user.phoneNumbers?.length
      ) {
        this.permissionserror = true;

        this.inlineMessage.message = this.$t("people.existing-person.login-requires-contact-info");
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      try {
        var archivedDate = null;
        if (this.user.isArchived && !this.user.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.user.archivedDate = archivedDate;

        var loginDisabledDate = null;
        // If we're archiving a person who is able to login, disable their login as well
        if ((this.user.isArchived || !this.user.isLoginActive) && !this.user.loginDisabledDate) {
          loginDisabledDate = new Date(new Date().toUTCString());
        }
        this.user.loginDisabledDate = loginDisabledDate;

        if (this.isPersonalProfile) {
          await this.savePartialUser();
        } else {
          await this.saveUser();
        }
        if (this.userPhoto !== this.originalUserPhoto) {
          if (this.userPhoto) {
            await userService.uploadUserPhoto(this.userID, this.userPhoto);
          } else {
            await userService.deleteUserPhoto(this.userID);
          }
        }

        // If this user didn't have a credential before, but are now able to log in, send a new account notice
        if (!this.user.securityCredentialID && this.user.isLoginActive) {
          await loginService.sendNewAccountNotice(
            `${document.location.protocol}//${document.location.host}`,
            this.userID!
          );
        }

        // User's file's are added/deleted in real time.  They don't need to be saved here.

        if (!this.isPersonalProfile) {
          this.$router.push("/people");
        }
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.saving = false;
      }
    },

    async deleteItem() {
      if (this.isPersonalProfile) return;

      await this.deleteUser();
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/people");
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      if (this.isPersonalProfile) return;

      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/people");
    },

    ...mapMutations({
      setUser: "SET_USER",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadProjects: "LOAD_PROJECTS"
    }),

    // *** DETAILS ***

    // *** EMAIL ADDRESSES ***
    async deletePersonalEmailAddress(item: any) {
      if (!this.user.emailAddresses) return;

      this.processing = true;
      try {
        await personalEmailAddressService.deleteItem(item.id!);
        const index = this.user.emailAddresses.indexOf(item);
        if (index < 0) {
          return;
        }
        this.user.emailAddresses.splice(index, 1);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    openNewEmailAddressDialog() {
      this.newEmailAddressDialogVisible = true;
    },

    cancelNewEmailAddressDialog() {
      this.newEmailAddressDialogVisible = false;
    },

    async emailAddressAdded(item: any) {
      if (!this.user.emailAddresses) this.user.emailAddresses = [];

      this.user.emailAddresses.push(item);
      this.newEmailAddressDialogVisible = false;

      if (this.userID != this.curUserID) return;

      // this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(item.emailAddress, null)) {
        this.loadUser(this.userID);
      }
    },

    /// *** PHONE NUMBER ***
    async deletePersonalPhoneNumber(item: any) {
      if (!this.user.phoneNumbers) return;

      this.processing = true;
      try {
        await personalPhoneNumberService.deleteItem(item.id!);
        const index = this.user.phoneNumbers.indexOf(item);
        if (index < 0) {
          return;
        }
        this.user.phoneNumbers.splice(index, 1);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    openNewPhoneNumberDialog() {
      this.newPhoneNumberDialogVisible = true;
    },

    cancelNewPhoneNumberDialog() {
      this.newPhoneNumberDialogVisible = false;
    },

    async phoneNumberAdded(item: any) {
      if (!this.user.phoneNumbers) this.user.phoneNumbers = [];

      this.user.phoneNumbers.push(item);
      this.newPhoneNumberDialogVisible = false;

      if (this.userID != this.curUserID) return;

      // this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(null, item.phoneNumber)) {
        this.loadUser(this.userID);
      }
    },

    // *** PROJECTS ***
    flipProjectSelected(item: ProjectWithPartsAndSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedProjectselected() {
      let selected = !this.allSearchedProjectsSelected;
      let changedProjectIDs = [] as string[];
      for (let project of this.searchedProjects) {
        if (project.selected !== selected) {
          project.selected = selected;
          changedProjectIDs.push(project.id!);
        }
      }
    },

    // *** CONTRACTORS ***
    flipContractorSelected(item: ContractorWithTagsAndSelected) {
      let contractorIDs = this.user.contractorIDs ?? [];
      if (contractorIDs.includes(item.id!)) {
        const index = this.user.contractorIDs!.indexOf(item.id!);
        if (index < 0) {
          return;
        }
        this.user.contractorIDs!.splice(index, 1);
      } else {
        contractorIDs.push(item.id!);
        this.user.contractorIDs = contractorIDs;
      }
    },

    flipSearchedContractorselected() {
      let selected = !this.allVisibleContractorsSelected;
      let changedContractorIDs = [] as string[];
      for (let contractor of this.visibleContractors) {
        if (contractor.id == this.user.contractorID) {
          contractor.selected = true;
        } else if (contractor.selected !== selected) {
          contractor.selected = selected;
          changedContractorIDs.push(contractor.id!);
        }
      }
    },

    // *** DISCIPLINES ***

    // DOES NOT manage processing or error message logic
    async loadPersonDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getByPersonID(this.userID);
      this.personDisciplines = disciplines;
    },

    // *** AREAS ***

    async loadPersonAreasFilter(): Promise<void> {
      this.areasFilter = await locationFilterService.getByPersonID(this.userID);
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async loadUserLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByPersonID(this.userID);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.personID = this.userID;
        await externalLinkService.addItem(newLink);
        this.externalLinks.push(newLink);

        var snackbarPayload = {
          text: this.$t("people.existing-person.save-link-success", [newLink.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("people.existing-person.update-link-success", [link.name]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("people.existing-person.delete-link-success", [link.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files
    async loadUserFiles() {
      this.processing = true;
      try {
        var fileNames = await userService.getUserFileList(this.userID);
        this.files = fileNames.map(function(fileName) {
          return {
            name: fileName,
            isPreviewable: isFilePreviewable(fileName),
            isPhoto: isFilePhoto(fileName)
          };
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.files);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData!.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.files);

        await this.saveNewFileData(this.newFileData!);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.files.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string | undefined) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await userService.downloadUserFile(this.userID!, fileNameToDownload);
        }
        await userService.uploadUserFile(this.userID, fileData.name, fileData.file as Blob);

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await userService.deleteUserFile(this.userID, originalFileName);
        }

        let snackbarText = fileData.isPhoto
          ? this.$t("people.existing-person.update-photo-success", [fileData.name])
          : this.$t("people.existing-person.update-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData) {
      if (!fileData) return;

      this.processing = true;
      try {
        await userService.uploadUserFile(this.userID, fileData.name, fileData.file as Blob);
        this.files.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("people.existing-person.save-photo-success", [fileData.name])
          : this.$t("people.existing-person.save-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.files);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (fileData.isPhoto) {
        this.editingFileData = fileData;
        this.imageName = fileData.name;
        if (!!fileData.file) {
          this.editImageSource = this.covertFileToDataURL(fileData.file);
        } else {
          this.editImageSource = `/services/FormidableDesigns.Services.V1.UserService.DownloadUserFile?personId=${this.userID}&fileName=${fileData.name}`;
        }
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await userService.downloadUserFile(this.userID, fileName);
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await userService.downloadUserFile(this.userID!, fileData.name);
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        fileData.file = await userService.downloadUserFile(this.userID!, fileData.name);
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        let url = `/services/FormidableDesigns.Services.V1.UserService.DownloadUserFile?personId=${this.userID}&fileName=${fileData.name}`;
        this.imageSource = url;
      }
    },
    async deleteFile(fileData: any) {
      let fileName = fileData.name;
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await userService.downloadUserFile(this.userID!, fileData.name);
        }
        await userService.deleteUserFile(this.userID, fileName);
        this.files.splice(this.files.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("people.existing-person.delete-photo-success", [fileData.name])
          : this.$t("people.existing-person.delete-file-success", [fileData.name]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    updateRoleBasedPrivileges() {
      // *** User controlled flags ***
      this.user.canApproveScaffoldRequests =
        !this.user.forceReadonlyAccess &&
        (this.user.canApproveScaffoldRequests! || this.user.isPlanner!);

      this.user.canEditWorkOrderPlannedWorkDate =
        !this.user.forceReadonlyAccess &&
        (this.user.canEditWorkOrderPlannedWorkDate! || this.user.isPlanner!);

      // *** Role controlled flags ***
      // NOTE: The forceReadonlyAccess flag DOES NOT affect these flags at this point.  It is used server-side to override these flags

      this.user.canUpdateAssociatedWorkOrdersWithoutBeingAssigned =
        this.user.isPlanner! || this.user.isCoordinator!;

      this.user.canWalkDownUnassignedWorkOrders = this.user.isPlanner!;
      this.user.canApproveWalkdowns = this.user.isPlanner! || this.user.isCoordinator!;

      this.user.canApproveCountSheets = this.user.isPlanner!;

      this.user.canEditWorkOrderArea = this.user.isPlanner!;
      this.user.canEditWorkOrderAreaForAllAssignedContractors = this.user.isPlanner!;
      this.user.canEditWorkOrderLocation = this.user.isPlanner!;
      this.user.canEditWorkOrderLocationForAllAssignedContractors = this.user.isPlanner!;
      this.user.canEditWorkOrderWorkDescription = this.user.isPlanner!;
      this.user.canEditWorkOrderWorkDescriptionForAllAssignedContractors = this.user.isPlanner!;
      this.user.canEditWorkOrderPriority = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canEditWorkOrderPriorityForAllAssignedContractors =
        this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canEditWorkOrderProgress =
        this.user.isPlanner! || this.user.isGeneralForeman! || this.user.isForeman!;
      this.user.canEditWorkOrderProgressForAllAssignedContractors = this.user.isPlanner!;

      this.user.canEditWorkOrderStatus =
        this.user.isPlanner! ||
        this.user.isCoordinator! ||
        this.user.isGeneralForeman! ||
        this.user.isForeman!;
      this.user.canEditWorkOrderStatusForAllAssignedContractors =
        this.user.isPlanner! || this.user.isCoordinator!;

      this.user.canCancelWorkOrder = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canCancelWorkOrderForAllAssignedContractors =
        this.user.isPlanner! || this.user.isCoordinator!;

      this.user.canEditWorkOrderRequiredDate = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canEditWorkOrderRequiredDateForAllAssignedContractors =
        this.user.isPlanner! || this.user.isCoordinator!;

      this.user.canEditWorkOrderContractor = this.user.isPlanner!;
      this.user.canEditWorkOrderCoordinator = this.user.isPlanner!;
      this.user.canEditWorkOrderCoordinatorForAllAssignedContractors = this.user.isPlanner!;
      this.user.canEditWorkOrderGeneralForeman = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canEditWorkOrderGeneralForemanForAllAssignedContractors = this.user.isPlanner!;
      this.user.canEditWorkOrderForeman =
        this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!;
      this.user.canEditWorkOrderForemanForAllAssignedContractors = this.user.isPlanner!;

      // Approve Request permission is automatic if Planner, otherwise is manual
      // View is guaranteed for ALL planners and Coordinators
      this.user.canViewScaffoldRequestApprovals = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canViewWorkOrderEstimates = this.user.isPlanner! || this.user.isCoordinator!;
      this.user.canViewWorkOrderSchedule =
        this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!;
      this.user.canEditWorkOrderSchedule =
        this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!;
      this.user.canViewToDoList =
        this.user.isPlanner! ||
        this.user.isCoordinator! ||
        this.user.isGeneralForeman! ||
        this.user.isForeman!;
      this.user.canViewScaffolds =
        this.user.isPlanner! ||
        this.user.isCoordinator! ||
        this.user.isGeneralForeman! ||
        this.user.isForeman!;
      this.user.canViewMaterialApproval = this.user.isPlanner!;
      // this.user.canViewTransfers = this.user.isPlanner!;
      // this.user.canCreateTransfers = this.user.isPlanner!;
    }
  },

  watch: {
    "user.forceReadonlyAccess": function(newValue, oldValue) {
      if (newValue) {
        this.user.canImportWorkOrders = false;
        this.user.canSubmitScaffoldRequests = false;
        this.user.canApproveScaffoldRequests = false;
        this.user.canApproveScaffoldRequestsFromAllAssignedContractors = this.user.canApproveScaffoldRequests;
        this.user.canEditWorkOrderPlannedWorkDate = false;
        this.user.canApproveCountSheets = false;
      } else {
        this.updateRoleBasedPrivileges();
      }
    },
    "user.canEditWorkOrderPlannedWorkDate": function(newValue, oldValue) {
      this.user.canEditWorkOrderPlannedWorkDateForAllAssignedContractors = this.user.canEditWorkOrderPlannedWorkDate;
    },
    "user.canApproveScaffoldRequests": function(newValue, oldValue) {
      this.user.canApproveScaffoldRequestsFromAllAssignedContractors = this.user.canApproveScaffoldRequests;
      if (newValue) {
        this.user.canViewScaffoldRequestApprovals = true;
      }
    },
    "user.contractorID": function(newValue, oldValue) {
      // If we're changing the value from SOMETHING to NOTHING, set the value to an empty GUID to show it's supposed to be empty, not ignored by the server
      if (!!oldValue && !newValue) this.user.contractorID = null;

      if (!!newValue) {
        let contractorIDs = this.user.contractorIDs ?? [];
        if (!contractorIDs.includes(newValue)) {
          contractorIDs.push(newValue);
          this.user.contractorIDs = contractorIDs;
        }
      }
    },
    "user.isLoginActive": function(newValue, oldValue) {
      if (!newValue) {
        this.user.forceReadonlyAccess = false;
        this.user.canViewWorkOrders = false;
        this.user.canImportWorkOrders = false;
        this.user.canSubmitScaffoldRequests = false;
        this.user.canApproveScaffoldRequests = false;
        this.user.canEditWorkOrderPlannedWorkDate = false;
        this.user.canConfigureSettings = false;
        this.user.isPlanner = false;
        this.user.isCoordinator = false;
        this.user.isGeneralForeman = false;
        this.user.isForeman = false;

        this.updateRoleBasedPrivileges();
      }
    },
    "user.canImportWorkOrders": function(newValue, oldValue) {
      if (newValue) {
        this.user.canViewWorkOrders = true;
      }
    },
    "user.canEditWorkOrderSchedule": function(newValue, oldValue) {
      if (newValue) {
        this.user.canViewWorkOrderSchedule = true;
      }
    },
    "user.isPlanner": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges();
    },
    "user.isCoordinator": function(newValue, oldValue) {
      if (!!this.user.legacyID) return;
      this.updateRoleBasedPrivileges();
    },
    "user.isGeneralForeman": function(newValue, oldValue) {
      if (!!this.user.legacyID) return;
      this.updateRoleBasedPrivileges();
    },
    "user.isForeman": function(newValue, oldValue) {
      if (!!this.user.legacyID) return;
      this.updateRoleBasedPrivileges();
    },
    user(newValue) {
      this.userHasLoaded = !!newValue;

      if (this.isPersonalProfile) {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.$t("people.existing-person.profile-title"),
          to: "/personalprofile",
          resetHistory: true
        });
      } else if (this.$route.name == "PersonSearchResult") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: newValue.firstName + " " + newValue.lastName,
          to: `/person/${this.userID}`
        });
      } else if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/contractors") {
        this.notifyNewBreadcrumb({
          text: newValue.firstName + " " + newValue.lastName,
          to: `/people/${this.userID}`
        });
      } else {
        // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
        // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/people") {
          this.notifyNewBreadcrumb({
            text: this.$t("users.menu-title"),
            to: "/people",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
        this.notifyNewBreadcrumb({
          text: newValue.firstName + " " + newValue.lastName,
          to: `/people/${this.userID}`
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "users-existing",
      parentalContext: "users",
      selectedTab: this.firstTabKey
    });

    await this._initialize();
  },

  beforeUpdate: async function() {
    var routedID = this.$route.params.id ?? this.curUserID;
    if (this.userID != routedID) {
      await this._initialize();
    }
  }
});

