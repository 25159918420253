import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { accessCodeService, AccessCodeChallenge } from "@fd/current/client/services";

type AccessCodeEntryDialogResult = boolean;

const AccessCodeEntryDialog = FDVue.extend({
  name: "fd-enter-access-code-dialog",

  mixins: [dialogSupport, rules],

  components: {
    "fd-code-entry": () => import("@fd/lib/vue/components/CodeEntry.vue")
  },

  data() {
    return {
      title: "Enter Access Code",
      email: "" as string | null,
      phoneNumber: "" as string | null,
      accesscode: "",
      accessCodeSent: false,
      accessCodeChallenge: null as AccessCodeChallenge | null
    };
  },

  computed: {
    dialogRules(): any {
      return {};
    }
  },

  mounted: async function() {
    this.$nextTick(async () => await this.sendVerificationCode());
  },

  methods: {
    async open(
      email: string | null,
      phoneNumber: string | null
    ): Promise<AccessCodeEntryDialogResult> {
      this.email = email;
      this.phoneNumber = phoneNumber;

      return await this.showDialog();
    },

    closeDialogEntry(result: AccessCodeEntryDialogResult) {
      this.closeDialog(result);
    },

    // Event for when the last digit of the access code has been entered by the user
    async codeEntered(codeString: string) {
      this.accesscode = codeString;
      await this.confirmVerifyEmailOrPhoneNumberWithAccessCode();
    },

    async sendVerificationCode() {
      this.inlineMessage.message = this.$t("loading-dot-dot-dot");
      this.inlineMessage.type = "info";
      this.processing = true;
      try {
        this.accessCodeChallenge = await accessCodeService.verifyEmailAddressOrPhoneNumber(
          this.email ?? "",
          this.phoneNumber ?? ""
        );
        if (!!this.accessCodeChallenge) {
          this.inlineMessage.message = "";

          this.accessCodeSent = true;
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 750);
        } else {
          this.inlineMessage.message = this.$t("error-messages.fdserverr_accesscodesendfailed");
          this.inlineMessage.type = "error";
        }
      } catch (error) {
        this.handleError(error as Error, "unexpected-network-error-short");
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
      }
    },

    async resendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;

      try {
        await accessCodeService.resendAccessCode(this.accessCodeChallenge!);
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          (this.$refs.accesscodeentry as any)?.focus();
        }, 750);
      }
    },

    async confirmVerifyEmailOrPhoneNumberWithAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      try {
        this.accessCodeChallenge = await accessCodeService.confirmVerifyEmailOrPhoneNumberWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        if (!this.accessCodeChallenge) {
          this.closeDialogEntry(true);
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        var message =
          (error as any).statusCode == 422
            ? this.$t("access-code-expired")
            : this.$t("unexpected-network-error-short");
        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
      }
    }
  }
});

export default AccessCodeEntryDialog;

export async function showAccessCodeEntryDialog(
  email: string | null,
  phoneNumber: string | null
): Promise<AccessCodeEntryDialogResult> {
  let dialog = createDialog(AccessCodeEntryDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(email, phoneNumber);
  console.log("showAccessCodeEntryDialog.result: " + JSON.stringify(result));
  return result;
}
