var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-form",
            { ref: "detailsform", on: { submit: _vm.onSubmit } },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("user-phone-numbers.new-dialog-title"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-2 pt-3", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "12" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t("common.phone"),
                          rules: _vm.dialogRules.phoneNumber,
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.personalPhoneNumber.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.personalPhoneNumber,
                              "phoneNumber",
                              $$v
                            )
                          },
                          expression: "personalPhoneNumber.phoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: _vm.$t("common.notifications"),
                          disabled: _vm.processing,
                          hint: _vm.$t(
                            "users.user-mobile-number-notifications-switch-hint"
                          ),
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.personalPhoneNumber.allowNotifications,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.personalPhoneNumber,
                              "allowNotifications",
                              $$v
                            )
                          },
                          expression: "personalPhoneNumber.allowNotifications"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.cancelDialog } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.add")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }