import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import { loginService, accessCodeService } from "@fd/current/client/services";
import { AccessCodeChallenge, LoginChallenge, LoginInformation } from "@fd/current/client/services";
import { setLogin } from "@fd/current/client/login";
import { pause } from "@fd/lib/client-util/util";
import { hmacSha512 } from "@fd/lib/client-util/encryption";
import { VForm } from "@fd/lib/vue/types";
import rules from "@fd/lib/vue/rules";
import errorHandling, { ErrorHandlingOverride } from "@fd/lib/vue/mixins/errorHandling";

enum LoginStages {
  EnterEmailOnLoad,
  StartOver,
  EnterPassword,
  EnterAccessCodeForLogin,
  EnterAccessCodeForForgotPassword,
  EnterAccessCodeForActivation,
  EnterNewPassword,
  NewPasswordUpdated,
  LoggingIn
}

export default FDVue.extend({
  name: "fd-Login",

  mixins: [rules, errorHandling],

  components: {
    "fd-privacy-dialog": () => import("@fd/current/client/views/components/PrivacyDialog.vue"),
    "fd-terms-dialog": () => import("@fd/current/client/views/components/TermsDialog.vue"),
    "fd-code-entry": () => import("@fd/lib/vue/components/CodeEntry.vue")
  },

  watch: {
    loginStage(newValue, oldValue) {
      console.log(`loginStage: ${LoginStages[oldValue]} -> ${LoginStages[newValue]}`);
    }
  },

  data: () => ({
    // The following tracks the current width of the browser window. It works in conjunction with a EventListener
    // setup in the "created" hook.
    windowWidth: 0,
    windowHeight: 0,
    windowScrollY: 0,

    // The following will control whether the controls on screen are disabled while we are processing.
    // processing: false,
    processingEmailEntry: false,
    processingSignInRequest: false,

    processingAccessCodeRequest: false,
    processingForgotPasswordRequest: false,
    processingFederatedIdentityRequest: false,
    processingSharedSecret: false,
    processingUpdatePassword: false,

    loginStage: LoginStages.EnterEmailOnLoad as LoginStages,

    brandImageWidth: 300,
    brandImageHeight: 155,
    brandImageWidthSmaller: 300,
    brandImageHeightSmaller: 155,
    landscapeOrientation: false,
    logobrandImageTopValue: 0,

    signincountdown: 0,

    emailAddressOrPhoneNumber: "",

    password: "",
    newpassword: "",
    confirmnewpassword: "",
    accesscode: "",
    showPassword: false,

    signinsuccessful: false,

    loginChallenge: null as LoginChallenge | null,
    accessCodeChallenge: null as AccessCodeChallenge | null
  }),

  computed: {
    processingData(): boolean {
      return (
        this.processing ||
        this.processingFederatedIdentityRequest ||
        this.processingEmailEntry ||
        this.processingSharedSecret ||
        this.processingAccessCodeRequest ||
        this.processingSignInRequest ||
        this.processingForgotPasswordRequest ||
        this.processingUpdatePassword
      );
    },
    showEmailEntry(): boolean {
      return this.showEmailEntryOnLoad || this.reshowEmailEntry;
    },
    showEmailEntryOnLoad(): boolean {
      return this.loginStage == LoginStages.EnterEmailOnLoad;
    },
    // Used to differentiate between email entry being shown on load vs going back to it, since restarting requires transitions for appearing
    reshowEmailEntry(): boolean {
      return this.loginStage == LoginStages.StartOver;
    },
    showPasswordEntry(): boolean {
      return this.loginStage == LoginStages.EnterPassword;
    },
    showAccessCodeEntry(): boolean {
      return (
        this.showAccessCodeEntryForLogin ||
        this.showAccessCodeEntryForForgotPassword ||
        this.showAccessCodeEntryForActivation
      );
    },
    showAccessCodeEntryForLogin(): boolean {
      return this.loginStage == LoginStages.EnterAccessCodeForLogin;
    },
    showAccessCodeEntryForForgotPassword(): boolean {
      return this.loginStage == LoginStages.EnterAccessCodeForForgotPassword;
    },
    showAccessCodeEntryForActivation(): boolean {
      return this.loginStage == LoginStages.EnterAccessCodeForActivation;
    },
    showUpdatePasswordEntry(): boolean {
      return this.loginStage == LoginStages.EnterNewPassword;
    },
    showPasswordUpdatedSuccessfullyResponse(): boolean {
      return this.loginStage == LoginStages.NewPasswordUpdated;
    },

    languageslist() {
      return this.$store.state.languages.fullList.sort(function(a: any, b: any) {
        return a.number - b.number;
      });
    },

    language: {
      get() {
        return this.$store.state.language;
      },
      set(val) {
        if (val != undefined) {
          let language = this.$store.state.languages.fullList.find((x: any) => x.number == val);
          this.$store.commit("SET_PREFERRED_LANGUAGE", {
            shortCode: language.shortCode,
            shortCodeExt: language.shortCodeExt,
            number: language.number
          });
        }
      }
    },

    smallishMobileDevice() {
      //First check to make sure the registered width and height have a value larger than zero other wise something strange is up.
      if (this.windowWidth > 0 && this.windowHeight > 0) {
        if (this.windowWidth > this.windowHeight) {
          //Potentially a LANDSCAPE oriented mobile device
          if (this.windowWidth < 1000) {
            //Since its width is less than 1000 is LIKELY a mobile device not a web browser on a workstation.
            if (this.windowHeight / this.windowWidth < 0.7) {
              //If the ratio of width to height is here it is VERY likely to be a mobile device in landscape orientation.
              this.landscapeOrientation = true;
              this.brandImageWidth = 200;
              this.brandImageWidthSmaller = 140;
              this.brandImageHeight = 103;
              this.brandImageHeightSmaller = 72;
              return true;
            } else {
              // This would indicate this is just a very thin browser window.
              if (this.windowWidth < 600) {
                this.brandImageWidth = 120;
                this.brandImageHeight = 62;
              } else {
                this.brandImageWidth = 300;
                this.brandImageHeight = 155;
              }
              return false;
            }
          } else {
            this.brandImageWidth = 300;
            this.brandImageHeight = 155;
            return false;
          }
        } else {
          //Potentially a PORTRAIT oriented mobile device.
          if (this.windowHeight < 1000) {
            //Since its width is less than 1000 is LIKELY a mobile device not a web browser on a workstation.
            if (this.windowWidth / this.windowHeight < 0.7) {
              //If the ratio of width to height is here it is VERY likely to be a mobile device in portrait orientation.
              this.landscapeOrientation = false;
              this.brandImageWidth = 120;
              this.brandImageHeight = 62;
              return true;
            } else {
              // This would indicate this is just a very thin browser window.
              if (this.windowWidth < 600) {
                this.brandImageWidth = 120;
                this.brandImageHeight = 62;
              } else {
                this.brandImageWidth = 300;
                this.brandImageHeight = 155;
              }
              return false;
            }
          } else {
            // This would indicate this is just a very thin browser window.
            if (this.windowWidth < 600) {
              this.brandImageWidth = 120;
              this.brandImageHeight = 62;
            } else {
              this.brandImageWidth = 300;
              this.brandImageHeight = 155;
            }
            return false;
          }
        }
      } else {
        this.brandImageWidth = 300;
        this.brandImageHeight = 155;
        return false;
      }
    },

    smallWidthBrowser() {
      if (this.windowWidth < 600) {
        this.brandImageWidth = 120;
        this.brandImageHeight = 62;
        return true;
      } else {
        return false;
      }
    },

    dialogPrivacy: {
      get() {
        return this.$store.state.dialogPrivacy;
      },
      set(val) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    },

    dialogTerms: {
      get() {
        return this.$store.state.dialogTerms;
      },
      set(val) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  },

  methods: {
    /*
      GLOBAL
    */
    ...mapMutations({
      setShowAppBar: "SET_SHOW_APP_BAR",
      setShowDrawer: "SET_SHOW_DRAWER",
      setShowFooter: "SET_SHOW_FOOTER",
      setShowBottomBar: "SET_SHOW_BOTTOM_BAR",
      setPrivacyDialog: "SET_PRIVACY_DIALOG",
      setTermsDialog: "SET_TERMS_DIALOG"
    }),

    async refreshUserReferenceData() {
      await Promise.all([this.$store.dispatch("LOAD_LANGUAGES")]);
    },

    startOver() {
      this.inlineMessage.message = "";
      this.loginStage = LoginStages.StartOver;

      // Keep email as it will probably be the same
      this.password = "";
      this.accesscode = "";
      this.newpassword = "";
      this.confirmnewpassword = "";

      this.processing = false;
    },

    // Navigate the logged-in user to the application
    loadIntoApplication() {
      this.loginStage = LoginStages.LoggingIn;
      this.setShowAppBar(true);
      this.setShowDrawer(true);
      this.setShowFooter(true);
      this.setShowBottomBar(true);
      let loginReturnPath = this.$store.state.loginReturnPath || "/?firstLoad=true";
      this.$store.commit("CLEAR_LOGIN_RETURN_PATH");
      this.$router.replace(loginReturnPath);
    },

    /*
      EMAIL ENTRY
    */

    // Method used in conjunction with the Save button.
    async typeYourPassword(e: Event) {
      e.preventDefault();

      if (!(this.$refs.enterEmailAddressForm as VForm).validate()) {
        return;
      }

      await this.getChallenge();
    },

    async getChallenge() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      let email = "";
      let phoneNumber = "";
      let identifier = "";
      if (this.rules.validPhoneNumber(this.emailAddressOrPhoneNumber) == true) {
        phoneNumber = this.emailAddressOrPhoneNumber;
      } else if (this.rules.validEmail(this.emailAddressOrPhoneNumber) == true) {
        email = this.emailAddressOrPhoneNumber;
      } else {
        identifier = this.emailAddressOrPhoneNumber;
      }

      this.processing = true;
      this.processingEmailEntry = true;
      try {
        let loginChallenge = await loginService.getLoginChallenge(email, phoneNumber, identifier);
        this.loginChallenge = loginChallenge;

        // After a brief moment set the focus on the Password Input box
        setTimeout(() => {
          this.processing = false;
          this.processingEmailEntry = false;
        }, 1000);

        if (!loginChallenge) {
          this.inlineMessage.message = this.$t("username-not-found");
          this.inlineMessage.type = "warning";
          return;
        } else if (loginChallenge.needsActivation) {
          this.accessCodeChallenge = await accessCodeService.activateUserInformation(
            this.loginChallenge!,
            email,
            phoneNumber
          );
          this.loginStage = LoginStages.EnterAccessCodeForActivation;
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 4000);
          return;
        } else if (!loginChallenge.publicSalt) {
          // If there is no public salt, there is a user associated to the email/phone, but it doesn't have a password yet
          this.sendAccessCode(true);
          return;
        } else {
          this.loginStage = LoginStages.EnterPassword;
          return;
        }
      } catch (error) {
        // We want to keep using the error code sent by the server as our key
        // But if our error is due to multiple accounts or an unverified contact method we want it displayed as a warning
        let overrides = {
          "409": { type: "warning" },
          "412": { type: "warning" }
        } as ErrorHandlingOverride;
        this.handleError(error, "unexpected-network-error-short", overrides);
      } finally {
        this.processing = false;
        this.processingEmailEntry = false;

        // After a brief moment set the focus on the Password Input box
        setTimeout(() => {
          if (this.$refs.passwordinput) {
            (this.$refs.passwordinput as any).focus();
          }
        }, 500);
      }
    },

    /*
      PASSWORD ENTRY
    */
    startOverFromTypePassword() {
      this.startOver();
      this.processingEmailEntry = false;
    },

    async signin(e: Event) {
      e.preventDefault();
      if (!(this.$refs.enterPasswordForm as VForm).validate()) {
        return;
      }

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        let encoder = new TextEncoder();
        let publicHash = await hmacSha512(
          this.loginChallenge!.publicSalt,
          encoder.encode(this.password)
        );
        let loginResult = await loginService.respondToLoginChallenge(
          this.loginChallenge!.loginContext,
          publicHash
        );
        if (loginResult) {
          await setLogin(loginResult);
          this.loadIntoApplication();
        } else {
          this.inlineMessage.message = this.$t("invalid-password");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the Password Input box
          // FYI you cannot use the "focus()" or "select()" immediately you need to use setTimeout or nextTick
          // Also getting at the appropriate input control within the vuetify text field is a little tricky.
          setTimeout(() => {
            if (this.$refs.passwordinput) {
              (this.$refs.passwordinput as any).focus();
              (this.$refs.passwordinput as any).$el.querySelector("input").select();
            }
          }, 200);
        }
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      ACCESS CODE
    */
    startOverFromAccessCodeEntry() {
      this.startOver();

      (this.$refs.accesscodeentry as any)?.clear();

      this.processingForgotPasswordRequest = false;
      this.processingAccessCodeRequest = false;
      this.processingEmailEntry = false;
    },

    // This function is fired by the user clicking on the button that requests an "Access Code"
    async sendAccessCode(forgotPassword: boolean = false) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        this.accessCodeChallenge = await accessCodeService.generateAccessCode(this.loginChallenge!);

        if (forgotPassword) this.loginStage = LoginStages.EnterAccessCodeForForgotPassword;
        else this.loginStage = LoginStages.EnterAccessCodeForLogin;
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          (this.$refs.accesscodeentry as any)?.focus();
        }, 4000);
      }
    },

    async resendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        await accessCodeService.resendAccessCode(this.accessCodeChallenge!);
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          (this.$refs.accesscodeentry as any)?.focus();
        }, 4000);
      }
    },

    // Event for when the last digit of the access code has been entered by the user
    codeEntered(codeString: string) {
      this.accesscode = codeString;
      this.processAccessCode();
    },

    async processAccessCode() {
      if (this.showAccessCodeEntryForForgotPassword || this.showAccessCodeEntryForLogin) {
        this.signinWithAccessCode();
      } else if (this.showAccessCodeEntryForActivation) {
        this.activateAccountViaAccessCode();
      }
    },

    async signinWithAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        this.accessCodeChallenge = await accessCodeService.loginWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        let loginResult = this.accessCodeChallenge.loginInformation;
        if (loginResult) {
          await setLogin(loginResult);
          if (this.showAccessCodeEntryForForgotPassword) {
            this.loginStage = LoginStages.EnterNewPassword;
          } else {
            this.loadIntoApplication();
          }
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        var message =
          error.statusCode == 422
            ? this.$t("access-code-expired")
            : this.$t("unexpected-network-error-short");
        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      VERIFY EMAIL ADDRESS OR PHONE NUMBER VIA ACCESS CODE
    */

    async activateAccountViaAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        this.accessCodeChallenge = await accessCodeService.activateAccountWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        let loginResult = this.accessCodeChallenge.loginInformation;
        if (loginResult) {
          await setLogin(loginResult);
          this.loginStage = LoginStages.EnterNewPassword;
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        var message =
          error.statusCode == 422
            ? this.$t("access-code-expired")
            : this.$t("unexpected-network-error-short");
        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      FORGOT PASSWORD
    */
    startOverFromForgotPassword() {
      this.inlineMessage.message = "";
      this.loginStage = LoginStages.StartOver;

      this.processing = false;
      this.processingForgotPasswordRequest = false;
      this.processingAccessCodeRequest = false;
      this.processingEmailEntry = false;
    },

    // Once the password has been updated, login the user automatically
    decrementCountdownTimer() {
      this.signincountdown = 12;
      setInterval(() => {
        this.signincountdown--;
        if (this.signincountdown == 0) {
          this.loadIntoApplication();
        }
      }, 1000);
    },

    async updatePassword() {
      if (!(this.$refs.changePasswordForm as VForm).validate()) {
        return;
      }
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingUpdatePassword = true;

      try {
        let publicSalt = new Uint8Array(32);
        crypto.getRandomValues(publicSalt);
        let textEncoder = new TextEncoder();
        let publicHash = await hmacSha512(publicSalt, textEncoder.encode(this.newpassword));
        if (await loginService.updateUserPassword(publicSalt, publicHash)) {
          this.loginStage = LoginStages.NewPasswordUpdated;
          this.decrementCountdownTimer();
          await pause(2000); // Do the animation before turning off the processing flags
        } else {
          this.inlineMessage.message = this.$t("password.update-password-reset-expired");
          this.inlineMessage.type = "warning";
        }
      } catch (e) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.processingUpdatePassword = false;
      }
    },
    resize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.windowScrollY = window.scrollY;

      //The logo image will be placed such that it's top is the height of the window minus the height of the logo
      //minus a fixed amount of 20px for aesthetic reasons and then also remove the amount of the Scroll Y offset
      // since especially on iOS devices the presence or absence of the URL bar will affect the overall height.
      this.logobrandImageTopValue =
        this.windowHeight - this.brandImageHeight - 20 + this.windowScrollY;
    },
    scroll() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.windowScrollY = window.scrollY;

      //The logo image will be placed such that it's top is the height of the window minus the height of the logo
      //minus a fixed amount of 20px for aesthetic reasons and then also remove the amount of the Scroll Y offset
      // since especially on iOS devices the presence or absence of the URL bar will affect the overall height.
      this.logobrandImageTopValue =
        this.windowHeight - this.brandImageHeight - 20 + this.windowScrollY;
    }
  },

  created: async function() {
    // Listen to the "resize" even for the browser so we always know the width and height and can use that
    // knowledge for various responsive layout reasons.
    // Also we will additionally track the ScrollY offset for layout and placement related purposes.
    window.addEventListener("resize", this.resize);

    // Listen to the "scroll" even for the browser so we always know the width height and can use that
    // knowledge for various responsive layout reasons.
    window.addEventListener("scroll", this.scroll);

    //Grab the original width, height and ScrollY offset and place the logo image appropriately.
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.windowScrollY = window.scrollY;
    this.logobrandImageTopValue =
      this.windowHeight - this.brandImageHeight - 20 + this.windowScrollY;

    // Tells the store to hide the various UI bars and drawers as appropriate.
    this.setShowAppBar(false);
    this.setShowDrawer(false);
    this.setShowFooter(false);
    this.setShowBottomBar(false);

    //Refresh any dependency data in the store.
    this.refreshUserReferenceData();

    // After a couple seconds set the focus on the Username Input box
    const interval = setTimeout(() => {
      if (this.$refs.usernameinput) {
        (this.$refs.usernameinput as any).focus();
      }
    }, 1500);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.scroll);
  }
});



