import FDVue from "../index";
import twoWayProps from "../mixins/twoWayProps";
import * as DateUtil from "../../client-util/datetime";

export default FDVue.extend({
  name: "fd-date-picker",
  mixins: [twoWayProps],

  inheritAttrs: false,

  props: {
    label: String,
    value: [Date, String],
    rules: Array,
    disabled: { default: false, type: Boolean },
    outlined: { default: true, type: Boolean },
    clearable: { default: false, type: Boolean },
    i18nLocale: { default: "en-us", type: String },
    closeOnSelect: { default: true, type: Boolean },
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-date-picker" },
    format: { type: String, default: "llll" },
    min: { type: [String, Date], default: undefined },
    max: { type: [String, Date], default: undefined },
    textFieldClass: { type: [Object, String, Array], default: undefined },
    textFieldStyle: { type: String, default: undefined },
    badgeColor: { type: String, default: "red" },
    badgeContent: { type: [String, Number], default: "" },
    displayBadge: { type: Boolean, default: false },
    displayDot: { type: Boolean, default: false }
  },

  twoWayProps: ["value"],

  data: () => ({
    menuOpen: false
  }),

  computed: {
    displayBadgeAsDot(): boolean {
      return this.displayDot || (this.displayBadge && this.$vuetify.breakpoint.smAndDown);
    },
    valueString: {
      get(): string | undefined {
        var result = DateUtil.isoDateString(this.twoWayProps.value);
        // console.log(`valueString.get result: ${result}`);
        return result;
      },
      set(val: string | undefined) {
        // console.log(`valueString.set val: ${val}`);
        var dateString = val;
        var dateVal = null; // This needs to be null so that if emptied a null instead of an undefined is returned to the form.  Undefined means not set, which will be ignored by the server
        if (!!dateString?.length) dateVal = this.parseDate(dateString, this.twoWayProps.value);
        this.twoWayProps.value = dateVal;

        // This allows the `v-model` syntax
        this.$emit("input", dateVal);
      }
    },
    formattedDate(): string | undefined {
      // console.log(`formattedDate value: ${this.twoWayProps!.value}`);
      return !!this.twoWayProps!.value
        ? DateUtil.stripTimeFromLocalizedDateTime(this.twoWayProps!.value, this.$props.format)
        : "";
    },
    formattedMin(): string | undefined {
      let min: string | Date = this.min;
      if (min == "today") min = new Date(new Date().toDateString());
      else if (min == "now") min = new Date();
      return !!min ? DateUtil.isoDateString(min) : undefined;
    },
    formattedMax(): string | undefined {
      let max: string | Date = this.max;
      if (max == "today") max = new Date(new Date().toDateString());
      else if (max == "now") max = new Date();
      return !!max ? DateUtil.isoDateString(max) : undefined;
    }
  },

  methods: {
    input() {
      if (this.closeOnSelect) {
        this.menuOpen = false;
      }
    },
    clear() {
      this.valueString = undefined;
    },
    parseDate(val: string, baseDate: Date | null): Date {
      // console.log(`parseDate val: ${val}`);
      // We capture the time of the current date value, so we can use it when resetting the value with the newly selected date
      // This prevents the new date from removing the passed-in date's time value
      let timeString = DateUtil.isoTimeString(baseDate);
      if (!timeString.length || timeString == "Invalid Date") {
        timeString = "00:00:00";
      }
      let newFullDateTimeString = `${val} ${timeString}`;
      // console.log(`    newFullDateTimeString: ${newFullDateTimeString}`);
      let dateVal = new Date(
        DateUtil.localizedDateTimeString(newFullDateTimeString, this.$props.format)
      );

      // console.log(`    dateVal: ${dateVal}`);
      return dateVal;
    }
  }
});

