import FDVue from "@fd/lib/vue";
import { mapMutations, mapState, mapActions } from "vuex";
import i18n from "../i18n";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { filterByContractors } from "../services/taggableItems";
import { createNewPerson } from "./components/dialogs/PersonNewDialog.vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { ContractorWithTags, PersonWithDetails } from "../services";
import rules from "@fd/lib/vue/rules";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

type ContractorWithDetails = ContractorWithTags & {
  archived: boolean;
};

export default FDVue.extend({
  mixins: [errorHandling, rules, tabbedView],

  name: "fd-Contractor-Existing",

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-people-list": () => import("./components/PeopleListTable.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      peopleTab: {
        tabname: "People",
        key: "1",
        visible: false
      } as Tab,

      contractorsSelectedForFiltering: [] as any[],

      contractor: {} as ContractorWithDetails,

      selectedTags: [] as any[],

      archivedLoading: false
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.peopleTab] as Tab[];
    },
    availableTags(): any[] {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },
    users(): any[] {
      return filterByContractors(
        this.contractorsSelectedForFiltering,
        this.$store.state.users.fullList.map((x: any) => {
          return { ...x, archived: !!x.archivedDate };
        })
      );
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get(): boolean {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val: boolean) {
        console.log(`set showarchived val: ${val}`);
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.archivedLoading = true;
        await this.reloadUsers();
        this.archivedLoading = false;
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadUsers();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },
    // Method used in conjunction with the Save button.
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.contractor.archived) {
          this.contractor.archivedDate = null;
        } else if (this.contractor.archived && !this.contractor.archivedDate) {
          this.contractor.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateContractor({
          ...this.contractor,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map((x: any) => x.id) : null
        });
        this.$router.push("/contractors");
      } catch (error) {
        this.handleError(error as Error, "contractors.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteContractor({ id: this.$route.params.id, name: this.contractor.name });
        this.$router.push("/contractors");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async openNewUserDialog() {
      await createNewPerson({
        contractorID: this.$route.params.id
      });
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/contractors");
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      await this.$store.dispatch("DELETE_USER", item);
      await this.reloadUsers();
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateUser({
          id: item.id,
          archivedDate: archivedDate,
          firstName: item.firstName,
          lastName: item.lastName
        });
        this.reloadUsers();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async flipCanLogIn(item: PersonWithDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for disabled, since we're flipping it
        var loginDisabledDate = item.isLoginActive ? new Date(new Date().toUTCString()) : null;
        await this.updateUser({
          id: item.id,
          loginDisabledDate: loginDisabledDate,
          firstName: item.firstName,
          lastName: item.lastName
        });
        await this.reloadUsers();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async reloadUsers() {
      await this.loadUsers({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    ...mapMutations({
      setContractor: "SET_CONTRACTOR",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractor: "LOAD_CONTRACTOR",
      loadTags: "LOAD_TAGS",
      loadUsers: "LOAD_USERS",
      updateUser: "UPDATE_USER",
      updateContractor: "UPDATE_CONTRACTOR",
      deleteContractor: "DELETE_CONTRACTOR"
    })
  },

  watch: {
    contractor(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/contractors/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "users-contractors",
      parentalContext: "contractors",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadContractor(this.$route.params.id),
        this.reloadUsers(),
        this.loadTags()
      ]);

      //Qualify the list of users to only show the contractor in question.
      this.contractorsSelectedForFiltering.push(this.$route.params.id);

      let contractor = this.$store.state.contractors.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.contractor = { ...contractor, archived: !!contractor.archivedDate };
      if (contractor.tagIDs) {
        this.selectedTags = contractor.tagIDs
          .map((x: any) => this.$store.state.tags.fullList.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

