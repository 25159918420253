var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "fdwalkdownform",
      attrs: { "data-cy": _vm.cy },
      on: { submit: _vm.preventSubmit }
    },
    [
      _vm.walkdownReadonly
        ? _c(
            "v-col",
            { staticClass: "mt-0 py-0", attrs: { cols: "12" } },
            [
              _vm.screenLoaded && false
                ? _c("fd-alert", {
                    attrs: {
                      type: "info",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.work-order-not-in-walkdown-message"
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.screenLoaded &&
              _vm.requireUnassign &&
              !_vm.workOrder.walkdown.currentUserPermissions
                .canPerformToDoListWalkdown &&
              _vm.workOrder.walkdown.currentUserPermissions.canUnassignWalkdown
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-3 mb-0 mx-3",
                      attrs: {
                        color: "warning",
                        transition: "scale-transition"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "shrink pr-2" },
                            [
                              _c(
                                "v-icon",
                                { staticStyle: { color: "inherit" } },
                                [_vm._v("fas fa-clipboard-list-check")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "grow pr-1" }, [
                            _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "workorders.existing-work-order.walk-down-assigned-to-do-list-explanation"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "fd-alert-comment-hint-text" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "workorders.existing-work-order.walk-down-assigned-to-do-list-detail"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { outlined: "", color: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("unassign")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "workorders.existing-work-order.unassign-walk-down"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.screenLoaded &&
                  !!_vm.walkdown.currentUserPermissions &&
                  !_vm.walkdown.currentUserPermissions.canPerformWalkdown
                ? _c("fd-alert", {
                    attrs: {
                      type: "info",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.person-not-assigned-message"
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2 pt-5", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pt-1 pb-1", attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                staticClass: "fd-readonly-input fd-remove-messages",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("walkdowns.work-order-status"),
                  disabled: _vm.processing || _vm.walkdownReadonly,
                  readonly: true
                },
                model: {
                  value: _vm.workOrder.workOrderStatusName,
                  callback: function($$v) {
                    _vm.$set(_vm.workOrder, "workOrderStatusName", $$v)
                  },
                  expression: "workOrder.workOrderStatusName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pt-5 pb-1 pt-sm-1",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("fd-text-field", {
                staticClass: "fd-readonly-input fd-remove-messages",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("walkdowns.coordinator"),
                  disabled: "",
                  readonly: true
                },
                model: {
                  value: _vm.workOrder.coordinatorName,
                  callback: function($$v) {
                    _vm.$set(_vm.workOrder, "coordinatorName", $$v)
                  },
                  expression: "workOrder.coordinatorName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pt-5 pb-1 pt-sm-1",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("fd-text-field", {
                staticClass: "fd-readonly-input fd-remove-messages",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("walkdowns.general-foreman"),
                  disabled: "",
                  readonly: true
                },
                model: {
                  value: _vm.workOrder.generalForemanName,
                  callback: function($$v) {
                    _vm.$set(_vm.workOrder, "generalForemanName", $$v)
                  },
                  expression: "workOrder.generalForemanName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pt-5 pb-1 pt-sm-1",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("fd-text-field", {
                staticClass: "fd-readonly-input fd-remove-messages",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("walkdowns.foreman"),
                  disabled: "",
                  readonly: true
                },
                model: {
                  value: _vm.workOrder.foremanName,
                  callback: function($$v) {
                    _vm.$set(_vm.workOrder, "foremanName", $$v)
                  },
                  expression: "workOrder.foremanName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-5 pb-1 pt-sm-1", attrs: { cols: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "fd-remove-messages",
                attrs: {
                  outlined: "",
                  label: _vm.$t("walkdowns.notes"),
                  disabled: _vm.processing || _vm.walkdownReadonly,
                  "hide-details": ""
                },
                model: {
                  value: _vm.walkdown.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.walkdown, "notes", $$v)
                  },
                  expression: "walkdown.notes"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mx-2 pt-3 fd-subheader-with-control",
          attrs: { align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0",
              attrs: { align: "center", cols: "12", sm: "8" }
            },
            [
              _c(
                "v-subheader",
                { staticStyle: { "font-size": "1em", "margin-top": "10px" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("walkdowns.existing-walkdown.estimate-subheader")
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pt-1 pb-1",
              attrs: { align: "center", cols: "12", sm: "4" }
            },
            [
              _c(
                "v-row",
                { staticClass: "pr-3 d-flex justify-sm-end" },
                [
                  _c("v-switch", {
                    staticClass: "fd-remove-messages pl-3 pl-sm-0",
                    attrs: {
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.hard-barricade"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing
                    },
                    model: {
                      value: _vm.scaffoldIsHardBarricade,
                      callback: function($$v) {
                        _vm.scaffoldIsHardBarricade = $$v
                      },
                      expression: "scaffoldIsHardBarricade"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2", attrs: { align: "center" } },
        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
        1
      ),
      _vm._v(" "),
      _vm.workOrder.isModifyRequest
        ? _c(
            "span",
            [
              _c(
                "v-row",
                {
                  staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "3" }
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          outlined: "",
                          items: _vm.crewSizeOptions,
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.crew-size"
                          ),
                          disabled: _vm.processing || _vm.walkdownReadonly,
                          rules: [_vm.getGreaterThanNumberValueRule(0)]
                        },
                        model: {
                          value: _vm.walkdown.crewSize,
                          callback: function($$v) {
                            _vm.$set(_vm.walkdown, "crewSize", $$v)
                          },
                          expression: "walkdown.crewSize"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "3" }
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          outlined: "",
                          items: _vm.workDayOptions,
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.work-days"
                          ),
                          disabled: _vm.processing || _vm.walkdownReadonly,
                          rules: [_vm.getGreaterThanNumberValueRule(0)]
                        },
                        model: {
                          value: _vm.walkdown.workDays,
                          callback: function($$v) {
                            _vm.$set(_vm.walkdown, "workDays", $$v)
                          },
                          expression: "walkdown.workDays"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "3" }
                    },
                    [
                      _c("fd-duration-picker", {
                        attrs: {
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.modify-man-hours"
                          ),
                          disabled: _vm.processing || _vm.walkdownReadonly,
                          "hour-options": [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15
                          ]
                        },
                        model: {
                          value: _vm.workTimePerDay,
                          callback: function($$v) {
                            _vm.workTimePerDay = $$v
                          },
                          expression: "workTimePerDay"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "3" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.modify-total-time"
                          ),
                          readonly: true,
                          value: _vm.walkdown.modificationHours,
                          rules: [_vm.getGreaterThanNumberValueRule(0)]
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "span",
            [
              _c(
                "v-row",
                {
                  staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "4" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          cy: "length",
                          outlined: "",
                          label: _vm.$t("walkdowns.existing-walkdown.length"),
                          disabled: _vm.processing || _vm.walkdownReadonly,
                          "persistent-hint": "",
                          hint: _vm.$t("common.feet-shorthand-hint")
                        },
                        model: {
                          value: _vm.walkdown.length,
                          callback: function($$v) {
                            _vm.$set(_vm.walkdown, "length", $$v)
                          },
                          expression: "walkdown.length"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "justify-space-between pt-1 pb-1 pt-sm-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              cy: "width",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.width"
                              ),
                              disabled:
                                _vm.scaffoldIsHardBarricade ||
                                _vm.processing ||
                                _vm.walkdownReadonly,
                              "persistent-hint": "",
                              hint: _vm.$t("common.feet-shorthand-hint")
                            },
                            model: {
                              value: _vm.walkdown.width,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "width", $$v)
                              },
                              expression: "walkdown.width"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "justify-space-between pt-1 pb-1 pt-sm-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              cy: "height",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.height"
                              ),
                              disabled:
                                _vm.scaffoldIsHardBarricade ||
                                _vm.processing ||
                                _vm.walkdownReadonly,
                              "persistent-hint": "",
                              hint: _vm.$t("common.feet-shorthand-hint")
                            },
                            model: {
                              value: _vm.walkdown.height,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "height", $$v)
                              },
                              expression: "walkdown.height"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "4" }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          "data-cy": "baylength",
                          outlined: "",
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.typical-bay-length"
                          ),
                          disabled: _vm.walkdownReadonly || _vm.processing,
                          items: _vm.selectableScaffoldBayLengths,
                          "item-text": "name",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.walkdown.scaffoldBayLengthID,
                          callback: function($$v) {
                            _vm.$set(_vm.walkdown, "scaffoldBayLengthID", $$v)
                          },
                          expression: "walkdown.scaffoldBayLengthID"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "4" }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          "data-cy": "baywidth",
                          outlined: "",
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.typical-bay-width"
                          ),
                          disabled: _vm.walkdownReadonly || _vm.processing,
                          items: _vm.selectableScaffoldBayWidths,
                          "item-text": "name",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.walkdown.scaffoldBayWidthID,
                          callback: function($$v) {
                            _vm.$set(_vm.walkdown, "scaffoldBayWidthID", $$v)
                          },
                          expression: "walkdown.scaffoldBayWidthID"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "bayheight",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.typical-bay-height"
                              ),
                              disabled:
                                _vm.scaffoldIsHardBarricade ||
                                _vm.walkdownReadonly ||
                                _vm.processing,
                              items: _vm.selectableScaffoldBayHeights,
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.walkdown.scaffoldBayHeightID,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.walkdown,
                                  "scaffoldBayHeightID",
                                  $$v
                                )
                              },
                              expression: "walkdown.scaffoldBayHeightID"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-1",
                      attrs: { align: "center", cols: "12", sm: "4" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.number-of-length-bays"
                          ),
                          value: _vm.numberOfLengthBays,
                          disabled: "",
                          readonly: true
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.number-of-width-bays"
                              ),
                              value: _vm.numberOfWidthBays,
                              disabled: "",
                              readonly: true
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              cy: "decklevels",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.deck-levels"
                              ),
                              disabled:
                                _vm.scaffoldIsHardBarricade ||
                                _vm.walkdownReadonly ||
                                _vm.processing,
                              rules: [_vm.rules.numeric]
                            },
                            model: {
                              value: _vm.walkdown.deckLevels,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "deckLevels", $$v)
                              },
                              expression: "walkdown.deckLevels"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "accesstype",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.access-type"
                              ),
                              disabled:
                                _vm.scaffoldIsHardBarricade ||
                                _vm.walkdownReadonly ||
                                _vm.processing,
                              items: _vm.accessTypes,
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.walkdown.accessType,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "accessType", $$v)
                              },
                              expression: "walkdown.accessType"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scaffoldIsHardBarricade
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.barricade-gates"
                              ),
                              disabled:
                                !_vm.scaffoldIsHardBarricade ||
                                _vm.walkdownReadonly ||
                                _vm.processing,
                              rules: [_vm.rules.numeric]
                            },
                            model: {
                              value: _vm.walkdown.barricadeGates,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "barricadeGates", $$v)
                              },
                              expression: "walkdown.barricadeGates"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { align: "center", cols: "12" } },
            [
              _c("v-subheader", { staticStyle: { "font-size": "1em" } }, [
                _vm._v(_vm._s(_vm.$t("walkdowns.existing-walkdown.modifiers")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2", attrs: { align: "center" } },
        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5", attrs: { align: "center" } },
        [
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.scaffold-type"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allScaffoldTypes,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.walkdown.scaffoldTypeModifierID,
                      callback: function($$v) {
                        _vm.$set(_vm.walkdown, "scaffoldTypeModifierID", $$v)
                      },
                      expression: "walkdown.scaffoldTypeModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.build-elevation"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allScaffoldElevations,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.walkdown.scaffoldElevationModifierID,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.walkdown,
                          "scaffoldElevationModifierID",
                          $$v
                        )
                      },
                      expression: "walkdown.scaffoldElevationModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.scaffold-height"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allScaffoldHeights,
                      "item-text": "name",
                      "item-value": "id",
                      hint: _vm.$t(
                        "walkdowns.existing-walkdown.scaffold-height-hint"
                      ),
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.walkdown.scaffoldHeightModifierID,
                      callback: function($$v) {
                        _vm.$set(_vm.walkdown, "scaffoldHeightModifierID", $$v)
                      },
                      expression: "walkdown.scaffoldHeightModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "justify-space-between pt-1 pb-1",
              attrs: { align: "center", cols: "12", sm: "4" }
            },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  label: _vm.$t(
                    "walkdowns.existing-walkdown.congestion-factor"
                  ),
                  disabled: _vm.walkdownReadonly || _vm.processing,
                  items: _vm.allScaffoldCongestionFactors,
                  "item-text": "name",
                  "item-value": "id"
                },
                model: {
                  value: _vm.walkdown.scaffoldCongestionFactorID,
                  callback: function($$v) {
                    _vm.$set(_vm.walkdown, "scaffoldCongestionFactorID", $$v)
                  },
                  expression: "walkdown.scaffoldCongestionFactorID"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.internal-confined-space"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allInternalModifiers,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.walkdown.internalModifierID,
                      callback: function($$v) {
                        _vm.$set(_vm.walkdown, "internalModifierID", $$v)
                      },
                      expression: "walkdown.internalModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.hoarding-wrapping"
                      ),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allHoardingModifiers,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.walkdown.hoardingModifierID,
                      callback: function($$v) {
                        _vm.$set(_vm.walkdown, "hoardingModifierID", $$v)
                      },
                      expression: "walkdown.hoardingModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("walkdowns.existing-walkdown.mob-demob"),
                      disabled: _vm.walkdownReadonly || _vm.processing,
                      items: _vm.allScaffoldDistances,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.walkdown.scaffoldDistanceModifierID,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.walkdown,
                          "scaffoldDistanceModifierID",
                          $$v
                        )
                      },
                      expression: "walkdown.scaffoldDistanceModifierID"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }