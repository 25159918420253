import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import {
  TransferWithDetails,
  transferService,
  CountSheetGroupWithParts,
  Part,
  TransferDirection,
  Yard,
  yardService,
  TransferPartDetails
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import {
  CountSheetGroupPartFromTransferPart,
  CountSheetGroupWithSortedParts,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../dataMixins/countSheetGroupSorting";
import userAccess from "@fd/current/client/dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";

type FormattedTransferWithDetails = TransferWithDetails & {
  formattedTransferDate: string;
};
export default FDVue.extend({
  name: "fd-transfer-existing",
  mixins: [serviceErrorHandling, userAccess, rules],

  data: function() {
    return {
      slidein: false,
      openPanels: [0] as number[],

      saving: false,
      unlocked: false,

      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      transfer: {
        currentUserPermissions: {}
      } as FormattedTransferWithDetails,
      /*** TO/FROM YARD ***/
      allYards: [] as Yard[]
    };
  },

  computed: {
    fromYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.transfer.toYardID
      }));
    },
    toYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.transfer.fromYardID
      }));
    },
    directionIsScaffoldDelivery(): boolean {
      return this.transfer.direction == TransferDirection.ScaffoldDelivery;
    },
    directionIsScaffoldReturn(): boolean {
      return this.transfer.direction == TransferDirection.ScaffoldReturn;
    },
    directionIsYardTransfer(): boolean {
      return this.transfer.direction == TransferDirection.YardTransfer;
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (this.unlocked) return this.countSheetGroups;
      // if (!!this.transfer?.currentUserPermissions?.canOverrideTransferValues)
      //   return this.countSheetGroups;

      return this.countSheetGroups
        .map(x => {
          return {
            ...x,
            sortedParts: x.sortedParts.filter(x => !!x.count && x.count > 0)
          };
        })
        .filter(x => x.sortedParts.length > 0);
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    }
  },

  watch: {
    transfer() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/transfers") {
        this.notifyNewBreadcrumb({
          text: this.$t("transfers.list.title"),
          to: "/transfers",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      let directionText = this.$t("transfers.direction.yardtransfer");
      if (this.transfer.direction == TransferDirection.ScaffoldDelivery) {
        directionText = this.$t("transfers.direction.scaffolddelivery");
      } else if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
        directionText = this.$t("transfers.direction.scaffoldreturn");
      }
      let summary = `${directionText} #${this.transfer.legacyID}`;
      this.notifyNewBreadcrumb({
        text: summary,
        to: `/transfers/${this.$route.params.id}`
      });
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    async save() {
      if (!this.unlocked) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        let parts = this.partsForSummary
          .filter(x => !!x.count && x.count > 0)
          .map(
            x =>
              ({
                partID: x.id,
                count: +x.count!,
                currentAssignedCount: +x.assigned
              } as TransferPartDetails)
          );

        this.partsForSummary.forEach(x => {
          let part = this.transfer.parts?.find(p => p.partID == x.id);
          if (!part) return;
          part.count = +x.count! ?? 0;
        });
        await transferService.overrideTransferDetails(
          this.transfer.id!,
          this.transfer.fromYardID,
          this.transfer.toYardID,
          this.transfer.transferDate,
          this.transfer.transactionNumber,
          parts
        );

        let directionText = this.$t("transfers.direction.yardtransfer");
        if (this.transfer.direction == TransferDirection.ScaffoldDelivery) {
          directionText = this.$t("transfers.direction.scaffolddelivery");
        } else if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
          directionText = this.$t("transfers.direction.scaffoldreturn");
        }
        var snackbarPayload = {
          text: this.$t("transfers.existing.save-success", [directionText, this.transfer.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push("/transfers");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },

    async loadScreenData() {
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });

      this.allYards = (await yardService.getAll(false, null, null))
        .filter(x => !x.isSystemYard)
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });

      let transfer = await transferService.getByID(this.$route.params.id);
      this.transfer = {
        ...transfer,
        formattedTransferDate: DateUtil.stripTimeFromLocalizedDateTime(transfer.transferDate)
      } as FormattedTransferWithDetails;

      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part =>
                    CountSheetGroupPartFromTransferPart(
                      part,
                      this.transfer.parts,
                      this.transfer.direction
                    )
                  )
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.transfer.parts?.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part =>
              CountSheetGroupPartFromTransferPart(
                part,
                this.transfer.parts,
                this.transfer.direction
              )
            )
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;
    },

    toggleUnlocked() {
      this.unlocked = !this.unlocked;
    },

    // *** SCREEN NAVIGATION ***
    partCountValueChanged(part: PartWithCounts) {
      let addCount = 0;
      let removeCount = 0;
      let count = !!part.count ? +part.count : 0;
      part.overridden = part.overridden || count != (part.originalCount ?? 0);
      if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
        removeCount = count;
      } else {
        addCount = count;
      }
      part.total = part.assigned + addCount - removeCount;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!) + 1;
      if (!this.openPanels.includes(groupPanelNumber)) {
        this.openPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/transfers") {
      this.notifyNewBreadcrumb({
        text: this.$t("transfers.list.title"),
        to: "/transfers",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: "",
      to: `/transfers/${this.$route.params.id}`
    });

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadScreenData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

