export default {
  props: {
    value: { type: [String, Object, Number], default: "" },
    valueStyle: { type: String, default: "" },
    valueClass: { type: [String, Array], default: () => [] },

    hideNoValuePlaceholder: { type: Boolean, default: false },
    noValueKey: { type: String, default: "common.not-available" },
    noValueStyle: { type: String, default: "" },
    noValueClass: { type: [String, Array], default: () => [] }
  }
};
