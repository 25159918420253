










































































































































import {mapMutations, mapState, mapActions} from "vuex";
import {filterByTags, getTagsInUse} from "../services/taggableItems";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],

    name: 'fd-Suppliers',

    components: {
        "fd-supplier-new-dialog": () => import('./components/SupplierNewDialog.vue')
    },

    directives: {
        fdColumn: FDColumnDirective,
        fdRowNavigate: FDRowNavigateDirective
    },

    data: () => ({
        // The following tracks the current width of the browser window. It works in conjunction with a EventListener
        // setup in the "created" hook.
        windowWidth:0,

        showNewDialog: false,

        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following is used to define the breadcrumbs navigation for the view.
        breadcrumbs: [
            {
                text: 'Suppliers',
                disabled: true,
            },
        ],
        

    }),

    computed: {
        suppliers () { return filterByTags(this.tagsSelectedForFiltering, this.$store.state.suppliers.fullList); },
        tagsInUse () { return this.$store.getters.getSortedInUseTags(this.$store.state.suppliers.fullList); },

        tablesearch: {
            get () {
                return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
            },
            set (val) {
                this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
            },
        },

        tagsSelectedForFiltering: {
            get () {
                return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.tagsForFiltering;
            },
            set (val) {
                this.$store.commit('SET_TAGS_FOR_FILTERING', val)
            },
        },
    },

    methods: {
        openNewDialog () {
            this.showNewDialog = true;
        },

        // the following works with the delete "Action" button in the Datatable.
        async deleteTableItem (item) {
            this.inlineMessage.message = null;
            this.processing = true;
            try {
                await this.deleteSupplier({ id: item.id, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        async flipEnabled (item) {
            this.inlineMessage.message = null;
            this.processing = true;
            try {
                await this.updateSupplier({ id: item.id, enabled: !item.enabled, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        ...mapMutations({
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
            setFilteringContext: 'SET_FILTERING_CONTEXT'
        }),
        ...mapActions({
            loadSuppliers: 'LOAD_SUPPLIERS',
            loadTags: 'LOAD_TAGS',
            updateSupplier: 'UPDATE_SUPPLIER',
            deleteSupplier: 'DELETE_SUPPLIER'
        })
    },

    created: async function () {
        // Listen to the "resize" even for the browser so we always know the width and can use that
        // knowledge for various responsive layout reasons.
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        });
        this.windowWidth = window.innerWidth;

        // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
        // a sub screen of something that is currently filtered by their choices that those choices will be
        // preserved as they move between the two screens.
        this.setFilteringContext({
            context: "suppliers",
            parentalContext: null,
            searchStringForFiltering: "",
            tagsForFiltering: [],
            suppliersForFiltering: []
        });

        this.notifyNewBreadcrumb({
            text: "Suppliers",
            to: "/suppliers",
            resetHistory: true
        });
        this.processing = true;
        try {
            await Promise.all([
                this.loadSuppliers(),
                this.loadTags()
            ]);
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }
    }
})
