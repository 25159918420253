import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";

const WorkOrderHistoryDialog = FDVue.extend({
  name: "fd-work-order-history-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-work-order-history-form": () => import("../forms/WorkOrderHistoryForm.vue")
  },

  data: function() {
    return {
      foremanID: null as string | null
    };
  },

  computed: {},

  watch: {},

  methods: {
    async open(foremanID: string | null): Promise<boolean> {
      this.foremanID = foremanID;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    }
  },

  created: async function() {}
});

export default WorkOrderHistoryDialog;

export async function showWorkOrderHistoryDialog(foremanID: string | null): Promise<boolean> {
  let dialog = createDialog(WorkOrderHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(foremanID);
}

