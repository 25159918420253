var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-stepper",
        {
          on: { change: _vm.wizardStepChanged },
          model: {
            value: _vm.wizardStep,
            callback: function($$v) {
              _vm.wizardStep = $$v
            },
            expression: "wizardStep"
          }
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    rules: [
                      function() {
                        return !_vm.detailsInError || !_vm.detailsComplete
                      }
                    ],
                    complete: !_vm.detailsInError && _vm.detailsComplete,
                    step: "1",
                    editable: ""
                  }
                },
                [
                  _vm._v(
                    "\n                                " +
                      _vm._s(
                        _vm.$t("projects.new-project-step-title-details")
                      ) +
                      "\n                                "
                  ),
                  _vm.detailsInError && _vm.detailsComplete
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "projects.new-project-step-title-details-errors-message"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !(_vm.detailsInError && _vm.detailsComplete) &&
                  _vm.wizardStep == 1
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "projects.new-project-step-inline-message-details"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    rules: [
                      function() {
                        return !_vm.partsListInError || !_vm.partsListComplete
                      }
                    ],
                    complete: !_vm.partsListInError && _vm.partsListComplete,
                    step: "2",
                    editable: ""
                  }
                },
                [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.$t("projects.new-project-step-title-parts")) +
                      "\n                                "
                  ),
                  _vm.partsListInError && !_vm.partsListComplete
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "projects.new-project-step-title-parts-errors-message"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !(_vm.partsListInError && !_vm.partsListComplete) &&
                  _vm.wizardStep == 2
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "projects.new-project-step-inline-message-parts"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.processing || _vm.saving,
                    expression: "processing || saving"
                  }
                ],
                staticClass:
                  "stepper-progress-indicator-compensation-when-additional-step-details",
                attrs: { indeterminate: _vm.processing || _vm.saving }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { staticClass: "pa-3", attrs: { step: "1" } },
                [
                  _c(
                    "v-form",
                    { ref: "detailsform" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pt-0", attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-center justify-space-between pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.project.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.project, "name", $$v)
                                          },
                                          expression: "project.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: _vm.$t("common.description"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.project.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "project.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "alias",
                                          "item-value": "id",
                                          items: _vm.owners,
                                          label: _vm.$t("projects.owner"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.project.ownerID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "ownerID",
                                              $$v
                                            )
                                          },
                                          expression: "project.ownerID"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.regions,
                                          label: _vm.$t("projects.region"),
                                          disabled: _vm.processing,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.project.regionID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "regionID",
                                              $$v
                                            )
                                          },
                                          expression: "project.regionID"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-center justify-space-between",
                                  attrs: { cols: "12", sm: "12", md: "6" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "projects.productivity"
                                          ),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numbersonly]
                                        },
                                        model: {
                                          value: _vm.project.productivity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "productivity",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "project.productivity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-center justify-space-between",
                                  attrs: { cols: "12", sm: "12", md: "6" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("projects.labourrate"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numbersonly]
                                        },
                                        model: {
                                          value: _vm.project.labourRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "labourRate",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "project.labourRate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(
                                                _vm.inlineMessage.message
                                              ) +
                                              "\n                                                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "pa-0" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", disabled: _vm.processing },
                                  on: { click: _vm.cancelDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.processing
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.moveNextFromDetailsStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("common.continue")) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "2" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pl-9",
                      staticStyle: {
                        background: "#EAEAEA",
                        "margin-top": "3px"
                      }
                    },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.partsCatalogUsageType,
                            callback: function($$v) {
                              _vm.partsCatalogUsageType = $$v
                            },
                            expression: "partsCatalogUsageType"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "projects.parts-selection-type-entire-catalog"
                              ),
                              value: "entire"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            staticClass: "ml-4",
                            attrs: {
                              label: _vm.$t(
                                "projects.parts-selection-type-individual-parts-selection"
                              ),
                              value: "selection"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expand-transition",
                    [
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.partsCatalogUsageType == "selection",
                              expression: "partsCatalogUsageType == 'selection'"
                            }
                          ],
                          staticClass: "new-project",
                          attrs: { flat: "", tile: "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ml-0 mr-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-sm-0",
                                      attrs: { cols: "12", sm: "12", md: "7" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "projects.only-included-label"
                                              )
                                            },
                                            model: {
                                              value: _vm.showOnlyIncluded,
                                              callback: function($$v) {
                                                _vm.showOnlyIncluded = $$v
                                              },
                                              expression: "showOnlyIncluded"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-4",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projects.parts-selection-sub-menu-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-sm-1 pt-md-3 pb-0",
                                      attrs: { cols: "12", sm: "12", md: "5" }
                                    },
                                    [
                                      _c("v-combobox", {
                                        class: [
                                          _vm.tagsSelectedForFiltering.length >
                                          0
                                            ? "fd-combobox-control-item-selected-small"
                                            : "fd-combobox-control-item-not-selected"
                                        ],
                                        attrs: {
                                          items: _vm.tagsInUse,
                                          label: _vm.$t(
                                            "common.filter-by-tags"
                                          ),
                                          "item-text": "name",
                                          "item-value": "id",
                                          multiple: "",
                                          "small-chips": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tagsSelectedForFiltering,
                                          callback: function($$v) {
                                            _vm.tagsSelectedForFiltering = $$v
                                          },
                                          expression: "tagsSelectedForFiltering"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mr-0 ml-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "order-md-1 order-0 pb-0",
                                      attrs: { cols: "12", sm: "12", md: "5" }
                                    },
                                    [
                                      _c("v-select", {
                                        class: [
                                          _vm.suppliersSelectedForFiltering
                                            .length > 0
                                            ? "fd-select-control-item-selected"
                                            : "",
                                          "pt-3"
                                        ],
                                        attrs: {
                                          "item-text": "alias",
                                          "item-value": "id",
                                          items: _vm.suppliersInUse,
                                          label: _vm.$t(
                                            "projects.parts-selection-supplier-filter-label"
                                          ),
                                          multiple: "",
                                          "small-chips": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.suppliersSelectedForFiltering,
                                          callback: function($$v) {
                                            _vm.suppliersSelectedForFiltering = $$v
                                          },
                                          expression:
                                            "suppliersSelectedForFiltering"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "order-0",
                                      attrs: { cols: "12", sm: "12", md: "7" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "search",
                                          label: "Search",
                                          "single-line": "",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tablesearchparts,
                                          callback: function($$v) {
                                            _vm.tablesearchparts = $$v
                                          },
                                          expression: "tablesearchparts"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            ref: "partsDataTable",
                            staticClass: "fixed-header v-table__overflow",
                            staticStyle: {
                              "max-height": "calc(50vh - 10px)",
                              "backface-visibility": "hidden"
                            },
                            attrs: {
                              headers: _vm.computedPartsHeaders,
                              items: _vm.parts,
                              search: _vm.tablesearchparts,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "sort-by": _vm.sortPartsTableBy
                            },
                            on: { "current-items": _vm.setVisibleParts },
                            scopedSlots: _vm._u([
                              {
                                key: "header.name",
                                fn: function(ref) {
                                  var header = ref.header
                                  return [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(
                                          _vm.$t("common." + header.value)
                                        ) +
                                        "\n                                            "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "header.description",
                                fn: function(ref) {
                                  var header = ref.header
                                  return [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(
                                          _vm.$t("common." + header.value)
                                        ) +
                                        "\n                                            "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "header.include",
                                fn: function(ref) {
                                  var header = ref.header
                                  return [
                                    _c("v-checkbox", {
                                      attrs: {
                                        indeterminate:
                                          _vm.someSearchedPartsSelected,
                                        label: _vm.$t("common." + header.value)
                                      },
                                      model: {
                                        value: _vm.allSearchedPartsSelected,
                                        callback: function($$v) {
                                          _vm.allSearchedPartsSelected = $$v
                                        },
                                        expression: "allSearchedPartsSelected"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.include",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: { disabled: _vm.processing },
                                      model: {
                                        value: item.selected,
                                        callback: function($$v) {
                                          _vm.$set(item, "selected", $$v)
                                        },
                                        expression: "item.selected"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.supplierID",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(
                                          _vm.lookupSupplier(item.supplierID)
                                        ) +
                                        "\n                                            "
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pl-5 pr-5 pb-0 pt-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n                                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    {
                      class: [
                        _vm.partsCatalogUsageType != "selection" &&
                        !_vm.inlineMessage.message
                          ? "pt-5"
                          : "pt-0",
                        "pl-5"
                      ]
                    },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", disabled: _vm.processing },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n                                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.processing,
                            loading: _vm.processing
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(_vm.$t("common.finish")) +
                              "\n                                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }