import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import ServiceError from "@fd/lib/client-util/serviceError";
import { SortCountSheetGroups, SortParts } from "../dataMixins/countSheetGroupSorting";
import userAccess from "../dataMixins/userAccess";
import {
  CountSheetGroupWithParts,
  CountSheetPartWithDetails,
  CountSheetReviewStatus,
  countSheetService,
  CountSheetType,
  CountSheetWithParts,
  Part,
  yardService
} from "../services";
import { showCountSheetYardSelectionDialog } from "./components/dialogs/CountSheetYardSelectionDialog.vue";
import rules from "@fd/lib/vue/rules";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import {
  CountSheetGroupPartFromPart,
  CountSheetGroupWithSortedParts,
  CountSheetPartFromGroupPart,
  SummarizeModifiedPartsInGroups
} from "../dataMixins/countSheet";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";

export default FDVue.extend({
  name: "fd-count-sheet-existing",

  mixins: [tabbedView, serviceErrorHandling, userAccess, rules],

  components: {
    "fd-work-order-details": () => import("../views/components/WorkOrderDetails.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-count-sheet-form": () => import("./components/forms/CountSheetForm.vue")
  },

  data: function() {
    return {
      slidein: false,

      saving: false,
      approving: false,
      declining: false,

      firstTabKey: `1`,
      countSheetTab: {
        tabname: this.$t("countsheet.existing.tabs.countsheet"),
        key: `1`,
        visible: true
      } as Tab,
      workOrderTab: {
        tabname: this.$t("countsheet.existing.tabs.work-order"),
        key: `2`,
        visible: true
      } as Tab,

      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      countSheet: {} as CountSheetWithParts,

      isDeclining: false,
      isOverriding: false
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.workOrderTab] as Tab[];
    },
    // Status Checks
    countSheetCanBeSaved(): boolean {
      return this.countSheet.reviewStatusID == CountSheetReviewStatus.Pending;
    },
    countSheetCanBeApproved(): boolean {
      return this.countSheet.reviewStatusID == CountSheetReviewStatus.Pending && !this.isDeclining;
    },
    countSheetCanBeDeclined(): boolean {
      return (
        this.countSheet.reviewStatusID == CountSheetReviewStatus.Pending &&
        // (this.isDeclining ||
        //   this.countSheet.countSheetTypeID != CountSheetType.Individual ||
        //   !this.countSheet.parts?.length) &&
        !this.isOverriding
      );
    }
  },

  watch: {
    countSheet() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/scaffolds") {
      } else if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheets") {
        this.notifyNewBreadcrumb({
          text: this.$t("countsheet.list.title"),
          to: "/countsheets",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      let countSheetSummary = `WO-${this.countSheet.workOrder.legacyID ?? 0}, T-${this.countSheet
        .workOrder.scaffoldNumber ?? 0}`;
      this.notifyNewBreadcrumb({
        text: countSheetSummary,
        to: `/countsheets/${this.$route.params.id}`
      });
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        await this.saveCountSheet();
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },

    async loadScreenData() {
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });
      this.countSheet = await countSheetService.getByID(this.$route.params.id!);

      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part => CountSheetGroupPartFromPart(part, this.countSheet))
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.countSheet.parts?.filter(x => !x.countSheetGroupName);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part => CountSheetGroupPartFromPart(part, this.countSheet))
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      this.countSheetGroups = countSheetGroups;

      this.countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));
      let rejectedParts = SummarizeModifiedPartsInGroups(this.countSheetGroups).filter(
        x => !!x.rejected && x.rejected === true
      );
      this.isDeclining = rejectedParts.length > 0;

      if (!this.isDeclining) {
        let overriddenParts = SummarizeModifiedPartsInGroups(this.countSheetGroups).filter(
          x => x.overridden
        );
        this.isOverriding = overriddenParts.length > 0;
      }
    },

    // Actions

    async saveCountSheet() {
      if (!this.countSheet) return;

      var materialNotRequired = this.countSheet.countSheetTypeID == CountSheetType.NotApplicable;

      let countSheet = this.countSheet;
      let parts = [] as CountSheetPartWithDetails[];
      if (materialNotRequired) {
        parts = [];
      } else {
        parts = SummarizeModifiedPartsInGroups(this.countSheetGroups).map(x =>
          CountSheetPartFromGroupPart(x, countSheet)
        );
      }
      await countSheetService.saveCountSheet(this.countSheet.id!, {
        ...this.countSheet,
        parts: parts
      } as CountSheetWithParts);
      this.countSheet.parts = parts;
    },

    async approveCountSheet() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.approving = true;
      try {
        let fromYardID = null;
        let toYardID = null;
        let parts = [] as CountSheetPartWithDetails[];
        var materialNotRequired = this.countSheet.countSheetTypeID == CountSheetType.NotApplicable;
        if (materialNotRequired) {
          parts = [];
        } else {
          let countSheet = this.countSheet;
          parts = SummarizeModifiedPartsInGroups(this.countSheetGroups).map(x =>
            CountSheetPartFromGroupPart(x, countSheet)
          );
        }

        let addedParts = parts.filter(x => {
          let added = x.overridden ? x.addCountOverride : x.addCount;
          return !!added && added > 0;
        });
        let removedParts = parts.filter(x => {
          let removed = x.overridden ? x.removeCountOverride : x.removeCount;
          return !!removed && removed > 0;
        });

        let needsFromYard = !!addedParts?.length;
        let needsToYard = !!removedParts?.length;
        if (!!addedParts?.length || !!removedParts?.length) {
          let selectedYardIDs = await showCountSheetYardSelectionDialog({
            selectSourceYard: needsFromYard,
            selectDestinationYard: needsToYard
          });

          if (!selectedYardIDs) {
            this.approving = false;
            this.processing = false;
            return false;
          }

          fromYardID = selectedYardIDs.sourceYardID;
          toYardID = selectedYardIDs.destinationYardID;

          if ((needsFromYard && !fromYardID) || (needsToYard && !toYardID)) {
            this.approving = false;
            this.processing = false;
            return false;
          }
        }

        await this.saveCountSheet();
        await countSheetService.approvePendingCountSheet(this.countSheet.id!, fromYardID, toYardID);

        var snackbarPayload = {
          text: this.$t("countsheet.approval.approve-success", [
            this.countSheet.workOrder.legacyID
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.back();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async declineCountSheet() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("countsheet.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await this.saveCountSheet();
        await countSheetService.declinePendingCountSheet(this.countSheet.id!, reason);

        var snackbarPayload = {
          text: this.$t("countsheet.approval.decline-success", [
            this.countSheet.workOrder.legacyID
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.back();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/scaffolds") {
    } else if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheets") {
      this.notifyNewBreadcrumb({
        text: this.$t("countsheet.list.title"),
        to: "/countsheets",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      this.notifyNewBreadcrumb({
        text: "",
        to: `/countsheets/${this.$route.params.id}`
      });
    }

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "count-sheet-existing",
      parentalContext: "countsheets",
      selectedTab: this.firstTabKey
    });

    try {
      await this.loadScreenData();
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  }
});

