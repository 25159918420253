var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "950px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "transfernew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.dialogTitle))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.currentUserCanCreateTransfers
            ? _c("fd-alert", {
                staticClass: "mx-3",
                attrs: {
                  type: "info",
                  label: _vm.$t("transfers.no-permission-to-create")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form", on: { submit: _vm.onSubmit } },
            [
              _c(
                "v-row",
                {
                  staticClass: "pt-1",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 pb-0 pt-5",
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-date-picker", {
                        attrs: {
                          label: _vm.$t("transfers.transfer-date-label"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.required]
                        },
                        model: {
                          value: _vm.transferDate,
                          callback: function($$v) {
                            _vm.transferDate = $$v
                          },
                          expression: "transferDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 pb-0 pt-5",
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t("transfers.transaction-number-label"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.transactionNumber,
                          callback: function($$v) {
                            _vm.transactionNumber = $$v
                          },
                          expression: "transactionNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.directionIsScaffoldDelivery ||
                  _vm.directionIsScaffoldReturn
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between px-3 py-0",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          !_vm.canSearchWorkOrders
                            ? _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  items: _vm.availableWorkOrders,
                                  label: _vm.$t("transfers.work-order-label"),
                                  multiple: false,
                                  itemText: "description",
                                  itemDetail: "details",
                                  disabled: _vm.processing,
                                  placeholder: _vm.$t(
                                    "transfers.work-order-select-placeholder"
                                  ),
                                  rules: [_vm.rules.required]
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var attrs = ref.attrs
                                        var item = ref.item
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.description) +
                                                "\n                "
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fd-item-detail pb-2"
                                              },
                                              [_vm._v(_vm._s(item.details))]
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3278730678
                                ),
                                model: {
                                  value: _vm.selectedWorkOrder,
                                  callback: function($$v) {
                                    _vm.selectedWorkOrder = $$v
                                  },
                                  expression: "selectedWorkOrder"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canSearchWorkOrders
                            ? _c("fd-async-search-box", {
                                attrs: {
                                  refreshItems: _vm.loadWorkOrders,
                                  items: _vm.availableWorkOrders,
                                  selectedItems: _vm.selectedWorkOrder,
                                  label: _vm.$t("transfers.work-order-label"),
                                  multiple: false,
                                  itemText: "description",
                                  itemDetail: "details",
                                  chipClass:
                                    "keywords-selection-chip-colors-secondary",
                                  disabled: _vm.processing,
                                  placeholder: _vm.$t(
                                    "transfers.work-order-select-placeholder"
                                  ),
                                  rules: [_vm.rules.required]
                                },
                                on: {
                                  "update:selectedItems": function($event) {
                                    _vm.selectedWorkOrder = $event
                                  },
                                  "update:selected-items": function($event) {
                                    _vm.selectedWorkOrder = $event
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.directionIsScaffoldReturn
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between px-3 py-0",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          _c("fd-async-search-box", {
                            attrs: {
                              refreshItems: _vm.loadScaffolds,
                              items: _vm.availableScaffolds,
                              selectedItems: _vm.selectedFromScaffold,
                              label: _vm.$t("transfers.from-scaffold-label"),
                              multiple: false,
                              itemText: "description",
                              itemDetail: "details",
                              chipClass:
                                "keywords-selection-chip-colors-secondary",
                              disabled: _vm.processing,
                              readonly: !_vm.canSearchScaffolds,
                              placeholder: !_vm.canSearchScaffolds
                                ? ""
                                : _vm.$t("transfers.tag-select-placeholder"),
                              rules: [_vm.rules.required]
                            },
                            on: {
                              "update:selectedItems": function($event) {
                                _vm.selectedFromScaffold = $event
                              },
                              "update:selected-items": function($event) {
                                _vm.selectedFromScaffold = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.directionIsScaffoldDelivery || _vm.directionIsYardTransfer
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between px-3 py-0",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.fromYards,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("transfers.from-yard-label"),
                              clearable: "",
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.selectedFromYardID,
                              callback: function($$v) {
                                _vm.selectedFromYardID = $$v
                              },
                              expression: "selectedFromYardID"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.directionIsScaffoldDelivery
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between px-3 py-0",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          _c("fd-async-search-box", {
                            attrs: {
                              refreshItems: _vm.loadScaffolds,
                              items: _vm.availableScaffolds,
                              selectedItems: _vm.selectedToScaffold,
                              label: _vm.$t("transfers.to-scaffold-label"),
                              multiple: false,
                              itemText: "description",
                              itemDetail: "details",
                              chipClass:
                                "keywords-selection-chip-colors-secondary",
                              disabled: _vm.processing,
                              readonly: !_vm.canSearchScaffolds,
                              placeholder: !_vm.canSearchScaffolds
                                ? ""
                                : _vm.$t("transfers.tag-select-placeholder"),
                              rules: [_vm.rules.required]
                            },
                            on: {
                              "update:selectedItems": function($event) {
                                _vm.selectedToScaffold = $event
                              },
                              "update:selected-items": function($event) {
                                _vm.selectedToScaffold = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.directionIsScaffoldReturn || _vm.directionIsYardTransfer
                    ? _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between px-3 py-0",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.toYards,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("transfers.to-yard-label"),
                              clearable: "",
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.selectedToYardID,
                              callback: function($$v) {
                                _vm.selectedToYardID = $$v
                              },
                              expression: "selectedToYardID"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panels",
                {
                  staticStyle: { "border-radius": "0" },
                  attrs: { multiple: "", accordion: "" },
                  model: {
                    value: _vm.openPanels,
                    callback: function($$v) {
                      _vm.openPanels = $$v
                    },
                    expression: "openPanels"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "fd-count-sheet-group-panel-summary" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticStyle: { "padding-left": "24px !important" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("countsheet.dialog.summary-panel-title")
                              )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !_vm.partsForSummary ||
                          _vm.partsForSummary.length == 0
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "justify-content": "center"
                                          },
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("fd-alert", {
                                            attrs: {
                                              label: _vm.$t(
                                                "transfers.summary-has-no-parts-message"
                                              ),
                                              type: "hint"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.partsForSummary, function(part) {
                            return _c(
                              "v-row",
                              {
                                key: part.id,
                                staticClass: "fd-part-row",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "fd-group-part-content" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1",
                                        attrs: { cols: "7" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fd-count-sheet-part-code-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(part.publicID) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fd-count-sheet-part-description-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          part.description
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !!part.groupName
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fd-count-sheet-part-group-label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              part.groupName.toUpperCase()
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pl-0 py-1",
                                        attrs: { cols: "5" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            !_vm.directionIsYardTransfer
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: {
                                                      cols: _vm.directionIsYardTransfer
                                                        ? 0
                                                        : 4
                                                    }
                                                  },
                                                  [
                                                    _c("fd-text-field", {
                                                      staticClass:
                                                        "fd-count-sheet-text-field",
                                                      attrs: {
                                                        "data-cy":
                                                          "summaryassigned",
                                                        dense: "",
                                                        "hide-details": "",
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true,
                                                        label: _vm.$t(
                                                          "transfers.assigned-label"
                                                        ),
                                                        numeric: "",
                                                        "highlight-on-focus": ""
                                                      },
                                                      model: {
                                                        value: part.assigned,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            part,
                                                            "assigned",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "part.assigned"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pl-1 order-1 order-sm-2",
                                                attrs: {
                                                  cols: _vm.directionIsYardTransfer
                                                    ? 12
                                                    : 4
                                                }
                                              },
                                              [
                                                _c("fd-text-field", {
                                                  staticClass:
                                                    "fd-count-sheet-text-field",
                                                  attrs: {
                                                    "data-cy": "summarycount",
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled:
                                                      _vm.processing ||
                                                      !_vm.partsAreEditable ||
                                                      (_vm.directionIsScaffoldReturn &&
                                                        part.assigned == 0),
                                                    label: _vm.$t(
                                                      "transfers.count-label"
                                                    ),
                                                    numeric: "",
                                                    "highlight-on-focus": ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.partCountValueChanged(
                                                        part
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: part.count,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        part,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "part.count"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            !_vm.directionIsYardTransfer
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: {
                                                      cols: _vm.directionIsYardTransfer
                                                        ? 0
                                                        : 4
                                                    }
                                                  },
                                                  [
                                                    _c("fd-text-field", {
                                                      staticClass:
                                                        "fd-count-sheet-text-field",
                                                      attrs: {
                                                        "data-cy":
                                                          "summarytotal",
                                                        dense: "",
                                                        "hide-details": "",
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true,
                                                        label: _vm.$t(
                                                          "transfers.total-label"
                                                        ),
                                                        numeric: "",
                                                        "highlight-on-focus": ""
                                                      },
                                                      model: {
                                                        value: part.total,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            part,
                                                            "total",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "part.total"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.visibleCountSheetGroups, function(group, index) {
                    return _c(
                      "v-expansion-panel",
                      { key: index, staticClass: "fd-count-sheet-group-panel" },
                      [
                        _c("v-expansion-panel-header", [
                          _vm._v(" " + _vm._s(group.name))
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-expansion-panel-content",
                          [
                            !group.parts || group.parts.length == 0
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-row", {
                                          staticStyle: {
                                            "justify-content": "center"
                                          },
                                          attrs: { "no-gutters": "" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(group.sortedParts, function(part) {
                              return _c(
                                "v-row",
                                {
                                  key: part.id,
                                  staticClass: "fd-part-row",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "fd-group-part-content" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: { cols: "7" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-count-sheet-part-code-label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            part.publicID
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-count-sheet-part-description-label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            part.description
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0 py-1",
                                          attrs: { cols: "5" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              !_vm.directionIsYardTransfer
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: {
                                                        cols: _vm.directionIsYardTransfer
                                                          ? 0
                                                          : 4
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          "data-cy": "assigned",
                                                          dense: "",
                                                          "hide-details": "",
                                                          disabled:
                                                            _vm.processing,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "transfers.assigned-label"
                                                          ),
                                                          numeric: "",
                                                          "highlight-on-focus":
                                                            ""
                                                        },
                                                        model: {
                                                          value: part.assigned,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              part,
                                                              "assigned",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "part.assigned"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pl-1 order-1 order-sm-2",
                                                  attrs: {
                                                    cols: _vm.directionIsYardTransfer
                                                      ? 12
                                                      : 4
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    ref: _vm.fieldRefForPart(
                                                      "count",
                                                      part
                                                    ),
                                                    refInFor: true,
                                                    staticClass:
                                                      "fd-count-sheet-text-field",
                                                    attrs: {
                                                      "data-cy": "count",
                                                      dense: "",
                                                      "hide-details": "",
                                                      disabled:
                                                        _vm.processing ||
                                                        !_vm.partsAreEditable ||
                                                        (_vm.directionIsScaffoldReturn &&
                                                          part.assigned == 0),
                                                      label: _vm.$t(
                                                        "transfers.count-label"
                                                      ),
                                                      numeric: "",
                                                      "highlight-on-focus": ""
                                                    },
                                                    on: {
                                                      keydown: [
                                                        function($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return (function(e) {
                                                            return _vm.selectPreviousField(
                                                              e,
                                                              "count",
                                                              part
                                                            )
                                                          })($event)
                                                        },
                                                        function($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown"
                                                              ]
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return (function(e) {
                                                            return _vm.selectNextField(
                                                              e,
                                                              "count",
                                                              part
                                                            )
                                                          })($event)
                                                        },
                                                        function($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return (function(e) {
                                                            return _vm.enterPressed(
                                                              e,
                                                              "count",
                                                              part
                                                            )
                                                          })($event)
                                                        }
                                                      ],
                                                      change: function($event) {
                                                        return _vm.partCountValueChanged(
                                                          part
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: part.count,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          part,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "part.count"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              !_vm.directionIsYardTransfer
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: {
                                                        cols: _vm.directionIsYardTransfer
                                                          ? 0
                                                          : 4
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          "data-cy": "total",
                                                          dense: "",
                                                          "hide-details": "",
                                                          disabled:
                                                            _vm.processing,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "transfers.total-label"
                                                          ),
                                                          numeric: "",
                                                          "highlight-on-focus":
                                                            ""
                                                        },
                                                        model: {
                                                          value: part.total,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              part,
                                                              "total",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "part.total"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-cy": "cancel",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "save",
                        color: "primary",
                        disabled: _vm.processing,
                        loading: _vm.saving,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }