import FDVue from "../";

export default FDVue.extend({
  name: "fd-duration-picker",

  props: {
    value: { type: Number, default: 0 },
    hourOptions: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    minuteInterval: { type: Number, default: 15 },
    showSeconds: { type: Boolean, default: false },
    secondInterval: { type: Number, default: 15 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    cy: { type: String, default: "fd-duration-picker" }
  },

  data: function() {
    return {
      hoursValue: 0,
      allMinuteOptions: Array.from(Array(60).keys()),
      minutesValue: 0,
      allSecondOptions: Array.from(Array(60).keys()),
      secondsValue: 0
    };
  },
  computed: {
    colSpan(): number {
      var controls = 2;
      if (this.showSeconds) controls += 1;
      return Math.floor(12 / controls);
    },
    minuteOptions(): number[] {
      var minuteOptions = this.allMinuteOptions.filter(x => x % this.minuteInterval == 0);
      return minuteOptions;
    },
    secondOptions(): number[] {
      var secondOptions = this.allSecondOptions.filter(x => x % this.secondInterval == 0);
      return secondOptions;
    },
    totalValue(): number {
      return (
        this.hoursValue +
        this.minutesValue / 60.0 +
        (this.showSeconds ? this.secondsValue / 3600.0 : 0)
      );
    }
  },
  watch: {
    value(newValue) {
      console.log(`DurationPicker.value changed: ${newValue}`);
      var cumulativeValue = this.value;
      this.hoursValue = Math.floor(cumulativeValue);

      cumulativeValue -= this.hoursValue;
      var exactMinutes = Math.floor(cumulativeValue * 60);
      this.minutesValue = Math.round(exactMinutes / this.minuteInterval) * this.minuteInterval;

      if (this.showSeconds) {
        cumulativeValue -= this.minutesValue / 60;
        var exactSeconds = Math.floor(cumulativeValue * 3600);
        this.secondsValue = Math.round(exactSeconds / this.secondInterval) * this.secondInterval;
      }
    },
    hoursValue(newValue, oldValue) {
      this.$emit("input", this.totalValue);
    },
    minutesValue(newValue, oldValue) {
      this.$emit("input", this.totalValue);
    },
    secondsValue(newValue, oldValue) {
      this.$emit("input", this.totalValue);
    }
  },
  mounted: function() {
    console.log(`DurationPicker.mounted value: ${this.value}`);
    var cumulativeValue = this.value;
    this.hoursValue = Math.floor(cumulativeValue);

    cumulativeValue -= this.hoursValue;
    var exactMinutes = Math.floor(cumulativeValue * 60);
    this.minutesValue = Math.round(exactMinutes / this.minuteInterval) * this.minuteInterval;

    if (this.showSeconds) {
      cumulativeValue -= this.minutesValue / 60;
      var exactSeconds = Math.floor(cumulativeValue * 3600);
      this.secondsValue = Math.round(exactSeconds / this.secondInterval) * this.secondInterval;
    }
  }
});

