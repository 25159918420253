import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import {
  ScaffoldRequestTypes,
  WorkOrderStatuses,
  workOrderService,
  workOrderStatusHelper
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import ServiceError from "@fd/lib/client-util/serviceError";

import workOrderList, {
  WorkOrderWithExtraDetails,
  ParseWorkOrderWithLegacyDetails,
  FilteringRequestorContext,
  FilteringContext
} from "../dataMixins/workOrderList";
import { GetPersonName, PersonWithName } from "../utils/person";

export default FDVue.extend({
  name: "fd-work-order-estimates",

  mixins: [rules, workOrderList],

  data: function() {
    return {
      cancelling: false
    };
  },

  computed: {
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 14;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        return 5;
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 10;
      } else {
        return 14;
      }

      return 14;
    }
  },

  watch: {},

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async loadWorkOrders() {
      this.allWorkOrders = await (
        await workOrderService.getWorkOrdersToEstimate(
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      ).map(x => {
        return ParseWorkOrderWithLegacyDetails(x);
      });
    },

    async cancelWorkOrder(item: WorkOrderWithExtraDetails) {
      this.cancelling = true;
      await this.saveField(item, "workOrderStatus", WorkOrderStatuses.Cancelled, true);
      this.cancelling = false;
    },

    async flipShowWorkOrderOnToDo(item: WorkOrderWithExtraDetails) {
      console.log(`flipShowWorkOrderOnToDo WorkOrder ID: ${item.legacyID}`);
      if (item.workOrderStatus == WorkOrderStatuses.Approved) {
        var snackbarPayload = {
          text: this.$t("scheduler.cannot-mark-approved-to-do", [item.legacyID]),
          type: "error",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        return;
      }

      if (this.workOrderNeedsWalkdown(item)) {
        await this.flipWalkdownPlanned(item);
      } else {
        await this.flipWorkPlanned(item);
      }
    },

    async flipWalkdownPlanned(item: WorkOrderWithExtraDetails) {
      console.log(`flipWalkdownPlanned WorkOrder ID: ${item.legacyID}`);

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var plannedWalkdownStartDate = item.isWalkdownPlanned
          ? null
          : new Date(new Date().toUTCString());
        var updatedItem = await workOrderService.updateItem(item.id!, {
          id: item.id,
          legacyID: item.legacyID,
          plannedWalkdownStartDate: plannedWalkdownStartDate
        } as WorkOrderWithExtraDetails);

        item.plannedWalkdownStartDate = updatedItem.plannedWalkdownStartDate;
        item.isWalkdownPlanned =
          !!updatedItem.plannedWalkdownStartDate &&
          updatedItem.plannedWalkdownStartDate < new Date();

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipWorkPlanned(item: WorkOrderWithExtraDetails) {
      console.log(`flipWorkPlanned WorkOrder ID: ${item.legacyID}`);
      if (
        item.scaffoldRequestType == ScaffoldRequestTypes.Dismantle &&
        this.workOrderScaffoldHasOtherActiveWorkOrders(item) &&
        !item.isWorkPlanned
      ) {
        // Dismantle work order for a scaffold that still has other active WOs
        // Work is not already planned (meaning we're turning it on)
        if (!(await this.openWorkDetailsForScaffoldDialog(item, true))) {
          // Change the value to something else and then back to its current to force a rebind
          let previousWorkPlanned = item.isWorkPlanned;
          item.isWorkPlanned = !previousWorkPlanned;
          this.$nextTick(() => {
            item.isWorkPlanned = previousWorkPlanned;
          });

          return;
        }
      }

      if (!item.isWorkPlanned) {
        // If it's not planned (therefore it's BECOMING planned) validate data.
        if (!item.generalForemanID || !item.foremanID) {
          var snackbarPayload = {
            text: this.$t("scheduler.cannot-mark-unassigned-to-do", [item.legacyID]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          return;
        }
      }

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var plannedWorkStartDate = item.isWorkPlanned ? null : new Date(new Date().toUTCString());
        var updatedItem = await workOrderService.updateItem(item.id!, {
          id: item.id,
          legacyID: item.legacyID,
          plannedWorkStartDate: plannedWorkStartDate
        } as WorkOrderWithExtraDetails);

        item.plannedWorkStartDate = updatedItem.plannedWorkStartDate;
        item.isWorkPlanned =
          !!updatedItem.plannedWorkStartDate && updatedItem.plannedWorkStartDate < new Date();

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: WorkOrderWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // Flipping the archived switch has no effect on the SP1 work order, so we can do a basic update here
        var updatedItem = await workOrderService.updateItem(item.id!, {
          id: item.id,
          legacyID: item.legacyID,
          isArchived: !item.isArchived
        } as WorkOrderWithExtraDetails);

        item.archivedDate = updatedItem.archivedDate;
        var utcNow = new Date(
          new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
        );

        // divide by 1000 to get rid of milliseconds.  Also provide a bit of buffer for time differences
        item.isArchived =
          updatedItem.archivedDate != null &&
          updatedItem.archivedDate.getTime() / 60000 <= utcNow.getTime() / 60000;

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async updateWorkOrderProgress(item: WorkOrderWithExtraDetails, progress: number) {
      // If the user is setting this to 100%, the WO is going to be marked as completed and archived
      // As such, we want to present the undo option
      await this.saveField(item, "progress", progress, progress >= 100);
    },

    async updateWorkOrderStatus(
      item: WorkOrderWithExtraDetails,
      workOrderStatus: WorkOrderStatuses
    ) {
      var allowUndo = false;
      if (
        workOrderStatus == WorkOrderStatuses.Completed ||
        workOrderStatus == WorkOrderStatuses.Cancelled
      ) {
        // If the user is setting this to completed or cancelled, the WO is going to be archived and disappear from the list
        // As such, we want to present the undo option
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Approved &&
        workOrderStatus != WorkOrderStatuses.Approved
      ) {
        // If the WO's current status is Approved and the user is setting it to anything, allow the undo
        // This is because "Approved" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Estimated &&
        workOrderStatus != WorkOrderStatuses.Estimated
      ) {
        // If the WO's current status is Estimated and the user is setting it to anything, allow the undo
        // This is because "Estimated" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.InScheduling &&
        workOrderStatus != WorkOrderStatuses.InScheduling
      ) {
        // If the WO's current status is InScheduling and the user is setting it to anything, allow the undo
        // This is because "InScheduling" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        workOrderStatus != WorkOrderStatuses.Approved
      ) {
        // If the WO's current status is WALKDOWN and the user is setting it to anything other than Approved, allow the undo
        // This is because "Walkdown" cannot be selected on this screen unless in approved status and therefore this is an irreversible action
        allowUndo = true;
      }

      await this.saveField(item, "workOrderStatus", workOrderStatus, allowUndo);
    },

    async saveField(
      item: WorkOrderWithExtraDetails,
      fieldName: string,
      value: any,
      presentUndo: boolean = false
    ) {
      var canMakeChange = true;
      if (!this.currentUserCanEditWorkOrderSchedule) canMakeChange = false;

      if (fieldName == "areaID" && !item.currentUserPermissions.canEditArea) canMakeChange = false;
      if (fieldName == "subAreaID" && !item.currentUserPermissions.canEditArea)
        canMakeChange = false;
      if (fieldName == "specificWorkLocation" && !item.currentUserPermissions.canEditLocation)
        canMakeChange = false;
      if (
        fieldName == "detailedWorkDescription" &&
        !item.currentUserPermissions.canEditWorkDescription
      )
        canMakeChange = false;
      if (fieldName == "priority" && !item.currentUserPermissions.canEditPriority)
        canMakeChange = false;
      if (fieldName == "isUrgent" && !item.currentUserPermissions.canEditPriority)
        canMakeChange = false;
      if (
        (fieldName == "plannedWorkStartDate" || fieldName == "plannedWalkdownStartDate") &&
        !item.currentUserPermissions.canPushToToDoList
      )
        canMakeChange = false;
      if (fieldName == "progress" && !item.currentUserPermissions.canEditProgress)
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        !item.currentUserPermissions.canEditProgress &&
        (value == WorkOrderStatuses.Started || value == WorkOrderStatuses.Completed)
      )
        canMakeChange = false;
      if (fieldName == "coordinatorID" && !item.currentUserPermissions.canEditAssignedCoordinator)
        canMakeChange = false;
      if (
        fieldName == "generalForemanID" &&
        !item.currentUserPermissions.canEditAssignedGeneralForeman
      )
        canMakeChange = false;
      if (fieldName == "foremanID" && !item.currentUserPermissions.canEditAssignedForeman)
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value != WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canEditStatus
      )
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value == WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canCancel
      )
        canMakeChange = false;
      if (fieldName == "requiredDate" && !item.currentUserPermissions.canEditRequiredDate)
        canMakeChange = false;

      if (!canMakeChange) {
        // Change the value to something else and then back to its current to force a rebind
        let previous = item.workOrderStatus;
        item.workOrderStatus = undefined;
        this.$nextTick(() => {
          item.workOrderStatus = previous;
        });
        return false;
      }

      // Capture the original value. This allows us to set the value of the client-side item immediately while still being able to revert it on error
      var originalValue = (item as any)[fieldName];

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        let updatedItem = {
          id: item.id,
          legacyID: item.legacyID,
          [fieldName]: value,
          archivedDate: item.archivedDate // Status changes rely on this to be passed in, and this property is not checked directly for modified
        } as WorkOrderWithExtraDetails;

        if (
          item.scaffoldRequestType == ScaffoldRequestTypes.Dismantle &&
          this.workOrderScaffoldHasOtherActiveWorkOrders(item)
        ) {
          if (
            (fieldName == "isUrgent" && value == true) ||
            (fieldName == "workOrderStatus" &&
              (value == WorkOrderStatuses.Started || value == WorkOrderStatuses.Completed))
          ) {
            if (!(await this.openWorkDetailsForScaffoldDialog(item, true))) {
              // Change the value to something else and then back to its current to force a rebind
              let previousUrgent = item.isUrgent;
              item.isUrgent = undefined;
              let previousStatus = item.workOrderStatus;
              item.workOrderStatus = undefined;
              this.$nextTick(() => {
                item.isUrgent = previousUrgent;
                item.workOrderStatus = previousStatus;
              });
              return false;
            }
          }
        }

        if (fieldName == "assignedContractorID") {
          var assignedContractorID = value as string;
          if (!assignedContractorID) {
            updatedItem.coordinatorID = null;
            updatedItem.generalForemanID = null;
            updatedItem.foremanID = null;
          } else {
            var foundAssignableCoordinator = this.getAssignableCoordinatorsForWorkOrder(item).find(
              x => (x as PersonWithName)?.id == item.coordinatorID
            ) as PersonWithName;
            updatedItem.coordinatorID = foundAssignableCoordinator?.id ?? null;
            updatedItem.generalForemanID =
              this.getGeneralForemenForContractor(assignedContractorID).find(
                x => x.id == item.generalForemanID
              )?.id ?? null;
            updatedItem.foremanID =
              this.getForemenForContractor(assignedContractorID).find(x => x.id == item.foremanID)
                ?.id ?? null;
          }
        } else if (fieldName == "isUrgent") {
          let details = "" as string | undefined;
          if (value == true) {
            let title = this.$t("scheduler.urgent-reason");

            details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
              this.rules.required
            ]);

            // If details is undefined the dialog was cancelled
            if (!details) {
              // Change the value to something else and then back to its current to force a rebind
              let previousUrgent = item.isUrgent;
              item.isUrgent = undefined;
              this.$nextTick(() => {
                item.isUrgent = previousUrgent;
              });
              return false;
            }
          }
          updatedItem.isUrgentDetail = details;
        } else if (fieldName == "workOrderStatus") {
          let details = "" as string | undefined;
          if (value == WorkOrderStatuses.OnHold || value == WorkOrderStatuses.Cancelled) {
            var title = this.$t("scheduler.status-reason");
            if (value == WorkOrderStatuses.OnHold) title = this.$t("scheduler.on-hold-reason");
            if (value == WorkOrderStatuses.Cancelled)
              title = this.$t("scheduler.cancellation-reason");

            details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
              this.rules.required
            ]);

            // If details is undefined the dialog was cancelled
            if (!details) {
              // Change the value to something else and then back to its current to force a rebind
              var previous = item.workOrderStatus;
              item.workOrderStatus = undefined;
              this.$nextTick(() => {
                item.workOrderStatus = previous;
              });
              return false;
            }
          }
          updatedItem.workOrderStatusDetail = details;
        }

        // We've cached the item's originalValue above to use in case of error
        // So we can set the item's value here so the UI updates while the call happens
        (item as any)[fieldName] = value;
        var loadedItem = await workOrderService.updateItem(item.id!, updatedItem);

        // The following fields might have been updated via business logic when doing the update
        // They could be updated via various fields and don't hurt anything to set again based on the loadedItem details
        item.workOrderStatus = loadedItem.workOrderStatus;
        item.workOrderStatusDetail = loadedItem.workOrderStatusDetail;
        item.archivedDate = loadedItem.archivedDate;
        item.progress = loadedItem.progress;
        var utcNow = new Date(
          new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
        );
        item.isArchived =
          loadedItem.archivedDate != null &&
          loadedItem.archivedDate.getTime() / 60000 <= utcNow.getTime() / 60000;

        if (fieldName == "assignedContractorID") {
          item.coordinatorID = loadedItem.coordinatorID;
          item.generalForemanID = loadedItem.generalForemanID;
          item.foremanID = loadedItem.foremanID;

          var contractor = this.allContractors.find(x => item.assignedContractorID == x.id);
          if (!!contractor) {
            item.assignedContractorName = contractor.name!;
          } else {
            item.assignedContractorName = "";
          }

          var coordinator = this.allCoordinators.find(x => item.coordinatorID == x.id);
          if (!!coordinator) {
            item.coordinatorName = GetPersonName(coordinator);
          } else {
            item.coordinatorName = "";
          }

          var generalForeman = this.allGeneralForemen.find(x => item.generalForemanID == x.id);
          if (!!generalForeman) {
            item.generalForemanName = GetPersonName(generalForeman);
          } else {
            item.generalForemanName = "";
          }

          var foreman = this.allForemen.find(x => item.foremanID == x.id);
          if (!!foreman) {
            item.foremanName = GetPersonName(foreman);
          } else {
            item.foremanName = "";
          }
        } else if (fieldName == "coordinatorID") {
          var coordinator = this.allCoordinators.find(x => item.coordinatorID == x.id);
          if (!!coordinator) {
            item.coordinatorName = GetPersonName(coordinator);
          } else {
            item.coordinatorName = "";
          }
        } else if (fieldName == "foremanID") {
          var foreman = this.allForemen.find(x => item.foremanID == x.id);
          if (!!foreman) {
            item.foremanName = GetPersonName(foreman);
          } else {
            item.foremanName = "";
          }
        } else if (fieldName == "generalForemanID") {
          var generalForeman = this.allGeneralForemen.find(x => item.generalForemanID == x.id);
          if (!!generalForeman) {
            item.generalForemanName = GetPersonName(generalForeman);
          } else {
            item.generalForemanName = "";
          }
        } else if (fieldName == "workOrderStatus") {
          var status = this.allWorkOrderStatuses.find(x => x.value == item.workOrderStatus);
          if (!!status) {
            item.workOrderStatusName = status.displayName;
          } else {
            item.workOrderStatusName = "";
          }
        } else if (fieldName == "isUrgent") {
          item.isUrgentDetail = updatedItem.isUrgentDetail;
          if (value == true) {
            item.lastUrgentValueChangedBy = this.curUserFullName;
            item.lastUrgentValueChangedDate = new Date();
          }
        }

        let undoCallback = null;
        if (presentUndo) {
          undoCallback = async () => {
            this.saveField(item, fieldName, originalValue, false);
          };
        }
        let snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.legacyID]),
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        // Change the value to something else and then back to its original value to force a rebind to undo the change the user made
        (item as any)[fieldName] = undefined;
        this.$nextTick(() => {
          (item as any)[fieldName] = originalValue;
        });

        // If the error is a 422 (meaning server validation failed) show a snackbar with the appropriate error message
        // If it's anything, handle as normal
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t(`error-messages.${(error as ServiceError).details}`, [item.legacyID]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    var requestorFilter: FilteringRequestorContext = !!this.curUserAccess.homeContractorID
      ? "mine"
      : "all";
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "work-order-estimates",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: {
        requestorFilter: requestorFilter,
        assignedFilter: true,
        unassignedFilter: true
      } as FilteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("work-order-estimates.list-title"),
      to: "/estimatedworkorders",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadContractors()
      ]);
      this.processing = true;

      this.allWorkOrderStatuses = await workOrderStatusHelper.getEstimatesSelectableWorkOrderStatuses();
      // var allWorkOrderStatuses = await workOrderStatusHelper.getEstimatesSelectableWorkOrderStatuses();
      this.allStatusNames = this.allWorkOrderStatuses.map(x => x.displayName);
      this.scaffoldContractors = this.allContractors.filter(
        x => !!x.isScaffoldCompany && x.isScaffoldCompany
      );
      await this.reloadTableData();

      // double check selectedRequestor filter based on table results
      var myWorkOrders = this.allWorkOrders.filter(
        x =>
          (!!x.coordinatorLegacyID && x.coordinatorLegacyID == this.curUserAccess.legacyID) ||
          (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
          (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
          (!!x.foremanID && x.foremanID == this.curUserID)
      );
      if (this.requestorFilterIsMine && myWorkOrders.length == 0) {
        this.requestorFilterIsMine = false;
      } else if (!this.requestorFilterIsMine && myWorkOrders.length > 0) {
        this.requestorFilterIsMine = true;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

