var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "scaffolds" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("scaffolds.list-title")) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.contractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background fd-scaffolds-switches"
            },
            [
              _c("v-switch", {
                staticClass: "pr-6",
                attrs: {
                  "data-cy": "standingswitch",
                  label: _vm.$t("scaffolds.list-selection-type-standing")
                },
                model: {
                  value: _vm.standingContextSelected,
                  callback: function($$v) {
                    _vm.standingContextSelected = $$v
                  },
                  expression: "standingContextSelected"
                }
              }),
              _vm._v(" "),
              _c("v-switch", {
                staticClass: "pr-3",
                attrs: {
                  "data-cy": "dismantledswitch",
                  label: _vm.$t("scaffolds.list-selection-type-dismantled")
                },
                model: {
                  value: _vm.dismantledContextSelected,
                  callback: function($$v) {
                    _vm.dismantledContextSelected = $$v
                  },
                  expression: "dismantledContextSelected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { class: [!_vm.canViewContractorFilter ? "mt-3" : "", "pb-0"] },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas.length || _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "pt-0",
                        {
                          "pb-5": !_vm.canViewContractorFilter,
                          "pb-0": _vm.canViewContractorFilter
                        },
                        "order-sm-1",
                        "order-2"
                      ],
                      attrs: {
                        cols: "12",
                        sm: _vm.canViewContractorFilter ? 6 : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0  order-sm-2 order-1",
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffolds.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractorNames,
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedContractorNames,
                              callback: function($$v) {
                                _vm.selectedContractorNames = $$v
                              },
                              expression: "selectedContractorNames"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:id.hide-when-header-text-empty",
                value: "",
                expression: "''",
                arg: "id",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:tagNumber",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scaffolds.tag-number-label")
                  : _vm.$t("scaffolds.tag-number-label-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scaffolds.tag-number-label')\n          : $t('scaffolds.tag-number-label-short')\n      ",
                arg: "tagNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldStatusName.show-when-extra-small",
                value: _vm.$t("scaffolds.status-label"),
                expression: "$t('scaffolds.status-label')",
                arg: "scaffoldStatusName",
                modifiers: { "show-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:daysStanding.hide-when-extra-small",
                value: _vm.$t("scaffolds.days-standing-label"),
                expression: "$t('scaffolds.days-standing-label')",
                arg: "daysStanding",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:areaName.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.$t("scaffolds.area-label"),
                expression: "\n        $t('scaffolds.area-label')\n      ",
                arg: "areaName",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:subAreaName.hide-when-extra-small",
                value: _vm.$t("scaffolds.sub-area-label"),
                expression: "$t('scaffolds.sub-area-label')",
                arg: "subAreaName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:specificWorkLocation.hide-when-medium",
                value: _vm.$t("scaffolds.location-label"),
                expression: "$t('scaffolds.location-label')",
                arg: "specificWorkLocation",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldContractorName.hide-when-medium",
                value: _vm.$t("scaffolds.contractor-label"),
                expression: "$t('scaffolds.contractor-label')",
                arg: "scaffoldContractorName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:plannedErect.hide-when-medium",
                value: _vm.$t("scaffolds.planned-erect-date-label"),
                expression: "$t('scaffolds.planned-erect-date-label')",
                arg: "plannedErect",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:actualErect.hide-when-medium",
                value: _vm.$t("scaffolds.actual-erect-date-label"),
                expression: "$t('scaffolds.actual-erect-date-label')",
                arg: "actualErect",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:plannedDismantle.hide-when-medium",
                value: _vm.$t("scaffolds.planned-dismantle-date-label"),
                expression: "$t('scaffolds.planned-dismantle-date-label')",
                arg: "plannedDismantle",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-mobile.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-mobile": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-actions-cell-edit-only",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell-edit-only": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: function(item) {
                  return _vm.$router.push("/scaffolds/" + item.id)
                },
                expression: "item => $router.push(`/scaffolds/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            class: [
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-actions-table",
              "fd-expanding-table",
              "fd-requests-list"
            ],
            attrs: {
              "data-cy": "list",
              "custom-sort": _vm.customSort,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.scaffolds,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["tagNumber"],
              "sort-desc": true,
              "mobile-breakpoint": "0",
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing
                      },
                      on: {
                        "input:showArchived": function(v) {
                          return (_vm.showArchived = v)
                        },
                        "input:showArchivedDateRange": function(v) {
                          return (_vm.showArchivedDateRange = v)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.legacyRequestStatusName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        item.scaffoldRequestStatus == 4 ||
                        (!!item.walkdown && item.walkdown.walkdownStatus == 4)
                          ? _c(
                              "v-icon",
                              { staticClass: "fd-table-cell-icon" },
                              [_vm._v("fas fa-exclamation-circle")]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(item.legacyRequestStatusName) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.archived",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.showArchived
                      ? _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: { value: item.archived, disabled: true },
                              on: {
                                input: function($event) {
                                  return _vm.flipArchived(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: { colspan: _vm.expanderColSpan }
                      },
                      [
                        true
                          ? _c(
                              "div",
                              { staticClass: "fd-details-section pt-3" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0 pl-0 pt-2 pb-3",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scaffolds.tag-number-label"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.tagNumber }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("scaffolds.status-label")
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: {
                                            value: item.scaffoldStatusName
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scaffolds.planned-erect-date-label"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.plannedErect }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scaffolds.planned-dismantle-date-label"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: {
                                            value: item.plannedDismantle
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scaffolds.actual-erect-date-label"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.actualErect }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scaffolds.actual-dismantle-date-label"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.actualDismantle }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("scaffolds.iwp-label")
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        !!item.workPackageNames &&
                                        item.workPackageNames.length > 0
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                item.workPackageNames,
                                                function(iwp, key) {
                                                  return _c(
                                                    "div",
                                                    { key: key },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(iwp) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fd-item-placeholder"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.not-available"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "fd-remove-left-padding-cols-below pl-0",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mx-0 px-0",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pa-0",
                                                attrs: {
                                                  align: "center",
                                                  cols: "12"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-subheader",
                                                  {
                                                    staticClass: "pa-0 mt-3",
                                                    staticStyle: {
                                                      "font-size": "1em",
                                                      height: "32px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "scaffolds.location-sub-header"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mx-0 pl-0",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c("v-divider", {
                                              staticClass: "mx-0 mt-0 px-0"
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mx-0 pl-0 pt-2 pb-3",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "3" } },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "scaffolds.area-label"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "9" } },
                                              [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.areaName
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "3" } },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "scaffolds.sub-area-label"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "9" } },
                                              [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.subAreaName
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "3" } },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "scaffolds.location-label"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6", sm: "9" } },
                                              [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value:
                                                      item.specificWorkLocation
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: "/scaffolds/" + item.id
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }