import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { WalkdownDetails, WorkOrderDetails } from "./WorkOrderDetails.vue";

const WorkOrderDetailsDialog = FDVue.extend({
  name: "fd-work-order-details-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-work-order-details": () => import("./WorkOrderDetails.vue")
  },

  props: {
    cy: { type: String, default: "fd-work-order-details-dialog" }
  },

  data: function() {
    return {
      workOrder: {} as WorkOrderDetails,
      walkdown: {} as WalkdownDetails
    };
  },

  methods: {
    async openWithData(workOrder: WorkOrderDetails, walkdown: WalkdownDetails) {
      this.workOrder = workOrder;
      this.walkdown = walkdown;

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    cancelDialog() {
      this.closeDialog!(false);
    }
  }
});

export default WorkOrderDetailsDialog;

export async function openWorkOrderDetailsDialog(
  workOrder: WorkOrderDetails,
  walkdown: WalkdownDetails
): Promise<boolean> {
  let dialog = createDialog(WorkOrderDetailsDialog);
  return await dialog.openWithData(workOrder, walkdown);
}
