import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { TransferWithDetails, transferService, TransferDirection } from "../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import { mapMutations } from "vuex";
import userAccess from "../dataMixins/userAccess";
import { showTransferNewDialog } from "./components/dialogs/TransferNewDialog.vue";

type FilteringContext = "delivery" | "return" | "transfer";

export default FDVue.extend({
  name: "fd-transfers-list",

  mixins: [userAccess, serviceErrorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      archivedLoading: false,
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,

      transfers: [] as TransferWithDetails[],

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    filteredTransfers(): TransferWithDetails[] {
      let transfers = this.transfers;

      if (this.transferContextIsDelivery) {
        transfers = transfers.filter(x => x.direction == TransferDirection.ScaffoldDelivery);
      } else if (this.transferContextIsReturn) {
        transfers = transfers.filter(x => x.direction == TransferDirection.ScaffoldReturn);
      } else {
        transfers = transfers.filter(x => x.direction == TransferDirection.YardTransfer);
      }

      return transfers;
    },
    transferContext: {
      get(): FilteringContext {
        return (
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? "delivery"
        );
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    transferContextIsDelivery(): boolean {
      return this.transferContext == "delivery";
    },
    transferContextIsReturn(): boolean {
      return this.transferContext == "return";
    },
    transferContextIsTransfer(): boolean {
      return this.transferContext == "transfer";
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.loadTransfers();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    }
  },

  methods: {
    async openNewDelivery() {
      await this.openNewDialog(TransferDirection.ScaffoldDelivery);
    },
    async openNewReturn() {
      await this.openNewDialog(TransferDirection.ScaffoldReturn);
    },
    async openNewYardTransfer() {
      await this.openNewDialog(TransferDirection.YardTransfer);
    },
    async openNewDialog(direction: TransferDirection) {
      if (!this.currentUserCanCreateTransfers) return;

      if (await showTransferNewDialog({ direction })) {
        await this.reloadTableData();
      }
    },
    formatDate(date: Date | string) {
      return DateUtil.stripTimeFromLocalizedDateTime(date);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadTransfers();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadTransfers() {
      this.transfers = await transferService.getAll(
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },

  created: async function() {
    this.maxDate = DateUtil.addDaysToDate(null, 0);
    this.minDate = DateUtil.addMonthsToDate(this.maxDate, -2);
    let fromDate = DateUtil.addDaysToDate(null, -13);
    this.setFilteringContext({
      context: "transfers",
      parentalContext: null,
      showArchivedForFiltering: true,
      showArchivedForFilteringFromDate: fromDate,
      showArchivedForFilteringToDate: this.maxDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: "delivery"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("transfers.list.title"),
      to: "/transfers",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.reloadTableData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

