import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import VueI18n from "vue-i18n";
import {
  LegacyWorkOrderWithRequestDetails,
  WorkPackage,
  LegacyScaffoldSearchResult,
  ScaffoldRequestWithDetails
} from "../services";
import { GetPersonName } from "../utils/person";

export type WorkPackageWithNameCode = WorkPackage & { nameCode: string };
export type LegacyWorkOrderWithExtraRequestDetails = LegacyWorkOrderWithRequestDetails & {
  archived: boolean;
};
export type ScaffoldRequestRules = {
  requestType: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestSubType: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requiredDate: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requiredUntilDate: ((value: any) => boolean | VueI18n.TranslateResult)[];
  tagNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestingContractorID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  disciplineID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestorID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  areaID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  subAreaID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  siteContact: ((value: any) => boolean | VueI18n.TranslateResult)[];
  location: ((value: any) => boolean | VueI18n.TranslateResult)[];
  description: ((value: any) => boolean | VueI18n.TranslateResult)[];
  notes: ((value: any) => boolean | VueI18n.TranslateResult)[];
};

export type ScaffoldRequestWithExtraDetails = ScaffoldRequestWithDetails & {
  archived: boolean;
  requestorFullName: string;
  requestingContractorName: string;
  requestingEmployeeFullName: string;
  filteredWorkOrderID: number | null | undefined;
};

export function ParseScaffoldRequestWithExtraDetails(
  request: ScaffoldRequestWithDetails
): ScaffoldRequestWithExtraDetails {
  return {
    ...request,
    archived: !!request.archivedDate,
    requestorFullName: GetPersonName(request.requestor),
    requestingContractorName: !!request.requestingContractor?.name?.length
      ? `${request.requestingContractor.name}`
      : "",
    requestingEmployeeFullName: !!request.requestingEmployee
      ? `${request.requestingEmployee.firstName} ${request.requestingEmployee.lastName}`
      : "",
    siteContact: request.siteContact?.toUpperCase(),
    filteredWorkOrderID: undefined
  } as ScaffoldRequestWithExtraDetails;
}

export default FDVue.extend({
  mixins: [rules],
  data: function() {
    return {
      isSavingDraft: true,
      legacyRequest: {} as LegacyWorkOrderWithExtraRequestDetails
    };
  },
  methods: {
    getScaffoldDescription(scaffold: LegacyScaffoldSearchResult): string {
      let paddedTagNumber = `00000${scaffold.id}`.slice(-5);
      let existingRequestString = "";
      if (!!scaffold.existingRequestNumber && scaffold.existingRequestNumber > 0) {
        existingRequestString = ` | R-${scaffold.existingRequestNumber}`;
      }
      return `T-${paddedTagNumber}${existingRequestString}`;
    },

    getScaffoldDetails(scaffold: LegacyScaffoldSearchResult): string {
      // The sub area is more specific than the area, so if a subarea exists, display it
      // Otherwise, display the area
      let areaString = "";
      if (scaffold.subAreaName && scaffold.subAreaName.trim().length > 0) {
        areaString = `${scaffold.subAreaName.trim()}`;
      }
      if (areaString.length == 0 && scaffold.areaName && scaffold.areaName.trim().length > 0) {
        areaString = `${scaffold.areaName.trim()}`;
      }

      var detailsString = areaString.length > 0 ? `${areaString}` : "";
      return `${detailsString}`;
    },

    /// This value's purpose is solely to determine which validation rules to return
    /// As such, modifying it changes which rules are applied to the UI, which requires a DOM refresh
    /// We need to use an awaited setter here to provide time for the UI to update
    updateIsDraft(val: boolean): Promise<void> {
      let _this = this;
      this.isSavingDraft = val;
      return new Promise((resolve, reject) => {
        _this.$nextTick(() => {
          resolve();
        });
      });
    }
  },

  computed: {
    // Override this in the file using this mixin. Used to calculate rules.
    isNewScaffold(): boolean {
      return this.legacyRequest.requestTypeID == 1;
    },
    // Override this in the file using this mixin. Used to calculate rules.
    requiredDate(): Date | null | undefined {
      return this.legacyRequest.requiredDate;
    },
    hasTag(): boolean {
      return !!this.legacyRequest.applyToScaffoldID;
    },
    scaffoldRequestRules(): ScaffoldRequestRules {
      let requiredDateLaterThanToday = (value: any) => {
        // The required date is valid if empty, or if entered and is today or later
        return (
          !value ||
          new Date(value).getTime() >= new Date(new Date().toDateString()).getTime() ||
          this.$t("scaffold-requests.required-date-later-than-today")
        );
      };
      let requiredUntilLaterThanRequired = (value: any) => {
        return (
          !value ||
          !this.requiredDate ||
          new Date(value).getTime() >= this.requiredDate.getTime() ||
          this.$t("scaffold-requests.required-until-date-later-than-required-date")
        );
      };
      if (this.isSavingDraft) {
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [requiredDateLaterThanToday],
          requiredUntilDate: [requiredUntilLaterThanRequired],
          tagNumber: [],
          requestingContractorID: [],
          disciplineID: [],
          requestorID: [],
          areaID: [],
          subAreaID: [],
          siteContact: [],
          location: [],
          description: [],
          notes: []
        };
      } else {
        var disciplineRequiredRule = this.rules.required;
        var requestorRequiredRule = this.rules.required;
        var areaRequiredRule = this.rules.required;
        var subAreaRequiredRule = this.rules.required;
        // if (!this.legacyRequest.requestingContractorID) {
        //   var contractorRequiredRule = this.getRequiredRuleWithCustomText(
        //     "scaffold-requests.contractor-required"
        //   );
        //   disciplineRequiredRule = contractorRequiredRule;
        //   requestorRequiredRule = contractorRequiredRule;
        //   areaRequiredRule = contractorRequiredRule;
        //   subAreaRequiredRule = contractorRequiredRule;
        // } else {
        //   if (!this.legacyRequest.disciplineID) {
        //     requestorRequiredRule = this.getRequiredRuleWithCustomText(
        //       "scaffold-requests.discipline-required"
        //     );
        //   }

        //   if (!this.legacyRequest.areaID) {
        //     subAreaRequiredRule = this.getRequiredRuleWithCustomText(
        //       "scaffold-requests.area-required"
        //     );
        //   }
        // }
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [this.rules.required, requiredDateLaterThanToday],
          requiredUntilDate: this.isNewScaffold
            ? [this.rules.required, requiredUntilLaterThanRequired]
            : [requiredUntilLaterThanRequired],
          tagNumber: this.isNewScaffold ? [] : [this.rules.required],
          requestingContractorID: [this.rules.required],
          disciplineID: [disciplineRequiredRule],
          requestorID: [requestorRequiredRule],
          areaID: this.hasTag ? [] : [areaRequiredRule],
          subAreaID: this.hasTag ? [] : [subAreaRequiredRule],
          siteContact: [],
          location: [this.rules.required],
          description: [this.rules.required],
          notes: []
        };
      }
    }
  }
});

