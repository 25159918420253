<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <v-card-title class="grey">
        {{ $t("terms.title") }}
      </v-card-title>
      <v-container class="fd-privacy-terms">
        <v-card-text class="pt-0">
          <h3 class="mb-3">{{ $t("terms.last-updated", [lastUpdated]) }}</h3>
          <p>{{ $t("terms.line1") }}</p>
          <h1>{{ $t("terms.interpretation-and-definitions.header") }}</h1>
          <h2>{{ $t("terms.interpretation-and-definitions.interpretation.header") }}</h2>
          <p>{{ $t("terms.interpretation-and-definitions.interpretation.line1") }}</p>
          <h2>{{ $t("terms.interpretation-and-definitions.definitions.header") }}</h2>
          <p>{{ $t("terms.interpretation-and-definitions.definitions.line1") }}</p>
          <ul>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line2a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line2b", [platformName]) }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line3a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line3b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line4a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line4b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line5a") }}</strong>
                {{
                  $t("terms.interpretation-and-definitions.definitions.line5b", [companyCountry])
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line6a") }}</strong>
                {{
                  $t("terms.interpretation-and-definitions.definitions.line6b", [
                    companyName,
                    companyAddress
                  ])
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line7a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line7b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line8a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line8b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ $t("terms.interpretation-and-definitions.definitions.line9a") }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line9b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("terms.interpretation-and-definitions.definitions.line10a")
                }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line10b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("terms.interpretation-and-definitions.definitions.line11a")
                }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line11b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("terms.interpretation-and-definitions.definitions.line12a")
                }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line12b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("terms.interpretation-and-definitions.definitions.line13a")
                }}</strong>
                {{ $t("terms.interpretation-and-definitions.definitions.line13b") }}
              </p>
            </li>
          </ul>
          <h1>{{ $t("terms.acknowledgement.header") }}</h1>
          <p>{{ $t("terms.acknowledgement.line1") }}</p>
          <p>{{ $t("terms.acknowledgement.line2") }}</p>
          <p>{{ $t("terms.acknowledgement.line3") }}</p>
          <p>{{ $t("terms.acknowledgement.line4") }}</p>
          <p>{{ $t("terms.acknowledgement.line5") }}</p>
          <h1>{{ $t("terms.content.header") }}</h1>
          <h2>{{ $t("terms.content.your-right-to-post.header") }}</h2>
          <p>{{ $t("terms.content.your-right-to-post.line1") }}</p>
          <p>{{ $t("terms.content.your-right-to-post.line2") }}</p>
          <p>{{ $t("terms.content.your-right-to-post.line3") }}</p>
          <h2>{{ $t("terms.content.content-restrictions.header") }}</h2>
          <p>{{ $t("terms.content.content-restrictions.line1") }}</p>
          <p>{{ $t("terms.content.content-restrictions.line2") }}</p>
          <ul>
            <li>{{ $t("terms.content.content-restrictions.line3") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line4") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line5") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line6") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line7") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line8") }}</li>
            <li>{{ $t("terms.content.content-restrictions.line9") }}</li>
            <li class="mb-2">{{ $t("terms.content.content-restrictions.line10") }}</li>
          </ul>
          <p>{{ $t("terms.content.content-restrictions.line11") }}</p>
          <h2>{{ $t("terms.content.content-backups.header") }}</h2>
          <p>{{ $t("terms.content.content-backups.line1") }}</p>
          <p>{{ $t("terms.content.content-backups.line2") }}</p>
          <p>{{ $t("terms.content.content-backups.line3") }}</p>
          <p>{{ $t("terms.content.content-backups.line4") }}</p>
          <h1>{{ $t("terms.copyright.header") }}</h1>
          <h2>{{ $t("terms.copyright.intellectual-property-infringement.header") }}</h2>
          <p>{{ $t("terms.copyright.intellectual-property-infringement.line1") }}</p>
          <p>
            {{ $t("terms.copyright.intellectual-property-infringement.line2", [companyInfoEmail]) }}
          </p>
          <p>{{ $t("terms.copyright.intellectual-property-infringement.line3") }}</p>
          <h2>{{ $t("terms.copyright.dmca-notice.header") }}</h2>
          <p>{{ $t("terms.copyright.dmca-notice.line1") }}</p>
          <ul>
            <li>{{ $t("terms.copyright.dmca-notice.line2") }}</li>
            <li>{{ $t("terms.copyright.dmca-notice.line3") }}</li>
            <li>{{ $t("terms.copyright.dmca-notice.line4") }}</li>
            <li>{{ $t("terms.copyright.dmca-notice.line5") }}</li>
            <li>{{ $t("terms.copyright.dmca-notice.line6") }}</li>
            <li>{{ $t("terms.copyright.dmca-notice.line7") }}</li>
          </ul>
          <p>{{ $t("terms.copyright.dmca-notice.line8", [companyInfoEmail]) }}</p>
          <h1>{{ $t("terms.intellectual-property.header") }}</h1>
          <p>{{ $t("terms.intellectual-property.line1") }}</p>
          <p>{{ $t("terms.intellectual-property.line2") }}</p>
          <p>{{ $t("terms.intellectual-property.line3") }}</p>
          <h1>{{ $t("terms.your-feedback.header") }}</h1>
          <p>{{ $t("terms.your-feedback.line1") }}</p>
          <h1>{{ $t("terms.links-to-other-websites.header") }}</h1>
          <p>{{ $t("terms.links-to-other-websites.line1") }}</p>
          <p>{{ $t("terms.links-to-other-websites.line2") }}</p>
          <p>{{ $t("terms.links-to-other-websites.line3") }}</p>
          <h1>{{ $t("terms.termination.header") }}</h1>
          <p>{{ $t("terms.termination.line1") }}</p>
          <p>{{ $t("terms.termination.line2") }}</p>
          <h1>{{ $t("terms.limitation-of-liability.header") }}</h1>
          <p>{{ $t("terms.limitation-of-liability.line1") }}</p>
          <p>{{ $t("terms.limitation-of-liability.line2") }}</p>
          <p>{{ $t("terms.limitation-of-liability.line3") }}</p>
          <h1>{{ $t("terms.as-is-disclaimer.header") }}</h1>
          <p>{{ $t("terms.as-is-disclaimer.line1") }}</p>
          <p>{{ $t("terms.as-is-disclaimer.line2") }}</p>
          <p>{{ $t("terms.as-is-disclaimer.line3") }}</p>
          <h1>{{ $t("terms.governing-law.header") }}</h1>
          <p>{{ $t("terms.governing-law.line1") }}</p>
          <h1>{{ $t("terms.disputes-resolution.header") }}</h1>
          <p>{{ $t("terms.disputes-resolution.line1") }}</p>
          <h1>{{ $t("terms.eu-users.header") }}</h1>
          <p>{{ $t("terms.eu-users.line1") }}</p>
          <h1>{{ $t("terms.us-legal-compliance.header") }}</h1>
          <p>{{ $t("terms.us-legal-compliance.line1") }}</p>
          <h1>{{ $t("terms.severability-and-waiver.header") }}</h1>
          <h2>{{ $t("terms.severability-and-waiver.severability.header") }}</h2>
          <p>{{ $t("terms.severability-and-waiver.severability.line1") }}</p>
          <h2>{{ $t("terms.severability-and-waiver.waiver.header") }}</h2>
          <p>{{ $t("terms.severability-and-waiver.severability.line1") }}</p>
          <h1>{{ $t("terms.translation-interpretation.header") }}</h1>
          <p>{{ $t("terms.translation-interpretation.line1") }}</p>
          <h1>{{ $t("terms.changes-to-terms.header") }}</h1>
          <p>{{ $t("terms.changes-to-terms.line1") }}</p>
          <p>{{ $t("terms.changes-to-terms.line2") }}</p>
          <h1>{{ $t("terms.contact-us.header") }}</h1>
          <p>{{ $t("terms.contact-us.line1") }}</p>
          <ul>
            <li>
              {{ $t("terms.contact-us.line2") }}
              <a :href="companyContactURL" rel="external nofollow noopener" target="_blank">{{
                companyContactURL
              }}</a>
            </li>
          </ul>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">{{ $t("common.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TermsDialog",

  data: () => ({
    platformName: "Swift Plant",
    lastUpdated: "January 25, 2022",
    companyName: "MPC Solutions LLC",
    companyAddress: "1006 Lemke avenue, Rothschild, WI, 54474",
    companyCountry: "Wisconsin,  United States",
    companyContactURL: "https://mpcsolutionsllc.com/contact.html",
    companyInfoEmail: "info@mpcsolutionsllc.com"
  }),

  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogTerms;
      },
      set(val) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  }
};
</script>
