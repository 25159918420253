import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { ProjectLocation } from "../services";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import rules from "@fd/lib/vue/rules";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

type ProjectLocationWithArchived = ProjectLocation & { archived: boolean };

export default FDVue.extend({
  name: "fd-area-existing",

  mixins: [errorHandling, tabbedView, rules],

  components: {
    "fd-sub-area-new-dialog": () => import("./components/SubAreaNewDialog.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data() {
    return {
      showNewDialog: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      archivedLoading: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      subAreasTab: {
        tabname: "Sub Areas",
        key: "1",
        visible: false
      } as Tab,

      //Simple object for the form data
      area: {
        name: "",
        description: "",
        archived: false
      } as ProjectLocationWithArchived
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.subAreasTab] as Tab[];
    },

    areas(): ProjectLocation[] {
      return (this.$store.state.projectLocations as ProjectLocation[])
        .filter(x => x.parentLocationID == this.$route.params.areaID)
        .map((x: ProjectLocation) => {
          return {
            ...x,
            archived: !!x.archivedDate
          };
        });
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get(): boolean {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val: boolean) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        await Promise.all([
          this.loadAreaWithSubAreas({
            areaID: this.$route.params.areaID,
            forcedArchivedState: this.showArchived,
            archivedFromDate: this.showArchivedFromDate,
            archivedToDate: this.showArchivedToDate
          })
        ]);
        this.processing = false;
        this.archivedLoading = false;
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await Promise.all([
              this.loadAreaWithSubAreas({
                areaID: this.$route.params.areaID,
                forcedArchivedState: this.showArchived,
                archivedFromDate: this.showArchivedFromDate,
                archivedToDate: this.showArchivedToDate
              })
            ]);
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  mounted() {
    // The following is used to compensate for the apparent bug in vuetify that is preventing the underline from appearing
    // on the initial tab that is in focus within the view. This kicks it in the butt to cause it to render appropriately.
    // Without it the line under the initial tab that has focus will NOT show up until you resize the screen which then
    // causes it to re-render and show up.
    const initial = (this.$refs.tab as any).$el.offsetWidth;
    const interval = setInterval(() => {
      if (this.$refs.tab) {
        if ((this.$refs.tab as any).$el.offsetWidth !== initial) {
          clearInterval(interval);
          (this.$refs.tabs as any).callSlider();
        }
      }
    }, 100);
  },

  methods: {
    openNewDialog() {
      this.showNewDialog = true;
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    // Method used in conjunction with the Save button.
    async save() {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.area.archived) {
          this.area.archivedDate = null;
        } else if (this.area.archived && !this.area.archivedDate) {
          this.area.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateArea(this.area);
        this.$router.push(`/projects/${this.area.projectID}`);
      } catch (error) {
        this.inlineMessage.message = this.$t("projects.areas.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteArea({ id: this.$route.params.areaID, name: this.area.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.$router.push(`/projects/${this.area.projectID}`);
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push(`/projects/${this.area.projectID}`);
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSubArea({ id: item.id, name: item.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("projects.areas.sub-areas.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateSubArea({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("projects.areas.sub-areas.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadProjectWithAreas: "LOAD_PROJECT_WITH_AREAS",
      loadAreaWithSubAreas: "LOAD_AREA_WITH_SUBAREAS",
      updateArea: "UPDATE_AREA",
      deleteArea: "DELETE_AREA",
      updateSubArea: "UPDATE_SUBAREA",
      deleteSubArea: "DELETE_SUBAREA"
    })
  },

  watch: {
    area: async function(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/projects") {
        this.notifyNewBreadcrumb({
          text: this.$t("projects.list-title"),
          to: "/projects",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        await this.loadProjectWithAreas({
          projectID: newValue.projectID,
          showAll: false
        });

        this.notifyNewBreadcrumb({
          text: this.$store.state.project.name,
          to: `/projects/${this.$store.state.project.id}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/areas/${this.$route.params.areaID}`
      });
    }
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "subareas",
      parentalContext: "areas",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    try {
      await Promise.all([
        this.loadAreaWithSubAreas({ areaID: this.$route.params.areaID, showAll: this.showArchived })
      ]);
      var area = this.$store.state.projectLocations.find(
        (x: any) => x.id == this.$route.params.areaID
      ) as ProjectLocation;
      this.area = {
        ...area,
        archived: !!area.archivedDate
      } as ProjectLocationWithArchived;
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});

