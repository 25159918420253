var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "configuration" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("configuration.no-permission")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-breadcrumbs", {
                            class: [
                              _vm.processing
                                ? "breadcrumb-processing-opacity"
                                : "",
                              "pl-0"
                            ],
                            attrs: {
                              items: _vm.$store.state.currentBreadcrumbs,
                              large: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "divider",
                                fn: function() {
                                  return [
                                    _c("v-icon", [_vm._v("fa-chevron-right")])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.processing
                            ? _c("v-progress-circular", {
                                staticClass: "mr-3",
                                attrs: {
                                  indeterminate: true,
                                  rotate: 0,
                                  size: 32,
                                  width: 4,
                                  color: "white"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-tabs",
                {
                  ref: "tabs",
                  staticClass: "mt-3",
                  attrs: {
                    "icons-and-text": "",
                    "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                    "next-icon": "fa-arrow-circle-right",
                    "prev-icon": "fa-arrow-circle-left"
                  },
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      key: _vm.detailsTab.key,
                      ref: "tab",
                      attrs: {
                        "data-cy": "details",
                        href: "#tab-" + _vm.detailsTab.key
                      },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.detailsTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.detailsTab.tabname) +
                          "\n          "
                      ),
                      _c("v-icon", [_vm._v("fas fa-edit")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.workflowTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.workflowTab.key,
                          attrs: {
                            "data-cy": "workflow",
                            href: "#tab-" + _vm.workflowTab.key
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.workflowTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.workflowTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-arrows-turn-to-dots")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                      staticStyle: { position: "relative" },
                                      attrs: { text: "" }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.more")) +
                                      "\n\n              "
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-icon", [
                                        _vm._v("fas fa-ellipsis-h")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { staticClass: "grey lighten-3" },
                        _vm._l(_vm.hiddenTabDefinitions, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.tabname,
                              on: {
                                click: function($event) {
                                  return _vm.showNewTabFromMoreMenu(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.tabname) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.detailsTab.key,
                      attrs: { value: "tab-" + _vm.detailsTab.key }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-name"
                                      ),
                                      disabled: _vm.processing,
                                      rules: [_vm.rules.required]
                                    },
                                    model: {
                                      value: _vm.environment.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.environment, "name", $$v)
                                      },
                                      expression: "environment.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.workflowTab.key,
                      attrs: { value: "tab-" + _vm.workflowTab.key, eager: "" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "workflowform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2 pt-3" },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.requests-require-walkdown"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldRequestRequiresWalkDown,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldRequestRequiresWalkDown",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldRequestRequiresWalkDown"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveScaffoldRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveScaffoldRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveScaffoldRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-count-sheet-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveCountSheets,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveCountSheets",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveCountSheets"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-from-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetFromYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetFromYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetFromYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetFromYardID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-to-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetToYardID,
                                      hint: _vm.$t(
                                        "configuration.workflow.default-to-yard-hint"
                                      ),
                                      "persistent-hint":
                                        _vm.environment
                                          .automaticallyApproveCountSheets &&
                                        !_vm.environment
                                          .defaultCountSheetToYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetToYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetToYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetToYardID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mr-0",
                                  attrs: { type: _vm.inlineMessage.type }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanConfigureSettings
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }