import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations } from "vuex";
import workPackageModel from "../dataMixins/workPackageModel";
import { VForm } from "@fd/lib/vue/types";
import store from "../store";
import { isoDateString } from "@fd/lib/client-util/datetime";
import { workPackageService } from "../services";

export default FDVue.extend({
  name: "fd-work-package-Existing",

  mixins: [errorHandling, workPackageModel],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-date-picker": () => import("@fd/lib/vue/components/DatePicker.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: "IWPs",
          disabled: false,
          to: "/iwps"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ],
      slidein: false,

      statusItems: [] as string[]
    };
  },

  computed: {},

  methods: {
    async loadWorkPackage(workPackageID: string) {
      this.workPackageID = workPackageID;
      await store.dispatch("LOAD_WORK_PACKAGE", workPackageID);
      let workPackage = this.allWorkPackages.find(x => x.id == workPackageID);
      if (workPackage) {
        this.workPackage = {
          ...workPackage,
          id: undefined,
          archived: !!workPackage.archivedDate,
          startDate: isoDateString(workPackage.startDate),
          finishDate: isoDateString(workPackage.finishDate)
        } as any; // Typing needed
      } else {
        // TODO: Should we raise an error here?
      }
    },
    async saveWorkPackage() {
      if (this.workPackage.completionPercentage)
        this.workPackage.completionPercentage = +this.workPackage.completionPercentage!;

      if (!this.workPackage.archived) {
        this.workPackage.archivedDate = null;
      } else if (this.workPackage.archived && !this.workPackage.archivedDate) {
        this.workPackage.archivedDate = new Date(new Date().toUTCString());
      }

      await store.dispatch("UPDATE_WORK_PACKAGE", {
        ...this.workPackage,
        id: this.workPackageID
      });
    },
    async deleteWorkPackage() {
      await store.dispatch("DELETE_WORK_PACKAGE", {
        id: this.workPackageID,
        name: this.workPackage.name
      });
    },
    // Method used in conjunction with the Save button.
    async save() {
      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      this.saving = true;
      try {
        await this.saveWorkPackage();
        this.$router.push("/iwps");
      } finally {
        this.saving = false;
      }
    },
    async deleteItem() {
      await this.deleteWorkPackage();
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/iwps");
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/iwps");
    },
    ...mapMutations({
      setWorkPackage: "SET_WORK_PACKAGE",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  watch: {
    workPackage(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/iwps") {
        this.notifyNewBreadcrumb({
          text: "IWPs",
          to: "/iwps",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/iwps/${this.$route.params.id}`
      });
    },
    processing(val) {
      console.log(`processing changed to ${val}`);
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    console.log("start processing");
    this.processing = true;
    try {
      console.log("load status items");
      this.statusItems = await workPackageService.getUsedWorkStatuses();
      console.log("load work package details");
      this.loadWorkPackage(this.$route.params.id);
      console.log("load work package details - DONE");
    } catch (error) {
      this.handleError(error);
    } finally {
      console.log("stop processing");
      this.processing = false;
    }
  }
});
