var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "", disabled: !_vm.showTooltip },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-simple-checkbox",
                    _vm._g(
                      {
                        class: { "d-none": _vm.chipsVisible },
                        attrs: {
                          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
                          value: _vm.value,
                          loading: _vm.loading,
                          disabled: _vm.readonly || _vm.disabled
                        },
                        on: {
                          input: function(v) {
                            return _vm.$emit("input", v)
                          }
                        }
                      },
                      on
                    )
                  ),
                  _vm._v(" "),
                  _c(
                    "v-chip",
                    _vm._g(
                      {
                        class: { "d-none": !(_vm.chipsVisible && _vm.value) },
                        attrs: {
                          color: _vm.onColor,
                          "text-color": _vm.onTextColor
                        }
                      },
                      on
                    ),
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(_vm.onTextKey)) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-chip",
                    _vm._g(
                      {
                        class: { "d-none": !(_vm.chipsVisible && !_vm.value) },
                        attrs: {
                          color: _vm.offColor,
                          "text-color": _vm.offTextColor
                        }
                      },
                      on
                    ),
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            !!_vm.offText ? _vm.offText : _vm.$t(_vm.offTextKey)
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm.showActiveTooltip
            ? _c("span", [_vm._v(_vm._s(_vm.tooltip))])
            : _vm._e(),
          _vm._v(" "),
          _vm.showInactiveTooltip
            ? _c("span", [_vm._v(_vm._s(_vm.inactiveTooltip))])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }