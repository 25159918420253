// GENERATED CODE - DO NOT EDIT
import { executeServiceRequest, executeUploadServiceRequest } from "@fd/lib/client-util/serviceRequest";
export const contractorService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ContractorWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: ContractorWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: ContractorWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.DeleteItem", {
        id,
      }
    );
  },
  getByLegacyID(
    id: number,
  ): Promise<ContractorWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetByLegacyID", {
        id,
      }
    );
  },
  getByName(
    name: string,
  ): Promise<ContractorWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetByName", {
        name,
      }
    );
  },
}
export const countSheetGroupService = {
  getByID(
    id: string,
  ): Promise<CountSheetGroupWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetGroupWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetGroupWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: CountSheetGroupWithParts,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.AddItem", {
        clientData,
      }
    );
  },
  updateCountSheetGroupParts(
    countSheetGroupID: string,
    parts: Array<Part>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateCountSheetGroupParts", {
        countSheetGroupID,
        parts,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: CountSheetGroupWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  updateCountSheetGroupOrders(
    groups: Array<CountSheetGroupWithParts>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateCountSheetGroupOrders", {
        groups,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.DeleteItem", {
        id,
      }
    );
  },
}
export const countSheetService = {
  getAllApproved(
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetAllApproved", {
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllPendingOrDeclined(
  ): Promise<Array<CountSheetWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetAllPendingOrDeclined", {
      }
    );
  },
  getByScaffoldID(
    scaffoldID: string,
  ): Promise<Array<CountSheetWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByScaffoldID", {
        scaffoldID,
      }
    );
  },
  getByID(
    countSheetID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByID", {
        countSheetID,
      }
    );
  },
  getByWorkOrderID(
    workOrderID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByWorkOrderID", {
        workOrderID,
      }
    );
  },
  approvePendingCountSheet(
    countSheetID: string,
    fromYardID: string | null,
    toYardID: string | null,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.ApprovePendingCountSheet", {
        countSheetID,
        fromYardID,
        toYardID,
      }
    );
  },
  declinePendingCountSheet(
    countSheetID: string,
    declineReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.DeclinePendingCountSheet", {
        countSheetID,
        declineReason,
      }
    );
  },
  cancelPendingCountSheet(
    countSheetID: string,
    cancelReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.CancelPendingCountSheet", {
        countSheetID,
        cancelReason,
      }
    );
  },
  saveCountSheet(
    countSheetID: string,
    data: CountSheetWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.SaveCountSheet", {
        countSheetID,
        data,
      }
    );
  },
  submitCountSheet(
    countSheetID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.SubmitCountSheet", {
        countSheetID,
      }
    );
  },
}
export const dataReplicationService = {
  synchronizeMissingSP1ScaffoldData(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.SynchronizeMissingSP1ScaffoldData", {
      }
    );
  },
  synchronizeMissingSP1WorkOrderData(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.SynchronizeMissingSP1WorkOrderData", {
      }
    );
  },
  replicateSP1WalkdownReferenceData(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1WalkdownReferenceData", {
      }
    );
  },
  replicateSP1Data(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1Data", {
      }
    );
  },
  replicateSP1PersonData(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1PersonData", {
      }
    );
  },
  replicateSP1LEMs(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1LEMs", {
      }
    );
  },
  replicateSP1Transfers(
    forceLoadAll: boolean,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1Transfers", {
        forceLoadAll,
      }
    );
  },
  replicateSP1DropoffLocations(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1DropoffLocations", {
      }
    );
  },
  replicateSP1Yards(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1Yards", {
      }
    );
  },
  replicateSP1CountSheetGroups(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1CountSheetGroups", {
      }
    );
  },
  replicateSP1Articles(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DataReplicationService.ReplicateSP1Articles", {
      }
    );
  },
}
export const disciplineService = {
  getByLegacyID(
    id: number,
  ): Promise<Discipline> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByLegacyID", {
        id,
      }
    );
  },
  getByName(
    name: string,
  ): Promise<Discipline> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByName", {
        name,
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByPersonID", {
        personID,
      }
    );
  },
  getAll(
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Discipline> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Discipline,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Discipline,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.DeleteItem", {
        id,
      }
    );
  },
}
export const dropoffLocationService = {
  getByLegacyID(
    id: number,
  ): Promise<DropoffLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetByLegacyID", {
        id,
      }
    );
  },
  getByName(
    name: string,
  ): Promise<DropoffLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetByName", {
        name,
      }
    );
  },
  addItem(
    clientData: DropoffLocation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: DropoffLocation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.DeleteItem", {
        id,
      }
    );
  },
  updateDropoffLocationOrders(
    dropoffLocations: Array<DropoffLocation>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UpdateDropoffLocationOrders", {
        dropoffLocations,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<DropoffLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<DropoffLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<DropoffLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const estimateService = {
  getAll(
  ): Promise<Array<EstimateWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<EstimateWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: EstimateWithParts,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: EstimateWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateService.DeleteItem", {
        id,
      }
    );
  },
}
export const externalLinkService = {
  getByPersonID(
    personId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByPersonID", {
        personId,
      }
    );
  },
  getPersonExternalLinksByPersonID(
    personIDs: Array<string>,
  ): Promise<{ [key: string]: Array<ExternalLink> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetPersonExternalLinksByPersonID", {
        personIDs,
      }
    );
  },
  getByScaffoldRequestID(
    scaffoldRequestId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByScaffoldRequestID", {
        scaffoldRequestId,
      }
    );
  },
  getScaffoldRequestExternalLinksByScaffoldRequestID(
    scaffoldRequestIDs: Array<string>,
  ): Promise<{ [key: string]: Array<ExternalLink> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetScaffoldRequestExternalLinksByScaffoldRequestID", {
        scaffoldRequestIDs,
      }
    );
  },
  addItem(
    clientData: ExternalLink,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ExternalLink,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.DeleteItem", {
        id,
      }
    );
  },
}
export const legacyArticleService = {
  validateArticleList(
    templateIdentifierColumnName: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    countSheetGroupIDTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyArticleService.ValidateArticleList", requestBody, {
        templateIdentifierColumnName,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        rentalRateTemplateColumnName,
        countSheetGroupIDTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
      }
    );
  },
  uploadArticleList(
    templateIdentifierColumnName: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    countSheetGroupIDTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyArticleService.UploadArticleList", requestBody, {
        templateIdentifierColumnName,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        rentalRateTemplateColumnName,
        countSheetGroupIDTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
      }
    );
  },
  getAll(
  ): Promise<Array<LegacyArticle>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyArticleService.GetAll", {
      }
    );
  },
}
export const legacyFIWPService = {
  validateFIWPList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyFIWPService.ValidateFIWPList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  uploadFIWPList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyFIWPService.UploadFIWPList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  downloadFIWPListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyFIWPService.DownloadFIWPListTemplate", {
      }
    );
  },
  downloadFIWPList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyFIWPService.DownloadFIWPList", {
      }
    );
  },
  getAll(
  ): Promise<Array<LegacyFIWP>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyFIWPService.GetAll", {
      }
    );
  },
}
export const legacyReferenceDataService = {
  getSchedulerRequestStatuses(
  ): Promise<Array<LegacyRequestStatus>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetSchedulerRequestStatuses", {
      }
    );
  },
  getAllRequestStatuses(
  ): Promise<Array<LegacyRequestStatus>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllRequestStatuses", {
      }
    );
  },
  getAllRequestTypes(
  ): Promise<Array<LegacyRequestType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllRequestTypes", {
      }
    );
  },
  getAllRequestSubTypes(
  ): Promise<Array<LegacyRequestSubType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllRequestSubTypes", {
      }
    );
  },
  getAllScaffoldStatuses(
  ): Promise<Array<LegacyScaffoldStatus>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldStatuses", {
      }
    );
  },
  getAllContractors(
  ): Promise<Array<LegacyContractor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllContractors", {
      }
    );
  },
  getScaffoldContractors(
  ): Promise<Array<LegacyContractorWithChildIDs>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetScaffoldContractors", {
      }
    );
  },
  getScaffoldRequestingContractors(
  ): Promise<Array<LegacyContractorWithChildIDs>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetScaffoldRequestingContractors", {
      }
    );
  },
  getAllDisciplines(
  ): Promise<Array<LegacyDisciplineWithEmployeeIDs>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllDisciplines", {
      }
    );
  },
  getAllRequestors(
  ): Promise<Array<LegacyEmployee>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllRequestors", {
      }
    );
  },
  getAllCoordinators(
  ): Promise<Array<LegacyEmployee>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllCoordinators", {
      }
    );
  },
  getAllGeneralForemen(
  ): Promise<Array<LegacyEmployee>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllGeneralForemen", {
      }
    );
  },
  getAllForemen(
  ): Promise<Array<LegacyEmployee>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllForemen", {
      }
    );
  },
  getAllAreas(
  ): Promise<Array<LegacyArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllAreas", {
      }
    );
  },
  getVisibleAreas(
  ): Promise<Array<LegacyArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetVisibleAreas", {
      }
    );
  },
  getAllAreasForEmployee(
    employeeID: number | null,
  ): Promise<Array<LegacyArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllAreasForEmployee", {
        employeeID,
      }
    );
  },
  getAreasForContractor(
    contractorID: number,
  ): Promise<Array<LegacyArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAreasForContractor", {
        contractorID,
      }
    );
  },
  getAllSubAreas(
  ): Promise<Array<LegacySubArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllSubAreas", {
      }
    );
  },
  getVisibleSubAreas(
  ): Promise<Array<LegacySubArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetVisibleSubAreas", {
      }
    );
  },
  getAllSubAreasForEmployee(
    employeeID: number | null,
  ): Promise<Array<LegacySubArea>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllSubAreasForEmployee", {
        employeeID,
      }
    );
  },
  getAllScaffoldBayLengths(
  ): Promise<Array<LegacyScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldBayLengths", {
      }
    );
  },
  getAllScaffoldBayWidths(
  ): Promise<Array<LegacyScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldBayWidths", {
      }
    );
  },
  getAllScaffoldBayHeights(
  ): Promise<Array<LegacyScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldBayHeights", {
      }
    );
  },
  getScaffoldDefaults(
  ): Promise<LegacyScaffoldDefaults> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.getScaffoldDefaults", {
      }
    );
  },
  getAllScaffoldTypes(
  ): Promise<Array<LegacyScaffoldType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldTypes", {
      }
    );
  },
  getAllScaffoldDistancees(
  ): Promise<Array<LegacyScaffoldDistance>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldDistancees", {
      }
    );
  },
  getAllScaffoldElevations(
  ): Promise<Array<LegacyScaffoldElevation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldElevations", {
      }
    );
  },
  getAllScaffoldHeights(
  ): Promise<Array<LegacyScaffoldHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldHeights", {
      }
    );
  },
  getAllBuildDismantleRatios(
  ): Promise<Array<LegacyBuildDismantleRatio>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllBuildDismantleRatios", {
      }
    );
  },
  getAllScaffoldCongestionFactors(
  ): Promise<Array<LegacyScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllScaffoldCongestionFactors", {
      }
    );
  },
  getAllInternalModifiers(
  ): Promise<Array<LegacyInternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllInternalModifiers", {
      }
    );
  },
  getAllHoardingModifiers(
  ): Promise<Array<LegacyHoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyReferenceDataService.GetAllHoardingModifiers", {
      }
    );
  },
}
export const legacyScaffoldService = {
  searchAll(
    searchString: string,
    includeInactiveScaffolds: boolean,
  ): Promise<Array<LegacyScaffoldSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyScaffoldService.SearchAll", {
        searchString,
        includeInactiveScaffolds,
      }
    );
  },
}
export const legacyWorkOrderService = {
  getDefaultImportSettings(
  ): Promise<LegacyWorkOrderImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.GetDefaultImportSettings", {
      }
    );
  },
  validateWorkOrderList(
    friendlyIdentifierColumnNameOverride: string,
    workOrderNumberTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    requiredDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.ValidateWorkOrderList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        workOrderNumberTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        requiredDateTemplateColumnNameOverride,
      }
    );
  },
  uploadWorkOrderList(
    friendlyIdentifierColumnNameOverride: string,
    workOrderNumberTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    requiredDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.UploadWorkOrderList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        workOrderNumberTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        requiredDateTemplateColumnNameOverride,
      }
    );
  },
  downloadWorkOrderListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.DownloadWorkOrderListTemplate", {
      }
    );
  },
  downloadWorkOrderList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.DownloadWorkOrderList", {
      }
    );
  },
  getAll(
  ): Promise<Array<LegacyWorkOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LegacyWorkOrderService.GetAll", {
      }
    );
  },
}
export const locationFilterService = {
  getPeopleWithFilters(
  ): Promise<Array<PersonWithLocations>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.GetPeopleWithFilters", {
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<LocationFilterWithLocations> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.GetByPersonID", {
        personID,
      }
    );
  },
  peopleWithMatchingFilter(
    personID: string,
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.PeopleWithMatchingFilter", {
        personID,
      }
    );
  },
  setLocationsInFilterForPersonID(
    projectLocationIDs: Array<string>,
    personID: string,
    additionalModifyPersonIDs: Array<string>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.SetLocationsInFilterForPersonID", {
        projectLocationIDs,
        personID,
        additionalModifyPersonIDs,
      }
    );
  },
  addLocationToFilterForPersonID(
    projectLocationID: string,
    personID: string,
    additionalModifyPersonIDs: Array<string>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.AddLocationToFilterForPersonID", {
        projectLocationID,
        personID,
        additionalModifyPersonIDs,
      }
    );
  },
}
export const loginService = {
  getLoginChallenge(
    emailAddress: string,
    phoneNumber: string,
    personalIdentifier: string,
  ): Promise<LoginChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetLoginChallenge", {
        emailAddress,
        phoneNumber,
        personalIdentifier,
      }
    );
  },
  respondToLoginChallenge(
    loginContext: Uint8Array,
    publicHash: Uint8Array,
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.RespondToLoginChallenge", {
        loginContext,
        publicHash,
      }
    );
  },
  getCurrentLoginInformation(
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentLoginInformation", {
      }
    );
  },
  logout(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.Logout", {
      }
    );
  },
  getCurrentUserAccessInformation(
  ): Promise<AccessInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentUserAccessInformation", {
      }
    );
  },
  sendNewAccountNotice(
    baseUrl: string,
    personID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.SendNewAccountNotice", {
        baseUrl,
        personID,
      }
    );
  },
  updateUserPassword(
    publicSalt: Uint8Array,
    publicHash: Uint8Array,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.UpdateUserPassword", {
        publicSalt,
        publicHash,
      }
    );
  },
}
export const messageService = {
  getForScaffoldRequest(
    scaffoldRequestID: string,
  ): Promise<Array<MessageWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.GetForScaffoldRequest", {
        scaffoldRequestID,
      }
    );
  },
  getForWorkOrder(
    workOrderID: string,
  ): Promise<Array<MessageWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.GetForWorkOrder", {
        workOrderID,
      }
    );
  },
  addNewMessageForScaffoldRequest(
    messageText: string,
    scaffoldRequestID: string,
  ): Promise<MessageWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.AddNewMessageForScaffoldRequest", {
        messageText,
        scaffoldRequestID,
      }
    );
  },
  addNewMessageForWorkOrder(
    messageText: string,
    workOrderID: string,
  ): Promise<MessageWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.AddNewMessageForWorkOrder", {
        messageText,
        workOrderID,
      }
    );
  },
}
export const noteService = {
  getForScaffoldRequest(
    scaffoldRequestID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForScaffoldRequest", {
        scaffoldRequestID,
      }
    );
  },
  getForWorkOrder(
    workOrderID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForWorkOrder", {
        workOrderID,
      }
    );
  },
  addNewNoteForScaffoldRequest(
    noteText: string,
    scaffoldRequestID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForScaffoldRequest", {
        noteText,
        scaffoldRequestID,
      }
    );
  },
  addNewNoteForWorkOrder(
    noteText: string,
    workOrderID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForWorkOrder", {
        noteText,
        workOrderID,
      }
    );
  },
}
export const notificationService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<NotificationWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetByID", {
        id,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.DeleteItem", {
        id,
      }
    );
  },
  addItem(
    data: Notification,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Notification,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  getCurrentUserActiveNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserActiveNotifications", {
      }
    );
  },
  getCurrentUserUnreadNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserUnreadNotifications", {
      }
    );
  },
  markNotificationWithReadStatus(
    notificationID: string,
    readStatus: boolean,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.MarkNotificationWithReadStatus", {
        notificationID,
        readStatus,
      }
    );
  },
  sendTestNotificationToLoggedInPerson(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.SendTestNotificationToLoggedInPerson", {
      }
    );
  },
}
export const ownerService = {
  getAll(
  ): Promise<Array<Owner>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Owner> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Owner,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Owner,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.DeleteItem", {
        id,
      }
    );
  },
}
export const partService = {
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByCountSheetGroupID(
    countSheetGroupID: string,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetByCountSheetGroupID", {
        countSheetGroupID,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PartWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PartWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PartWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DeleteItem", {
        id,
      }
    );
  },
  getAllPartYardBalances(
  ): Promise<Array<YardTotal>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAllPartYardBalances", {
      }
    );
  },
  getDefaultImportSettings(
  ): Promise<PartsImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetDefaultImportSettings", {
      }
    );
  },
  validatePartList(
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.ValidatePartList", requestBody, {
      }
    );
  },
  validateCustomizedPartList(
    friendlyIdentifierColumnName: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    longDescriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    lashingMPPTemplateColumnName: string,
    cleatingMPPTemplateColumnName: string,
    carpentryMPPTemplateColumnName: string,
    otherMPPTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    tagsTemplateColumnName: string,
    suppliersTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.ValidateCustomizedPartList", requestBody, {
        friendlyIdentifierColumnName,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        longDescriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        lashingMPPTemplateColumnName,
        cleatingMPPTemplateColumnName,
        carpentryMPPTemplateColumnName,
        otherMPPTemplateColumnName,
        rentalRateTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
        tagsTemplateColumnName,
        suppliersTemplateColumnName,
      }
    );
  },
  uploadPartList(
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadPartList", requestBody, {
      }
    );
  },
  uploadCustomizedPartList(
    friendlyIdentifierColumnName: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    longDescriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    lashingMPPTemplateColumnName: string,
    cleatingMPPTemplateColumnName: string,
    carpentryMPPTemplateColumnName: string,
    otherMPPTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    tagsTemplateColumnName: string,
    suppliersTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadCustomizedPartList", requestBody, {
        friendlyIdentifierColumnName,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        longDescriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        lashingMPPTemplateColumnName,
        cleatingMPPTemplateColumnName,
        carpentryMPPTemplateColumnName,
        otherMPPTemplateColumnName,
        rentalRateTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
        tagsTemplateColumnName,
        suppliersTemplateColumnName,
      }
    );
  },
  uploadPartList_Old(
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadPartList_Old", requestBody, {
      }
    );
  },
  downloadPartListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DownloadPartListTemplate", {
      }
    );
  },
  downloadPartList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DownloadPartList", {
      }
    );
  },
}
export const personService = {
  getAllCoordinators(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllCoordinators", {
      }
    );
  },
  getAllGeneralForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllGeneralForemen", {
      }
    );
  },
  getAllForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllForemen", {
      }
    );
  },
  getVisibleForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetVisibleForemen", {
      }
    );
  },
}
export const projectLocationService = {
  getByProjectID(
    projectID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByProjectID", {
        projectID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByParentLocationID(
    parentLocationID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByParentLocationID", {
        parentLocationID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllAreas", {
      }
    );
  },
  getVisibleAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetVisibleAreas", {
      }
    );
  },
  getAllAreasForPerson(
    personID: string,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllAreasForPerson", {
        personID,
      }
    );
  },
  getAreaByLegacyID(
    id: number,
  ): Promise<ProjectLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAreaByLegacyID", {
        id,
      }
    );
  },
  getAllSubAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllSubAreas", {
      }
    );
  },
  getVisibleSubAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetVisibleSubAreas", {
      }
    );
  },
  getAllSubAreasForPerson(
    personID: string,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllSubAreasForPerson", {
        personID,
      }
    );
  },
  getSubAreaByLegacyID(
    id: number,
  ): Promise<ProjectLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetSubAreaByLegacyID", {
        id,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ProjectLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ProjectLocation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ProjectLocation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.DeleteItem", {
        id,
      }
    );
  },
}
export const projectService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ProjectWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: ProjectWithParts,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: ProjectWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.UpdateItem", {
        id,
        data,
      }
    );
  },
  updateProjectParts(
    projectID: string,
    partIDs: Array<string>,
    included: boolean,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.UpdateProjectParts", {
        projectID,
        partIDs,
        included,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.DeleteItem", {
        id,
      }
    );
  },
}
export const regionService = {
  getAll(
  ): Promise<Array<Region>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Region> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Region,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Region,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.DeleteItem", {
        id,
      }
    );
  },
}
export const requestSubTypeService = {
  getByLegacyID(
    legacyID: number,
  ): Promise<ScaffoldRequestSubTypeWithLegacyID> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RequestSubTypeService.GetByLegacyID", {
        legacyID,
      }
    );
  },
  getByRequestType(
    id: ScaffoldRequestTypes,
  ): Promise<Array<ScaffoldRequestSubTypeWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RequestSubTypeService.GetByRequestType", {
        id,
      }
    );
  },
}
export const requestTypeService = {
  getByLegacyID(
    legacyID: number,
  ): Promise<ScaffoldRequestTypeWithLegacyID> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RequestTypeService.GetByLegacyID", {
        legacyID,
      }
    );
  },
}
export const scaffoldRequestService = {
  getByRequestNumber(
    requestNumber: string,
  ): Promise<ScaffoldRequest> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByRequestNumber", {
        requestNumber,
      }
    );
  },
  getByIDWithDetails(
    scaffoldRequestID: string,
  ): Promise<ScaffoldRequestWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByIDWithDetails", {
        scaffoldRequestID,
      }
    );
  },
  getByLegacyWorkOrderID(
    legacyWorkOrderID: number,
  ): Promise<LegacyWorkOrderWithRequestDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByLegacyWorkOrderID", {
        legacyWorkOrderID,
      }
    );
  },
  getApprovalsWithDetails(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetApprovalsWithDetails", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithDetails(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAllWithDetails", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addScaffoldRequestByLegacyWorkOrder(
    legacyWorkOrder: LegacyWorkOrderWithRequestDetails,
    isBeingSubmitted: boolean,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.AddScaffoldRequestByLegacyWorkOrder", {
        legacyWorkOrder,
        isBeingSubmitted,
      }
    );
  },
  updateScaffoldRequestApproval(
    scaffoldRequestID: string,
    isApproved: boolean,
    contractorID: string | null,
    approvalComments: string,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateScaffoldRequestApproval", {
        scaffoldRequestID,
        isApproved,
        contractorID,
        approvalComments,
      }
    );
  },
  updateScaffoldRequestDetailsByLegacyWorkOrder(
    legacyWorkOrder: LegacyWorkOrderWithRequestDetails,
  ): Promise<LegacyWorkOrderWithRequestDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateScaffoldRequestDetailsByLegacyWorkOrder", {
        legacyWorkOrder,
      }
    );
  },
  updateScaffoldWalkdownByLegacyWorkOrder(
    legacyWorkOrder: LegacyWorkOrderWithRequestDetails,
  ): Promise<LegacyWorkOrderWithRequestDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateScaffoldWalkdownByLegacyWorkOrder", {
        legacyWorkOrder,
      }
    );
  },
  getDismantleWorkOrdersForScaffoldTag(
    tagNumber: string,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetDismantleWorkOrdersForScaffoldTag", {
        tagNumber,
      }
    );
  },
  getSubmittedRequestsForScaffoldTag(
    tagNumber: string,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetSubmittedRequestsForScaffoldTag", {
        tagNumber,
      }
    );
  },
  getDismantleRequestsForScaffoldTag(
    tagNumber: string,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetDismantleRequestsForScaffoldTag", {
        tagNumber,
      }
    );
  },
  cancelScaffoldRequest(
    scaffoldRequestID: string,
    reason: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.CancelScaffoldRequest", {
        scaffoldRequestID,
        reason,
      }
    );
  },
  cancelScaffoldRequestByLegacyWorkOrder(
    legacyWorkOrderID: number,
    reason: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.CancelScaffoldRequestByLegacyWorkOrder", {
        legacyWorkOrderID,
        reason,
      }
    );
  },
  submitScaffoldRequestByLegacyWorkOrder(
    legacyWorkOrderID: number,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.SubmitScaffoldRequestByLegacyWorkOrder", {
        legacyWorkOrderID,
      }
    );
  },
  getScaffoldRequestFileList(
    requestId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetScaffoldRequestFileList", {
        requestId,
      }
    );
  },
  downloadScaffoldRequestFile(
    requestId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile", {
        requestId,
        fileName,
      }
    );
  },
  uploadScaffoldRequestFile(
    requestId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UploadScaffoldRequestFile", requestBody, {
        requestId,
        fileName,
      }
    );
  },
  uploadScaffoldRequestLegacyFile(
    requestId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UploadScaffoldRequestLegacyFile", requestBody, {
        requestId,
        fileName,
      }
    );
  },
  deleteScaffoldRequestFile(
    requestId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteScaffoldRequestFile", {
        requestId,
        fileName,
      }
    );
  },
  deleteAllScaffoldRequestFiles(
    requestId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteAllScaffoldRequestFiles", {
        requestId,
      }
    );
  },
  declineLegacyWorkOrderRequest(
    scaffoldRequestID: string,
    comments: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeclineLegacyWorkOrderRequest", {
        scaffoldRequestID,
        comments,
      }
    );
  },
  approveLegacyWorkOrderRequest(
    scaffoldRequestID: string,
    comments: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.ApproveLegacyWorkOrderRequest", {
        scaffoldRequestID,
        comments,
      }
    );
  },
  assignLegacyWorkOrderToWalkdown(
    scaffoldRequestID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.AssignLegacyWorkOrderToWalkdown", {
        scaffoldRequestID,
      }
    );
  },
  declineLegacyWorkOrderWalkdown(
    scaffoldRequestID: string,
    comments: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeclineLegacyWorkOrderWalkdown", {
        scaffoldRequestID,
        comments,
      }
    );
  },
  approveLegacyWorkOrderWalkdown(
    scaffoldRequestID: string,
    comments: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.ApproveLegacyWorkOrderWalkdown", {
        scaffoldRequestID,
        comments,
      }
    );
  },
  populateMissingApprovedRequiredDates(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.PopulateMissingApprovedRequiredDates", {
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequest>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequest>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ScaffoldRequest> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ScaffoldRequest,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldRequest,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldRequestSubTypeHelper = {
  getAllSelectableRequestSubTypes(
  ): Promise<Array<ScaffoldRequestSubTypeWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllSelectableRequestSubTypes", {
      }
    );
  },
  getAllRequestSubTypesWithLegacyID(
  ): Promise<Array<ScaffoldRequestSubTypeWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllRequestSubTypesWithLegacyID", {
      }
    );
  },
}
export const scaffoldRequestTypeHelper = {
  getAllSelectableRequestTypes(
  ): Promise<Array<ScaffoldRequestTypeWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllSelectableRequestTypes", {
      }
    );
  },
  getAllRequestTypesWithLegacyID(
  ): Promise<Array<ScaffoldRequestTypeWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllRequestTypesWithLegacyID", {
      }
    );
  },
}
export const scaffoldService = {
  getAllScaffolds(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetAllScaffolds", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getStandingPartCountsForScaffoldID(
    scaffoldID: string,
    minTransferDate: Date | null,
    maxTransferDate: Date | null,
  ): Promise<Array<PartWithTotal>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetStandingPartCountsForScaffoldID", {
        scaffoldID,
        minTransferDate,
        maxTransferDate,
      }
    );
  },
  getByID(
    scaffoldID: string,
  ): Promise<ScaffoldWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetByID", {
        scaffoldID,
      }
    );
  },
  getByIDWithOptions(
    scaffoldID: string,
    includeWorkOrderData: boolean,
  ): Promise<ScaffoldWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetByIDWithOptions", {
        scaffoldID,
        includeWorkOrderData,
      }
    );
  },
  searchAll(
    searchString: string,
    includeInactiveScaffolds: boolean,
  ): Promise<Array<ScaffoldSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.SearchAll", {
        searchString,
        includeInactiveScaffolds,
      }
    );
  },
}
export const searchService = {
  globalSearch(
    searchString: string,
    tagIDs: Array<string>,
  ): Promise<Array<SearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SearchService.GlobalSearch", {
        searchString,
        tagIDs,
      }
    );
  },
}
export const statisticsService = {
  getRequestsNeedingApprovalCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetRequestsNeedingApprovalCount", {
      }
    );
  },
  getWalkdownsToCompleteCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetWalkdownsToCompleteCount", {
      }
    );
  },
  getEstimatesToCompleteCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetEstimatesToCompleteCount", {
      }
    );
  },
}
export const supplierService = {
  getAll(
  ): Promise<Array<SupplierWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<SupplierWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: SupplierWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: SupplierWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.DeleteItem", {
        id,
      }
    );
  },
}
export const testService = {
  testSimpleMethod(
    input: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestSimpleMethod", {
        input,
      }
    );
  },
  testFailedMethod(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestFailedMethod", {
      }
    );
  },
  testServiceMethod(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestServiceMethod", {
      }
    );
  },
  testAuthentication(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestAuthentication", {
      }
    );
  },
}
export const transferService = {
  overrideTransferDetails(
    transferID: string,
    fromYardID: string | null | undefined,
    toYardID: string | null | undefined,
    transferDate: Date | undefined,
    transactionNumber: string | undefined,
    parts: Array<TransferPartDetails> | undefined,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.OverrideTransferDetails", {
        transferID,
        fromYardID,
        toYardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  transferPartsBetweenYards(
    fromYardID: string,
    toYardID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.TransferPartsBetweenYards", {
        fromYardID,
        toYardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  deliverPartsToScaffold(
    yardID: string,
    scaffoldID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.DeliverPartsToScaffold", {
        yardID,
        scaffoldID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  returnPartsToYard(
    scaffoldID: string,
    yardID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.ReturnPartsToYard", {
        scaffoldID,
        yardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  transferPartsBetweenScaffolds(
    sourceScaffoldID: string,
    targetScaffoldID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.TransferPartsBetweenScaffolds", {
        sourceScaffoldID,
        targetScaffoldID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  mergeScaffolds(
    sourceScaffoldID: string,
    targetScaffoldID: string,
    transferDate: Date,
    transactionNumber: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.MergeScaffolds", {
        sourceScaffoldID,
        targetScaffoldID,
        transferDate,
        transactionNumber,
      }
    );
  },
  getByID(
    transferID: string,
  ): Promise<TransferWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetByID", {
        transferID,
      }
    );
  },
  getAll(
    fromDate: Date | null,
    toDate: Date | null,
  ): Promise<Array<TransferWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetAll", {
        fromDate,
        toDate,
      }
    );
  },
}
export const userService = {
  getByLegacyID(
    id: number,
  ): Promise<Person> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByLegacyID", {
        id,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithIncludes(
    includeChildren: boolean,
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAllWithIncludes", {
        includeChildren,
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getCurrent(
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrent", {
      }
    );
  },
  getCurrentUserFullName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrentUserFullName", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByID", {
        id,
      }
    );
  },
  getByIDWithIncludes(
    id: string,
    includeChildren: boolean,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByIDWithIncludes", {
        id,
        includeChildren,
      }
    );
  },
  addItem(
    data: PersonWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PersonWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteItem", {
        id,
      }
    );
  },
  downloadUserPhoto(
    id: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserPhoto", {
        id,
      }
    );
  },
  uploadUserPhoto(
    personId: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserPhoto", requestBody, {
        personId,
      }
    );
  },
  deleteUserPhoto(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserPhoto", {
        personId,
      }
    );
  },
  getUserFileList(
    personId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetUserFileList", {
        personId,
      }
    );
  },
  downloadUserFile(
    personId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserFile", {
        personId,
        fileName,
      }
    );
  },
  uploadUserFile(
    personId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserFile", requestBody, {
        personId,
        fileName,
      }
    );
  },
  deleteUserFile(
    personId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserFile", {
        personId,
        fileName,
      }
    );
  },
  deleteAllUserFiles(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteAllUserFiles", {
        personId,
      }
    );
  },
}
export const walkdownReferenceDataService = {
  getAllScaffoldBayLengths(
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayLengths", {
      }
    );
  },
  getAllScaffoldBayWidths(
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayWidths", {
      }
    );
  },
  getAllScaffoldBayHeights(
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayHeights", {
      }
    );
  },
  getAllScaffoldTypeModifiers(
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldTypeModifiers", {
      }
    );
  },
  getAllScaffoldDistanceModifiers(
  ): Promise<Array<ScaffoldDistanceModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldDistanceModifiers", {
      }
    );
  },
  getAllScaffoldElevationModifiers(
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldElevationModifiers", {
      }
    );
  },
  getAllScaffoldHeightModifiers(
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldHeightModifiers", {
      }
    );
  },
  getAllBuildDismantleRatios(
  ): Promise<Array<BuildDismantleRatio>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllBuildDismantleRatios", {
      }
    );
  },
  getAllScaffoldCongestionFactors(
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldCongestionFactors", {
      }
    );
  },
  getAllInternalModifiers(
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllInternalModifiers", {
      }
    );
  },
  getAllHoardingModifiers(
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllHoardingModifiers", {
      }
    );
  },
}
export const walkdownService = {
  startNewWalkdown(
    workOrderId: string,
  ): Promise<Walkdown> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownService.StartNewWalkdown", {
        workOrderId,
      }
    );
  },
  updateItem(
    walkdownId: string,
    data: Walkdown,
  ): Promise<Walkdown> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownService.UpdateItem", {
        walkdownId,
        data,
      }
    );
  },
}
export const workOrderService = {
  updateItem(
    workOrderId: string,
    data: WorkOrderWithLegacyDetails,
  ): Promise<WorkOrder> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.UpdateItem", {
        workOrderId,
        data,
      }
    );
  },
  getWorkOrderByID(
    workOrderID: string,
  ): Promise<WorkOrderWithLegacyDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrderByID", {
        workOrderID,
      }
    );
  },
  getWorkOrdersForTodoListForForeman(
    foremanID: string,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersForTodoListForForeman", {
        foremanID,
      }
    );
  },
  getWorkOrdersToEstimate(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersToEstimate", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrderHistory(
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
    assignedForemanID: string | null,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrderHistory", {
        archivedFromDate,
        archivedToDate,
        assignedForemanID,
      }
    );
  },
  getWorkOrdersRequiringAdminWork(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersRequiringAdminWork", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrdersToSchedule(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersToSchedule", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByScaffoldID(
    scaffoldID: string,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetByScaffoldID", {
        scaffoldID,
      }
    );
  },
  getDismantleWorkOrdersForScaffoldTag(
    scaffoldID: string | null,
    tagNumber: string,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetDismantleWorkOrdersForScaffoldTag", {
        scaffoldID,
        tagNumber,
      }
    );
  },
  getActiveWorkOrdersForScaffoldTag(
    scaffoldID: string | null,
    tagNumber: string,
  ): Promise<Array<WorkOrderWithLegacyDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetActiveWorkOrdersForScaffoldTag", {
        scaffoldID,
        tagNumber,
      }
    );
  },
  searchActiveByLegacyID(
    workOrderLegacyIDearchString: string | undefined,
    scaffoldIDearchString: string | undefined,
  ): Promise<Array<WorkOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.SearchActiveByLegacyID", {
        workOrderLegacyIDearchString,
        scaffoldIDearchString,
      }
    );
  },
}
export const workOrderStatusHelper = {
  getApprovalsSelectableScaffoldRequestStatuses(
  ): Promise<Array<ScaffoldRequestStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetApprovalsSelectableScaffoldRequestStatuses", {
      }
    );
  },
  getWorkingSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetWorkingSelectableWorkOrderStatuses", {
      }
    );
  },
  getRequiresAdminSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetRequiresAdminSelectableWorkOrderStatuses", {
      }
    );
  },
  getHistorySelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetHistorySelectableWorkOrderStatuses", {
      }
    );
  },
  getEstimatesSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetEstimatesSelectableWorkOrderStatuses", {
      }
    );
  },
  getSchedulerSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetSchedulerSelectableWorkOrderStatuses", {
      }
    );
  },
  getToDoListSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetToDoListSelectableWorkOrderStatuses", {
      }
    );
  },
  getAllWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusWithLegacyID>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetAllWorkOrderStatuses", {
      }
    );
  },
}
export const workPackageService = {
  getAllWithStatus(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
    statuses: Array<string>,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAllWithStatus", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
        statuses,
      }
    );
  },
  getUsedWorkStatuses(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetUsedWorkStatuses", {
      }
    );
  },
  searchAll(
    searchString: string,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.SearchAll", {
        searchString,
      }
    );
  },
  getDefaultImportSettings(
  ): Promise<WorkPackagesImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetDefaultImportSettings", {
      }
    );
  },
  validateWorkPackageList(
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ValidateWorkPackageList", requestBody, {
      }
    );
  },
  validateCustomizedWorkPackageList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ValidateCustomizedWorkPackageList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  uploadWorkPackageList(
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UploadWorkPackageList", requestBody, {
      }
    );
  },
  uploadCustomizedWorkPackageList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UploadCustomizedWorkPackageList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  downloadWorkPackageListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DownloadWorkPackageListTemplate", {
      }
    );
  },
  downloadWorkPackageList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DownloadWorkPackageList", {
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<WorkPackage> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: WorkPackage,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: WorkPackage,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DeleteItem", {
        id,
      }
    );
  },
}
export const yardService = {
  getByLegacyID(
    id: number,
  ): Promise<Yard> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetByLegacyID", {
        id,
      }
    );
  },
  getByName(
    name: string,
  ): Promise<Yard> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetByName", {
        name,
      }
    );
  },
  addItem(
    clientData: Yard,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Yard,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.DeleteItem", {
        id,
      }
    );
  },
  updateYardOrders(
    yards: Array<Yard>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UpdateYardOrders", {
        yards,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Yard>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Yard>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Yard> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const accessCodeService = {
  validateAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ValidateAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  resendAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ResendAccessCode", {
        accessCodeChallenge,
      }
    );
  },
  verifyEmailAddressOrPhoneNumber(
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyEmailAddressOrPhoneNumber", {
        emailAddress,
        phoneNumber,
      }
    );
  },
  confirmVerifyEmailOrPhoneNumberWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ConfirmVerifyEmailOrPhoneNumberWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  activateUserInformation(
    loginChallenge: LoginChallenge,
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateUserInformation", {
        loginChallenge,
        emailAddress,
        phoneNumber,
      }
    );
  },
  activateAccountWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateAccountWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  generateAccessCode(
    loginChallenge: LoginChallenge,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.GenerateAccessCode", {
        loginChallenge,
      }
    );
  },
  loginWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.LoginWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
}
export const diagnosticService = {
  generateServerErrorCodes(
  ): Promise<{ [key: string]: string }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GenerateServerErrorCodes", {
      }
    );
  },
  getStatus(
  ): Promise<AppStatus> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetStatus", {
      }
    );
  },
  getCurrentVersion(
  ): Promise<Version> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetCurrentVersion", {
      }
    );
  },
}
export const environmentService = {
  getCurrent(
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetCurrent", {
      }
    );
  },
  getAll(
  ): Promise<Array<Environment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Environment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Environment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const languageService = {
  getLanguageCodeForLanguageID(
    id: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetLanguageCodeForLanguageID", {
        id,
      }
    );
  },
  getAll(
  ): Promise<Array<Language>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Language> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Language,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Language,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.DeleteItem", {
        id,
      }
    );
  },
}
export const organizationService = {
  downloadOrgLogo(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OrganizationService.DownloadOrgLogo", {
      }
    );
  },
  uploadOrgLogo(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.OrganizationService.UploadOrgLogo", requestBody, {
      }
    );
  },
  deleteOrgLogo(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OrganizationService.DeleteOrgLogo", {
      }
    );
  },
}
export const personalEmailAddressService = {
  getAll(
  ): Promise<Array<PersonalEmailAddress>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalEmailAddress,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalEmailAddress,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const personalPhoneNumberService = {
  getAll(
  ): Promise<Array<PersonalPhoneNumber>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalPhoneNumber,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalPhoneNumber,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const tagService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByNames(
    tagNames: Array<string>,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByNames", {
        tagNames,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Tag> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: Tag,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Tag,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.DeleteItem", {
        id,
      }
    );
  },
}
export const versionService = {
  getVersion(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.VersionService.GetVersion", {
      }
    );
  },
}
export const reportService = {
  getPlannerPrintoutReportContent(
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    statusFilterValue: string,
    assignedSelected: string,
    unassignedSelected: string,
    onlyMineSelected: string,
    showOnlyToDos: string,
    clientPrintDateTimeString: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetPlannerPrintoutReportContent", {
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        statusFilterValue,
        assignedSelected,
        unassignedSelected,
        onlyMineSelected,
        showOnlyToDos,
        clientPrintDateTimeString,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrderAdminPrintoutReportContentWithData(
    workOrders: Array<WorkOrderWithLegacyDetails>,
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetWorkOrderAdminPrintoutReportContentWithData", {
        workOrders,
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        clientPrintDateTimeString,
      }
    );
  },
  getYardBalancesPrintoutReportContentWithData(
    parts: Array<PartWithYardBalance>,
    yards: Array<Yard>,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetYardBalancesPrintoutReportContentWithData", {
        parts,
        yards,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
  getPlannerPrintoutReportContentWithData(
    workOrders: Array<WorkOrderWithLegacyDetails>,
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    statusFilterValue: string,
    assignedSelected: string,
    unassignedSelected: string,
    onlyMineSelected: string,
    showOnlyToDos: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetPlannerPrintoutReportContentWithData", {
        workOrders,
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        statusFilterValue,
        assignedSelected,
        unassignedSelected,
        onlyMineSelected,
        showOnlyToDos,
        clientPrintDateTimeString,
      }
    );
  },
}
export interface ContractorWithTags {
  tagIDs: Array<string> | undefined
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  tagIDJson: string | undefined
  isScaffoldCompany: boolean | undefined
  canActAsProxy: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface CountSheetGroupWithParts {
  parts: Array<Part> | undefined
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  description: string | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Part {
  id: string | undefined
  legacyID: number | null | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface CountSheetWithParts {
  parts: Array<CountSheetPartWithDetails> | undefined
  workOrder: WorkOrderWithLegacyDetails
  submittedByName: string
  submittedByContractorName: string
  reviewApprovedByName: string
  reviewStatusName: string
  countSheetTypeName: string
  relatedLegacyTransferIDs: Array<string>
  currentUserPermissions: SummarizedCountSheetPermissions
  id: string | undefined
  workOrderID: string | null | undefined
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Discipline {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface DropoffLocation {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  description: string | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EstimateWithParts {
  id: string | undefined
  scaffoldNumber: string | undefined
  estimatedBy: string | undefined
  projectID: string | undefined
  projectLocationID: string | null | undefined
  validFrom: Date | null | undefined
  validTo: Date | null | undefined
  regionID: string | null | undefined
  productivity: number | null | undefined
  labourRate: number | null | undefined
  partCounts: { [key: string]: number } | undefined
}
export interface ExternalLink {
  id: string | undefined
  personID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  name: string | undefined
  address: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BulkImportResults {
  foundRowCount: number | null
  updatedRowCount: number | null
  insertedRowCount: number | null
  successful: boolean
}
export interface LegacyArticle {
  id: number | undefined
  code: string | undefined
  displayCode: string | undefined
  description: string | undefined
  weight: number | null | undefined
  minsPerPc: number | null | undefined
  countSheetGroupID: number | null | undefined
  displayInTemplate: boolean | undefined
  templateDisplayOrder: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyFIWP {
  id: number | undefined
  siteID: number | null | undefined
  code: string | undefined
  name: string | undefined
  startDate: Date | null | undefined
  finishDate: Date | null | undefined
  completionPercentage: number | null | undefined
  packageStatus: string | undefined
  purpose: string | undefined
  plannedStartDate: Date | null | undefined
  plannedFinishDate: Date | null | undefined
  cwpplannedStartDate: Date | null | undefined
  cwpplannedFinishDate: Date | null | undefined
  forecastStartDate: Date | null | undefined
  forecastFinishDate: Date | null | undefined
  discipline: string | undefined
  unit: string | undefined
  constructionWorkArea: string | undefined
  area: string | undefined
  longDescription: string | undefined
  description: string | undefined
  cwp: string | undefined
  estimatedHours: number | null | undefined
  earnedHours: number | null | undefined
  statusDate: Date | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyRequestStatus {
  id: number | undefined
  name: string | undefined
  sortOrder: number | undefined
  status: boolean | undefined
  created: Date | undefined
  updated: Date | null | undefined
}
export interface LegacyRequestType {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  defaultCostCodeID: number | null | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyRequestSubType {
  id: number | undefined
  requisitionTypeID: number | undefined
  name: string | undefined
  sortOrder: number | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldStatus {
  id: number | undefined
  code: string | undefined
  name: string | undefined
  percentage: number | null | undefined
  sortOrder: number | null | undefined
  overallPercentage: number | null | undefined
  status: boolean | undefined
  created: Date | undefined
  updated: Date | null | undefined
}
export interface LegacyContractor {
  id: number | undefined
  name: string | undefined
  code: string | undefined
  isScaffoldCompany: boolean | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyContractorWithChildIDs {
  disciplineIDs: Array<number>
  employeeIDs: Array<number>
  areaIDs: Array<number>
  id: number | undefined
  name: string | undefined
  code: string | undefined
  isScaffoldCompany: boolean | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyDisciplineWithEmployeeIDs {
  employeeIDs: Array<number>
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyEmployee {
  id: number | undefined
  contractorID: number | null | undefined
  crewTypeID: number | null | undefined
  code: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  canRequestScaffolds: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyArea {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacySubArea {
  id: number | undefined
  areaID: number | undefined
  name: string | undefined
  status: boolean | undefined
}
export interface LegacyScaffoldBayLength {
  id: number | undefined
  name: string | undefined
  modifier: number | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldBayWidth {
  id: number | undefined
  name: string | undefined
  modifier: number | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldBayHeight {
  id: number | undefined
  name: string | undefined
  modifier: number | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldDefaults {
  id: number | undefined
  siteID: number | undefined
  typeOfScaffoldModifierID: number | null | undefined
  mobDistanceModifierID: number | null | undefined
  elevFromLaydownModID: number | null | undefined
  scaffoldHeightModID: number | null | undefined
  buildDismantleRatioID: number | null | undefined
  estimatedCrewSize: number | null | undefined
  estimatedWorkDays: number | null | undefined
  nwaid: number | null | undefined
  activityCostCodeID: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldType {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldDistance {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldElevation {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldHeight {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyBuildDismantleRatio {
  id: number | undefined
  ratio: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldCongestionFactor {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyInternalModifier {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyHoardingModifier {
  id: number | undefined
  name: string | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyScaffoldSearchResult {
  areaName: string
  subAreaName: string
  existingRequestNumber: number | null
  id: number | undefined
  siteID: number | null | undefined
  projectID: number | null | undefined
  areaID: number | null | undefined
  location: string | undefined
  scaffoldStatusID: number | undefined
  contractorID: number | null | undefined
  plannedErect: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantle: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface LegacyWorkOrderImportSettings {
  identifyingColumnFriendlyName: string
  workOrderNumber: string
  startDate: string
  requiredDate: string
  columnFriendlyNames: Array<string>
}
export interface LegacyWorkOrder {
  id: number | undefined
  siteID: number | null | undefined
  applyToScaffoldID: number | null | undefined
  requestStatusID: number | undefined
  requestStatusDetails: string | undefined
  completedDate: Date | null | undefined
  requestTypeID: number | null | undefined
  requestSubTypeID: number | null | undefined
  requestingContractorID: number | null | undefined
  disciplineID: number | null | undefined
  requestorID: number | null | undefined
  existingRequestNumber: number | null | undefined
  areaID: number | null | undefined
  subAreaID: number | null | undefined
  approvedComments: string | undefined
  approvedDate: Date | null | undefined
  startDate: Date | null | undefined
  requiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  location: string | undefined
  description: string | undefined
  siteContact: string | undefined
  contactNotes: string | undefined
  notes: string | undefined
  progress: number | null | undefined
  assigneeID: number | null | undefined
  generalForemanID: number | null | undefined
  foremanID: number | null | undefined
  contractorID: number | null | undefined
  walkdownApprovalComments: string | undefined
  walkdownApprovalDate: Date | null | undefined
  walkdownApprovalStatus: boolean | null | undefined
  lastEstimateScaffoldType: number | null | undefined
  lastEstimateLength: number | null | undefined
  lastEstimateBayLengthID: number | null | undefined
  lastEstimateNLB: number | null | undefined
  lastEstimateWidth: number | null | undefined
  lastEstimateBayWidthID: number | null | undefined
  lastEstimateNWB: number | null | undefined
  lastEstimateHeight: number | null | undefined
  lastEstimateBayHeightID: number | null | undefined
  lastEstimateAccessType: number | null | undefined
  lastEstimateBarricadeGates: number | null | undefined
  lastEstimateDeckLevels: number | null | undefined
  elevFromLaydownModID: number | null | undefined
  scaffoldHeightModID: number | null | undefined
  typeOfScaffoldModifierID: number | null | undefined
  mobDistanceModifierID: number | null | undefined
  buildDismantleRatioID: number | null | undefined
  congestionFactorID: number | null | undefined
  internalModifierID: number | null | undefined
  hoardingModifierID: number | null | undefined
  estimatedCrewSize: number | null | undefined
  estimatedWorkDays: number | null | undefined
  modification: number | null | undefined
  priority: number | null | undefined
  isPlanned: number | null | undefined
  isPlanned2: number | null | undefined
  isPlanned3: number | null | undefined
  isUrgentReason: string | undefined
  activityCostCodeID: number | null | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isUpdatingLocked: boolean | undefined
  requestDate: Date | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface PersonWithLocations {
  id: string
  firstName: string
  lastName: string
  areas: Array<AreaWithSubAreas>
}
export interface LocationFilterWithLocations {
  locations: Array<ProjectLocation>
  areas: Array<AreaWithSubAreas>
  id: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Person {
  employeeCode: string | undefined
  legacyID: number | null | undefined
  contractorID: string | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDJson: string | undefined
  includesAllProjects: boolean | undefined
  projectIDJson: string | undefined
  locationFilterID: string | null | undefined
  isPlanner: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  id: string | undefined
  organizationID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface LoginChallenge {
  loginContext: Uint8Array
  publicSalt: Uint8Array
  needsActivation: boolean
}
export interface LoginInformation {
  firstName: string
  lastName: string
  emailAddress: string
  userID: string
  languageID: string | null
}
export interface AccessInformation {
  legacyID: number | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDs: Array<string> | undefined
  contractorLegacyIDs: Array<number> | undefined
  homeContractorID: string | null | undefined
  homeContractorLegacyID: number | null | undefined
  homeContractorName: string | undefined
  includesAllProjects: boolean | undefined
  projectIDs: Array<string> | undefined
  canConfigureSettings: boolean | undefined
  canConfigurePrivateSettings: boolean | undefined
  canSubmitScaffoldRequests: boolean | undefined
  canWalkDownUnassignedWorkOrders: boolean | undefined
  canImportWorkOrders: boolean | undefined
  canViewScaffoldRequestApprovals: boolean | undefined
  canViewWorkOrders: boolean | undefined
  canViewWorkOrderEstimates: boolean | undefined
  canViewWorkOrderSchedule: boolean | undefined
  canEditWorkOrderSchedule: boolean | undefined
  canViewToDoList: boolean | undefined
  canViewScaffolds: boolean | undefined
  canViewMaterialApproval: boolean | undefined
  canViewTransfers: boolean | undefined
  canCreateTransfers: boolean | undefined
}
export interface MessageWithSenderDetails {
  sender: Person | undefined
  id: string | undefined
  messageThreadID: string | undefined
  text: string | undefined
  personID: string | undefined
  sentTime: Date | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface NoteWithSenderDetails {
  sender: Person | undefined
  id: string | undefined
  noteThreadID: string | undefined
  text: string | undefined
  personID: string | undefined
  sentTime: Date | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface NotificationWithDetails {
  isRead: boolean | undefined
  sentTime: Date | undefined
  projectID: string | null | undefined
  contractorID: string | null | undefined
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Notification {
  projectID: string | null | undefined
  contractorID: string | null | undefined
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Owner {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  enabled: boolean | undefined
}
export interface PartWithTags {
  countSheetGroupName: string | undefined
  tagIDs: Array<string> | undefined
  supplierIDs: Array<string> | undefined
  id: string | undefined
  legacyID: number | null | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface YardTotal {
  yardID: string
  partID: string | null
  name: string
  total: number
}
export interface PartsImportSettings {
  identifyingColumnFriendlyName: string
  publicID: string
  name: string
  description: string
  longDescription: string
  weight: string
  mpp: string
  lashingMPP: string
  cleatingMPP: string
  carpentryMPP: string
  otherMPP: string
  countSheetGroupID: string
  rentalRate: string
  costUsed: string
  costNew: string
  designation: string
  tagNames: string
  supplierNames: string
  columnFriendlyNames: Array<string>
}
export interface ProjectLocation {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  description: string | undefined
  projectID: string | null | undefined
  parentLocationID: string | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ProjectWithParts {
  partIDs: Array<string> | undefined
  id: string | undefined
  name: string | undefined
  description: string | undefined
  productivity: number | null | undefined
  labourRate: number | null | undefined
  ownerID: string | undefined
  regionID: string | null | undefined
  includesAllParts: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Region {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enabled: boolean | undefined
}
export interface ScaffoldRequestSubTypeWithLegacyID {
  name: string
  displayName: string
  value: ScaffoldRequestSubTypes
  legacyID: number
}
export enum ScaffoldRequestTypes {
  Erect = 1,
  Dismantle = 2,
  Modify = 3,
}
export interface ScaffoldRequestTypeWithLegacyID {
  name: string
  displayName: string
  value: ScaffoldRequestTypes
  legacyID: number
}
export interface ScaffoldRequest {
  id: string | undefined
  internalRequestNumber: string | undefined
  requestorID: string | undefined
  legacyWorkOrderID: number | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  scaffoldRequestStatus: ScaffoldRequestStatuses | undefined
  scaffoldRequestStatusDetail: string | undefined
  scaffoldRequestApprovalIsAutomatic: boolean | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldID: string | null | undefined
  legacyScaffoldID: number | null | undefined
  tagNumber: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  siteContact: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  isWalkdownRequired: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldRequestWithDetails {
  relatedWorkOrderID: string | null
  scaffoldSubmittedRequestIDs: Array<string>
  scaffoldDismantleRequestIDs: Array<string>
  scaffoldActiveWorkOrderIDs: Array<string>
  scaffoldDismantleWorkOrderIDs: Array<string>
  lastStatusChangeDate: Date | null
  lastStatusChangedBy: string
  isModifyRequest: boolean
  walkdown: WalkdownWithRequestDetails
  requestingContractor: Contractor
  workOrderAssignedContractor: Contractor
  requestor: Person
  requestingEmployee: Person
  submitterName: string
  coordinator: Person
  requestTypeName: string
  requestSubTypeName: string
  areaName: string
  subAreaName: string
  disciplineName: string
  canEditRequest: boolean
  tagIDs: Array<string> | undefined
  legacyRequestStatusName: string
  legacyWorkOrderApprovalComments: string
  legacyWorkOrderWalkdownApprovalComments: string
  legacyWorkOrderPriority: number | null
  legacyWorkOrderProgress: number | null
  legacyWorkOrderScaffoldContractorName: string
  legacyWorkOrderCoordinatorName: string
  legacyWorkOrderGeneralForemanName: string
  legacyWorkOrderForemanName: string
  legacyWorkOrderRequestStatusDetails: string
  legacyWorkOrderIsPlanned: boolean | null
  legacyWorkOrderRequestDate: Date | null
  legacyWorkOrderStartDate: Date | null
  legacyWorkOrderCompletedDate: Date | null
  workPackageNames: Array<string> | undefined
  currentUserPermissions: SummarizedScaffoldRequestPermissions
  id: string | undefined
  internalRequestNumber: string | undefined
  requestorID: string | undefined
  legacyWorkOrderID: number | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  scaffoldRequestStatus: ScaffoldRequestStatuses | undefined
  scaffoldRequestStatusDetail: string | undefined
  scaffoldRequestApprovalIsAutomatic: boolean | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldID: string | null | undefined
  legacyScaffoldID: number | null | undefined
  tagNumber: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  siteContact: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  isWalkdownRequired: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface LegacyWorkOrderWithRequestDetails {
  isWalkdownRequired: boolean | undefined
  requestStatusName: string | undefined
  canEditRequestDetails: boolean
  scaffoldRequestID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  internalRequestNumber: string | undefined
  workPackageIDs: Array<string> | undefined | null
  requestTypeName: string | undefined
  requestSubTypeName: string | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  disciplineName: string | undefined
  requestingContractorName: string | undefined
  requestingEmployeeFirstName: string | undefined
  requestingEmployeeLastName: string | undefined
  assignedContractorName: string | undefined
  assignedEmployeeFirstName: string | undefined
  assignedEmployeeLastName: string | undefined
  scaffoldBayLengthName: string | undefined
  scaffoldBayLengthInches: number | null | undefined
  scaffoldBayWidthName: string | undefined
  scaffoldBayWidthInches: number | null | undefined
  scaffoldBayHeightName: string | undefined
  scaffoldBayHeightInches: number | null | undefined
  scaffoldTypeModifierName: string | undefined
  scaffoldHeightModifierName: string | undefined
  scaffoldDistanceModifierName: string | undefined
  scaffoldElevationModifierName: string | undefined
  buildDismantleRatioName: string | undefined
  scaffoldCongestionFactorName: string | undefined
  internalModifierName: string | undefined
  hoardingModifierName: string | undefined
  archivedDate: Date | null | undefined
  tagIDs: Array<string> | undefined
  walkdownStatus: WalkdownStatuses | undefined
  currentUserPermissions: SummarizedScaffoldRequestPermissions
  id: number | undefined
  siteID: number | null | undefined
  applyToScaffoldID: number | null | undefined
  requestStatusID: number | undefined
  requestStatusDetails: string | undefined
  completedDate: Date | null | undefined
  requestTypeID: number | null | undefined
  requestSubTypeID: number | null | undefined
  requestingContractorID: number | null | undefined
  disciplineID: number | null | undefined
  requestorID: number | null | undefined
  existingRequestNumber: number | null | undefined
  areaID: number | null | undefined
  subAreaID: number | null | undefined
  approvedComments: string | undefined
  approvedDate: Date | null | undefined
  startDate: Date | null | undefined
  requiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  location: string | undefined
  description: string | undefined
  siteContact: string | undefined
  contactNotes: string | undefined
  notes: string | undefined
  progress: number | null | undefined
  assigneeID: number | null | undefined
  generalForemanID: number | null | undefined
  foremanID: number | null | undefined
  contractorID: number | null | undefined
  walkdownApprovalComments: string | undefined
  walkdownApprovalDate: Date | null | undefined
  walkdownApprovalStatus: boolean | null | undefined
  lastEstimateScaffoldType: number | null | undefined
  lastEstimateLength: number | null | undefined
  lastEstimateBayLengthID: number | null | undefined
  lastEstimateNLB: number | null | undefined
  lastEstimateWidth: number | null | undefined
  lastEstimateBayWidthID: number | null | undefined
  lastEstimateNWB: number | null | undefined
  lastEstimateHeight: number | null | undefined
  lastEstimateBayHeightID: number | null | undefined
  lastEstimateAccessType: number | null | undefined
  lastEstimateBarricadeGates: number | null | undefined
  lastEstimateDeckLevels: number | null | undefined
  elevFromLaydownModID: number | null | undefined
  scaffoldHeightModID: number | null | undefined
  typeOfScaffoldModifierID: number | null | undefined
  mobDistanceModifierID: number | null | undefined
  buildDismantleRatioID: number | null | undefined
  congestionFactorID: number | null | undefined
  internalModifierID: number | null | undefined
  hoardingModifierID: number | null | undefined
  estimatedCrewSize: number | null | undefined
  estimatedWorkDays: number | null | undefined
  modification: number | null | undefined
  priority: number | null | undefined
  isPlanned: number | null | undefined
  isPlanned2: number | null | undefined
  isPlanned3: number | null | undefined
  isUrgentReason: string | undefined
  activityCostCodeID: number | null | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isUpdatingLocked: boolean | undefined
  requestDate: Date | null | undefined
  status: boolean | undefined
  created: Date | undefined
  createdBy: number | null | undefined
  updated: Date | null | undefined
  updatedBy: number | null | undefined
}
export interface WorkOrderWithLegacyDetails {
  countSheet: CountSheet
  scaffoldSubmittedRequestIDs: Array<string>
  scaffoldDismantleRequestIDs: Array<string>
  scaffoldActiveWorkOrderIDs: Array<string>
  scaffoldDismantleWorkOrderIDs: Array<string>
  requestDate: Date | null | undefined
  lastStatusChangeDate: Date | null | undefined
  lastStatusChangedBy: string
  urgentChangeLogs: Array<WorkOrderUrgentLogWithDetails>
  lastUrgentValueChangedDate: Date | null | undefined
  lastUrgentValueChangedBy: string | undefined
  lastUrgentDetailChangedDate: Date | null | undefined
  lastUrgentDetailChangedBy: string | undefined
  workPackages: Array<WorkPackage> | undefined
  walkdown: WalkdownWithRequestDetails
  isModifyRequest: boolean
  externalLinks: Array<ExternalLink>
  fileNames: Array<string>
  isArchived: boolean | undefined
  tagIDs: Array<string> | undefined
  assignedContractorName: string | undefined
  coordinatorName: string | undefined
  generalForemanName: string | undefined
  foremanName: string | undefined
  requestingContractorName: string | undefined
  requestingEmployeeID: string | null | undefined
  requestingEmployeeName: string | undefined
  requestSubmittedOn: Date | null | undefined
  requestSubmitterName: string | undefined
  coordinatorLegacyID: number | null | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  scaffoldNumber: string | undefined
  requestNumber: string | undefined
  areaLegacyID: number | null | undefined
  subAreaLegacyID: number | null | undefined
  disciplineName: string | undefined
  requestStatus: ScaffoldRequestStatuses | undefined
  workOrderStatusName: string | undefined
  requestTypeName: string | undefined
  requestSubTypeName: string | undefined
  isPlanned: boolean | null | undefined
  crewSize: string | undefined
  workDays: string | undefined
  modificationHours: string | undefined
  length: string | undefined
  bayLengthName: string | undefined
  lengthBayCount: number | null | undefined
  width: string | undefined
  bayWidthName: string | undefined
  widthBayCount: number | null | undefined
  height: string | undefined
  bayHeightName: string | undefined
  isHardBarricade: boolean | null | undefined
  accessType: number | null | undefined
  barricadeGates: number | null | undefined
  deckLevels: number | null | undefined
  currentUserPermissions: SummarizedWorkOrderPermissions
  id: string | undefined
  legacyID: number | null | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldID: string | null | undefined
  legacyScaffoldID: number | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  disciplineID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldWithDetails {
  tagIDs: Array<string> | undefined
  erectRequestNumber: string | undefined
  contractorName: string | undefined
  lastWorkOrderContractorName: string | undefined
  scaffoldStatusName: string | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  workOrders: Array<WorkOrderWithLegacyDetails> | undefined
  workPackages: Array<WorkPackage> | undefined
  inventory: Array<Part> | undefined
  transactions: Array<Transfer> | undefined
  id: string | undefined
  legacyID: number | null | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PartWithTotal {
  partID: string
  total: number
}
export interface ScaffoldSearchResult {
  areaName: string
  subAreaName: string
  existingRequestNumber: string
  id: string | undefined
  legacyID: number | null | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SearchResult {
  scaffoldRequest: ScaffoldRequestResult
  scaffold: ScaffoldResult
  person: PersonResult
  workPackage: WorkPackageResult
  workOrder: WorkOrderResult
  countSheet: CountSheetResult
  resultID: string | undefined | null
  foundTagIDs: Array<string>
}
export interface SupplierWithTags {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  enabled: boolean | undefined
  tagIDs: Array<string> | undefined
}
export interface TransferPartDetails {
  partID: string
  count: number
  currentAssignedCount: number | null
}
export interface TransferWithDetails {
  parts: Array<TransferPartWithDetails> | undefined
  direction: TransferDirection | null
  fromScaffoldLegacyID: number | null
  toScaffoldLegacyID: number | null
  fromYardName: string
  toYardName: string
  currentUserPermissions: SummarizedTransferPermissions
  id: string | undefined
  legacyID: number | null | undefined
  transferDate: Date | undefined
  workOrderID: string | null | undefined
  transactionNumber: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  fromYardID: string | null | undefined
  toYardID: string | null | undefined
  fromScaffoldID: string | null | undefined
  toScaffoldID: string | null | undefined
  transferTypeID: TransferType | undefined
  transferContextID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonWithDetails {
  tagIDs: Array<string> | undefined
  emailAddresses: Array<PersonalEmailAddressWithVerified> | undefined
  phoneNumbers: Array<PersonalPhoneNumberWithVerified> | undefined
  loginDisabledDate: Date | null | undefined
  loginState: PersonLoginStates | undefined
  isLoginActive: boolean | undefined
  isArchived: boolean | undefined
  canSignIn: boolean | undefined
  hasVerifiedContactMethod: boolean | undefined
  projectIDs: Array<string> | undefined
  contractorIDs: Array<string> | undefined
  canConfigureSettings: boolean | undefined
  canConfigurePrivateSettings: boolean | undefined
  canUpdateAssociatedWorkOrdersWithoutBeingAssigned: boolean | undefined
  forceReadonlyAccess: boolean | undefined
  canSubmitScaffoldRequests: boolean | undefined
  canApproveScaffoldRequests: boolean | undefined
  canApproveScaffoldRequestsFromAllAssignedContractors: boolean | undefined
  canWalkDownUnassignedWorkOrders: boolean | undefined
  canApproveWalkdowns: boolean | undefined
  canApproveCountSheets: boolean | undefined
  canCreateTransfers: boolean | undefined
  canOverrideTransferValues: boolean | undefined
  canImportWorkOrders: boolean | undefined
  canEditWorkOrderArea: boolean | undefined
  canEditWorkOrderAreaForAllAssignedContractors: boolean | undefined
  canEditWorkOrderLocation: boolean | undefined
  canEditWorkOrderLocationForAllAssignedContractors: boolean | undefined
  canEditWorkOrderWorkDescription: boolean | undefined
  canEditWorkOrderWorkDescriptionForAllAssignedContractors: boolean | undefined
  canEditWorkOrderPriority: boolean | undefined
  canEditWorkOrderPriorityForAllAssignedContractors: boolean | undefined
  canEditWorkOrderProgress: boolean | undefined
  canEditWorkOrderProgressForAllAssignedContractors: boolean | undefined
  canEditWorkOrderStatus: boolean | undefined
  canEditWorkOrderStatusForAllAssignedContractors: boolean | undefined
  canCancelWorkOrder: boolean | undefined
  canCancelWorkOrderForAllAssignedContractors: boolean | undefined
  canEditWorkOrderRequiredDate: boolean | undefined
  canEditWorkOrderRequiredDateForAllAssignedContractors: boolean | undefined
  canEditWorkOrderPlannedWorkDate: boolean | undefined
  canEditWorkOrderPlannedWorkDateForAllAssignedContractors: boolean | undefined
  canEditWorkOrderContractor: boolean | undefined
  canEditWorkOrderCoordinator: boolean | undefined
  canEditWorkOrderCoordinatorForAllAssignedContractors: boolean | undefined
  canEditWorkOrderGeneralForeman: boolean | undefined
  canEditWorkOrderGeneralForemanForAllAssignedContractors: boolean | undefined
  canEditWorkOrderForeman: boolean | undefined
  canEditWorkOrderForemanForAllAssignedContractors: boolean | undefined
  canViewScaffoldRequestApprovals: boolean | undefined
  canViewWorkOrders: boolean | undefined
  canViewWorkOrderEstimates: boolean | undefined
  canViewWorkOrderSchedule: boolean | undefined
  canEditWorkOrderSchedule: boolean | undefined
  canViewToDoList: boolean | undefined
  canViewScaffolds: boolean | undefined
  canViewMaterialApproval: boolean | undefined
  canViewTransfers: boolean | undefined
  employeeCode: string | undefined
  legacyID: number | null | undefined
  contractorID: string | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDJson: string | undefined
  includesAllProjects: boolean | undefined
  projectIDJson: string | undefined
  locationFilterID: string | null | undefined
  isPlanner: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  id: string | undefined
  organizationID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldBayLength {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  inches: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldBayWidth {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  inches: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldBayHeight {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  inches: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldTypeModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDistanceModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldElevationModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldHeightModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BuildDismantleRatio {
  id: string | undefined
  legacyID: number | null | undefined
  ratio: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldCongestionFactor {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface InternalModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface HoardingModifier {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Walkdown {
  id: string | undefined
  workOrderID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  legacyWorkOrderID: number | null | undefined
  walkdownStatus: WalkdownStatuses | undefined
  notes: string | undefined
  startedOn: Date | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  approvedBy: string | null | undefined
  approvalComments: string | undefined
  approvalChangedDate: Date | null | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  isHardBarricade: boolean | undefined
  barricadeGates: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrder {
  id: string | undefined
  legacyID: number | null | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldID: string | null | undefined
  legacyScaffoldID: number | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  disciplineID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldRequestStatusWithLegacyID {
  name: string
  displayName: string
  value: ScaffoldRequestStatuses
  legacyID: number
}
export interface WorkOrderStatusWithLegacyID {
  name: string
  displayName: string
  value: WorkOrderStatuses
  legacyID: number
}
export interface WorkPackage {
  id: string | undefined
  legacyID: number | null | undefined
  activityID: string | undefined
  name: string | undefined
  startDate: Date | null | undefined
  finishDate: Date | null | undefined
  completionPercentage: number | null | undefined
  workStatus: string | undefined
  purpose: string | undefined
  plannedStartDate: Date | null | undefined
  plannedFinishDate: Date | null | undefined
  cwpplannedStartDate: Date | null | undefined
  cwpplannedFinishDate: Date | null | undefined
  forecastStartDate: Date | null | undefined
  forecastFinishDate: Date | null | undefined
  discipline: string | undefined
  unit: string | undefined
  constructionWorkArea: string | undefined
  area: string | undefined
  longDescription: string | undefined
  description: string | undefined
  cwp: string | undefined
  estimatedHours: number | null | undefined
  earnedHours: number | null | undefined
  statusDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface WorkPackagesImportSettings {
  identifyingColumnFriendlyName: string
  activityID: string
  name: string
  startDate: string
  finishDate: string
  completionPercentage: string
  workStatus: string
  purpose: string
  plannedStartDate: string
  plannedFinishDate: string
  cwpPlannedStartDate: string
  cwpPlannedFinishDate: string
  forecastStartDate: string
  forecastFinishDate: string
  discipline: string
  unit: string
  constructionWorkArea: string
  area: string
  longDescription: string
  description: string
  cwp: string
  estimatedHours: string
  earnedHours: string
  statusDate: string
  columnFriendlyNames: Array<string>
}
export interface Yard {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  isSystemYard: boolean | null | undefined
  yardTypeID: YardType | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface AccessCodeChallenge {
  loginContext: Uint8Array
  codeContext: Uint8Array
  expires: Date
  attempts: number
  verifyEmailAddressID: Uint8Array
  sentToEmailAddress: string
  sentToEmailAddresses: Array<Uint8Array>
  verifyPhoneNumberID: Uint8Array
  sentToPhoneNumberTail: string
  sentToPhoneNumbers: Array<Uint8Array>
  loginInformation: LoginInformation
}
export interface AppStatus {
  appVersion: string
  databaseConnection: string
  legacyDatabaseConnection: string
  notifications: string
}
export interface Version {
  major: number
  minor: number
  build: number
  revision: number
  majorRevision: number
  minorRevision: number
}
export interface Environment {
  defaultScaffoldRequestRequiresWalkDown: boolean | undefined
  automaticallyApproveScaffoldRequests: boolean | undefined
  defaultWorkOrderAssignedContractorID: string | null | undefined
  defaultWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveCountSheets: boolean | undefined
  defaultCountSheetFromYardID: string | null | undefined
  defaultCountSheetToYardID: string | null | undefined
  id: string | undefined
  organizationID: string | null | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Language {
  id: string | undefined
  name: string | undefined
  number: number | undefined
  shortCode: string | undefined
  shortCodeExt: string | undefined
}
export interface PersonalEmailAddress {
  id: string | undefined
  personID: string | undefined
  emailAddress: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonalPhoneNumber {
  id: string | undefined
  personID: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Tag {
  id: string | undefined
  organizationID: string | null | undefined
  name: string | undefined
  description: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PartWithYardBalance {
  countsByYardID: { [key: string]: number }
  id: string | undefined
  legacyID: number | null | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface CountSheetPartWithDetails {
  name: string
  description: string
  publicID: string
  countSheetGroupDisplayOrder: number | null
  countSheetGroupName: string
  countSheetGroupOrder: number | null
  standingScaffoldPartCount: number | null
  countSheetID: string | undefined
  partID: string | undefined
  currentAssignedCount: number | null | undefined
  addCount: number | undefined
  addCountOverride: number | null | undefined
  removeCount: number | undefined
  removeCountOverride: number | null | undefined
  overridden: boolean | undefined
  rejected: boolean | undefined
  rejectedReason: string | undefined
}
export interface SummarizedCountSheetPermissions {
  canEditAnything: boolean
  canOverrideCountSheetValues: boolean
  canApprove: boolean
  canCancel: boolean
}
export enum CountSheetType {
  Individual = 0,
  RemoveAll = 1,
  NotApplicable = 2,
}
export enum CountSheetReviewStatus {
  Draft = 0,
  Pending = 1,
  Declined = 2,
  Approved = 3,
  Cancelled = 4,
}
export interface AreaWithSubAreas {
  subAreas: Array<ProjectLocation>
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  description: string | undefined
  projectID: string | null | undefined
  parentLocationID: string | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export enum NotificationSeverity {
  Info = 1,
  Warning = 10,
  Critical = 100,
}
export enum ScaffoldRequestSubTypes {
  ErectScaffold = 1,
  ErectHardBarricade = 2,
  DismantleFull = 3,
  ModifyAdjustmentOnly = 4,
  ModifyMaterialAdded = 5,
  ModifyMaterialRemoved = 6,
}
export enum ScaffoldRequestStatuses {
  Draft = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Cancelled = 5,
  Completed = 6,
}
export enum WorkOrderStatuses {
  Draft = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Walkdown = 5,
  Estimated = 6,
  InScheduling = 7,
  OnHold = 8,
  Started = 9,
  Completed = 10,
  Cancelled = 11,
  Archived = 12,
  CompletionPendingAdministration = 13,
}
export interface WalkdownWithRequestDetails {
  bayLengthName: string | undefined
  lengthBayCount: number | null | undefined
  bayWidthName: string | undefined
  widthBayCount: number | null | undefined
  bayHeightName: string | undefined
  scaffoldTypeName: string | undefined
  elevationModifierName: string | undefined
  heightModifierName: string | undefined
  distanceModifierName: string | undefined
  congestionFactorName: string | undefined
  internalModifierName: string | undefined
  hoardingModifierName: string | undefined
  buildDismantleRatioName: string | undefined
  submitterName: string | undefined
  currentUserPermissions: SummarizedWalkdownPermissions
  id: string | undefined
  workOrderID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  legacyWorkOrderID: number | null | undefined
  walkdownStatus: WalkdownStatuses | undefined
  notes: string | undefined
  startedOn: Date | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  approvedBy: string | null | undefined
  approvalComments: string | undefined
  approvalChangedDate: Date | null | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  isHardBarricade: boolean | undefined
  barricadeGates: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Contractor {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  tagIDJson: string | undefined
  isScaffoldCompany: boolean | undefined
  canActAsProxy: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedScaffoldRequestPermissions {
  canUpdateApproval: boolean
  canEditAssignedContractor: boolean
  canCancel: boolean
  canEditAnything: boolean
}
export enum WalkdownStatuses {
  Pending = 1,
  Draft = 2,
  Submitted = 3,
  Declined = 4,
  Approved = 5,
  Cancelled = 6,
}
export interface CountSheet {
  id: string | undefined
  workOrderID: string | null | undefined
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderUrgentLogWithDetails {
  changedByName: string
  id: string | undefined
  workOrderID: string | undefined
  isUrgent: boolean | null | undefined
  isUrgentDetail: string | undefined
  changed: Date | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedWorkOrderPermissions {
  canEditAnything: boolean
  canEditArea: boolean
  canEditLocation: boolean
  canEditWorkDescription: boolean
  canEditPriority: boolean
  canEditProgress: boolean
  canEditStatus: boolean
  canCancel: boolean
  canEditRequiredDate: boolean
  canPushToToDoList: boolean
  canEditAssignedContractor: boolean
  canEditAssignedCoordinator: boolean
  canEditAssignedGeneralForeman: boolean
  canEditAssignedForeman: boolean
}
export interface Transfer {
  id: string | undefined
  legacyID: number | null | undefined
  transferDate: Date | undefined
  workOrderID: string | null | undefined
  transactionNumber: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  fromYardID: string | null | undefined
  toYardID: string | null | undefined
  fromScaffoldID: string | null | undefined
  toScaffoldID: string | null | undefined
  transferTypeID: TransferType | undefined
  transferContextID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export enum ScaffoldStatuses {
  Designed = 0,
  Estimated = 1,
  Erecting = 2,
  Erected = 3,
  Dismantling = 4,
  Dismantled = 5,
  Completed = 6,
  Cancelled = 7,
}
export interface ScaffoldRequestResult {
  id: string | null
  internalRequestNumber: string
  legacyWorkOrderID: number | null
  tagNumber: string
  requestingContractorName: string
  requestorFirstName: string
  requestorLastName: string
  requestStatusName: string
  requestTypeName: string
  requestSubTypeName: string
  tagIDs: Array<string>
}
export interface ScaffoldResult {
  id: string | null
  legacyID: number | null
  statusName: string
  actualErectDate: string
  actualDismantleDate: string
  tagIDs: Array<string>
}
export interface PersonResult {
  id: string | null
  firstName: string
  lastName: string
  contractorName: string
  employeeCode: string
  tagIDs: Array<string>
}
export interface WorkPackageResult {
  id: string | null
  name: string
  activityID: string
  startDate: string
  finishDate: string
  workStatus: string
  completionPercentage: number | null
}
export interface WorkOrderResult {
  id: string | null
  legacyID: number | null
  startDate: string
  completedDate: string
  workOrderStatusID: WorkOrderStatuses | null
  progress: number | null
}
export interface CountSheetResult {
  id: string | null
  workOrderLegacyID: number | null
  scaffoldLegacyID: number | null
  countSheetTypeID: CountSheetType | null
  submittedBy: string
  submittedDate: string
  reviewStatusID: CountSheetReviewStatus | null
  reviewStatus: string
  declineReason: string
  cancelReason: string
  lastStatusChangeDate: string
  approvedBy: string
}
export interface TransferPartWithDetails {
  name: string
  description: string
  publicID: string
  countSheetGroupDisplayOrder: number | null
  countSheetGroupID: string | null
  transferID: string | undefined
  partID: string | undefined
  count: number | undefined
  currentAssignedCount: number | null | undefined
  isOverridden: boolean | undefined
  originalCount: number | null | undefined
}
export enum TransferDirection {
  ScaffoldDelivery = 0,
  ScaffoldReturn = 1,
  YardTransfer = 2,
}
export interface SummarizedTransferPermissions {
  canEditAnything: boolean
  canOverrideTransferValues: boolean
}
export enum TransferType {
  DeliveryReturnYardTransfer = 0,
  ScaffoldTransfer = 1,
  ScaffoldMerge = 2,
  CountSheet = 3,
}
export interface PersonalEmailAddressWithVerified {
  id: string | undefined
  emailAddress: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export interface PersonalPhoneNumberWithVerified {
  id: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export enum PersonLoginStates {
  None = 0,
  Unused = 1,
  Active = 2,
  Deactivated = 3,
}
export enum YardType {
  OnRent = 1,
  OffRent = 2,
  Corrections = 3,
  Distribution = 4,
  Damaged = 5,
}
export interface ProjectLocation {
  id: string | undefined
  legacyID: number | null | undefined
  name: string | undefined
  description: string | undefined
  projectID: string | null | undefined
  parentLocationID: string | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedWalkdownPermissions {
  canPerformWalkdown: boolean
  canPerformToDoListWalkdown: boolean
  canUnassignWalkdown: boolean
  canEditAnything: boolean
  canEditStatus: boolean
  canCancel: boolean
}
