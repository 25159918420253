import FDVue from "../";

export default FDVue.extend({
  name: "fd-alert",

  inheritAttrs: false,

  props: {
    value: {},
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-alert" },
    disabled: { type: Boolean, default: false },

    labelStyle: { type: String, default: "" },

    persistentComments: { type: Boolean, default: false },
    commentHint: { type: String, default: undefined },
    commentHintStyle: { type: String, default: "" },
    commentHintClass: { type: [String, Array], default: () => [] },

    commentPlaceholderKey: { type: String, default: "common.none-entered" },
    commentPlaceholderStyle: { type: String, default: "" },
    commentPlaceholderClass: { type: [String, Array], default: () => [] }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {
    computedClass(): string | Array<string | Object> | undefined {
      let classes: string[] = [];
      if (!!this.$attrs.class) {
        if (typeof this.$attrs.class === "string") {
          classes = (this.$attrs.class as string)?.split(" ") ?? [];
        } else {
          classes = (this.$attrs.class as string[]) ?? [];
        }
      }

      classes = classes.concat([`fd-alert`, `mb-0`, this.$attrs.type]);
      if (this.$attrs.type == "hint") {
        classes = ["v-alert--outlined", "primary--text"].concat(classes);
      }

      let isDense = !!this.$attrs.dense && this.$attrs.dense != "false";
      if (!isDense) {
        classes.push(`mt-3`);
      }
      return classes;
    },
    sanitizedType(): string | undefined {
      // valid v-alert types include success, info, warning or error
      // If the passed in type does not match those, return undefined
      let type = this.$attrs?.type?.toLowerCase() ?? "";
      if (type != "success" && type != "info" && type != "warning" && type != "error")
        return undefined;
      else return type;
    },
    computedIcon() {
      if (this.$attrs.type == "info") {
        return "mdi-information";
      } else if (this.$attrs.type == "warning") {
        return "mdi-alert-circle";
      } else if (this.$attrs.type == "error") {
        return "mdi-close-octagon";
      } else if (this.$attrs.type == "urgent") {
        return "fa-fire-alt";
      } else {
        return undefined;
      }
    }
  },

  watch: {}
});

