import FDVue from "@fd/lib/vue";
import { mapMutations, mapState } from "vuex";

export default FDVue.extend({
  name: "fd-mobile-more-menu",

  components: {
    "fd-privacy-dialog": () => import("./components/PrivacyDialog.vue"),
    "fd-terms-dialog": () => import("./components/TermsDialog.vue")
  },

  data: () => ({
    sidebarmenuitems: [
      {
        icon: "fas fa-check-circle",
        text: "menu.scaffold-requests.scaffold-request-approvals-more-menu",
        to: "/scaffoldrequestapprovals",
        accessCheck: "canViewScaffoldRequestApprovals",
        showInBottomBarIfPossible: true
      },
      {
        icon: "fas fa-ruler-combined",
        text: "menu.work-orders.estimates",
        to: "/estimatedworkorders",
        accessCheck: "canViewWorkOrderEstimates",
        showInBottomBarIfPossible: true
      },
      {
        icon: "fas fa-box-open",
        text: "menu.material.admin-more-menu",
        to: "/countsheetadministration",
        accessCheck: "canViewMaterialApproval",
        showInBottomBarIfPossible: false
      },
      {
        icon: "fas fa-box-check",
        text: "menu.material.countsheets-more-menu",
        to: "/countsheets",
        accessCheck: "canViewMaterialApproval",
        showInBottomBarIfPossible: false
      },
      {
        icon: "fas fa-exchange",
        text: "menu.material.transfers",
        to: "/transfers",
        accessCheck: "canViewTransfers",
        showInBottomBarIfPossible: false
      },
      {
        icon: "fas fa-square-poll-vertical",
        text: "menu.material.yard-balances-more-menu",
        to: "/yardbalances",
        accessCheck: "canViewMaterialApproval",
        showInBottomBarIfPossible: false
      },
      {
        icon: "fas fa-history",
        text: "menu.work-orders.history",
        to: "/estimatedworkorders",
        accessCheck: "canViewToDoList",
        showInBottomBarIfPossible: true
      },
      {
        icon: "fas fa-layer-group",
        text: "menu.scaffolds.scaffolds",
        to: "/scaffolds",
        accessCheck: "canViewScaffolds",
        showInBottomBarIfPossible: true
      },
      {
        icon: "fa-cog",
        text: "menu.setup.subgroup-title",
        accessCheck: "canConfigureSettings",
        model: false,
        children: [
          { text: "menu.setup.contractors", icon: "fas fa-people-arrows", to: "/contractors" },
          { text: "menu.setup.projects", icon: "fas fa-project-diagram", to: "/projects" },
          { text: "menu.setup.catalog", icon: "fas fa-book", to: "/parts" },
          { text: "menu.setup.tags", icon: "fa-search-plus", to: "/tags" },
          { text: "menu.setup.iwp", icon: "fas fa-boxes", to: "/iwps" },
          { text: "menu.setup.people", icon: "fas fa-users", to: "/people" }
        ]
      }
    ]
  }),

  computed: {
    ...mapState(["barColor", "barImage", "avatarInitials", "curUserID", "curUserAccess"]),

    accessibleSidebarMenuItems() {
      return this.sidebarmenuitems.filter(
        x => !x.accessCheck?.length || this.curUserAccess[x.accessCheck] == true
      );
    },

    dialogPrivacy: {
      get() {
        return this.$store.state.dialogPrivacy;
      },
      set(val) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    },
    dialogTerms: {
      get() {
        return this.$store.state.dialogTerms;
      },
      set(val) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  },

  methods: {
    ...mapMutations({
      setPrivacyDialog: "SET_PRIVACY_DIALOG",
      setTermsDialog: "SET_TERMS_DIALOG"
    })
  }
});

