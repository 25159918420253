var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.back()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "12" } },
                    [
                      _vm.pageHasLoaded && !_vm.phoneNumber.isVerified
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                text: "",
                                color: "warning",
                                transition: "scale-transition"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { align: "center", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "shrink pr-2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { color: "inherit" } },
                                        [_vm._v("fas fa-phone")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "grow pr-1" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "users.user-mobile-number-not-verified-message"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.phoneNumber.personID == _vm.curUserID
                                    ? _c(
                                        "v-col",
                                        { staticClass: "shrink" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "warning" },
                                              on: {
                                                click: _vm.verifyPhoneNumber
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "users.user-mobile-number-resend"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.mobile-number"),
                                          disabled: _vm.phoneNumber.isVerified,
                                          readonly: _vm.phoneNumber.isVerified,
                                          rules:
                                            _vm.phoneNumberRules.phoneNumber,
                                          hint: _vm.phoneNumber.isVerified
                                            ? _vm.$t(
                                                "common.verified-disabled-control-hint"
                                              )
                                            : "",
                                          "persistent-hint": ""
                                        },
                                        model: {
                                          value: _vm.phoneNumber.phoneNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.phoneNumber,
                                              "phoneNumber",
                                              $$v
                                            )
                                          },
                                          expression: "phoneNumber.phoneNumber"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.phoneNumber.isVerified
                                        ? _c(
                                            "v-chip",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                color: "teal",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { left: "" } },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("common.verified")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-chip",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                color: "orange",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { left: "" } },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-help-rhombus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.pending-verification"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("common.notifications"),
                                  disabled: _vm.processing,
                                  hint: _vm.$t(
                                    "users.user-mobile-number-notifications-switch-hint"
                                  ),
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.phoneNumber.allowNotifications,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.phoneNumber,
                                      "allowNotifications",
                                      $$v
                                    )
                                  },
                                  expression: "phoneNumber.allowNotifications"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.inlineMessage.message) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "ml-0 mr-0 align-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: "",
                                        small:
                                          _vm.$vuetify.breakpoint.name == "xs"
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        link: "",
                                        disabled: _vm.processing,
                                        small:
                                          _vm.$vuetify.breakpoint.name == "xs"
                                      },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.saving,
                                        disabled: _vm.processing,
                                        small:
                                          _vm.$vuetify.breakpoint.name == "xs"
                                      },
                                      on: { click: _vm.save }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }