import FDVue from "../index";
import * as DateUtil from "../../client-util/datetime";

export default FDVue.extend({
  name: "fd-archived-data-loader",

  components: {
    "fd-show-archived-switch": () => import("@fd/lib/vue/components/ShowArchivedSwitch.vue")
  },

  props: {
    showArchived: {
      default: false,
      type: Boolean
    },
    hideDateRangePicker: {
      default: false,
      type: Boolean
    },
    disableArchivedSwitch: {
      default: false,
      type: Boolean
    },
    hideArchivedSwitch: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    },
    maxArchivedDateRangeMonths: {
      default: 2,
      type: Number
    }
  },

  data: () => ({
    minDate: null as String | null,
    maxDate: null as String | null
  }),

  computed: {},

  watch: {
    showArchived() {
      this.minDate = this.showArchivedMinDate;
      this.maxDate = this.showArchivedMaxDate;
      console.log(`Data Loader.showArchived changed min: ${this.minDate}, max: ${this.maxDate}`);
    },
    showArchivedMinDate(newValue: string | null) {
      this.minDate = newValue;
    },
    showArchivedMaxDate(newValue: string | null) {
      this.maxDate = newValue;
    }
  },

  methods: {
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.isoDateString(
        DateUtil.addMonthsToDate(val, this.maxArchivedDateRangeMonths)
      );
      console.log(`Data Loader.fromDateChanged min: ${this.minDate}, max: ${this.maxDate}`);
      this.$emit("input:showArchivedFromDate", val);
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.isoDateString(
        DateUtil.addMonthsToDate(val, -this.maxArchivedDateRangeMonths)
      );
      console.log(`Data Loader.toDateChanged min: ${this.minDate}, max: ${this.maxDate}`);
      this.$emit("input:showArchivedToDate", val);
    },
    dateRangeChanged(val: any) {
      this.$emit("input:showArchivedDateRange", val);
    }
  },

  created: function() {
    this.minDate = this.showArchivedMinDate;
    this.maxDate = this.showArchivedMaxDate;
  },

  mounted: function() {
    this.minDate = this.showArchivedMinDate;
    this.maxDate = this.showArchivedMaxDate;
  }
});

