import { mapMutations, mapState, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../dataMixins/userAccess";
import { createNewScaffoldRequest } from "./components/LegacyScaffoldRequestNew.vue";
import { createNewWorkOrderDialog } from "./components/WorkOrderNewDialog.vue";
import {
  environmentService,
  statisticsService,
  notificationService,
  NotificationWithDetails
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";

type NotificationWithExtraDetails = NotificationWithDetails & { date: string };
export default FDVue.extend({
  mixins: [errorHandling, userAccess],

  name: "fd-LandingPage",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    carouselModel: 0,

    showNewDialog: false,

    siteName: "",

    curid: null,

    slidein: false,

    // Used to show/hide the metrics functionality.  When false, the cards don't display and the data doesn't load
    showStatistics: true,
    // Count of all requests in "SUBMITTED" status visible to the current user
    requestsToApproveCount: 0,
    // Count of all work orders in "WALKDOWN" status where the current user is assigned as either CO/GM/FM for the work order (or the current user is a planner)
    walkdownsToCompleteCount: 0,
    // Count of all work orders in "ESTIMATED" status visible to the current user
    estimatesToCompleteCount: 0,

    // Used to track the the auto-reload for the table data
    reloadTimer: null as NodeJS.Timeout | null,
    dataReloadMinutes: 5
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["customSort"];
    },
    notifications(): NotificationWithExtraDetails[] {
      return this.$store.state.notifications.fullList.map((x: NotificationWithDetails) => {
        return {
          ...x,
          date: DateUtil.localizedDateTimeString(x.sentTime)
        } as NotificationWithExtraDetails;
      });
    },
    tablesearch: {
      get() {
        //return this.$store.state.searchStringForFiltering;
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    customSort(items: NotificationWithExtraDetails[], index: any[], isDescending: any[]) {
      let desc = isDescending[0] == true;
      let propName = index[0];
      if (propName === "date") {
        propName = "sentTime";
      }
      items.sort((a: NotificationWithExtraDetails, b: NotificationWithExtraDetails) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });

      return items;
    },
    navigateFromNotification(item: NotificationWithExtraDetails) {
      if (item.context == "legacyscaffoldrequest" && !!item.contextID) {
        this.$router.push(`/scaffoldrequests/${item.contextID}`);
      } else if (item.context == "scheduler" && !!item.contextID) {
        this.$router.push(`/scheduler/${item.contextID}`);
      } else if (item.context == "workorder" && !!item.contextID) {
        this.$router.push(`/workorder/${item.contextID}`);
      }
    },
    async reloadDashboardData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.processing = true;
      try {
        await Promise.all([this.loadNotifications(), this.loadStatistics()]);

        let _this = this;
        this.reloadTimer = setTimeout(async function() {
          _this.reloadDashboardData();
        }, _this.dataReloadMinutes * 60 * 1000);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadStatistics() {
      if (!this.showStatistics) return;

      this.requestsToApproveCount = await statisticsService.getRequestsNeedingApprovalCount();
      this.walkdownsToCompleteCount = await statisticsService.getWalkdownsToCompleteCount();
      // this.estimatesToCompleteCount = await statisticsService.getEstimatesToCompleteCount();
    },
    async addScaffoldRequest() {
      this.optOutOfErrorHandling();
      if (await createNewScaffoldRequest()) {
        try {
          // do... something?... here
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
        }
      }
    },
    async importWorkOrders() {
      this.optOutOfErrorHandling();
      await createNewWorkOrderDialog();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // Delete notification
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async flipUnread(item: NotificationWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // Update notification
        await notificationService.markNotificationWithReadStatus(item.id!, !item.isRead);
        await this.reloadDashboardData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadNotifications: "LOAD_NOTIFICATIONS"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeMount() {
    if (this.$route.query?.firstLoad) {
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "home",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: "Home",
      to: "/landingpage",
      resetHistory: true
    });
    this.processing = true;

    try {
      // Load notifications for table
      let environment = await environmentService.getCurrent();
      if (!!environment) {
        this.siteName = environment.name ?? "";
      }
      await this.reloadDashboardData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

