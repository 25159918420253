<template>
    <v-dialog
            v-model="dialogVisible"
            width="800px"
            persistent
    >
        <v-form ref="form" v-on:submit="onSubmit">
            <v-card>
                <v-card-title v-bind:class="[processing ? 'dialog-new-top-color-processing' : 'dialog-new-top-color']">
                    <span v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '']">{{ $t('suppliers.new-dialog-title') }}</span>
                    <v-spacer></v-spacer>
                    <v-progress-circular
                            :indeterminate="true"
                            :rotate="0"
                            :size="32"
                            :width="4"
                            color="white"
                            v-if="processing"
                    ></v-progress-circular>
                </v-card-title>
                <v-container>
                    <v-row class="mx-2">
                        <v-col cols="12">
                            <fd-chip-selector
                                    v-bind:available-items="availableTags"
                                    v-bind:selected-items.sync="selectedTags"
                                    item-label="name"
                                    v-bind:disabled="processing"
                                    v-bind:control-label="$t('common.add-tags')"
                                    chip-class="keywords-selection-chip-colors"
                            />
                        </v-col>
                        <v-col
                                class="align-center justify-space-between"
                                cols="12"
                        >
                            <v-row
                                    align="center"
                                    class="mr-0 ml-0"
                            >
                                <v-text-field
                                        v-bind:label="$t('common.name')"
                                        v-model="supplier.name"
                                        v-bind:disabled="processing"
                                        :rules="[rules.required]"
                                ></v-text-field>
                            </v-row>
                        </v-col>
                        <v-col
                                class="align-center justify-space-between"
                                cols="12"
                        >
                            <v-row
                                    align="center"
                                    class="mr-0 ml-0"
                            >
                                <v-text-field
                                        v-bind:label="$t('common.alias')"
                                        v-model="supplier.alias"
                                        persistent-hint
                                        v-bind:hint="$t('common.alias-hint')"
                                        v-bind:disabled="processing"
                                        :rules="[rules.aliascounter, rules.required]"
                                        maxlength="20"
                                        counter
                                ></v-text-field>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row
                                    align="center"
                                    class="mr-0 ml-0"
                            >
                                <v-textarea
                                        v-bind:label="$t('common.description')"
                                        v-model="supplier.description"
                                        v-bind:disabled="processing"
                                ></v-textarea>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type">
                                {{ inlineMessage.message }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            v-bind:disabled="processing"
                            @click="cancelDialog"
                    >{{ $t('common.cancel') }}</v-btn>
                    <v-btn
                            color="primary"
                            v-bind:loading="processing"
                            v-bind:disabled="processing"
                            @click="saveDialog"
                    >{{ $t('common.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import {mapState, mapMutations, mapActions} from "vuex";
    import i18n from '../../i18n'

    export default {
        name: 'fd-supplier-new-dialog',

        components: {
            "fd-chip-selector": () => import('@fd/lib/vue/components/ChipItemSelector.vue')
        },

        // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
        props: ['show'],

        mounted () {
            // Bind the inbound value of the 'show' property to our own handlers so we can act
            // when we're asked to show and hide ourselves
            // TODO: Consider a binding that exposes a callable method; toggling data like this seems to be weird, although it's what I saw online
            this.onShowPropChanged(this.value);
        },

        data: () => ({
            dialogVisible: false,

            supplier : {
                name: "",
                alias: "",
                description: "",
                enabled: true
            },

            rules: {
                required: value => !!value || i18n.t('common.rule-required'),
                aliascounter: value => !value || value.length <= 20 || i18n.t('common.rule-alias-counter', '20'),
            },

            // The following will control whether the controls on screen are disabled while we are processing.
            processing: false,

            selectedTags: [],

            // The following is responsible for the inline messages that may be presented to the user.
            // If the message property is null then no message will be shown to the user.
            // The type can be one of the following strings:
            // 'success'
            // 'info'
            // 'warning'
            // 'error'
            inlineMessage: {
                message: null,
                type: 'error'
            },

        }),

        computed: {
            availableTags() { return this.$store.getters.sortedEnabledTags; }
        },

        watch: {
            // We need to watch for when the "show" property is changed by the parent view.
            show (val) {
                this.onShowPropChanged(val);
            },
        },

        methods: {
            onShowPropChanged(value) {
                this.dialogVisible = value;
            },
            onSubmit(e) {
                e.preventDefault();
                this.saveDialog();
            },

            closeDialog() {
                this.$refs.form.reset();
                this.inlineMessage.message = null;
                this.dialogVisible = false;
                this.$emit("update:show", false);
            },

            // Method used in conjunction with the Cancel dialog.
            cancelDialog() {
                this.closeDialog();
            },

            //Method used in conjunction with new view dialog.
            async saveDialog () {
                // First reset the inline message if there are any.
                this.inlineMessage.message = '';

                if (!this.$refs.form.validate()) {
                    return;
                }

                this.processing = true;
                try {
                    await this.addSupplier({
                        ...this.supplier,
                        enabled: true,
                        tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
                    });
                    this.closeDialog();
                } catch (error) {
                    this.inlineMessage.message = this.$t("suppliers.save-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            ...mapActions({
                addSupplier: 'ADD_SUPPLIER',
                loadTags: 'LOAD_TAGS',
            }),
        },

        created: async function () {
            this.processing = true;

            try {
                await Promise.all([
                    this.loadTags()
                ]);
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        }
    }
</script>
