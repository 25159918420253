var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: { value: _vm.value },
          on: {
            input: function(v) {
              return _vm.$emit("input", v)
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.displayCount
                ? {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            {
                                              staticClass: "fd-counter-select"
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-badge",
                                            {
                                              class: [
                                                item[_vm.itemCount] > 0
                                                  ? ""
                                                  : "fd-counter-zero",
                                                "fd-counter-select"
                                              ],
                                              attrs: {
                                                content:
                                                  "" +
                                                  (!item[_vm.itemCount]
                                                    ? 0
                                                    : item[_vm.itemCount])
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n        " +
                                                  _vm._s(
                                                    item[
                                                      _vm.$attrs["item-text"]
                                                    ]
                                                  ) +
                                                  "\n      "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        )
                      ]
                    }
                  }
                : null
            ],
            null,
            true
          )
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }