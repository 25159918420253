import FDVue from "@fd/lib/vue";
import {
  canOpenFileInNewWindow,
  FileData,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { Attachment } from "../../dataMixins/attachment";
import {
  CountSheetReviewStatus,
  CountSheetType,
  WalkdownStatuses,
  WorkOrderStatuses,
  WorkOrderWithLegacyDetails
} from "../../services";

export type ScreenContext = "workOrder" | "walkdown";
export type ToDoListItem = {
  // Computed properties unique to this screen
  initialProgress: number; // Will be used to limit available Progress % options for a work order, allowing the user to change the percentage and then change it back in the same session
  order: number; // Allows the vertical re-ordering of cards within their same column
  type: ScreenContext; // Liste item type: Either work order or walkdown
  photos: FileData[] | undefined;
  nonPhotoFiles: FileData[] | undefined;
  attachments: Attachment[] | undefined;
  fullAreaName: string | undefined;
};
export type FormattedWorkOrder = WorkOrderWithLegacyDetails &
  ToDoListItem & { workPackageNames: string[] | undefined };

function compareFormattedWorkOrders(a: FormattedWorkOrder, b: FormattedWorkOrder): number {
  if (a.order != b.order) return a.order - b.order;

  var aPriority = !!a.priority && a.priority > 0 ? a.priority : 99;
  if (a.isUrgent) aPriority = 0;
  var bPriority = !!b.priority && b.priority > 0 ? b.priority : 99;
  if (b.isUrgent) bPriority = 0;
  if (aPriority != bPriority) return aPriority - bPriority;

  var aScaffold = Number(a.scaffoldNumber) ?? 0;
  var bScaffold = Number(b.scaffoldNumber) ?? 0;
  if (aScaffold != bScaffold) return aScaffold - bScaffold;

  return 0;
}
export function sortFormattedWorkOrderArray<T extends FormattedWorkOrder>(workOrders: T[]): T[] {
  return workOrders.sort(compareFormattedWorkOrders);
}
export function JoinNameValues(
  firstName: string | null | undefined,
  secondName: string | null | undefined,
  separator: string = " "
) {
  var names = [];
  if (!!firstName) {
    names.push(firstName);
  }
  if (!!secondName) {
    names.push(secondName);
  }
  return names.join(separator);
}
export function FormattedWorkOrderFromWorkOrderWithLegacyDetails(
  workOrder: WorkOrderWithLegacyDetails
): FormattedWorkOrder {
  let files = workOrder.fileNames.map(
    fileName =>
      ({
        name: fileName,
        isPreviewable: isFilePreviewable(fileName),
        isPhoto: isFilePhoto(fileName)
      } as FileData)
  );
  let photos = files.filter(x => x.isPhoto == true);
  let nonPhotoFiles = files.filter(x => !x.isPhoto);

  // Attachments
  let nonPhotoAttachments = nonPhotoFiles.map(
    file =>
      ({
        type: "file",
        name: file.name,
        isPhoto: file.isPreviewable ?? false,
        isPreviewable: file.isPreviewable ?? false,
        canOpenInNew: canOpenFileInNewWindow(file.name),
        file: file
      } as Attachment)
  );
  let linkAttachments = workOrder.externalLinks.map(
    link =>
      ({
        type: "link",
        name: link.name!,
        isPhoto: false,
        isPreviewable: false,
        canOpenInNew: true,
        link: link
      } as Attachment)
  );
  let allAttachments = nonPhotoAttachments.concat(linkAttachments);
  return {
    ...workOrder,
    type:
      workOrder.workOrderStatus == WorkOrderStatuses.Walkdown ||
      workOrder.workOrderStatus == WorkOrderStatuses.Estimated
        ? "walkdown"
        : "workOrder",
    workPackageNames: workOrder.workPackages?.map(
      x => (x.name ?? "") + " | " + (x.activityID ?? "")
    ),
    progress: !!workOrder.progress
      ? Math.max(Math.min(100, Math.round(workOrder.progress / 5) * 5), 0)
      : 0, // The progress picker is in increments of 5 so round to the nearest 5
    initialProgress: workOrder.progress ?? 0,
    priority: !!workOrder.priority && workOrder.priority > 0 ? Math.min(workOrder.priority, 5) : 5,
    siteContact: workOrder.siteContact?.trim(),
    specificWorkLocation: workOrder.specificWorkLocation?.trim(),
    detailedWorkDescription: workOrder.detailedWorkDescription?.trim(),
    photos: photos,
    nonPhotoFiles: nonPhotoFiles,
    attachments: allAttachments,
    fullAreaName: JoinNameValues(workOrder.areaName, workOrder.subAreaName, " - ")
  } as FormattedWorkOrder;
}

export default FDVue.extend({
  name: "fd-kanban-item",
  components: {
    "fd-kanban-item-value-display": () => import("./KanbanItemValueDisplay.vue"),
    "fd-kanban-item-expander-details-item": () => import("./KanbanItemExpanderDetailsItem.vue")
  },
  props: {
    item: { type: Object, default: undefined },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inProgress: { type: Boolean, default: false }
  },
  data: function() {
    return {
      inProgressValues: Array.from(Array(101).keys())
        .filter(x => x % 5 == 0)
        .map(x => ({
          text: x,
          value: x,
          // disabled: x < item.initialProgress
          disabled: x == 0
        }))
    };
  },
  computed: {
    statusClass(): string {
      if (this.itemIsCancelled) return "fd-kanban-item-cancelled";
      else if (this.itemIsDone) return "fd-kanban-item-done";
      else if (this.itemIsDeclinedWalkdown) return "fd-drag-item-on-hold";
      else if (this.itemIsOnHoldWorkOrder) return "fd-drag-item-on-hold";
      else if (this.item.isUrgent) return "fd-drag-item-urgent";
      else if (this.inProgress) return "fd-kanban-item--inprogress";
      else return "fd-kanban-item--todo";
    },
    itemIsDeclinedWalkdown(): boolean {
      return (
        this.item.type == "walkdown" &&
        this.item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        !!this.item.walkdown &&
        this.item.walkdown.walkdownStatus == WalkdownStatuses.Declined
      );
    },
    itemIsCancelledWorkOrder(): boolean {
      return (
        this.item.type == "workOrder" && this.item.workOrderStatus == WorkOrderStatuses.Cancelled
      );
    },
    itemIsCancelledWalkdown(): boolean {
      return (
        !!this.item.walkdown && this.item.walkdown.walkdownStatus == WalkdownStatuses.Cancelled
      );
    },
    itemIsCancelled(): boolean {
      return this.itemIsCancelledWalkdown || this.itemIsCancelledWorkOrder;
    },
    itemIsStartedWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "workOrder" && toDoItem.workOrderStatus == WorkOrderStatuses.Started;
    },
    itemIsOnHoldWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "workOrder" && toDoItem.workOrderStatus == WorkOrderStatuses.OnHold;
    },
    itemIsCompletedWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return (
        toDoItem.type == "workOrder" &&
        (toDoItem.workOrderStatus == WorkOrderStatuses.Completed ||
          toDoItem.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration)
      );
    },
    itemIsCompletedWalkdown(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "walkdown" && toDoItem.workOrderStatus == WorkOrderStatuses.Estimated;
    },
    itemIsDone(): boolean {
      return this.itemIsCancelled || this.itemIsCompletedWorkOrder || this.itemIsCompletedWalkdown;
    },
    itemNeedsCallout(): boolean {
      return this.itemIsOnHoldWorkOrder || this.itemIsDeclinedWalkdown;
    },
    itemNeedsStatusCallout(): boolean {
      return (
        this.itemIsCancelled ||
        this.itemIsOnHoldWorkOrder ||
        this.itemIsDeclinedWalkdown ||
        this.item.isUrgent
      );
    },

    itemNeedsAction(): boolean {
      return this.requiresCountSheetEntry || this.requiresManHoursEntry;
    },

    itemMissingData(): boolean {
      return this.missingCountSheetEntry || this.missingManHoursEntry;
    },

    requiresCountSheetEntry(): boolean {
      return this.itemIsCompletedWorkOrder || this.countSheetIsDeclined;
    },

    missingCountSheetEntry(): boolean {
      return (
        this.item.type == "workOrder" &&
        (!this.item.countSheet || this.countSheetIsDraft || this.countSheetIsDeclined)
      );
    },
    countSheetIsDraft(): boolean {
      return (
        !(this.item as WorkOrderWithLegacyDetails).countSheet ||
        (this.item as WorkOrderWithLegacyDetails).countSheet.reviewStatusID ==
          CountSheetReviewStatus.Draft
      );
    },
    countSheetIsDeclined(): boolean {
      return (
        !!(this.item as WorkOrderWithLegacyDetails).countSheet &&
        (this.item as WorkOrderWithLegacyDetails).countSheet.reviewStatusID ==
          CountSheetReviewStatus.Declined
      );
    },

    requiresManHoursEntry(): boolean {
      return false;
    },

    missingManHoursEntry(): boolean {
      return false;
    },

    cardTitle(): String {
      let workOrderID = this.item.legacyID ?? this.item.walkdown?.legacyWorkOrderID;
      let workOrderNumber = this.$t("to-do-list.work-order-number", [workOrderID]);
      let tagNumber = this.$t("to-do-list.tag-number", [this.item.scaffoldNumber]);

      if (this.item.type == "workOrder") {
        let progress = this.inProgress ? ` (${this.item.progress}%)` : "";
        return `${workOrderNumber} - ${tagNumber}${progress}`;
      } else if (this.item.type == "walkdown") {
        return `${workOrderNumber} - ${tagNumber}`;
      }
      return "";
    },

    cardTitleShort(): String {
      let workOrderID = this.item.legacyID ?? this.item.walkdown?.legacyWorkOrderID;
      let workOrderNumber = this.$t("to-do-list.work-order-number-short", [workOrderID]);
      let tagNumber = this.$t("to-do-list.tag-number-short", [this.item.scaffoldNumber]);

      if (this.item.type == "workOrder") {
        let progress = this.inProgress ? ` (${this.item.progress}%)` : "";
        return `${workOrderNumber} - ${tagNumber}${progress}`;
      } else if (this.item.type == "walkdown") {
        return `${workOrderNumber} - ${tagNumber}`;
      }
      return "";
    },

    statusIndicatorText(): String {
      if (this.itemIsCancelled)
        return `${this.$t("to-do-list.cancelled-label-caps")}`.toUpperCase();
      else if (this.itemIsOnHoldWorkOrder)
        return `${this.$t("to-do-list.on-hold-label-caps")}`.toUpperCase();
      else if (this.itemIsDeclinedWalkdown)
        return `${this.$t("to-do-list.declined-label-caps")}`.toUpperCase();
      else if (this.item.isUrgent)
        return `${this.$t("to-do-list.hot-job-label-caps")}`.toUpperCase();

      return "";
    }
  }
});

