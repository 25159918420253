var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", staticStyle: { "max-width": "100%" } },
    [
      _c(
        "v-list",
        { staticClass: "mobilemoremenu", attrs: { dense: "" } },
        [
          _vm._l(_vm.accessibleSidebarMenuItems, function(item) {
            return [
              item.children
                ? _c(
                    "v-list-group",
                    {
                      key: _vm.$t(item.text),
                      attrs: {
                        "prepend-icon": item.icon,
                        "append-icon": item.model
                          ? item["sub-menu-icon-alt"]
                          : item["sub-menu-icon-alt"]
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t(item.text)) +
                                          "\n            "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: item.model,
                        callback: function($$v) {
                          _vm.$set(item, "model", $$v)
                        },
                        expression: "item.model"
                      }
                    },
                    [
                      _vm._v(" "),
                      _vm._l(item.children, function(child, i) {
                        return _c(
                          "v-list-item",
                          {
                            key: i,
                            staticClass: "drawer-sub-menu",
                            attrs: { link: "", to: child.to }
                          },
                          [
                            child.icon
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v(_vm._s(child.icon))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t(child.text)) +
                                      "\n            "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : (_vm.$vuetify.breakpoint.xsOnly &&
                    item.showInBottomBarIfPossible) ||
                  !item.showInBottomBarIfPossible
                ? _c(
                    "v-list-item",
                    { key: item.text, attrs: { link: "", to: item.to } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t(item.text)) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              on: {
                click: function($event) {
                  return _vm.setPrivacyDialog(!_vm.dialogPrivacy)
                }
              }
            },
            [
              _c(
                "v-list-item-action",
                [_c("v-icon", [_vm._v("fas fa-eye-slash")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("menu.privacypolicy")) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              on: {
                click: function($event) {
                  return _vm.setTermsDialog(!_vm.dialogTerms)
                }
              }
            },
            [
              _c(
                "v-list-item-action",
                [_c("v-icon", [_vm._v("fas fa-file-alt")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("menu.termsandconditions")) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        { attrs: { padless: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "text-right fd-version-footer",
              attrs: { flat: "", tile: "", width: "100%" }
            },
            [
              _c("v-card-text", { staticClass: "white--text" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("common.version") +
                        " " +
                        _vm.$store.getters.currentVersion
                    )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("fd-privacy-dialog", { staticClass: "hide-when-mobile-phone" }),
      _vm._v(" "),
      _c("fd-terms-dialog", { staticClass: "hide-when-mobile-phone" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }