var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "transferexisting" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/transfers/"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0 pt-1", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-5 pb-1",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          !_vm.unlocked
                            ? _c("fd-text-field", {
                                staticClass: "fd-remove-messages",
                                attrs: {
                                  dense: "",
                                  label: _vm.$t(
                                    "transfers.transfer-date-label"
                                  ),
                                  value: _vm.transfer.formattedTransferDate,
                                  disabled: _vm.processing,
                                  readonly: true
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.unlocked
                            ? _c("fd-date-picker", {
                                attrs: {
                                  label: _vm.$t(
                                    "transfers.transfer-date-label"
                                  ),
                                  disabled: _vm.processing,
                                  readonly: !_vm.unlocked,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.transfer.transferDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.transfer, "transferDate", $$v)
                                  },
                                  expression: "transfer.transferDate"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-5 pb-1",
                          attrs: { align: "center", cols: "12", sm: "6" }
                        },
                        [
                          _c("fd-text-field", {
                            class: { "fd-remove-messages": !_vm.unlocked },
                            attrs: {
                              dense: !_vm.unlocked,
                              label: _vm.$t(
                                "transfers.transaction-number-label"
                              ),
                              disabled: _vm.processing,
                              readonly: !_vm.unlocked
                            },
                            model: {
                              value: _vm.transfer.transactionNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.transfer, "transactionNumber", $$v)
                              },
                              expression: "transfer.transactionNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.directionIsScaffoldReturn
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                class: { "fd-remove-messages": !_vm.unlocked },
                                attrs: {
                                  dense: !_vm.unlocked,
                                  label: _vm.$t(
                                    "transfers.from-scaffold-label"
                                  ),
                                  value: _vm.transfer.fromScaffoldLegacyID,
                                  disabled: _vm.processing,
                                  readonly: true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.directionIsScaffoldDelivery ||
                      _vm.directionIsYardTransfer
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("v-select", {
                                class: {
                                  "fd-remove-messages": !_vm.unlocked,
                                  "fd-readonly": !_vm.unlocked
                                },
                                attrs: {
                                  outlined: "",
                                  dense: !_vm.unlocked,
                                  items: _vm.fromYards,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t("transfers.from-yard-label"),
                                  clearable: _vm.unlocked,
                                  disabled: _vm.processing,
                                  readonly: !_vm.unlocked,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.transfer.fromYardID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.transfer, "fromYardID", $$v)
                                  },
                                  expression: "transfer.fromYardID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.directionIsScaffoldDelivery
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                class: { "fd-remove-messages": !_vm.unlocked },
                                attrs: {
                                  dense: !_vm.unlocked,
                                  label: _vm.$t("transfers.to-scaffold-label"),
                                  value: _vm.transfer.toScaffoldLegacyID,
                                  disabled: _vm.processing,
                                  readonly: true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.directionIsScaffoldReturn ||
                      _vm.directionIsYardTransfer
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("v-select", {
                                class: {
                                  "fd-remove-messages": !_vm.unlocked,
                                  "fd-readonly": !_vm.unlocked
                                },
                                attrs: {
                                  outlined: "",
                                  dense: !_vm.unlocked,
                                  items: _vm.toYards,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t("transfers.to-yard-label"),
                                  clearable: _vm.unlocked,
                                  disabled: _vm.processing,
                                  readonly: !_vm.unlocked,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.transfer.toYardID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.transfer, "toYardID", $$v)
                                  },
                                  expression: "transfer.toYardID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panels",
                    {
                      staticStyle: { "border-radius": "0" },
                      attrs: { multiple: "", accordion: "" },
                      model: {
                        value: _vm.openPanels,
                        callback: function($$v) {
                          _vm.openPanels = $$v
                        },
                        expression: "openPanels"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fd-count-sheet-group-panel-summary" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticStyle: { "padding-left": "24px !important" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "countsheet.dialog.summary-panel-title"
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              !_vm.partsForSummary ||
                              _vm.partsForSummary.length == 0
                                ? _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                "justify-content": "center"
                                              },
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c("fd-alert", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "transfers.summary-has-no-parts-message"
                                                  ),
                                                  type: "hint"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.partsForSummary, function(part) {
                                return _c(
                                  "v-row",
                                  {
                                    key: part.id,
                                    staticClass: "fd-part-row",
                                    class: {
                                      "fd-overridden-part": part.overridden
                                    },
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "fd-group-part-content" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-1",
                                            attrs: { cols: "8" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    part.overridden
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fd-count-sheet-part-status-badge pr-2",
                                                            staticStyle: {
                                                              display:
                                                                "table-cell"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "fd-progress-badge",
                                                              {
                                                                attrs: {
                                                                  edited:
                                                                    part.overridden
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "table-cell"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fd-count-sheet-part-code-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  part.publicID
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fd-count-sheet-part-description-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  part.description
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        !!part.groupName
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-part-group-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      part.groupName.toUpperCase()
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pl-0 py-1",
                                            attrs: { cols: "4" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: { cols: "4" }
                                                  },
                                                  [
                                                    _c("fd-text-field", {
                                                      staticClass:
                                                        "fd-count-sheet-text-field",
                                                      attrs: {
                                                        "data-cy":
                                                          "summaryassigned",
                                                        dense: "",
                                                        "hide-details": "",
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true,
                                                        label: _vm.$t(
                                                          "transfers.assigned-label"
                                                        ),
                                                        numeric: "",
                                                        "highlight-on-focus": ""
                                                      },
                                                      model: {
                                                        value: part.assigned,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            part,
                                                            "assigned",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "part.assigned"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: { cols: "4" }
                                                  },
                                                  [
                                                    !part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summarycount",
                                                            dense: "",
                                                            "hide-details": "",
                                                            disabled:
                                                              _vm.processing ||
                                                              (_vm.directionIsScaffoldReturn &&
                                                                part.assigned ==
                                                                  0),
                                                            readonly: !_vm.unlocked,
                                                            label: _vm.$t(
                                                              "transfers.count-label"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partCountValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: part.count,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "count",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.count"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-overridden-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summarycountoverride",
                                                            dense: "",
                                                            hint: _vm.$t(
                                                              "transfers.original-value",
                                                              [
                                                                part.originalCount
                                                              ]
                                                            ),
                                                            "persistent-hint":
                                                              "",
                                                            disabled:
                                                              _vm.processing ||
                                                              (_vm.directionIsScaffoldReturn &&
                                                                part.assigned ==
                                                                  0),
                                                            readonly: !_vm.unlocked,
                                                            label: _vm.$t(
                                                              "transfers.count-override-label"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partCountValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: part.count,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "count",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.count"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: { cols: "4" }
                                                  },
                                                  [
                                                    !part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summarytotal",
                                                            value: part.total,
                                                            dense: "",
                                                            "hide-details": "",
                                                            disabled:
                                                              _vm.processing,
                                                            readonly: true,
                                                            label: _vm.$t(
                                                              "transfers.total-label"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            dense: "",
                                                            value: part.total,
                                                            hint: _vm.$t(
                                                              "transfers.original-value",
                                                              [
                                                                part.originalTotal
                                                              ]
                                                            ),
                                                            "persistent-hint":
                                                              "",
                                                            label: _vm.$t(
                                                              "transfers.total-override-label"
                                                            ),
                                                            readonly: true
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.visibleCountSheetGroups, function(
                        group,
                        index
                      ) {
                        return _c(
                          "v-expansion-panel",
                          {
                            key: index,
                            staticClass: "fd-count-sheet-group-panel"
                          },
                          [
                            _c("v-expansion-panel-header", [
                              _vm._v(" " + _vm._s(group.name))
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-expansion-panel-content",
                              [
                                !group.parts || group.parts.length == 0
                                  ? _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  "justify-content": "center"
                                                },
                                                attrs: { "no-gutters": "" }
                                              },
                                              [
                                                _c("fd-alert", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "countsheet.dialog.group-has-no-parts-message"
                                                    ),
                                                    type: "hint"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(group.sortedParts, function(part) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: part.id,
                                      staticClass: "fd-part-row",
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fd-group-part-content"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { cols: "8" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      part.overridden
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-count-sheet-part-status-badge pr-2",
                                                              staticStyle: {
                                                                display:
                                                                  "table-cell"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-progress-badge",
                                                                {
                                                                  attrs: {
                                                                    edited:
                                                                      part.overridden
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "table-cell"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-count-sheet-part-code-label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    part.publicID
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-count-sheet-part-description-label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    part.description
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pl-0 py-1",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: { cols: "4" }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          "data-cy": "assigned",
                                                          dense: "",
                                                          "hide-details": "",
                                                          disabled:
                                                            _vm.processing,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "transfers.assigned-label"
                                                          ),
                                                          numeric: "",
                                                          "highlight-on-focus":
                                                            ""
                                                        },
                                                        model: {
                                                          value: part.assigned,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              part,
                                                              "assigned",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "part.assigned"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: { cols: "4" }
                                                    },
                                                    [
                                                      !part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "count",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "count",
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                (_vm.directionIsScaffoldReturn &&
                                                                  part.assigned ==
                                                                    0),
                                                              readonly: !_vm.unlocked,
                                                              label: _vm.$t(
                                                                "transfers.count-label"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partCountValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value: part.count,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "count",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.count"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "count",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-overridden-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "countoverride",
                                                              dense: "",
                                                              hint: _vm.$t(
                                                                "transfers.original-value",
                                                                [
                                                                  part.originalCount
                                                                ]
                                                              ),
                                                              "persistent-hint":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                (_vm.directionIsScaffoldReturn &&
                                                                  part.assigned ==
                                                                    0),
                                                              readonly: !_vm.unlocked,
                                                              label: _vm.$t(
                                                                "transfers.count-override-label"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "count",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partCountValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value: part.count,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "count",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.count"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: { cols: "4" }
                                                    },
                                                    [
                                                      !part.overridden
                                                        ? _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "total",
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                _vm.processing,
                                                              readonly: true,
                                                              label: _vm.$t(
                                                                "transfers.total-label"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            model: {
                                                              value: part.total,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "total",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.total"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      part.overridden
                                                        ? _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "totaloverride",
                                                              dense: "",
                                                              value: part.total,
                                                              hint: _vm.$t(
                                                                "transfers.original-value",
                                                                [
                                                                  part.originalTotal
                                                                ]
                                                              ),
                                                              "persistent-hint":
                                                                "",
                                                              label: _vm.$t(
                                                                "transfers.total-override-label"
                                                              ),
                                                              readonly: true
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.transfer.currentUserPermissions
                        .canOverrideTransferValues
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled:
                                  _vm.processing ||
                                  !_vm.transfer.currentUserPermissions
                                    .canOverrideTransferValues ||
                                  _vm.unlocked
                              },
                              on: { click: _vm.toggleUnlocked }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("transfers.existing.unlock"))
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            link: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.transfer.currentUserPermissions
                        .canOverrideTransferValues
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-cy": "save",
                                color: "primary",
                                loading: _vm.saving,
                                disabled:
                                  _vm.processing ||
                                  !_vm.unlocked ||
                                  !_vm.transfer.currentUserPermissions
                                    .canOverrideTransferValues,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.save }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.save")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }