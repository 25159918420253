import FDVue from "@fd/lib/vue";
import workPackageModel from "../../dataMixins/workPackageModel";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import store from "../../store";
import { VForm } from "@fd/lib/vue/types";
import { workPackageService } from "../../services";
import { json } from "express";

export default FDVue.extend({
  mixins: [errorHandling, workPackageModel],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-date-picker": () => import("@fd/lib/vue/components/DatePicker.vue")
  },

  data: () => ({
    processing: false,

    statusItems: [] as string[]
  }),

  props: {
    closeAction: Function
  },

  computed: {},

  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    }
  },

  methods: {
    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      if (this.workPackage.completionPercentage)
        this.workPackage.completionPercentage = +this.workPackage.completionPercentage!;
      console.log(JSON.stringify(this.workPackage));
      await store.dispatch("ADD_WORK_PACKAGE", {
        ...this.workPackage,
        enabled: true
      });

      this.$props.closeAction(true);
    }
  },

  created: async function() {
    this.processing = true;
    try {
      this.statusItems = await workPackageService.getUsedWorkStatuses();
    } catch {
    } finally {
      this.processing = false;
    }
  }
});
