import { Person, PersonWithDetails } from "../services";

export type HasContractorName = { contractorName: string };
export type PersonWithDetailsAndName = PersonWithDetails & { name: string };
export type PersonWithName = Person & { name: string };
export function GetPersonName(item: {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}) {
  var names = [];
  if (!!item.firstName) {
    names.push(item.firstName);
  }
  if (!!item.lastName) {
    names.push(item.lastName);
  }
  return names.join(" ");
}

