var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "max-height": "100%" },
      attrs: {
        bottom: "",
        "mobile-breakpoint": "99999",
        absolute: "",
        height: _vm.height + "px"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-sheet",
        { attrs: { height: _vm.height + "px" } },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-card",
                {
                  staticClass: "bottom-sheet-control",
                  attrs: {
                    flat: "",
                    tile: "",
                    "data-cy": "itemselectiondialog"
                  }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      class: [
                        _vm.processing
                          ? "dialog-new-top-color-processing"
                          : "dialog-new-top-color"
                      ]
                    },
                    [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.processing
                              ? "breadcrumb-processing-opacity"
                              : ""
                          ]
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.processing
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: true,
                              rotate: 0,
                              size: 32,
                              width: 4,
                              color: "white"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pb-1 px-0 mx-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  "data-cy": "itemselect",
                                  label: _vm.fieldLabel,
                                  items: _vm.items,
                                  "item-text": _vm.itemText,
                                  "item-value": _vm.itemValue,
                                  rules: _vm.rules,
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.selectedValue,
                                  callback: function($$v) {
                                    _vm.selectedValue = $$v
                                  },
                                  expression: "selectedValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "accept",
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.acceptDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }