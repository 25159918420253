import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import userAccess from "../dataMixins/userAccess";
import { mapActions, mapMutations } from "vuex";
import { DropoffLocation } from "../services";
import rules from "@fd/lib/vue/rules";

type DropoffLocationWithArchived = DropoffLocation & { isArchived: boolean };

export default FDVue.extend({
  name: "fd-dropoff-location-existing",

  mixins: [userAccess, serviceErrorHandling, rules],

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      dropoffLocation: {} as DropoffLocationWithArchived,

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      slidein: false
    };
  },

  computed: {
    dropoffLocationRules() {
      return {
        name: [this.rules.required]
      };
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadDropoffLocation: "LOAD_DROPOFF_LOCATION",
      updateDropoffLocation: "UPDATE_DROPOFF_LOCATION",
      deleteDropoffLocation: "DELETE_DROPOFF_LOCATION"
    }),
    cancel() {
      this.$router.push("/dropofflocations");
    },
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.dropoffLocation.isArchived && !this.dropoffLocation.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.dropoffLocation.archivedDate = archivedDate;

        await this.updateDropoffLocation(this.dropoffLocation);
        this.$router.push("/dropofflocations");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteDropoffLocation({
          id: this.$route.params.id,
          name: this.dropoffLocation.name
        });
        this.$router.push("/dropofflocations");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    }
  },

  watch: {
    dropoffLocation() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/dropofflocations") {
        this.notifyNewBreadcrumb({
          text: this.$t("dropoff-locations.list.title"),
          to: "/dropofflocations",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.dropoffLocation.name,
        to: `/dropofflocations/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);
    this.processing = true;
    try {
      await Promise.all([this.loadDropoffLocation(this.$route.params.id)]);
      var storeDropoffLocations = this.$store.state.dropoffLocations.fullList as DropoffLocation[];
      let dropoffLocation = storeDropoffLocations.find((x: any) => x.id == this.$route.params.id);
      if (!!dropoffLocation) {
        this.dropoffLocation = {
          ...dropoffLocation,
          isArchived: !!dropoffLocation.archivedDate,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        };
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

